import styled from 'styled-components';

export const Wrapper = styled.div`
    overflow-y: scroll;
    position:relative;
    flex:1;
    border:1px solid #e0e0e0;
    border-radius: 15px;
    background: #fff;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    padding: 20px;
    height: 500px;
    box-sizing: border-box;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }
    
    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }

    @media all and (max-width:992px){
        height: 300px;
        flex:none;
    }

    .empty-box{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color:#303030;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }
`;

export const InfoWrapper = styled.div`
    &>.info-group:nth-child(1){
        margin-top: 0;
    }

    .info-group{
        display: flex;
        margin-top: 20px;
    }

    .info-box{
        flex:1
    }

    .info-box>.label{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 11px;
        }
    }

    .info-box>.info-el{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }

        .link{
            color:#606060;
        }
    }

    .button-group{
        display: flex;
        justify-content: flex-end;
        margin-top:20px;

    }

    .button-group>.button-el{
        margin:0;
        padding:0;
        height: 38px;
        width:110px;
        border: none;
        background: #f0f0f0;
        color:#808080;
        font-size: 14px;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

        margin-left: 5px;
        border-radius: 10px;

        &:first-child{
            margin-left: 0;
        }

        &:hover{
            background: #eaeaea;
        }
        @media all and (max-width:992px){
            flex:1;
            font-size: 12px;
        }
    }
`;

export const ProductListWrapper = styled.div`
    .total-price-box{
        margin-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-weight: 600;
        color:#b39283;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .product-box{
        display: flex;
        padding: 10px 0;
    }

    .thumbnail-box{
        overflow: hidden;
        width: 100px;
        border-radius: 10px;
        border: 1px solid #e0e0e0;

        @media all and (max-width:992px){
            width: 80px;
        }
    }

    .thumbnail-figure{
        position:relative;
        padding-bottom: 100%;
    }

    .thumbnail-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .product-info-box{
        flex:1;
        margin-left: 10px;
    }

    .product-info-box>.status-badge{
        padding:3px 0;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        color: #2c73d2;

        @media all and (max-width:992px){
            font-size: 11px;
        }
    }

    .product-info-box>.title{
        margin-top: 5px;
        font-size: 14px;
        font-weight: 600;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .product-info-box>.count{
        font-size: 14px;
        font-weight: 500;
        color:#808080;
        margin-top: 5px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.discount{
        font-size: 14px;
        font-weight: 500;
        color:#e56767;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.totalPrice{
        font-size: 14px;
        font-weight: 500;
        color:#808080;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;