import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userDataConnect } from '../../data_connect/userDataConnect';
import { useCustomRouterHook } from '../../hooks/router/useCustomRouterHook';
import { SwipeableDrawer } from '@mui/material';
import { useCartListLocalStorage } from 'hooks/useCartListLocalStorage';
import valueUtils from 'utils/valueUtils';
import { CustomImage } from 'components/image/custom_image';

const Container = styled.div`
    position: relative;
    z-index: 99;
    max-width: 1800px;
    margin: 0 auto;
`;

const FirstContainer = styled.div`
    overflow: hidden;
    position:relative;
    display: flex;
    align-items: center;
    
    padding:0 40px;
    box-sizing: border-box;
    background: #fff;
    height:84px;
    justify-content: space-between;

    @media all and (max-width:992px){
        padding:0 20px;
        height:64px;
    }
`;

const MenuWrapper = styled.div`
    width:350px;
    @media all and (max-width:992px){
        width:280px;
    }
`;

const LogoLink = styled(Link)`
    text-decoration: none;
    color:#ffffff;
    -webkit-tap-highlight-color: #00000000;
    color: #000000;
    width:70px;

    &:hover{
        color:#ffffff80;
    }

    @media all and (max-width:992px){
        width:55px;
    }
    

    .logo-figure{
        transition:all .3s;
        &:hover{
            scale: 1.1;
        }
    }

`;

const UserWrapper = styled.div`
    user-select: none;
    
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;

    .item-group{
        display: flex;
    }

    .item-box{
        flex:1;
        text-align: center;
        padding: 15px 0;
        letter-spacing: 0.1em;
    }

    .item-link{
        position:relative;
        text-decoration: none;
        color:#404040;
        -webkit-tap-highlight-color: #00000000;

        &:hover{
            color:#40404080;
        }
    }

    .item-button-box{
        padding: 15px 25px;
    }
    
    
    .item-button-link{
        margin:0;
        padding:0;
        height: 43px;
        border-radius: 5px;
        letter-spacing: 0.1em;

        &:hover{
            color:#40404080;
        }
    }

    .logout-button{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        text-decoration: none;
        color:#404040;
        cursor: pointer;

        &:hover{
            color:#40404080;
        }
    }
`;

const ItemWrapper = styled.div`
    user-select: none;
    -webkit-tap-highlight-color: #00000000;

    &>.item-box:nth-child(1){
        padding-top:30px;
    }

    .item-box{
        padding:15px 25px;
    }

    .item-link{
        text-decoration: none;
        color:#404040;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.1em;

        &:hover{
            color:#40404080;
        }

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .cart-size-badge{
        color:#e56767;
        font-size:12px;
        vertical-align:top;
    }
`;

const MenuButton = styled.button`
    position:relative;
    user-select: none;
    -webkit-tap-highlight-color: #00000000;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    transition: all .3s;

    &:hover{
        /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
        box-shadow: var(--defaultBoxShadow2);
        background: #f0f0f0;
        scale: 1.2;
    }

    .icon{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:70%;
        height:70%;
    }
`;

export default function NavbarMain() {
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook();
    const [menuOpen, setMenuOpen] = useState(false);
    const [cartList, setCartList] = useCartListLocalStorage();

    useEffect(() => {
        setMenuOpen(false);
    }, [customRouter.location]);


    const __handle = {
        action: {
            openMenu: () => {
                setMenuOpen(true);
            },
            closeMenu: () => {
                setMenuOpen(false);
            },
            routeToPath: (pathname) => {
                customRouter.push({
                    pathname: pathname
                })
            }
        }
    }

    const __userRdx = {
        req: {
            logout: async () => {
                await userDataConnect().logout()
                    .finally(() => {
                        customRouter.push({
                            pathname: '/',
                            replace: true
                        })
                    })
            }
        },
        submit: {
            logout: async () => {
                await __userRdx.req.logout();
            }
        }
    }

    return (
        <>
            <Container>
                <FirstContainer>
                    <LogoLink
                        to={'/'}
                        replace={false}
                    >
                        <CustomImage
                            className='logo-figure'
                            width={91}
                            height={64}
                        >
                            <CustomImage.Image
                                src={'/assets/images/campal_logo_only_91x64.png'}
                                alt={'캠핑렌탈 플랫폼 캠팔'}
                            />
                        </CustomImage>
                    </LogoLink>
                    <MenuButton
                        type='button'
                        onClick={__handle.action.openMenu}
                    >
                        <img
                            className='icon'
                            src='/assets/icon/menu_default_.svg'
                            alt='menu'
                        ></img>
                    </MenuButton>
                </FirstContainer>
            </Container>

            <SwipeableDrawer
                anchor={'right'}
                open={menuOpen}
                onClose={__handle.action.closeMenu}
                onOpen={__handle.action.openMenu}
            >
                <MenuWrapper>
                    {!userRdx.userInfo &&
                        <UserWrapper>
                            <div className='item-group'>
                                <div className='item-box'>
                                    <Link
                                        className='item-link'
                                        to={'/login'}
                                    >
                                        로그인
                                    </Link>
                                </div>
                                <div className='item-box'>
                                    <Link
                                        className='item-link'
                                        to={'/signup'}
                                    >
                                        회원가입
                                    </Link>
                                </div>
                            </div>
                        </UserWrapper>
                    }
                    {userRdx.userInfo &&
                        <UserWrapper>
                            <div className='item-group'>
                                <div className='item-box'>
                                    <Link
                                        className='item-link'
                                        to='/myaccount'
                                    >
                                        내정보
                                    </Link>
                                </div>
                                <div className='item-box'>
                                    <div
                                        className='logout-button'
                                        onClick={__userRdx.submit.logout}
                                    >로그아웃</div>
                                </div>
                            </div>
                        </UserWrapper>
                    }

                    <ItemWrapper>
                        <div
                            className='item-box'
                        >
                            <Link
                                className='item-link'
                                to='/search/order'
                            >
                                주문내역조회
                            </Link>
                        </div>
                        <div
                            className='item-box'
                        >
                            <Link
                                className='item-link'
                                to='/cart'
                            >
                                장바구니{!valueUtils.isEmptyValues(cartList) && <span className='cart-size-badge'>{cartList.length}</span>}
                            </Link>
                        </div>
                        <div
                            className='item-box'
                        >
                            <Link
                                className='item-link'
                                to='/myadmin'
                            >
                                마이룸
                            </Link>
                        </div>
                    </ItemWrapper>
                </MenuWrapper>
            </SwipeableDrawer>
        </>
    );
}