import FloatOrderCalc from "views/myadmin/fragments/float-order-calc";
import { useEffect } from "react";
import CustomerListFieldComponent from "./customer-list-field/CustomerListField.component";
import { useRentalOrderInfo, useSelectedRentalOrderInfo } from "./hooks/rentalOrderInfoHooks";
import MainLayout from "./layout/MainLayout";
import ProductListFieldComponent from "./product-list-field/ProductListField.component";
import TopControlFieldComponent from "./top-control-field/TopControlField.component";

export default function MainComponent(props) {
    const {
        rentalOrderInfoPage,
        reqSendSms,
        reqSaveCsMemo
    } = useRentalOrderInfo();

    const {
        selectedRentalOrderInfo,
        onSelect,
        onSet,
        onRefresh
    } = useSelectedRentalOrderInfo();

    useEffect(() => {
        if (!rentalOrderInfoPage || !selectedRentalOrderInfo) {
            return;
        }

        let newSelected = rentalOrderInfoPage.content.find(r => r.id === selectedRentalOrderInfo.id);
        if (newSelected) {
            onSet(newSelected);
        } else {
            onRefresh();
        }
    }, [rentalOrderInfoPage])

    return (
        <>
            <TopControlFieldComponent />
            <MainLayout>
                <CustomerListFieldComponent
                    rentalOrderInfoPage={rentalOrderInfoPage}
                    selectedRentalOrderInfo={selectedRentalOrderInfo}

                    onActionSelectRentalOrderInfo={onSelect}
                />
                <div style={{ padding: '20px' }}></div>
                <ProductListFieldComponent
                    rentalOrderInfoPage={rentalOrderInfoPage}
                    selectedRentalOrderInfo={selectedRentalOrderInfo}

                    onSubmitSendSms={reqSendSms}
                    onSubmitSaveCsMemo={reqSaveCsMemo}
                />
            </MainLayout>
            <FloatOrderCalc />
        </>
    );
}