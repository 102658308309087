import { Container, Title } from "./HeadField.styled";


export default function HeadFieldComponent(props) {
    if (props.room) {
        return (
            <>
                <Container>
                    <Title>
                        {props.room.name}
                    </Title>
                </Container>
            </>
        );
    }

    return null;
}