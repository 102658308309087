import styled, { css } from 'styled-components';

const FigureEl = styled.figure`
    position: relative;
    padding-bottom: ${props => props.width && props.height ? `calc(${props.height} / ${props.width} * 100%)` : '100%'}; // 1:1
    margin: 0;
`;

const ImageEl = styled.img`
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

function Figure({
    width,
    height,
    className,
    style,
    children
}) {
    return (
        <FigureEl
            width={width}
            height={height}
            className={className}
            style={style}
        >
            {children}
        </FigureEl>
    );
}

function Image({
    src,
    alt
}) {
    return (
        <ImageEl
            src={src}
            alt={alt}
        ></ImageEl>
    )
}

export const CustomImage = Object.assign(Figure, {
    Image: Image
})