import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
export default function useProductImagesHook(props) {
    const [productImages, setProductImages] = useState([
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // },
        // {
        //     id: uuidv4(),
        //     fileExtension: "JPEG",
        //     fileFullUri: "https://image.campal.co.kr/assets/images/CampingRental_20220826113423_531_108161.jpeg",
        //     fileName: "CampingRental_20220826113423_531_108161.jpeg",
        //     fileOriginName: "수수삼겹1.jpeg",
        //     filePath: "/assets/images",
        //     fileStorageUri: "https://image.campal.co.kr/assets/images",
        //     madeAt: "2022-08-26T11:34:24Z",
        //     productId: null,
        //     serviceUrl: "https://image.campal.co.kr",
        //     size: 72850,
        // }
    ]);
    const { __reqUploadImageFile } = useImageFileUploaderHook();

    const onPushImage = async (e) => {
        e.preventDefault();

        if (productImages.length >= 10) {
            alert('이미지는 최대 10개 까지 등록 가능합니다.');
            return;
        }

        // 파일을 선택하지 않은 경우
        if (e.target.files.length <= 0) return;

        let imageInfos = await __reqUploadImageFile(e);

        let newProductImages = [...productImages];
        newProductImages = newProductImages.concat(imageInfos.map(r => {
            return {
                ...r,
                productId: null
            }
        }));

        setProductImages([...newProductImages])
    }

    const onSetProductImages = (data) => {
        setProductImages([...data]);
    }

    const onDeleteImage = (imageId) => {
        let newImages = [...productImages.filter(r => r.id !== imageId)];

        setProductImages([...newImages])
    }

    const returnProductImagesSubmitValid = () => {
        if (!productImages || productImages.length <= 0 || productImages.length > 10) {
            alert('이미지는 1개 이상 10개 이하로 등록할 수 있습니다.');
            return false;
        }
        return true;
    }

    return {
        productImages,
        onPushImage,
        onDeleteImage,
        returnProductImagesSubmitValid,
        onSetProductImages
    }
}