import FooterComponent from "../../views/footer/FooterComponent";
import NavbarMain from "../../views/navbar/NavbarMain";
import MainComponent from "../../views/room";

export default function RoomPage(props) {
    return (
        <>
            <MainComponent />
        </>
    );
}