import styled from 'styled-components';

export const St = {
    Container: styled.div`
        max-width: 1800px;
        margin: 0 auto;
        /* padding: 20px 0; */
    `,
    SlickWrapper: styled.section`
        overflow: hidden;
        position: relative;
        &:hover{
            .slick-prev-arrow, .slick-next-arrow{
                display: block;
            }
        }
        .slick-slide > div{
            margin: 0 10px;
        }

        .slick-list{
            margin: 0 -10px;
        }

        .slick-prev-arrow{
            -webkit-tap-highlight-color: #00000000;
            display: none;
            width:40px;
            height:40px;
            background:#40404080;
            position:absolute;
            top:50%;
            left: 30px;
            transform: translate(0, -50%);
            padding:0;
            margin:0;
            cursor: pointer;
            transition: border-radius .3s;

            &:hover{
                background:#404040;
                border-radius: 50%;

                .icon{
                    opacity: 1;
                }
            }

            @media all and (max-width:992px){
                left: 20px;
                width:28px;
                height:28px;
            }

            .icon{
                width:inherit;
                height: inherit;
                opacity: 0.8;
            }
        }

        .slick-next-arrow{
            -webkit-tap-highlight-color: #00000000;
            display: none;
            width:40px;
            height:40px;
            background:#40404080;
            position:absolute;
            top:50%;
            right: 30px;
            transform: translate(0, -50%);
            padding:0;
            margin:0;
            cursor: pointer;
            transition: border-radius .3s;

            &:hover{
                background:#404040;
                border-radius: 50%;

                .icon{
                    opacity: 1;
                }
            }

            @media all and (max-width:992px){
                right: 20px;
                width:28px;
                height:28px;
            }

            .icon{
                width:inherit;
                height: inherit;
                opacity: 0.8;
            }
        }

        .slick-numbering{
            user-select:none;
            position: absolute;
            bottom:20px;
            right:30px;
            background:#40404080;
            width:80px;
            padding: 11px 0;
            color:#fff;
            text-align:center;
            border-radius:10px;
            letter-spacing:2px;
            font-size:16px;
            font-weight:600;
            transition: border-radius .3s;

            &:hover{
                border-radius:0;
            }

            @media all and (max-width:992px){
                bottom:10px;
                right:20px;
                width:55px;
                padding: 12px 0;
                font-size:11px;
            }
        }
    `,
    CardItem: styled.div`
        position:relative;

        .image-figure{
            display: block;
            position: relative;
            overflow: hidden;
            height: 0;
            padding-bottom: 51.25%;
            outline: 0;
            border-radius: 20px;
            overflow: hidden;
            z-index: 0;
            
            @media all and (max-width:992px){
                border-radius: 0;
            }

            img{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: scale .3s;
                
                &:hover{
                    scale: 1.02;
                }
            }
        }

        .opacity-06{
            opacity: 0.6;
        }

        .card-text{
            position: absolute;
            bottom: 10%;
            right: 10%;
            color:#fff;
            font-size: 20px;
            font-weight: 800;
            text-align: right;
            letter-spacing: 1.5px;

        }
        .accent-1{
            font-weight: 900;
            font-size: 26px;
            color: #e36;
        }

        .accent-2{
            font-weight: 900;
            font-size: 26px;
            color: #E8845B;
        }
    `,
}