import { useEffect, useState } from "react";
import formatValidUtils from "utils/formatValidUtils";
import { roomDataConnect } from "../../../../data_connect/roomDataConnect";
import { numberFormatHandler } from "../../../../utils/numberFormatHandler";
import SingleBlockButton from "../../../module/button/SingleBlockButton";
import CustomInput from "../../../module/form/CustomInput";
import ElementLoading from "../../../module/loading/ElementLoading";
import CommonModalComponent from "../../../module/modal/CommonModalComponent";
import { Container, ContentWrapper, HeadWrapper, ModalWrapper, Wrapper } from "./PhoneNumberField.styled";

export default function PhoneNumberFieldComponent(props) {
    const [changePhoneNumberModalOpen, setChangePhoneNumberModalOpen] = useState(false);

    const __handle = {
        action: {
            openChangePhoneNumberModal: () => {
                setChangePhoneNumberModalOpen(true)
            },
            closeChangePhoneNumberModal: () => {
                setChangePhoneNumberModalOpen(false)
            }
        },
        submit: {
            changePhoneNumber: ({ phoneNumber, phoneValidationCode }) => {
                if (phoneNumber === props.room.phoneNumber) {
                    __handle.action.closeChangePhoneNumberModal();
                    return;
                }
                let body = {
                    phoneNumber: phoneNumber,
                    phoneValidationCode: phoneValidationCode
                }
                props.onSubmitChangePhoneNumber({
                    body: body,
                    callback: () => __handle.action.closeChangePhoneNumberModal()
                });
            }
        }
    }

    if (props.room) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <HeadWrapper>
                            <div className='title'>등록된 연락처</div>
                            <SingleBlockButton
                                type='button'
                                className='button-item'
                                onClick={__handle.action.openChangePhoneNumberModal}
                            >
                                변경
                            </SingleBlockButton>
                        </HeadWrapper>
                        <ContentWrapper>
                            +82 {props.room?.phoneNumber}
                        </ContentWrapper>
                    </Wrapper>
                </Container>
                <CommonModalComponent
                    open={changePhoneNumberModalOpen}
                    onClose={__handle.action.closeChangePhoneNumberModal}
                >
                    <ChangePhoneNumberModal
                        room={props.room}
                        onClose={__handle.action.closeChangePhoneNumberModal}
                        onConfirm={__handle.submit.changePhoneNumber}
                    />
                </CommonModalComponent>
            </>
        );
    }

    /**
     * 로딩 필드
     */
    return (
        <>
            <Container>
                <Wrapper>
                    <HeadWrapper>
                        <ElementLoading
                            className='title-loading'
                        />
                        <ElementLoading
                            className='button-item-loading'
                        />
                    </HeadWrapper>
                    <ContentWrapper>
                        <ElementLoading
                            className='content-loading'
                        />
                    </ContentWrapper>
                </Wrapper>
            </Container>
        </>
    );
}

function ChangePhoneNumberModal({
    room,
    onClose,
    onConfirm
}) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneValidationCode, setPhoneValidationCode] = useState('');
    const [activePhoneValidationCodeInput, setActivePhoneValidationCodeInput] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!room) {
            return;
        }

        setPhoneNumber(room.phoneNumber);
    }, [room]);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }

        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);

    }, [disabledBtn])

    const __handle = {
        req: {
            sendPhoneValidationCode: async () => {
                await roomDataConnect().sendPhoneValidationCode({ phoneNumber })
                    .then(res => {
                        if (res.status === 200) {
                            alert('해당 휴대폰 번호로 인증번호를 발송했습니다.');
                            setActivePhoneValidationCodeInput(true);
                        }
                    })
                    .catch(err => {
                        let res = err.response;
                        if (res.status === 500) {
                            alert('undefined error.')
                            return;
                        }

                        alert(res.data.memo);
                    })
            }
        },
        change: {
            phoneNumber: (e) => {
                let value = e.target.value;
                if (!numberFormatHandler().checkNumberOnlyFormat(value) || value.length > 11) {
                    return;
                }
                setPhoneNumber(value);
            },
            phoneValidationCode: (e) => {
                let value = e.target.value;
                if (!numberFormatHandler().checkNumberOnlyFormat(value) || value.length > 6) {
                    return;
                }
                setPhoneValidationCode(value);
            }
        },
        submit: {
            sendPhoneValidationCode: async () => {
                setDisabledBtn(true);
                if (!formatValidUtils.isPhoneNumberFormatValid(phoneNumber)) {
                    alert('휴대폰 번호 형식을 확인해 주세요.');
                    return;
                }

                await __handle.req.sendPhoneValidationCode();
            },
            changePhoneNumber: (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if (!formatValidUtils.isPhoneNumberFormatValid(phoneNumber)) {
                    alert('휴대폰 번호 형식을 확인해 주세요.');
                    return;
                }

                if (!numberFormatHandler().checkNumberOnlyFormat(phoneValidationCode) || phoneValidationCode.length != 6) {
                    alert('인증번호를 정확히 입력해 주세요.');
                    return;
                }
                onConfirm({ phoneNumber, phoneValidationCode });
            }
        }
    }
    return (
        <>
            <ModalWrapper onSubmit={__handle.submit.changePhoneNumber}>
                <div className='content-box'>
                    <div className="input-box">
                        <CustomInput
                            type='text'
                            label={'휴대폰 번호'}
                            labelClassName={'input-label'}
                            value={phoneNumber || ''}
                            onChange={__handle.change.phoneNumber}
                            placeholder={'ex) 01012341234'}
                        />
                    </div>
                    {activePhoneValidationCodeInput &&
                        <div className="input-box">
                            <CustomInput
                                type='text'
                                label={'인증번호'}
                                labelClassName={'input-label'}
                                value={phoneValidationCode || ''}
                                onChange={__handle.change.phoneValidationCode}
                            />
                            <div className='input-notice'>인증번호를 발송했습니다.(유효시간 30분)</div>
                            <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                            <div className='input-notice' style={{ color: 'red' }}>이미 사용중인 휴대폰 번호는 인증번호를 받을 수 없습니다.</div>
                            <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메세지함을 확인하여 주세요.</div>
                        </div>
                    }
                    {!activePhoneValidationCodeInput &&
                        <SingleBlockButton
                            type='button'
                            onClick={__handle.submit.sendPhoneValidationCode}
                            disabled={disabledBtn}
                        >
                            인증번호 발송
                        </SingleBlockButton>
                    }
                    {activePhoneValidationCodeInput &&
                        <SingleBlockButton
                            type='button'
                            onClick={__handle.submit.sendPhoneValidationCode}
                            disabled={disabledBtn}
                        >
                            인증번호 재발송
                        </SingleBlockButton>
                    }
                </div>
                <div className='button-box'>
                    <SingleBlockButton
                        className='button-item'
                        type='button'
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        className='button-item'
                        type='submit'
                        style={{
                            color: '#b39283',
                            fontWeight: '600'
                        }}
                        disabled={disabledBtn}
                    >
                        변경
                    </SingleBlockButton>
                </div>
            </ModalWrapper>
        </>
    );
}