import { useReducer, useState } from "react";
import { rentalOrderInfoDataConnect } from "../../../data_connect/rentalOrderInfoDataConnect";
import HeadFieldComponent from "./head-field/HeadField.component";
import styled from 'styled-components';
import useRentalOrderInfoPageHook from "./hooks/useRentalOrderInfoPageHook";
import LayoutComponent from "./layout/Layout.component";
import OrderListComponent from "./order-list/OrderList.component";
import OrderDetailComponent from "./order-detail/OrderDetail.component";
import useRentalOrderProductsHook from "./hooks/useRentalOrderProductsHook";

const Container = styled.div`
    background: #f9fbfc;
    padding-bottom: 250px;
`;

export default function MainComponent(props) {
    const [selectedRentalOrderInfo, setSelectedRentalOrderInfo] = useState(null);
    const {
        rentalOrderInfoPage
    } = useRentalOrderInfoPageHook();
    const {
        rentalOrderProducts
    } = useRentalOrderProductsHook({
        selectedRentalOrderInfo: selectedRentalOrderInfo
    });


    const __handle = {
        action: {
            selectRentalOrderInfo: (data) => {
                if (selectedRentalOrderInfo && (data.id === selectedRentalOrderInfo.id)) {
                    setSelectedRentalOrderInfo(null);
                    return;
                }
                setSelectedRentalOrderInfo(data);
            }
        }
    }

    return (
        <Container>
            <HeadFieldComponent />
            <LayoutComponent>
                <OrderListComponent
                    rentalOrderInfos={rentalOrderInfoPage?.content}
                    selectedRentalOrderInfo={selectedRentalOrderInfo}

                    onActionSelectRentalOrderInfo={__handle.action.selectRentalOrderInfo}
                ></OrderListComponent>
                <div style={{ padding: '10px' }}></div>
                <OrderDetailComponent
                    selectedRentalOrderInfo={selectedRentalOrderInfo}
                    rentalOrderProducts={rentalOrderProducts}
                ></OrderDetailComponent>
            </LayoutComponent>
        </Container>
    );
}