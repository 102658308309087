import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding:0 10px;
    /* margin-bottom: 200px; */
`;

export const NavWrapper = styled.div`
    display:flex;
    justify-content: space-around;
    .nav-item{
        flex:1;
        padding:10px 0;
        border-bottom: 3px solid #00000000;

        font-size: 18px;
        font-weight: 500;
        text-align: center;
        cursor:pointer;
        -webkit-tap-highlight-color: #00000000;

        &:hover{
            border-bottom: 3px solid #e0e0e0;
        }

        @media all and (max-width: 992px){
            font-size: 16px;
        }
    }

    .active{
        border-bottom: 3px solid #b39283 !important;
    }
`;