import { productPackageDataConnect } from "data_connect/productPackageDataConnect";
import { useEffect, useState } from "react";

export default function useProductPackagesHook(props) {
    const [productPackages, setProductPackages] = useState([]);

    useEffect(() => {
        if (props.packageYn === 'n') {
            setProductPackages([]);
        }

    }, [props.packageYn])

    useEffect(() => {
        if (!props.product) {
            return;
        }

        if (!props.product.packageYn) {
            return;
        }

        reqFetchProductPackages();

    }, [props.product]);

    const reqFetchProductPackages = async () => {
        let productId = props.product.id;
        await productPackageDataConnect().searchListByProductId({ productId: productId })
            .then(res => {
                if (res.status === 200) {
                    setProductPackages(res.data.data);
                    return;
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })

    }

    const onSetProductPackages = (data) => {
        setProductPackages([...data])
    }

    const onAddProductPackage = (productPacakge) => {
        setProductPackages([
            ...productPackages,
            {
                ...productPacakge
            }
        ])
    }

    const onDeleteProductPackage = (productPacakgeId) => {
        let newProductPackages = productPackages.filter(r => r.id !== productPacakgeId);

        setProductPackages([
            ...newProductPackages
        ])
    }

    const onModifyProductPackage = (productPackage) => {
        let newProductPackages = productPackages.map(r => {
            if (r.id === productPackage.id) {
                return {
                    ...productPackage
                }
            } else {
                return {
                    ...r
                }
            }
        });

        setProductPackages([...newProductPackages])
    }

    const returnProductPackagesSubmitValid = () => {
        if (props.packageYn === 'y') {
            if (!productPackages || productPackages.length <= 0 || productPackages.length > 20) {
                alert('패키지 카테고리를 등록하기 위해서는 패키지 구성 제품이 최소 1개 최대 20개를 등록하셔야 합니다.');
                return false;
            }
        }

        return true;
    }
    return {
        productPackages,
        onAddProductPackage,
        onDeleteProductPackage,
        onModifyProductPackage,
        returnProductPackagesSubmitValid,
        onSetProductPackages
    }
}