import styled from 'styled-components';

export const Header = styled.div`
    .title-el{
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.1em;

        @media all and (max-width: 992px){
            font-size: 20px;
        }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    .link-button-el{
        user-select: none;
        color:#ffffff;
        width:150px;
        height: 34px;
        margin:0;
        letter-spacing: 0.1em;
        border-radius: 5px;
        background:#000;
        border:none;
        font-size: 14px;

        @media all and (max-width: 992px){
            font-size: 12px;
            width:100px;
        }
    }

`;

export const AddTemplateModalWrapper = styled.div`
    .form-box{
    }
    
    .input-box{
        padding:20px;
    }

    .button-box{
        margin-top: 20px;
        display: flex;
    }

    .button-box>.button-el{
        margin:0;
        border: none;
        letter-spacing: 0.5em;
        text-indent: 0.5em;
    }
`;