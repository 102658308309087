import MainComponent from "views/myaccount/root";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function MyAccountPage(props){
    const customRouter = useCustomRouterHook();
    const userRdx = useSelector(state => state.userRedux);

    useEffect(() => {
        if (userRdx.isLoading) {
            return;
        }

        if(!userRdx.userInfo){
            customRouter.push({
                pathname: '/login',
                replace: true
            })
            return;
        }

    }, [userRdx]);

    if(userRdx.userInfo){
        return (
            <>
                <MainComponent />
            </>
        );
    }

    return null;
}