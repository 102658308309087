import CommonModalComponent from 'views/module/modal/CommonModalComponent';
import { useState } from 'react';
import styled from 'styled-components';
import CalculateModalComponent from './modal/CalculateModal.component';

const FloatButtonElement = styled.button`
    position: fixed;
    z-index: 10;
    bottom: 30px;
    right:30px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #b39283;
    background: white;
    cursor: pointer;
    animation-name: FloatButtonElementAnimate;
    animation-duration: 0.1s;
    animation-timing-function: linear;

    @keyframes FloatButtonElementAnimate{
        0%{
            scale: 0
        }
        100%{
            scale: 1
        }
    }

    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
    }

    .badge-box{
        box-sizing: border-box;
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        border: 1px solid red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: red;
    }

    .badge-item{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-weight: 600;
    }

    &:hover{
        background: #b39283;
    }
`;

export default function FloatOrderCalc(props) {
    const [isHover, setIsHover] = useState(false);
    const [calculateModalOpen, setCalculateModalOpen] = useState(false);

    const __handle = {
        action: {
            openCalculateModal: () => {
                setCalculateModalOpen(true);
            },
            closeCalculateModal: () => {
                setCalculateModalOpen(false);
            }
        }
    }
    return (
        <>
            <FloatButtonElement
                type='button'
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
                onClick={() => __handle.action.openCalculateModal()}
            >
                <img
                    src={`${isHover ? '/assets/icon/calculate_default_ffffff.svg' : '/assets/icon/calculate_default_b39283.svg'}`}
                    alt='cart'
                ></img>
            </FloatButtonElement>

            <CommonModalComponent
                open={calculateModalOpen}
                onClose={() => __handle.action.closeCalculateModal()}
            >
                <CalculateModalComponent
                    orderTypes={props.orderTypes || []}
                    onClose={() => __handle.action.closeCalculateModal()}
                />
            </CommonModalComponent>
        </>
    );
}