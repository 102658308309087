import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
`;

export const Wrapper = styled.div`
    margin-bottom: 10px;
    border:1px solid #e0e0e0;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    height: 500px;
    overflow: auto;

    @media all and (max-width:992px){
        height: 300px;
    }

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }

    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }
`;

export const ItemWrapper = styled.div`
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    -webkit-tap-highlight-color: #00000000;
    cursor: pointer;

    ${Wrapper} &:nth-last-child(1){
        border-bottom: none;
    }
    

    .top-box{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color:#404040;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .count-board-box{
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        margin-top: 10px;
        color:#404040;
        
        @media all and (max-width: 992px){
            margin-top: 5px;
            font-size: 10px;
        }
    }
    
    .count-board-box>.item{
        user-select: none;
        cursor: pointer;
        padding:10px;
        text-align: center;

        @media all and (max-width: 992px){
            padding:5px;
            /* transform: scale(0.8); */
        }
    }
`;

export const ItemWrapperLoading = styled.div`
    padding: 10px;
    letter-spacing: 0.1em;
    border-bottom: 1px solid #e0e0e0;
    -webkit-tap-highlight-color: #00000000;
    cursor: pointer;

    ${Wrapper} &:nth-last-child(1){
        border-bottom: none;
    }
    

    .top-box{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .top-box>.name{
        width:150px;
        height: 25px;

        @media all and (max-width: 992px){
            width:80px;
            height: 20px;
        }
    }

    .top-box>.created-date{
        width:250px;
        height: 25px;

        @media all and (max-width: 992px){
            width:120px;
            height: 20px;
        }
    }

    .count-board-box{
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        margin-top: 10px;
        
        @media all and (max-width: 992px){
            margin-top: 5px;
            font-size: 10px;
        }
    }
    
    .count-board-box>.item{
        user-select: none;
        cursor: pointer;
        padding:10px;
        text-align: center;

        @media all and (max-width: 992px){
            padding:5px;
            /* transform: scale(0.8); */
        }
    }

    .count-board-box>.item>.item-loading{
        width:60px;
        height: 20px;

        @media all and (max-width: 992px){
            width:50px;
            height: 15px;
        }
    }
`;