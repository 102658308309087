import { useEffect, useState } from "react";
import { numberFormatHandler } from "utils/numberFormatHandler";

export default function useAddProductHook(props) {
    const [addProduct, setAddProduct] = useState({
        name: '',
        description: '',
        price: '',
        minimumRentalHour: '1',
        discountYn: 'n',
        discountMinimumHour: '',
        discountRate: '',
        productCategoryId: '',
        packageYn: 'n',
        maxOrderUnit: '1',
        productImages: [], // {id, fileOriginName, fileStorageUri, fileFullUri, serviceUrl, filePath, fileExtension, madeAt, size}
        productPackages: []
    });

    useEffect(() => {
        if (addProduct.productCategoryId === '5eaa96eb-2421-11ed-b93e-061b211b7e76') {
            onSetAddProduct({
                ...addProduct,
                packageYn: 'y'
            })
            return;
        }

        onSetAddProduct({
            ...addProduct,
            packageYn: 'n'
        })

    }, [addProduct.productCategoryId]);

    const onSetAddProduct = (data) => {
        setAddProduct({ ...data });
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setAddProduct({
            ...addProduct,
            [name]: value
        })
    }

    const onChangePrice = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberWithCommasOnlyFormat(value)) {
            return;
        }

        value = value.replace(/,/g, '');

        if (value < 0 || value > 1000000000) {
            return;
        }

        setAddProduct({
            ...addProduct,
            price: value
        })
    }

    const onChangeMinimumRentalHour = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
            return;
        }

        setAddProduct({
            ...addProduct,
            minimumRentalHour: value
        })
    }

    const onChangeDiscountYn = (e) => {
        let checked = e.target.checked;

        let discountYn = checked ? 'y' : 'n';

        setAddProduct({
            ...addProduct,
            discountYn: discountYn
        })
    }

    const onChangeDiscountMinimumHour = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
            return;
        }

        setAddProduct({
            ...addProduct,
            discountMinimumHour: value
        })
    }

    const onChangeDiscountRate = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 100) {
            return;
        }

        setAddProduct({
            ...addProduct,
            discountRate: value
        })
    }

    const onChangeMaxOrderUnit = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 1000) {
            return;
        }

        setAddProduct({
            ...addProduct,
            maxOrderUnit: value
        })
    }

    const checkNameValid = () => {
        if (!addProduct.name) {
            throw Error('제품명은 필수 입력입니다.');
        }

        if (addProduct.name.length < 0 || addProduct.name.length > 50) {
            throw Error('제품명은 1-50자로 입력해 주세요.');
        }
    }


    const checkProductCategoryValid = () => {
        if (!addProduct.productCategoryId) {
            throw Error('카테고리는 필수 선택입니다.');
        }
    }

    const checkPriceValid = () => {
        if (!addProduct.price || addProduct.price < 0 || addProduct.price > 1000000000) {
            throw Error('가격을 정확하게 입력해주세요. 0-1000000000 까지만 허용됩니다.');
        }
    }

    const checkMinimumRentalHourValid = () => {
        if (addProduct.minimumRentalHour <= 0 || addProduct.minimumRentalHour > 10000) {
            throw Error('최소 대여 가능 시간을 정확하게 입력해 주세요. 1-10000 까지만 허용됩니다.');
        }
    }

    const checkMaxOrderUnitValid = () => {
        if (addProduct.maxOrderUnit <= 0 || addProduct.maxOrderUnit > 100) {
            throw Error('최대 선택 가능 수량을 정확하게 입력해 주세요. 1-100 까지만 허용됩니다.');
        }
    }

    const checkDiscountMinimumHourValid = () => {
        if (addProduct.discountYn === 'y') {
            if (addProduct.discountMinimumHour <= 0 || addProduct.discountMinimumHour > 2400) {
                throw Error('할인 적용 시간을 정확하게 입력해 주세요. 1-2400 까지만 허용됩니다.');
            }
        }
    }

    const checkDiscountRateValid = () => {
        if (addProduct.discountYn === 'y') {
            if (addProduct.discountRate <= 0 || addProduct.discountRate > 100) {
                throw Error('할인율을 정확하게 입력해 주세요. 1-100 까지만 허용됩니다.');
            }
        }
    }

    const checkDescriptionValid = () => {
        if (addProduct.description && addProduct.description.length > 1000) {
            throw Error('설명은 최대 1000자 까지만 허용됩니다.');
        }
    }

    const returnSubmitValid = () => {
        try {
            checkNameValid();
            checkProductCategoryValid();
            checkPriceValid();
            checkMinimumRentalHourValid();
            checkMaxOrderUnitValid();
            checkDiscountMinimumHourValid();
            checkDiscountRateValid();
            checkDescriptionValid();
        } catch (err) {
            alert(err.message)
            return false;
        }

        return true;
    }

    return {
        addProduct,
        onChangeValueOfName,
        onChangePrice,
        onChangeMinimumRentalHour,
        onChangeDiscountYn,
        onChangeDiscountMinimumHour,
        onChangeDiscountRate,
        onChangeMaxOrderUnit,
        returnSubmitValid
    }
}