import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { CardBox, CardListWrapper, Container, HeaderWrapper } from "./ProductsByRoomField.styled";

export default function ProductsByRoomFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            routeToProduct: (productId) => {
                customRouter.push({
                    pathname: `/product`,
                    query: {
                        ...customRouter.query,
                        productId: productId
                    }
                })
            },
            routeToRoom: (roomId) => {
                customRouter.push({
                    pathname: '/room',
                    query: {
                        roomId: roomId
                    }
                })
            }
        }
    }
    return (
        <>
            <Container>
                <HeaderWrapper>
                    <div className='title-el'>
                        {props.roomName} 님의 TOP10
                    </div>
                    <SingleBlockButton
                        type='button'
                        className='more-button-el'
                        onClick={() => __handle.action.routeToRoom(props.roomId)}
                    >
                        더보기
                    </SingleBlockButton>
                </HeaderWrapper>
                <CardListWrapper>
                    {props.productPage.content.map(r => {
                        return (
                            <CardBox
                                key={r.id}
                                onClick={() => __handle.action.routeToProduct(r.id)}
                            >
                                <div className='thumbnail-box'>
                                    <div className='thumbnail-figure'>
                                        <img
                                            className='thumbnail-el'
                                            src={r.thumbnailUri}
                                            alt='thumbnail'
                                            loading="lazy"
                                        ></img>
                                    </div>
                                </div>
                                <div className='title-box'>
                                    <div className='title-el'>
                                        {r.name}
                                    </div>
                                </div>
                                <div className='price-box'>
                                    <div className='price-el'>
                                        {numberFormatHandler().numberWithCommas(r.price || 0)} 원 (1시간)
                                    </div>
                                </div>
                            </CardBox>
                        );
                    })}
                </CardListWrapper>
            </Container>
        </>
    );
}