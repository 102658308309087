import { productPackageDataConnect } from "data_connect/productPackageDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";

export default function useProductPackagesHook({ packageYn, ...props }) {
    const [productPackages, setProductPackages] = useState(null);
    const customRouter = useCustomRouterHook();

    useEffect(() => {
        if (packageYn && packageYn === 'y') {
            reqFetchProductPackages();
        }
    }, [packageYn])

    const reqFetchProductPackages = async () => {
        let productId = customRouter.query.productId || null;

        await productPackageDataConnect().searchListByProductId({
            productId: productId,
            params: {}
        })
            .then(res => {
                if (res.status === 200) {
                    setProductPackages(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        productPackages
    }
}