import CommonModalComponent from "views/module/modal/CommonModalComponent";
import * as St from './MdEditBorrower.styled';
import CustomBlockButton from "components/buttons/block-button/v1/CustomBlockButton";
import { useEffect, useState } from "react";
import CustomInput from "components/input/default/v1/CustomInput";
// ------------ MUI DatePicker Import Start
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { dateFormatUtils } from "utils/dateFormatUtils";
import formatValidUtils from "utils/formatValidUtils";
// ------------ MUI DatePicker Import End

export function MdEditBorrower({
    open = false,
    onClose = () => { },
    editTargetItem,
    onConfirm
}) {
    const [editRentalOrderInfo, setEditRentalOrderInfo] = useState({ ...editTargetItem?.rentalOrderInfo });

    const handleChangeBorrower = (e) => {
        let value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                borrower: value
            }
        });
    }

    const handleChangeBorrowerPhoneNumber = (e) => {
        let value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                borrowerPhoneNumber: value
            }
        });
    }

    const handleChangePickupDate = (value) => {
        let rDate = dateFormatUtils().getEndDate(value || new Date());
        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                pickupDate: rDate
            }
        });
    }

    const handleChangePickupTime = (e) => {
        const value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                pickupTime: value
            }
        });
    }

    const handleChangeReturnDate = (value) => {
        let rDate = dateFormatUtils().getEndDate(value || new Date());
        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                returnDate: rDate
            }
        });
    }

    const handleChangeReturnTime = (e) => {
        const value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                returnTime: value
            }
        });
    }

    const handleChangePickupPlace = (e) => {
        const value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                pickupPlace: value
            }
        });
    }

    const handleChangeReturnPlace = (e) => {
        const value = e.target.value;

        setEditRentalOrderInfo(prev => {
            return {
                ...prev,
                returnPlace: value
            }
        });
    }

    const handleSubmit = () => {
        try {
            // 예약자명 검수
            if (!editRentalOrderInfo.borrower || !formatValidUtils.isNameFormatValid(editRentalOrderInfo.borrower)) {
                throw new Error('예약자명을 정확히 입력해 주세요.\n[2-15자 내외 공백은 허용하지 않습니다.]');
            }

            // 예약자 연락처 검수
            if (!editRentalOrderInfo.borrowerPhoneNumber || !formatValidUtils.isPhoneNumberFormatValid(editRentalOrderInfo.borrowerPhoneNumber)) {
                throw new Error('예약자 연락처를 정확히 입력해 주세요.');
            }
            // 픽업 날짜 검수
            // 픽업 시간 검수
            // 반납 날짜 검수
            // 반납 시간 검수
            if (!dateFormatUtils().isValidDate(editRentalOrderInfo.pickupDate) || !dateFormatUtils().isValidDate(editRentalOrderInfo.returnDate)) {
                throw new Error('픽업 날짜 및 반납 날짜를 정확히 지정해 주세요.');
            }

            if (editRentalOrderInfo.pickupDate > editRentalOrderInfo.returnDate) {
                throw new Error('반납 날짜는 픽업 날짜 이후로만 선택 가능합니다.');
            }

            if (!editRentalOrderInfo.pickupTime) {
                throw new Error('픽업 시간을 선택해 주세요.');
            }

            if (!editRentalOrderInfo.returnTime) {
                throw new Error('반납 시간을 선택해 주세요.');
            }

            // 픽업 장소 검수
            if (!editRentalOrderInfo?.pickupPlace || editRentalOrderInfo?.pickupPlace?.length > 100) {
                throw new Error('픽업 장소를 최대 100자 이내로 입력해 주세요.');
            }

            // 반납 장소 검수
            if (!editRentalOrderInfo?.returnPlace || editRentalOrderInfo?.returnPlace?.length > 100) {
                throw new Error('반납 장소를 최대 100자 이내로 입력해 주세요.');
            }
        } catch (err) {
            alert(err.message);
            return;
        }
        onConfirm(editRentalOrderInfo);
    }

    return (
        <>
            <CommonModalComponent
                open={open}
                onClose={() => onClose()}
            >
                <St.Container>
                    <St.Title>
                        예약자 정보 수정
                    </St.Title>
                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                        <St.FormControlBox>
                            <label>예약자명</label>
                            <CustomInput
                                type='text'
                                placeholder='예약자명'
                                value={editRentalOrderInfo?.borrower}
                                onChange={(e) => handleChangeBorrower(e)}
                            />
                        </St.FormControlBox>
                        <St.FormControlBox>
                            <label>예약자 연락처</label>
                            <CustomInput
                                type='text'
                                placeholder='예약자 연락처'
                                value={editRentalOrderInfo?.borrowerPhoneNumber}
                                onChange={(e) => handleChangeBorrowerPhoneNumber(e)}
                            />
                        </St.FormControlBox>
                        <St.FormControlBox>
                            <div className='flexBox'>
                                <section>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'ko-KR'}
                                    >
                                        <DatePicker
                                            label="픽업 날짜 선택"
                                            inputFormat="YYYY.MM.DD"
                                            mask={'____.__.__'}
                                            toolbarFormat="YY.MM.DD dd"
                                            showToolbar={false}
                                            disablePast={false}
                                            value={dayjs(editRentalOrderInfo?.pickupDate || new Date())}
                                            onChange={(value) => handleChangePickupDate(value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className='date-picker'
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </section>
                                <section>
                                    <FormControl
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>픽업 시간 선택</InputLabel>
                                        <Select
                                            label="픽업 시간 선택"
                                            value={editRentalOrderInfo?.pickupTime || ''}
                                            name='pickupTime'
                                            onChange={(e) => handleChangePickupTime(e)}
                                        >
                                            <MenuItem value='00:00'>00:00</MenuItem>
                                            <MenuItem value='01:00'>01:00</MenuItem>
                                            <MenuItem value='02:00'>02:00</MenuItem>
                                            <MenuItem value='03:00'>03:00</MenuItem>
                                            <MenuItem value='04:00'>04:00</MenuItem>
                                            <MenuItem value='05:00'>05:00</MenuItem>
                                            <MenuItem value='06:00'>06:00</MenuItem>
                                            <MenuItem value='07:00'>07:00</MenuItem>
                                            <MenuItem value='08:00'>08:00</MenuItem>
                                            <MenuItem value='09:00'>09:00</MenuItem>
                                            <MenuItem value='10:00'>10:00</MenuItem>
                                            <MenuItem value='11:00'>11:00</MenuItem>
                                            <MenuItem value='12:00'>12:00</MenuItem>
                                            <MenuItem value='13:00'>13:00</MenuItem>
                                            <MenuItem value='14:00'>14:00</MenuItem>
                                            <MenuItem value='15:00'>15:00</MenuItem>
                                            <MenuItem value='16:00'>16:00</MenuItem>
                                            <MenuItem value='17:00'>17:00</MenuItem>
                                            <MenuItem value='18:00'>18:00</MenuItem>
                                            <MenuItem value='19:00'>19:00</MenuItem>
                                            <MenuItem value='20:00'>20:00</MenuItem>
                                            <MenuItem value='21:00'>21:00</MenuItem>
                                            <MenuItem value='22:00'>22:00</MenuItem>
                                            <MenuItem value='23:00'>23:00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </section>
                            </div>
                        </St.FormControlBox>
                        <St.FormControlBox>
                            <div className='flexBox'>
                                <section>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'ko-KR'}
                                    >
                                        <DatePicker
                                            label="반납 날짜 선택"
                                            inputFormat="YYYY.MM.DD"
                                            mask={'____.__.__'}
                                            toolbarFormat="YY.MM.DD dd"
                                            showToolbar={false}
                                            disablePast={false}
                                            value={dayjs(editRentalOrderInfo?.returnDate || new Date())}
                                            onChange={(value) => handleChangeReturnDate(value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className='date-picker'
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </section>
                                <section>
                                    <FormControl
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>반납 시간 선택</InputLabel>
                                        <Select
                                            label="반납 시간 선택"
                                            value={editRentalOrderInfo?.returnTime || ''}
                                            name='returnTime'
                                            onChange={(e) => handleChangeReturnTime(e)}
                                        >
                                            <MenuItem value='00:00'>00:00</MenuItem>
                                            <MenuItem value='01:00'>01:00</MenuItem>
                                            <MenuItem value='02:00'>02:00</MenuItem>
                                            <MenuItem value='03:00'>03:00</MenuItem>
                                            <MenuItem value='04:00'>04:00</MenuItem>
                                            <MenuItem value='05:00'>05:00</MenuItem>
                                            <MenuItem value='06:00'>06:00</MenuItem>
                                            <MenuItem value='07:00'>07:00</MenuItem>
                                            <MenuItem value='08:00'>08:00</MenuItem>
                                            <MenuItem value='09:00'>09:00</MenuItem>
                                            <MenuItem value='10:00'>10:00</MenuItem>
                                            <MenuItem value='11:00'>11:00</MenuItem>
                                            <MenuItem value='12:00'>12:00</MenuItem>
                                            <MenuItem value='13:00'>13:00</MenuItem>
                                            <MenuItem value='14:00'>14:00</MenuItem>
                                            <MenuItem value='15:00'>15:00</MenuItem>
                                            <MenuItem value='16:00'>16:00</MenuItem>
                                            <MenuItem value='17:00'>17:00</MenuItem>
                                            <MenuItem value='18:00'>18:00</MenuItem>
                                            <MenuItem value='19:00'>19:00</MenuItem>
                                            <MenuItem value='20:00'>20:00</MenuItem>
                                            <MenuItem value='21:00'>21:00</MenuItem>
                                            <MenuItem value='22:00'>22:00</MenuItem>
                                            <MenuItem value='23:00'>23:00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </section>
                            </div>
                        </St.FormControlBox>
                        <St.FormControlBox>
                            <label>픽업 장소</label>
                            <CustomInput
                                type='text'
                                placeholder='픽업 장소'
                                value={editRentalOrderInfo?.pickupPlace}
                                onChange={(e) => handleChangePickupPlace(e)}
                            />
                        </St.FormControlBox>
                        <St.FormControlBox>
                            <label>반납 장소</label>
                            <CustomInput
                                type='text'
                                placeholder='반납 장소'
                                value={editRentalOrderInfo?.returnPlace}
                                onChange={(e) => handleChangeReturnPlace(e)}
                            />
                        </St.FormControlBox>
                        <St.BottomButtonGroup>
                            <CustomBlockButton
                                type='button'
                                className='cancel'
                                onClick={() => onClose()}
                            >
                                취소
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='submit'
                                className='confirm'
                            >
                                완료
                            </CustomBlockButton>
                        </St.BottomButtonGroup>
                    </form>
                </St.Container>
            </CommonModalComponent>
        </>
    );
}