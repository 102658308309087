import styled from 'styled-components';

const Container = styled.div`
    margin-top: 20px;
    margin-bottom: 150px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width:992px){
    }
`;

const Wrapper = styled.div`
    display:flex;
    flex-direction: row;
    @media all and (max-width:992px){
        flex-direction: column;
    }
`;

export default function MainLayout(props) {
    return (
        <Container>
            <Wrapper>
                {props.children}
            </Wrapper>
        </Container>
    );
}