import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .link-button-el{
        user-select: none;
        color:#b39283;
        width:150px;
        height: 48px;
        margin:0;
        border: 1px solid #b39283;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 500;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

        &:hover{
            background: #b39283;
            color: #fff;
        }

        @media all and (max-width: 992px){
            font-size: 13px;
            width:100px;
        }
    }

`;