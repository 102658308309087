import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width:992px){
    }

    .add-button-el{
        margin: 0;
        width: 100px;
        border: none;
        border: 1px solid #b39283;
        border-radius: 5px;
        background: #fff;
        font-size: 14px;
        font-weight: 600;
        color: #b39283;
        cursor: pointer;
    }
`;

export const AddProductModalWrapper = styled.div`
    padding:10px;

    .input-box{
        padding:20px 10px;
    }

    .input-label{
        font-size: 14px;
        font-weight: 500;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .input-item{
        box-sizing: border-box;
        width:100%;
        padding:10px;
        border:none;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 5px;
        font-size: 14px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
        
        &:focus{
            outline:none;
            border-bottom: 1px solid #2c73d2;
        }

        &:read-only{
            cursor: pointer;
        }
    }

    .textarea-item{
        box-sizing: border-box;
        margin-top: 5px;
        padding:10px;
        width:100%;
        height: 200px;
        resize:none;
        font-size: 14px;
        border:none;
        border-bottom:1px solid #e0e0e0;

        @media all and (max-width:992px){
            font-size: 12px;
        }

        &:focus{
            outline:none;
            border: 1px solid #2c73d2;
        }

        &:read-only{
            cursor: pointer;
        }
    }

    .text-length-label{
        font-size: 12px;
        color:#505050;
    }

    .image-list-wrapper{
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
    }

    .image-add-button-item{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        position: relative;
        overflow: hidden;
        width:80px;
        height: 80px;
        background: white;
        border:1px solid #e0e0e0;
        cursor: pointer;
    }

    .image-add-button-icon{
        width: 50px;
        height: 50px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }

    .image-box{
        position: relative;
        overflow: hidden;
        width:80px;
        height: 80px;
        background: white;
        border:1px solid #e0e0e0;
        box-sizing: border-box;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .image-box:hover>.image-item{
        -webkit-filter: grayscale(50%) blur(1px);
	    filter: grayscale(50%) blur(1px);
    }

    .image-box:hover>.image-delete-button{
        display: block;
    }

    .image-item{
        width:100%;
        height: 100%;
        object-fit: cover;
    }

    .image-delete-button{
        display: none;
        position:absolute;
        padding:0;
        margin:0;
        box-sizing: border-box;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:25px;
        height: 25px;
        border:1px solid #e56767;
        border-radius: 50%;
        background:white;
        cursor: pointer;
    }

    .image-delete-button-icon{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:25px;
        height: 25px;
    }

    .button-box{
        display: flex;
        margin-top: 20px;
    }

    .button-item{
        border:none;
        margin: 0;
        font-size: 14px;
    }
`;