import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
`;

export const Title = styled.h3`
    margin-bottom: 30px;
    margin-top: 0;
`;

export const FormControlBox = styled.div`
    margin-bottom: 30px;
    
    label{
        display: inline-block;
        font-size: 12px;
        margin-bottom: 5px;
        color: #666;
    }

    .flexBox{
        display: flex;
        gap: 10px;
        align-items: center;

        section{
            flex:1;
        }

        section.first{

        }

        section.second{

        }
    }

    input{
        border-radius: 10px;
    }

    .loadProductButton{
        border-radius: 10px;
        font-weight: 700;
        border-color: #000;
        color: #000;
    }

    .thumbnailBox{
        position: relative;
        width: 150px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #f0f0f0;

        &:hover .hoverField{
            left: 0px;
            visibility: visible;
        }

        .hoverField{
            cursor: pointer;
            visibility: hidden;
            position: absolute;
            background-color: #20202050;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            transition: all .3s;
            
            .text{
                color: #fff;
                font-weight: 700;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .pendingField{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #20202050;

            .progressBox{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .progress{
                    color: #fff;
                }
            }
        }
    }
`;

export const BottomButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    button{
        border-radius: 10px;
    }

    button.cancel{
        background-color: #fff;
        color: #444;
    }

    button.confirm{
        font-weight: 700;
        background-color: var(--mainColor);
        color: #fff;
        border: 1px solid var(--mainColor);
    }
`;