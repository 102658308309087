import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import styled from "styled-components";

const Container = styled.div`
    border-top: 1px solid #e6e8ed;
    margin-bottom: 100px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    /* max-width: 1280px;
    margin-left: auto;
    margin-right: auto; */
    padding: 0 20px;
    margin: auto;

    @media all and (max-width:992px){
        padding: 10px;
    }
`;

const MenuItemsBox = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: center;

    .item-el{
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }

    .item-el:first-child::after{
        content: '|';
        font-weight: 500;
        color: #a1a1a1;
        margin-left: 3px;
        margin-right: 3px;
    }

    @media all and (max-width:992px){
        .item-el{
            font-size: 10px;
            font-weight: 700;
            cursor: pointer;
        }
    }
`;

const ComponyInfoBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    max-width: 750px;
    .item-el{
        margin-top: 1px;
        margin-bottom: 1px;
        margin-right: 10px;
        font-size: 12px;
        color:#52565d;
    }

    @media all and (max-width:992px){
        flex-direction: column;
        align-items: flex-start;
        max-width: inherit;
        /* margin: 5.55556vw 0 0; */
        font-size: 3.05556vw;
        color: #9da1a8;

        .item-el{
            margin-top: 2px;
            margin-bottom: 2px;
            margin-right: 10px;
            font-size: 10px;
        }
    }
`;

const Copyright = styled.div`
    margin-top: 15px;
    font-size: 12px;
    color:#222;

    @media all and (max-width:992px){
        font-size: 10px;
    }
`;
const FooterComponent = () => {
    const customRouter = useCustomRouterHook();
    const __handle = {
        action: {
            routeToPath: (path) => {
                customRouter.push({
                    pathname: path
                })
            }
        }
    }
    return (
        <>
            <Container>
                <Wrapper>
                    <div>
                        <MenuItemsBox>
                            <div
                                className='item-el'
                                onClick={() => __handle.action.routeToPath('/consent/service-terms')}
                            >
                                이용약관
                            </div>
                            <div
                                className='item-el'
                                onClick={() => __handle.action.routeToPath('/consent/privacy-policy')}
                            >
                                개인정보처리방침
                            </div>
                        </MenuItemsBox>
                        <ComponyInfoBox>
                            <div className="item-el">상호명: 피아르 첫째</div>
                            <div className="item-el">주소: 인천광역시 서구 건지로 250번길 53 201호</div>
                            <div className="item-el">사업자등록번호: 262-17-01212</div>
                            <div className="item-el">통신판매업 신고: 2020-인천서구-0154</div>
                            <div className="item-el">고객센터: 02-338-9464</div>
                            <div className="item-el">이메일: austin.ppark@piaar.co.kr</div>
                            <div className="item-el">대표: 양태영</div>
                            <div className="item-el">개인정보관리책임자: 박세훈</div>
                        </ComponyInfoBox>
                        <Copyright>
                            Copyright ⓒ 2022 All rights reserved by PIAAR HOLDINGS
                        </Copyright>
                    </div>
                </Wrapper>
            </Container>
        </>
    );
}

export default FooterComponent;