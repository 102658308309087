import { userDataConnect } from "data_connect/userDataConnect";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatValidUtils from "utils/formatValidUtils";

export default function useNicknameHook(props) {
    const [nickname, setNickname] = useState('');
    const userRdx = useSelector(state => state.userRedux);
    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!userRdx.userInfo) {
            return;
        }

        setNickname(_.cloneDeep(userRdx.userInfo.nickname));
    }, [userRdx])

    const reqFetchUserInfo = async () => {
        await userDataConnect().searchUserInfo()
            .then(res => {
                if (res.status === 200) {
                    reduxDispatch({
                        type: 'USER_REDUX_SET_DATA',
                        payload: {
                            userInfo: res.data.data,
                            isLoading: false,
                            status: 'fetched',
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err, err.response);
                reduxDispatch({
                    type: 'USER_REDUX_SET_DATA',
                    payload: {
                        userInfo: null,
                        isLoading: false,
                        status: 'fetched'
                    }
                });
            })
    }

    const reqChangeNickname = async ({ body, successCallback }) => {
        let bool = await userDataConnect().changeNickname({ body })
            .then(res => {
                if (res.status === 200) {
                    return true;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        if (bool) {
            alert('변경되었습니다.');
            await reqFetchUserInfo();
            successCallback();

        }
    }

    const onChangeNickname = (e) => {
        let value = e.target.value;

        setNickname(value);
    }

    const returnNicknameValid = (nickname) => {
        return formatValidUtils.isNicknameFormatValid(nickname);
    }

    return {
        nickname,
        onChangeNickname,
        returnNicknameValid,
        reqChangeNickname
    }
}