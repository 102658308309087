import { useCustomRouterHook } from 'hooks/router/useCustomRouterHook';
import { useEffect, useReducer, useState, useRef } from 'react';
import styled from 'styled-components';
import { numberFormatHandler } from '../../../../utils/numberFormatHandler';
import valueUtils from '../../../../utils/valueUtils';
import SingleBlockButton from '../../../module/button/SingleBlockButton';
import CustomCheckbox from '../../../module/checkbox/CustomCheckbox';
import CommonModalComponent from '../../../module/modal/CommonModalComponent';
import CustomSelect from '../../../module/select/CustomSelect';
import { useImageFileUploaderHook } from '../../../module/uploader/useImageFileUploaderHook';
import { AddProductModalWrapper, Container } from './ControlField.styled';

export default function ControlFieldComponent(props) {
    const [addProductModalOpen, setAddProductModalOpen] = useState(false);
    const customRouter = useCustomRouterHook();

    const __product = {
        action: {
            openAddProductModal: () => {
                setAddProductModalOpen(true);
            },
            closeAddProductModal: () => {
                setAddProductModalOpen(false);
            },
            routeToProductAdd: () =>{
                customRouter.push({
                    pathname:'/myadmin/product-add'
                })
            }
        },
        submit: {
            add: (product) => {
                props.onSubmitAddProduct(product);
                __product.action.closeAddProductModal();
            }
        }
    }

    return (
        <>
            <Container>
                <SingleBlockButton
                    type='button'
                    className='add-button-el'
                    onClick={__product.action.routeToProductAdd}
                >
                    제품 추가
                </SingleBlockButton>
            </Container>

            <CommonModalComponent
                open={addProductModalOpen}
                onClose={__product.action.closeAddProductModal}
                maxWidth={'sm'}
            >
                <AddProductModal
                    allCategories={props.allCategories}
                    onSubmitAdd={__product.submit.add}
                />
            </CommonModalComponent>
        </>
    );
}

function AddProductModal({
    allCategories,
    onSubmitAdd
}) {
    const { __reqUploadImageFile } = useImageFileUploaderHook();
    const fileUploaderRef = useRef();
    const [product, dispatchProduct] = useReducer(productReducer, initialProduct);

    const __product = {
        action: {
            openFileUploader: (e) => {
                if (product.productImages.length >= 10) {
                    alert('이미지는 최대 10개 까지 등록 가능합니다.');
                    return;
                }
                fileUploaderRef.current.click();
            },
            deleteImage: (imageId) => {
                let newImages = [...product.productImages.filter(r => r.id !== imageId)];

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        productImages: newImages
                    }
                })
            }
        },
        change: {
            valueOfName: (e) => {
                let name = e.target.name;
                let value = e.target.value;

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        [name]: value
                    }
                })
            },
            price: (e) => {
                let value = e.target.value;

                if (!numberFormatHandler().checkNumberWithCommasOnlyFormat(value)) {
                    return;
                }

                value = value.replace(/,/g, '');

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        price: value
                    }
                })
            },
            discountRate: (e) => {
                let value = e.target.value;

                if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 100) {
                    return;
                }

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        discountRate: value
                    }
                })
            },
            minimumRentalHour: (e) => {
                let value = e.target.value;

                if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
                    return;
                }

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        minimumRentalHour: value
                    }
                })
            },
            discountYn: (e) => {
                let checked = e.target.checked;

                let discountYn = checked ? 'y' : 'n';

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        discountYn: discountYn
                    }
                })
            },
            discountMinimumHour: (e) => {
                let value = e.target.value;

                if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
                    return;
                }

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        discountMinimumHour: value
                    }
                })
            },
            pushImage: async (e) => {
                e.preventDefault();

                if (product.productImages.length >= 10) {
                    alert('이미지는 최대 10개 까지 등록 가능합니다.');
                    return;
                }

                // 파일을 선택하지 않은 경우
                if (e.target.files.length <= 0) return;

                let imageInfos = await __reqUploadImageFile(e);

                let productImages = [...product.productImages];
                productImages = productImages.concat(imageInfos.map(r => {
                    return {
                        ...r,
                        productId: null
                    }
                }));

                dispatchProduct({
                    type: 'SET_DATA',
                    payload: {
                        ...product,
                        productImages: productImages
                    }
                })
            }
        },
        submit: {
            confirm: (e) => {
                e.preventDefault();
                if (product.productImages.length <= 0 || product.productImages.length > 10) {
                    alert('이미지는 1개 이상 10개 이하로 등록할 수 있습니다.');
                    return;
                }

                if (!product.name) {
                    alert('제품명은 필수 입력입니다.')
                    return;
                }

                if (!product.productCategoryId) {
                    alert('카테고리는 필수 선택입니다.')
                    return;
                }

                if (product.price < 0 || product.price > 1000000000) {
                    alert('가격을 정확하게 입력해주세요.');
                    return;
                }

                if (product.minimumRentalHour <= 0) {
                    alert('최소 대여 가능 시간을 정확하게 입력해 주세요.');
                    return;
                }

                if (product.discountYn === 'y') {
                    if (product.discountMinimumHour <= 0 || product.discountMinimumHour > 10000) {
                        alert('최소 할인 적용 시간을 정확하게 입력해 주세요.');
                        return;
                    }

                    if (product.discountRate <= 0 || product.discountRate > 100) {
                        alert('할인율을 정확하게 입력해 주세요.');
                        return;
                    }
                }

                let body = {
                    ...product,
                    price: valueUtils.isEmptyNumbers(parseInt(product.price)) ? 0 : parseInt(product.price),
                    minimumRentalHour: valueUtils.isEmptyNumbers(parseInt(product.minimumRentalHour)) ? 1 : parseInt(product.minimumRentalHour),
                    discountMinimumHour: valueUtils.isEmptyNumbers(parseInt(product.discountMinimumHour)) ? 0 : parseInt(product.discountMinimumHour),
                    discountRate: valueUtils.isEmptyNumbers(parseInt(product.discountRate)) ? 0 : parseInt(product.discountRate)
                }

                onSubmitAdd(body);
            }
        }
    }

    return (
        <>
            <AddProductModalWrapper>
                <form onSubmit={__product.submit.confirm}>
                    <div
                        className='input-box'
                        style={{
                            borderBottom: '1px solid #e0e0e0'
                        }}
                    >
                        <div className='input-label'>이미지({product.productImages.length} / 10)</div>
                        <div className='image-list-wrapper'>
                            {product.productImages.map(r => {
                                return (
                                    <div key={r.id} className='image-box'>
                                        <img
                                            className='image-item'
                                            src={r.fileFullUri}
                                            alt="file"
                                        ></img>
                                        <button
                                            type='button'
                                            className='image-delete-button'
                                            onClick={() => __product.action.deleteImage(r.id)}
                                        >
                                            <img
                                                className='image-delete-button-icon'
                                                src='/assets/icon/remove_default_red.svg'
                                                alt='delete icon'
                                            ></img>
                                        </button>
                                    </div>
                                );
                            })}
                            <button
                                type='button'
                                className='image-add-button-item'
                                onClick={__product.action.openFileUploader}
                            >
                                <img
                                    className='image-add-button-icon'
                                    src='/assets/icon/add_default_gray.svg'
                                    alt={'add default'}
                                ></img>
                            </button>
                        </div>
                        <input
                            ref={fileUploaderRef}
                            type="file"
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                            onClick={(e) => e.target.value = ''}
                            onChange={(e) => __product.change.pushImage(e)}
                        />
                    </div>
                    <div className='input-box'>
                        <div className='input-label'>제품명</div>
                        <input
                            type='text'
                            className='input-item'
                            name='name'
                            value={product.name || ''}
                            onChange={__product.change.valueOfName}
                            required
                        ></input>
                    </div>
                    <div className='input-box'>
                        <CustomSelect
                            style={{
                                margin: 0
                            }}
                            name='productCategoryId'
                            value={product.productCategoryId || ''}
                            onChange={__product.change.valueOfName}
                            required
                        >
                            <option value=''>카테고리 선택</option>
                            {allCategories?.map(r => {
                                return (
                                    <option key={r.id} value={r.id}>{r.name}</option>
                                )
                            })}
                        </CustomSelect>
                    </div>
                    <div className='input-box'>
                        <div className='input-label'>시간당 가격(원)</div>
                        <input
                            type='text'
                            className='input-item'
                            name='price'
                            value={numberFormatHandler().numberWithCommas(product.price) || ''}
                            onChange={__product.change.price}
                            required
                        ></input>
                    </div>
                    <div className='input-box'>
                        <div className='input-label'>최소 대여 가능 시간</div>
                        <input
                            type='text'
                            className='input-item'
                            name='minimumRentalHour'
                            value={product.minimumRentalHour || ''}
                            onChange={__product.change.minimumRentalHour}
                            required
                        ></input>
                    </div>
                    <div className='input-box'>
                        <div>
                            <CustomCheckbox
                                label={'할인 적용하기'}
                                checked={product.discountYn === 'y' ? true : false}
                                onChange={__product.change.discountYn}
                                labelStyle={{
                                    color: '#000000de'
                                }}
                            />
                        </div>
                        {product.discountYn === 'y' &&
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <input
                                        type='text'
                                        className='input-item'
                                        name='discountMinimumHour'
                                        value={product.discountMinimumHour || ''}
                                        max={100}
                                        min={0}
                                        placeholder={''}
                                        onChange={__product.change.discountMinimumHour}
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    ></input>
                                </div>
                                <div style={{ fontSize: '14px', fontWeight: '500' }}>시간 이상 대여시</div>
                                <div
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <input
                                        type='text'
                                        className='input-item'
                                        name='discountRate'
                                        value={product.discountRate || ''}
                                        max={100}
                                        min={0}
                                        onChange={__product.change.discountRate}
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    ></input>
                                </div>
                                <div style={{ fontSize: '14px', fontWeight: '500' }}>(%) 할인</div>
                            </div>
                        }
                    </div>
                    <div className='input-box'>
                        <div className='input-label'>설명</div>
                        <textarea
                            className='textarea-item'
                            name='description'
                            value={product.description || ''}
                            onChange={__product.change.valueOfName}
                        ></textarea>
                    </div>
                    <div className='input-box'>
                        <SingleBlockButton
                            type='submit'
                            style={{
                                margin: 0,
                                background: '#000000',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            추가
                        </SingleBlockButton>
                    </div>
                </form>
            </AddProductModalWrapper>
        </>
    );
}

const initialProduct = {
    name: '',
    description: '',
    price: '',
    minimumRentalHour: '1',
    discountYn: 'n',
    discountMinimumHour: '',
    discountRate: '',
    productCategoryId: '',
    productImages: [] // {id, fileOriginName, fileStorageUri, fileFullUri, serviceUrl, filePath, fileExtension, madeAt, size}
}

const productReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload;
        case 'CLEAR':
            return initialProduct;
        default: return initialProduct;
    }
}