import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 70px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width:992px){
    }
`;

export const FormWrapper = styled.form`
    position:relative;
    flex:1;
    border:1px solid #e0e0e0;
    border-radius: 15px;
    padding:20px;
    background: white;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

    &>.form-box:nth-child(1){
        margin-top: 0;
    }

    .form-box{
        width:100%;
        margin-top: 30px;
    }
    
    .form-box>.input-el{
        width:100%;
        height: 48px;
        padding:10px;
        box-sizing: border-box;
        border:none;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        outline: none;
        font-size: 16px;
        transition: all 0.3s;

        &:hover{
            border: 1px solid #b39283;
        }

        &:focus{
            border: 1px solid #b39283;
            box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
        }

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .form-box>.label{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color:#303030;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .input-notice{
        color: #707070;
        font-size: 12px;
        margin-top: 3px;

        @media all and (max-width: 992px){
            font-size: 10px;
        }
    }

    .validation-button-el{
        margin:0;
        padding:0;
        width:100%;
        height: 48px;
        background:#fff;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        font-size: 16px;
        color:#303030;
        font-weight: 500;
        margin-top: 20px;

        @media all and (max-width:992px){
            font-size: 14px;
            width:100%;
        }
    }

    .search-button-el{
        margin:0;
        padding:0;
        width:250px;
        height: 48px;
        background:#b39283;
        border: 1px solid #b39283;
        border-radius: 10px;
        font-size: 16px;
        color:white;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 14px;
            width:100%;
        }
    }
`;