import { dateFormatUtils } from "utils/dateFormatUtils";
import valueUtils from "utils/valueUtils";
import { Item, Wrapper } from "./OrderList.styled";

export default function OrderListComponent(props) {
    return (
        <>
            {props.rentalOrderInfos &&
                (
                    <>
                        <Wrapper>
                            {valueUtils.isEmptyValues(props.rentalOrderInfos) &&
                                <div className='empty-box'>주문내역이 없습니다.</div>
                            }
                            {props.rentalOrderInfos?.map(r => {
                                return (
                                    <Item
                                        key={r.id}
                                        onClick={() => props.onActionSelectRentalOrderInfo(r)}
                                        style={{
                                            background: props.selectedRentalOrderInfo?.id === r.id ? '#b3928330' : 'white',
                                        }}
                                    >
                                        <div
                                            className='text-group'
                                        >
                                            <div className='text-el-md'>
                                                <span className='label'>주문번호</span>{r.orderNumber}
                                            </div>
                                            <div className='text-el-md'>
                                                <span className='label'>주문일시</span>{dateFormatUtils().dateToYYMMDDHHmmss(r.createdAt)}
                                            </div>
                                        </div>
                                        <div
                                            className='text-group'
                                        >
                                            <div
                                                className='text-el-md'
                                                style={{
                                                    color: '#808080'
                                                }}
                                            >
                                                <span className='label'>대여처</span>{r.room.name}
                                            </div>
                                        </div>
                                    </Item>
                                );
                            })}
                        </Wrapper>
                    </>
                )
            }

            {!props.rentalOrderInfos &&
                <Wrapper></Wrapper>
            }
        </>
    );
}