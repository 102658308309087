import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
`;

export const Wrapper = styled.div`
    overflow: auto;
    position:relative;
    border:1px solid #e0e0e0;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    padding: 20px;
    height: 500px;

    @media all and (max-width:992px){
        height: 300px;
    }
    
    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }
    
    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }
`;

export const InfoWrapper = styled.div`
    &>.info-group:nth-child(1){
        margin-top: 0;
    }

    .info-group{
        display: flex;
        margin-top: 20px;
    }

    .info-box{
        flex:1
    }

    .info-box>.label{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 11px;
        }
    }

    .info-box>.info-el{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .button-group{
        display: flex;
        justify-content: flex-end;
        margin-top:20px;

    }

    .button-group>.button-el{
        margin:0;
        padding:0;
        height: 38px;
        width:110px;
        border: none;
        background: #f0f0f0;
        color:#808080;
        font-size: 14px;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

        margin-left: 5px;
        border-radius: 10px;

        &:first-child{
            margin-left: 0;
        }

        &:hover{
            background: #eaeaea;
        }
        @media all and (max-width:992px){
            flex:1;
            font-size: 12px;
        }
    }
`;

export const ProductListWrapper = styled.div`
    .total-price-box{
        margin-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-weight: 600;
        color:#b39283;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .product-box{
        display: flex;
        padding: 10px 0;
    }

    .thumbnail-box{
        overflow: hidden;
        width: 100px;
        border-radius: 10px;
        border: 1px solid #e0e0e0;

        @media all and (max-width:992px){
            width: 80px;
        }
    }

    .thumbnail-figure{
        position:relative;
        padding-bottom: 100%;
    }

    .thumbnail-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .product-info-box{
        flex:1;
        margin-left: 10px;
    }

    .product-info-box>.status-badge{
        padding:3px 0;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        color: #2c73d2;

        @media all and (max-width:992px){
            font-size: 11px;
        }
    }

    .product-info-box>.title{
        margin-top: 5px;
        font-size: 14px;
        font-weight: 600;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .product-info-box>.count{
        font-size: 14px;
        font-weight: 500;
        color:#808080;
        margin-top: 5px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.discount{
        font-size: 14px;
        font-weight: 500;
        color:#e56767;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.totalPrice{
        font-size: 14px;
        font-weight: 500;
        color:#808080;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;

export const WriteSmsModalWrapper = styled.div`
    position:relative;

    .header-box{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding: 0 20px;
    }

    .call-template-button-el{
        margin:0;
        width:150px;
        font-size: 14px;

        @media all and (max-width:992px){
            width:100px;
            font-size: 12px;
        }
    }

    .write-field-box{
        padding:20px;
        border-bottom: 1px solid #e0e0e0;
    }

    .write-field-box>.label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .write-field-box>.write-field-el{
        width:100%;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        height: 150px;
        padding:10px;
        letter-spacing: 0.07em;
        font-size: 14px;
        outline: none;
        border: 1px solid #e0e0e0;

        &::-webkit-scrollbar{
            background: #e1e1e130;
            height:5px;
            width: 5px;
        }
        
        &::-webkit-scrollbar-track{
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #00000030;
            border-radius: 10px;
        }

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box{
        padding:20px;
        
    }

    .view-field-box>.label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box>.notice{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color:red;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box>.view-field-el{
        white-space: pre-line;
        font-family: monospace;
        font-size: 14px;
        letter-spacing: 0.07em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .send-sms-button-box{
        position:sticky;
        bottom:0;
    }

    .send-sms-button-el{
        margin: 0;
        padding: 0;
        height: 48px;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }

    .template-list-wrapper{
        padding:20px;
    }

    .template-card-el{
        padding:10px;
        border:1px solid #e0e0e0;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        -webkit-tap-highlight-color: #00000000;
    }

    .template-card-el>.name{
        font-weight: 600;
        letter-spacing: 0.08em;
        font-size: 16px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .template-card-el>.message{
        margin-top: 5px;
        letter-spacing: 0.08em;
        white-space: pre-line;
        font-size: 10px;
        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
    }
`;

export const MemoModalWrapper = styled.div`
    .csMemo-box{
        padding:20px;
    }

    .csMemo-box>.label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .csMemo-box>.csMemo-el{
        width:100%;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        height: 200px;
        padding:10px;
        letter-spacing: 0.07em;
        font-size: 14px;
        outline: none;
        border: 1px solid #e0e0e0;

        &::-webkit-scrollbar{
            background: #e1e1e130;
            height:5px;
            width: 5px;
        }
        
        &::-webkit-scrollbar-track{
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #00000030;
            border-radius: 10px;
        }

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .csMemo-box>.notice{
        color:#e56767;
        font-size: 14px;
        letter-spacing: 0.08em;
        font-weight: 500;
    }

    .button-group{
        display: flex;
        margin-top: 20px;
    }

    .button-group>.button-el{
        margin: 0;
        padding: 0;
        height: 48px;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
`;

export const SmsHistoryModalWrapper = styled.div`
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }

    .title-box{
        padding: 0 20px;
    }

    .title-el{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color: #303030;
        padding-bottom: 20px;
    }

    .content-list-wrapper{
        margin-top: 40px;
        padding: 0 20px;
    }

    .content-box{
        margin-top: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 15px;
        padding:10px;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

        .date-el{
            color: #808080;
            font-size: 14px;

            @media all and (max-width:992px){
                font-size: 12px;
            }
        }

        .content-el{
            margin-top: 10px;
            color: #303030;
            font-size: 14px;
            word-break: keep-all;
            white-space: pre-line;

            @media all and (max-width:992px){
                font-size: 12px;
            }
        }

        .loading-el{
            height: 24px;
            margin-top: 10px;
            border-radius: 10px;
        }

        .empty-el{
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            color:#404040;
            font-size: 16px;
            font-weight: 500;

            @media all and (max-width:992px){
                font-size: 14px;
            }
        }
    }

    .button-group{
        display: flex;
        margin-top: 40px;
    }

    .button-group>.button-el{
        margin: 0;
        padding: 0;
        height: 48px;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
    }
`;