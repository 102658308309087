import { validationDataConnect } from "data_connect/validationDataConnect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dateFormatUtils } from "utils/dateFormatUtils";
import formatValidUtils from "utils/formatValidUtils";

export default function useOrderBasicInfoHook(props) {
    const userRdx = useSelector(state => state.userRedux);

    const [orderBasicInfo, setOrderBasicInfo] = useState({
        orderer: '',
        ordererPhoneNumber: '',
        borrower: '',
        borrowerPhoneNumber: '',
        borrowerPhoneNumberValidationCode: '',
        pickupDate: dateFormatUtils().getStartDate(new Date()),
        pickupTime: '',
        pickupPlace: '',
        returnDate: dateFormatUtils().getEndDate(new Date()),
        returnTime: '',
        returnPlace: '',
        serviceAgreementYn: 'n',
        sameWithOrdererFlag: false
    });

    useEffect(() => {
        setOrderBasicInfo({
            ...orderBasicInfo,
            orderer: userRdx.userInfo.nickname,
            ordererPhoneNumber: userRdx.userInfo.phoneNumber
        })
    }, []);

    const reqSendPhoneValidationCode = async ({
        phoneNumber,
        successCallback
    }) => {
        await validationDataConnect().sendPhoneValidationCodeV2({ phoneNumber, validationType: 'forRentalOrder' })
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const onSetServiceAgreementYn = (value) => {
        setOrderBasicInfo({
            ...orderBasicInfo,
            serviceAgreementYn: value
        })
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setOrderBasicInfo({
            ...orderBasicInfo,
            [name]: value
        })
    }

    const onChangePickupDate = (value) => {
        setOrderBasicInfo({
            ...orderBasicInfo,
            pickupDate: dateFormatUtils().getStartDate(value || new Date())
        })
    }

    const onChangeReturnDate = (value) => {
        let rDate = dateFormatUtils().getEndDate(value || new Date());

        setOrderBasicInfo({
            ...orderBasicInfo,
            returnDate: rDate
        })
    }

    const onChangePickupAndReturnPlace = (e) => {
        let value = e.target.value;

        setOrderBasicInfo({
            ...orderBasicInfo,
            pickupPlace: value,
            returnPlace: value
        })
    }

    const onChangeServiceAgreementYn = (e) => {
        let checked = e.target.checked;
        setOrderBasicInfo({
            ...orderBasicInfo,
            serviceAgreementYn: checked ? 'y' : 'n'
        })
    }

    const onChangeSameWithOrdererFlag = (e) => {
        let checked = e.target.checked;

        if (checked) {
            setOrderBasicInfo({
                ...orderBasicInfo,
                sameWithOrdererFlag: true,
                borrower: userRdx.userInfo.nickname,
                borrowerPhoneNumber: userRdx.userInfo.phoneNumber,
                borrowerPhoneNumberValidationCode: ''
            })
        } else {
            setOrderBasicInfo({
                ...orderBasicInfo,
                sameWithOrdererFlag: false,
                borrower: '',
                borrowerPhoneNumber: '',
                borrowerPhoneNumberValidationCode: '',
            })
        }
    }

    const onCheckFormValid = ({
        minRentalHour
    }) => {
        if (!orderBasicInfo.orderer || !orderBasicInfo.ordererPhoneNumber) {
            throw new Error('주문자 정보를 확인할 수 없습니다. 같은 문제가 지속적으로 발생한다면 관리자에 문의해 주세요.');
        }

        if (!orderBasicInfo.sameWithOrdererFlag && (!orderBasicInfo.borrower || !formatValidUtils.isNameFormatValid(orderBasicInfo.borrower))) {
            throw new Error('대여자 이름을 정확히 입력해 주세요.\n[2-15자 내외 공백은 허용하지 않습니다.]');
        }

        if (!orderBasicInfo.sameWithOrdererFlag && (!orderBasicInfo.borrowerPhoneNumber || !formatValidUtils.isPhoneNumberFormatValid(orderBasicInfo.borrowerPhoneNumber))) {
            throw new Error('대여자 연락처를 정확히 입력해 주세요.');
        }

        if (!orderBasicInfo.sameWithOrdererFlag && (!orderBasicInfo.borrowerPhoneNumberValidationCode || !formatValidUtils.isValidationCodeFormatValid(orderBasicInfo.borrowerPhoneNumberValidationCode))) {
            throw new Error('인증번호를 정확히 입력해 주세요.');
        }

        if (!orderBasicInfo.pickupPlace) {
            throw new Error('픽업 장소를 선택해 주세요.');
        }

        if (!orderBasicInfo.returnPlace) {
            throw new Error('반납 장소를 선택해 주세요.');
        }

        if (!dateFormatUtils().isValidDate(orderBasicInfo.pickupDate) || !dateFormatUtils().isValidDate(orderBasicInfo.returnDate)) {
            throw new Error('픽업 날짜 및 반납 날짜를 정확히 지정해 주세요.');
        }

        if (orderBasicInfo.pickupDate > orderBasicInfo.returnDate) {
            throw new Error('반납 날짜는 픽업 날짜 이후로만 선택 가능합니다.');
        }

        if (!orderBasicInfo.pickupTime) {
            throw new Error('픽업 시간을 선택해 주세요.');
        }

        if (!orderBasicInfo.returnTime) {
            throw new Error('반납 시간을 선택해 주세요.');
        }

        let pDate = dateFormatUtils().dateFromDateAndHH_mm(orderBasicInfo.pickupDate, orderBasicInfo.pickupTime);
        let rDate = dateFormatUtils().dateFromDateAndHH_mm(orderBasicInfo.returnDate, orderBasicInfo.returnTime);
        let diffHours = dateFormatUtils().getDiffHoursFromDatesAllowNegative(pDate, rDate);
        if (diffHours < minRentalHour) {
            throw new Error('해당 제품들의 주문을 위한 최소 대여 가능 시간은 10시간 입니다.')
        }
    }

    const onCheckPhoneValidationCodeValid = async () => {
        await validationDataConnect().checkPhoneValidationCodeValid({
            phoneNumber: orderBasicInfo.borrowerPhoneNumber,
            validationCode: orderBasicInfo.borrowerPhoneNumberValidationCode
        })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    throw new Error('네트워크 연결이 원활하지 않습니다.');
                }

                if (res.status === 500) {
                    throw new Error('undefined error. 관리자에 문의해 주세요.');
                }

                throw new Error(res.data.memo);
            })
    }

    const onCheckServiceAgreementYnValid = () => {
        if (orderBasicInfo.serviceAgreementYn !== 'y') {
            throw new Error('개인정보수집 및 이용에 동의해 주세요.')
        }
    }

    return {
        orderBasicInfo,
        reqSendPhoneValidationCode,
        onSetServiceAgreementYn,
        onChangeValueOfName,
        onChangePickupDate,
        onChangeReturnDate,
        onChangePickupAndReturnPlace,
        onChangeServiceAgreementYn,
        onChangeSameWithOrdererFlag,
        onCheckFormValid,
        onCheckPhoneValidationCodeValid,
        onCheckServiceAgreementYnValid
    }
}