import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useState } from "react";
import { useDisabledBtn, useValidationForm } from "../hooks/findUsernameHooks";
import { Container, FormGroup, HeaderWrapper, InputBox, Wrapper } from "./ValidationFormField.styled";

export default function ValidationFormFieldComponent(props) {
    const {
        validationForm,
        reqSendPhoneValidationCode,
        onChangeValueOfName,
        returnPhoneNumberValid,
        returnPhoneNumberValidationCodeValid
    } = useValidationForm();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const [phoneValidationCodeInputModeOpen, setPhoneValidationCodeInputModeOpen] = useState(false);

    const __handle = {
        action: {
            openPhoneValidationCodeInputMode: () => {
                setPhoneValidationCodeInputModeOpen(true);
            },
            closePhoneValidationCodeInputMode: () => {
                setPhoneValidationCodeInputModeOpen(false);
            }
        },
        submit: {
            sendPhoneNumberValidationCode: async () => {
                if (!returnPhoneNumberValid(validationForm.phoneNumber)) {
                    alert('휴대전화 형식을 확인해 주세요.');
                    return;
                }
                setDisabledBtn(true);

                reqSendPhoneValidationCode({
                    phoneNumber: validationForm.phoneNumber,
                    successCallback: () => { __handle.action.openPhoneValidationCodeInputMode() }
                })
            },
            findUsername: (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (!returnPhoneNumberValid(validationForm.phoneNumber) || !returnPhoneNumberValidationCodeValid(validationForm.phoneNumberValidationCode)) {
                    alert('휴대전화번호 및 인증번호를 정확하게 입력해 주세요.');
                    return;
                }

                let body = {
                    phoneNumber: validationForm.phoneNumber,
                    phoneNumberValidationCode: validationForm.phoneNumberValidationCode
                }
                props.onSubmitFindUsername({ body });
            }
        }
    }
    return (
        <>
            <Container>
                <Wrapper>
                    <HeaderWrapper>
                        <div className='head-box'>
                        </div>
                        <div className='head-box'>
                            <div className='title'>
                                아이디 찾기
                            </div>
                        </div>
                        <div className='head-box'>
                        </div>
                    </HeaderWrapper>
                    <FormGroup onSubmit={__handle.submit.findUsername}>
                        <InputBox>
                            <div
                                className='input-label'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >휴대전화</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <input
                                    type='text'
                                    className={`input-item`}
                                    name='phoneNumber'
                                    value={validationForm.phoneNumber || ''}
                                    onChange={(e) => onChangeValueOfName(e)}
                                    placeholder={'ex)01012341234'}
                                    required
                                ></input>
                                <SingleBlockButton
                                    type='button'
                                    className='validation-button-el'
                                    onClick={__handle.submit.sendPhoneNumberValidationCode}
                                    disabled={!returnPhoneNumberValid(validationForm.phoneNumber) || disabledBtn}
                                >
                                    인증번호 발송
                                </SingleBlockButton>
                            </div>
                            {phoneValidationCodeInputModeOpen &&
                                <div
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <input
                                        type='text'
                                        className={`input-item`}
                                        name='phoneNumberValidationCode'
                                        value={validationForm.phoneNumberValidationCode || ''}
                                        placeholder="인증번호를 입력하세요."
                                        onChange={(e) => onChangeValueOfName(e)}
                                        minLength={6}
                                        maxLength={6}
                                        required
                                    ></input>
                                    <div className='input-notice'>해당 번호로 인증번호를 발송했습니다.(유효시간 30분)</div>
                                    <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                    <div className='input-notice' style={{ color: 'red' }}>가입되어 있지 않은 휴대전화는 인증번호를 받을 수 없습니다.</div>
                                    <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메세지를 확인하여 주세요.</div>
                                </div>
                            }
                        </InputBox>
                        <SingleBlockButton
                            type='submit'
                            className='submit-button'
                            disabled={disabledBtn || !returnPhoneNumberValid(validationForm.phoneNumber) || !returnPhoneNumberValidationCodeValid(validationForm.phoneNumberValidationCode)}
                        >
                            다음
                        </SingleBlockButton>
                    </FormGroup>
                </Wrapper>
            </Container>
        </>
    );
}