import { useState } from "react";
import { useValidationForm } from "./hooks/findUsernameHooks";
import ResultFieldComponent from "./result-field/ResultField.component";
import ValidationFormFieldComponent from "./validation-form-field/ValidationFormField.component";

export default function MainComponent(props) {
    const {
        reqFindUsername
    } = useValidationForm();

    const [username, setUsername] = useState(null);

    const __handle = {
        submit: {
            findUsername: async ({ body }) => {
                let data = await reqFindUsername({
                    body
                });

                if (data) {
                    setUsername({ ...data })
                }
            }
        }
    }

    if (username) {
        return (
            <>
                <ResultFieldComponent
                    username={username}
                />
            </>
        );
    }

    return (
        <>
            <ValidationFormFieldComponent
                onSubmitFindUsername={__handle.submit.findUsername}
            />
        </>
    );
}