import { csSmsTemplateDataConnect } from "data_connect/csSmsTemplateDataConnect";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCustomRouterHook } from "../../../../../hooks/router/useCustomRouterHook";

export const useCustomModalOpen = () => {
    const [open, setOpen] = useState(false);

    const openModal = (callback) => {
        setOpen(true);
    }

    const closeModal = (callback) => {
        setOpen(false);
    }
    return {
        open,
        openModal,
        closeModal
    }
}

export const useCsSmsTemplates = () => {
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook();

    const [csSmsTemplates, setCsSmsTemplates] = useState(null);

    useEffect(() => {
        if (!customRouter.isReady) {
            return;
        }

        if (userRdx.isLoading) {
            return;
        }

        if (!userRdx.userInfo) {
            return;
        }

        reqFetch();
    }, [customRouter.isReady, userRdx.isLoading, userRdx.userInfo]);

    const reqFetch = async () => {
        let params = {
            page: customRouter.query.page,
            size: customRouter.query.size
        }
        await csSmsTemplateDataConnect().searchList({ params })
            .then(res => {
                if (res.status === 200) {
                    setCsSmsTemplates(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    const reqCreateOne = async ({ body, callback }) => {
        await csSmsTemplateDataConnect().createOne({ body: body })
            .then(res => {
                if (res.status === 200) {
                    reqFetch();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
        callback();
    }

    const reqChangeMessage = async ({ body, callback }) => {
        await csSmsTemplateDataConnect().changeMessage({ body: body })
            .then(res => {
                if (res.status === 200) {
                    reqFetch();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        callback();
    }

    const reqChangeName = async ({ body, callback }) => {
        await csSmsTemplateDataConnect().changeName({ body: body })
            .then(res => {
                if (res.status === 200) {
                    reqFetch();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        callback();
    }

    const reqDelete = async ({ body, callback }) => {
        await csSmsTemplateDataConnect().deleteOne({ body })
            .then(res => {
                if (res.status === 200) {
                    reqFetch();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        callback();
    }

    return {
        csSmsTemplates,
        reqCreateOne,
        reqChangeMessage,
        reqChangeName,
        reqDelete
    }
}

export const useSelectedCsSmsTemplate = () => {
    const [selectedCsSmsTemplate, setSelectedCsSmsTemplate] = useState(null);

    const onSet = (csSmsTemplate) => {
        setSelectedCsSmsTemplate(csSmsTemplate);
    }

    const onActionSelect = (csSmsTemplate) => {
        if (selectedCsSmsTemplate && selectedCsSmsTemplate.id === csSmsTemplate.id) {
            setSelectedCsSmsTemplate(null);
            return;
        }

        setSelectedCsSmsTemplate({ ...csSmsTemplate });
    }

    const onRefresh = () => {
        setSelectedCsSmsTemplate(null);
    }

    return {
        selectedCsSmsTemplate,
        onActionSelect,
        onRefresh,
        onSet
    }

}

export const useCsSmsTemplate = () => {
    const [csSmsTemplate, setCsSmsTemplate] = useState(null);
    const [changeNameModeOpen, setChangeNameModeOpen] = useState(false);
    const [changeMessageModeOpen, setChangeMessageModeOpen] = useState(false);
    const [deleteModeOpen, setDeleteModeOpen] = useState(false);

    const onInit = (data) => {
        setCsSmsTemplate(_.cloneDeep(data));
    }

    const onClear = () => {
        setCsSmsTemplate(null);
        setChangeNameModeOpen(false)
        setChangeMessageModeOpen(false);
        setDeleteModeOpen(false);
    }

    const onOpenChangeNameMode = (data) => {
        onInit(data);
        setChangeNameModeOpen(true);
    }

    const onCloseChangeNameMode = () => {
        onClear();
    }

    const onOpenChangeMessageMode = (data) => {
        onInit(data);
        setChangeMessageModeOpen(true);
    }

    const onCloseChangeMessageMode = () => {
        onClear();
    }

    const onOpenDeleteMode = (data) => {
        onInit(data);
        setDeleteModeOpen(true);
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setCsSmsTemplate({
            ...csSmsTemplate,
            [name]: value
        })
    }

    return {
        csSmsTemplate,
        changeNameModeOpen,
        changeMessageModeOpen,
        deleteModeOpen,
        onInit,
        onClear,
        onOpenChangeNameMode,
        onCloseChangeNameMode,
        onOpenChangeMessageMode,
        onCloseChangeMessageMode,
        onChangeValueOfName,
        onOpenDeleteMode
    }
}

export const useCreateTemplate = () => {
    const [createTemplate, setCreateTemplate] = useState({
        name: ''
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setCreateTemplate({
            ...createTemplate,
            [name]: value
        })
    }

    return {
        createTemplate,
        onChangeValueOfName
    }
}

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }

        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [disabledBtn, setDisabledBtn]
}