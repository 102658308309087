import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
`;

export const Wrapper = styled.div`
    
`;

export const CartItemsWrapper = styled.div`
    overflow: auto;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    max-height: 200px;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }

    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }

    @media all and (max-width: 992px){
        max-height: 150px;
    }
`;

export const CardItemBox = styled.div`
    user-select: none;
    -webkit-tap-highlight-color: #00000000;
    padding:10px 20px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    font-size: 16px;

    &:nth-last-child(1){
        border-bottom: none;    
    }

    @media all and (max-width: 992px){
        font-size: 14px;
    }
`;

export const CartItemsWrapperLoading = styled.div`
    overflow: auto;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 200px;
    
    .empty-notice{
        font-size: 14px;
        font-weight: 500;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    @media all and (max-width: 992px){
        height: 150px;
    }
`;

export const CardItemBoxLoading = styled.div`
    user-select: none;
    -webkit-tap-highlight-color: #00000000;
    padding:10px 20px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    font-size: 16px;

    &:nth-last-child(1){
        border-bottom: none;    
    }

    @media all and (max-width: 992px){
        font-size: 14px;
    }

    .card-item{
        height: 20px;
    }
`;