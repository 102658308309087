import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import { useSelector } from "react-redux";
import usePhoneNumberHook from "../hooks/usePhoneNumberHook";
import { ModifyPhoneNumberModalWrapper } from "../view-profile/ViewProfile.styled";

export default function ModifyPhoneNumberModal({
    onClose
}) {
    const userRdx = useSelector(state => state.userRedux);

    const {
        phoneNumber,
        phoneNumberValidationCode,
        validationInputModeOpen,
        returnPhoneNumberValid,
        onChangePhoneNumber,
        onChangePhoneNumberValidationCode,
        reqSendPhoneNumberValidationCode,
        reqChangePhoneNumber
    } = usePhoneNumberHook();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            modify: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                let body = {
                    phoneNumber: phoneNumber,
                    phoneNumberValidationCode: phoneNumberValidationCode
                }

                await reqChangePhoneNumber({
                    body: body,
                    successCallback: () => onClose()
                });
            },
            sendPhoneNumberValidationCode: async () => {
                setDisabledBtn(true);
                if (!returnPhoneNumberValid(phoneNumber)) {
                    alert('휴대전화 형식이 정확하지 않습니다.');
                    return;
                }

                const body = {
                    phoneNumber: phoneNumber
                }
                await reqSendPhoneNumberValidationCode({
                    body: body,
                    successCallback: () => { }
                });
            }
        }
    }
    return (
        <ModifyPhoneNumberModalWrapper>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    onClick={onClose}
                    className='header-close-button-el'
                >
                    <img
                        className='header-close-button-icon'
                        src='/assets/icon/close_default_959eae.svg'
                        alt='close icon'
                    ></img>
                </button>
            </div>
            <form onSubmit={__handle.submit.modify}>
                <div className='content-group'>
                    <div className='title'>
                        회원정보 중 <span style={{ color: '#b39283' }}>전화번호</span>를 수정하기 위해 인증절차가 필요합니다.
                    </div>
                    <div className='content-box'>
                        <div className='info-box'>
                            <img
                                className='icon-el'
                                src='/assets/icon/phone_default_black.svg'
                                alt='phone icon'
                            ></img>
                            <div
                                className='info-el'
                            >
                                {userRdx?.userInfo?.phoneNumber || ''}
                            </div>
                        </div>
                    </div>
                    <div className='content-box'>
                        <div className='input-box'>
                            <div className='flex'>
                                <input
                                    className='input-el'
                                    type='text'
                                    placeholder='휴대전화'
                                    value={phoneNumber || ''}
                                    onChange={onChangePhoneNumber}
                                    required
                                ></input>
                                <SingleBlockButton
                                    type='button'
                                    className='button-el'
                                    onClick={__handle.submit.sendPhoneNumberValidationCode}
                                    disabled={disabledBtn}
                                >
                                    인증
                                </SingleBlockButton>
                            </div>
                        </div>
                        {validationInputModeOpen &&
                            <div className='input-box'>
                                <input
                                    className='input-el'
                                    type='text'
                                    placeholder='인증번호'
                                    value={phoneNumberValidationCode || ''}
                                    onChange={onChangePhoneNumberValidationCode}
                                    required
                                ></input>
                                <div className='input-notice'>해당 번호로 인증번호를 발송했습니다.(유효시간 30분)</div>
                                <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                <div className='input-notice' style={{ color: 'red' }}>이미 등록된 전화번호는 인증번호를 받을 수 없습니다.</div>
                                <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메세지를 확인하여 주세요.</div>
                            </div>
                        }
                    </div>
                </div>
                <div className='button-group'>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        style={{
                            background: '#959eae',
                            flex: 1
                        }}
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='submit'
                        className='button-el'
                        style={{
                            background: '#b39283',
                            width: '60%'
                        }}
                        disabled={disabledBtn}
                    >
                        변경
                    </SingleBlockButton>
                </div>
            </form>
        </ModifyPhoneNumberModalWrapper>
    );
}