import { userDataConnect } from "data_connect/userDataConnect";
import { validationDataConnect } from "data_connect/validationDataConnect";
import { useState } from "react";
import { useDispatch } from "react-redux";
import formatValidUtils from "utils/formatValidUtils";

export default function usePhoneNumberHook(props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberValidationCode, setPhoneNumberValidationCode] = useState('');
    const [validationInputModeOpen, setValidationInputModeOpen] = useState(false);
    const reduxDispatch = useDispatch();

    const onChangePhoneNumber = (e) => {
        let value = e.target.value;

        setPhoneNumber(value);
    }

    const onChangePhoneNumberValidationCode = (e) => {
        let value = e.target.value;
        setPhoneNumberValidationCode(value);
    }

    const returnPhoneNumberValid = (phoneNumber) => {
        return formatValidUtils.isPhoneNumberFormatValid(phoneNumber);
    }

    const reqFetchUserInfo = async () => {
        await userDataConnect().searchUserInfo()
            .then(res => {
                if (res.status === 200) {
                    reduxDispatch({
                        type: 'USER_REDUX_SET_DATA',
                        payload: {
                            userInfo: res.data.data,
                            isLoading: false,
                            status: 'fetched',
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err, err.response);
                reduxDispatch({
                    type: 'USER_REDUX_SET_DATA',
                    payload: {
                        userInfo: null,
                        isLoading: false,
                        status: 'fetched'
                    }
                });
            })
    }

    const reqSendPhoneNumberValidationCode = async ({ body, successCallback }) => {

        await validationDataConnect().sendPhoneValidationCodeV2({ phoneNumber: body.phoneNumber, validationType: 'forModify' })
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                    setValidationInputModeOpen(true);
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

    }

    const reqChangePhoneNumber = async ({ body, successCallback }) => {
        let bool = await userDataConnect().changePhoneNumber({ body })
            .then(res => {
                if (res.status === 200) {
                    return true;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        if (bool) {
            alert('변경되었습니다.');
            await reqFetchUserInfo();
            successCallback();

        }
    }

    return {
        phoneNumber,
        phoneNumberValidationCode,
        validationInputModeOpen,
        returnPhoneNumberValid,
        onChangePhoneNumber,
        onChangePhoneNumberValidationCode,
        reqSendPhoneNumberValidationCode,
        reqChangePhoneNumber
    }
}