import styled from 'styled-components';

export const Wrapper = styled.div`
    border:1px solid #e0e0e0;
    border-radius: 15px;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    overflow: hidden;
    background: white;

    .order-submit-button-el{
        margin:0;
        padding:0;
        margin-top: 10px;
        height:48px;
        font-size:20px;
        font-weight: 700;
        border: none;
        background:#b39283;
        color:#fff;
    }
`;

export const HeadFieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 20px;
    border-bottom: 1px solid #f0f0f0;

    .room-info-box{
        font-size: 20px;
        font-weight: 600;

        @media all and (max-width: 992px){
            font-size: 16px;
        }
    }

    .button-el{
        position:relative;
        padding:0;
        margin:0;
        border-radius: 50%;
        width:50px;
        height:50px;
        border: none;

        &:hover{
            background:#f0f0f060;
        }

        @media all and (max-width: 992px){
            width:35px;
            height:35px;
        }
    }
    .button-el>.icon{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:70%;
        height: 70%;
    }
`;

export const CartProductCard = styled.div`
    padding:10px 0;
    border-bottom: 1px solid #f0f0f0;

    &:nth-last-child(1){
        border-bottom: none;
    }
    
    .card-wrapper {
        display: flex;
    }

    .delete-button-box{
        display: flex;
        align-items: center;
        padding: 0 20px;

        @media all and (max-width: 992px){
            padding: 0 10px;
        }
    }.delete-button-box>.button-el{
        /* position:relative; */
        padding:0;
        margin:0;
        border-radius: 50%;
        width:50px;
        height:50px;
        border: none;

        &:hover{
            background:#f0f0f060;
        }

        @media all and (max-width: 992px){
            width:35px;
            height:35px;
        }
    }.delete-button-box>.button-el>.icon{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:70%;
        height: 70%;
    }

    .image-box{
        width:120px;
        border-radius: 10px;
        overflow: hidden;

        @media all and (max-width: 992px){
            width:90px;
        }
    }.image-box>.image-figure{
        position:relative;
        padding-bottom:100%;
    }.image-box>.image-figure>.image-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .content-box{
        flex:1;
        padding: 0 20px;

        @media all and (max-width: 992px){
            padding: 0 10px;
        }
    }

    .content-box>.product-name{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        color:#303030;

        @media all and (max-width: 992px){
            font-size: 14px;
        }
    }

    .content-box>.product-info{
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color:#707070;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }
`;

export const OrderFormModalWrapper = styled.div`
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }


    .title-box{
        padding: 0 20px;
    }

    .title-el{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color: #303030;
        padding-bottom: 20px;
    }

    .content-wrapper{
        margin-top: 40px;
        padding: 0 20px;
    }

    .control-box{
        margin-top: 40px;
    }

    .input-label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .input-item{
        width:100%;

        &:focus{
            border-color:red;
        }
    }

    .time-control-group{
        margin-top: 40px;
        display: flex;
        flex-direction: row;

        @media all and (max-width: 992px){
            flex-direction: column;
        }
    }

    .time-control-group-block{
        padding:10px;

        @media all and (max-width: 992px){
            padding:5px;
        }
    }

    .select-label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .select-item{
        width: 100%;
    }

    .date-picker{
        width: 100%;
    }

    .diffHour-box{
        margin-top: 20px;
        font-weight: 600;
        font-size: 16px;

        @media all and (max-width: 992px){
            font-size: 14px;
        }
    }

    .button-box{
        margin-top: 40px;
        display: flex;
    }

    .button-el{
        margin: 0;
        padding: 0;
        height: 48px;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
    }
`;

export const OrderFormModalV2Wrapper = styled.div`
    background: #f9fbfc;
    .MuiOutlinedInput-root{
        border-radius: 10px;
    }

    .MuiOutlinedInput-root>fieldset{
        border-color: #e0e0e0;
    }

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }


    .title-box{
        padding: 0 20px;
    }

    .title-el{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color: #303030;
        padding-bottom: 20px;
    }

    .content-container{
        overflow: hidden;
        padding: 0 20px;
    }

    .content-wrapper{
        margin-top: 40px;
        border:1px solid #e0e0e0;
        border-radius: 15px;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
        background: #fff;

        .content-wrapper-label{
            font-size: 16px;
            font-weight: 500;
            color:#303030;
            padding: 10px;
            border-bottom: 1px solid #e0e0e0;
        }

        .content-group{
            display: flex;
            margin-top: 20px;
            padding: 0 10px;

            &:last-child{
                margin-bottom: 20px;
            }

            .content-el{
                font-size: 16px;
                font-weight: 500;
                flex: 1
            }
        }

        .content-input-box{
            margin-top: 30px;
            padding:0 10px;

            &:last-child{
                margin-bottom: 30px;
            }

            .content-input-item{
                width: 100%;
                box-sizing: border-box;
            }
        }
    }

    .control-box{
        margin-top: 30px;
        padding: 0 10px;
    }

    .input-label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .input-item{
        width:100%;

        &:focus{
            border-color:red;
        }
    }

    .time-control-group{
        margin-top: 30px;
        padding: 0 10px;
        display: flex;
        flex-direction: row;

        @media all and (max-width: 992px){
            flex-direction: column;
        }
    }

    .time-control-group-block{
        padding:10px;

        @media all and (max-width: 992px){
            padding:5px;
        }
    }

    .select-label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .select-item{
        width: 100%;
    }

    .date-picker{
        width: 100%;
        border:none;
    }

    .diffHour-box{
        padding:0 10px;
        margin-top: 20px;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 16px;

        @media all and (max-width: 992px){
            font-size: 14px;
        }
    }

    .button-box{
        margin-top: 40px;
        display: flex;
    }

    .button-el{
        margin: 0;
        padding: 0;
        height: 48px;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
    }
`;

export const OrderFormConfirmationContentBox = styled.div`
    padding:20px;

    .item-box{
        padding: 10px 0;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 5px;
    }

    .item-box>.item-name{
        font-size: 14px;
        font-weight: 600;
        color:#555;
    }

    .item-box>.item-discounted{
        font-size: 13px;
        color: red;
    }

    .item-box>.item-price{
        font-size: 14px;
        font-weight: 600;
        color: black;
    }

    .info-item-box{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        line-height: 1.5;

        &:first-child{
            margin-top: 0;
        }

        @media all and (max-width: 992px){
            flex-direction: column;
        }
    }

    .info-item-box>.info-item-label{
        font-size: 14px;
        font-weight: 700;
        color:#303030;
        width: 120px;
    }

    .info-item-box>.info-item-content{
        flex:1;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 600;
        color:#555;

        @media all and (max-width: 992px){
            justify-content: flex-start;
        }
    }

    .info-price-box{
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        line-height: 1.3;

        @media all and (max-width: 992px){
            flex-direction: column;
        }
    }

    .info-price-box>.info-price-label{
        font-size: 16px;
        font-weight: 600;
        color:#f36565;
        margin-bottom: 3px;
    }

    .info-price-box>.info-price-content{
        font-size: 16px;
        font-weight: 600;
        color:#f36565;
        margin-bottom: 3px;
        width:200px;
        display: flex;
        justify-content: flex-end;

        @media all and (max-width: 992px){
            width:auto;
        }
    }

    .info-notice-box{
        margin: 0;
        padding: 0 10px;
    }

    .info-notice-box>.info-notice-content{
        margin-top: 10px;
        font-size: 13px;
        font-weight: 700;
        color:#f36565;
        word-break: keep-all;

        &:first-child{
            margin-top: 0;
        }
    }
`;
