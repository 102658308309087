import { resetPasswordTokenDataConnect } from "data_connect/resetPasswordTokenDataConnect";
import { userDataConnect } from "data_connect/userDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useState } from "react";
import formatValidUtils from "utils/formatValidUtils";

export default function useForm(props) {
    const customRouter = useCustomRouterHook();
    const [form, setForm] = useState({
        password: '',
        passwordChecker: ''
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setForm({
            ...form,
            [name]: value
        })
    }

    const returnPasswordValid = (password) => {
        return formatValidUtils.isPasswordFormatValid(password);
    }

    const returnPasswordCheckerValid = (password, passwordChecker) => {
        if (password === passwordChecker) {
            return true;
        }

        return false;
    }

    const reqChangePassword = async ({ body, successCallback }) => {
        await resetPasswordTokenDataConnect().changePassword({ body })
            .then(res => {
                if (res.status === 200) {
                    alert('비밀번호가 정상적으로 변경되었습니다.\n현재 로그인된 모든 환경에서 로그아웃 됩니다.');
                    successCallback();
                    // customRouter.push({
                    //     pathname: '/login',
                    //     replace: true
                    // })
                    return;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    return {
        form,
        returnPasswordValid,
        returnPasswordCheckerValid,
        onChangeValueOfName,
        reqChangePassword
    }
}