import axios from "axios"
import { axiosAuthInterceptor } from "./axiosInterceptors";
import { csrfDataConnect } from "./csrfDataConnect";

const MAIN_API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const userConsentDataConnect = () => {
    return {
        searchMy: async () => {
            return await axiosAuthInterceptor.get(`${MAIN_API_ADDRESS}/api/v1/user-consents/my`, {
                withCredentials: true,
            })
        },
        changeMarketingPhoneYn: async ({ body }) => {
            await csrfDataConnect().getApiCsrf();
            return await axiosAuthInterceptor.patch(`${MAIN_API_ADDRESS}/api/v1/user-consents/target:marketingPhoneYn`, body, {
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        },
        changeMarketingEmailYn: async ({ body }) => {
            await csrfDataConnect().getApiCsrf();
            return await axiosAuthInterceptor.patch(`${MAIN_API_ADDRESS}/api/v1/user-consents/target:marketingEmailYn`, body, {
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        }
    }
}

export {
    userConsentDataConnect
}