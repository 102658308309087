import { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { productDataConnect } from '../../data_connect/productDataConnect';
import { useCustomRouterHook } from '../../hooks/router/useCustomRouterHook';
import { numberFormatHandler } from '../../utils/numberFormatHandler';
import SingleBlockButton from '../module/button/SingleBlockButton';
import InfoLayout from './layout/InfoLayout';
import Slider from 'react-slick';
import ImageFieldComponent from './image-field/ImageField.component';
import InfoFieldComponent from './info-field/InfoField.component';
import LineBreakerBottom from '../module/fragment/LineBreakerBottom';
import FloatCartMainComponent from '../float-cart';
import { useProductPage, useProductPageByCategory, useProductPageByRoom } from './hooks/productHooks';
import ProductsByRoomFieldComponent from './products-byroom-field/ProductsByRoomField.component';
import ProductsByCategoryFieldComponent from './products-bycategory-field/ProductsByCategoryField.component';
import { Helmet } from 'react-helmet-async';
import useProductPackagesHook from './hooks/useProductPackagesHook';

const Container = styled.div`
    margin-top: 20px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;
    padding: 0 10px;

    @media all and (max-width:992px){
    }
`;

export default function MainComponent(props) {
    const customRouter = useCustomRouterHook();
    const [product, dispatchProduct] = useReducer(productReducer, initialProduct);
    const {
        productPage: productPageByRoom,
        reqFetch: reqFetchProductPageByRoom
    } = useProductPageByRoom();

    const {
        productPage: productPageByCategory,
        reqFetch: reqFetchProductPageByCategory
    } = useProductPageByCategory();

    const {
        productPackages
    } = useProductPackagesHook({ packageYn: product?.packageYn });

    useEffect(() => {
        window.scrollTo(0, 0);
        __product.req.fetch();

    }, [customRouter.location]);

    useEffect(() => {
        if (!product) {
            return null;
        }

        let params1 = {
            roomId: product.room.id,
            page: 1,
            size: 10,
            displayYn: 'y',
            orderType: 'order_rank',
            related: 'roomAndRegions'
        }

        let params2 = {
            categoryId: product.productCategoryId,
            page: 1,
            size: 10,
            displayYn: 'y',
            orderType: 'order_rank',
            related: 'roomAndRegions'
        }

        reqFetchProductPageByRoom({ params: params1 });
        reqFetchProductPageByCategory({ params: params2 });

    }, [product]);

    const __product = {
        req: {
            fetch: async () => {
                const productId = customRouter.query.productId || null;
                const params = {
                    displayYn: 'y',
                    related: 'categoryAndRoomAndRegionsAndImages'
                }

                await productDataConnect().searchOneById({ productId: productId, params })
                    .then(res => {
                        if (res.status === 200) {
                            dispatchProduct({
                                type: 'SET_DATA',
                                payload: res.data.data
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err, err.response);
                    })
            }
        }
    }

    if (!product) {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{product.name} - 캠핑 용품 대여는 캠팔</title>
            </Helmet>
            <Container>
                <InfoLayout>
                    <ImageFieldComponent
                        images={product.productImages}
                        product={product}
                    />
                    <div className='flex-block'></div>
                    <InfoFieldComponent
                        product={product}
                        productPackages={productPackages}
                    />
                </InfoLayout>
                {productPageByRoom &&
                    <ProductsByRoomFieldComponent
                        productPage={productPageByRoom}
                        roomName={product.room.name}
                        roomId={product.room.id}
                    />
                }
                {productPageByCategory &&
                    <ProductsByCategoryFieldComponent
                        productPage={productPageByCategory}
                    />
                }
            </Container>
            <FloatCartMainComponent />
        </>
    );
}

const initialProduct = null;

const productReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload;
        case 'CLEAR':
            return initialProduct;
        default: return initialProduct;
    }
}