import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import useNicknameHook from "../hooks/useNicknameHook";
import { ModifyNicknameModalWrapper } from "../view-profile/ViewProfile.styled";

export default function ModifyNicknameModal({
    onClose
}) {
    const {
        nickname,
        onChangeNickname,
        returnNicknameValid,
        reqChangeNickname
    } = useNicknameHook();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            modify: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (!returnNicknameValid(nickname)) {
                    alert('2-15자로 입력해주세요.')
                    return;
                }

                let body = {
                    nickname: nickname
                }
                await reqChangeNickname({
                    body: body,
                    successCallback: () => onClose()
                });
            }
        }
    }
    return (
        <ModifyNicknameModalWrapper>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    onClick={onClose}
                    className='header-close-button-el'
                >
                    <img
                        className='header-close-button-icon'
                        src='/assets/icon/close_default_959eae.svg'
                        alt='close icon'
                    ></img>
                </button>
            </div>
            <form onSubmit={__handle.submit.modify}>
                <div className='content-group'>
                    <div className='title'>
                        수정하실 회원님의 <span style={{ color: '#b39283' }}>이름</span>을 지정해 주세요.
                    </div>
                    <div className='content-box'>
                        <div className='input-box'>
                            <input
                                className='input-el'
                                type='text'
                                placeholder='이름'
                                value={nickname || ''}
                                onChange={onChangeNickname}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className='button-group'>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        style={{
                            background: '#959eae',
                            flex: 1
                        }}
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='submit'
                        className='button-el'
                        style={{
                            background: '#b39283',
                            width: '60%'
                        }}
                        disabled={disabledBtn}
                    >
                        변경
                    </SingleBlockButton>
                </div>
            </form>
        </ModifyNicknameModalWrapper>
    );
}