import { useEffect, useState } from 'react';
import { numberFormatHandler } from '../../../utils/numberFormatHandler';
import SingleBlockButton from '../../module/button/SingleBlockButton';
import LineBreakerBottom from '../../module/fragment/LineBreakerBottom';
import CommonModalComponent from '../../module/modal/CommonModalComponent';
import CustomSelect from '../../module/select/CustomSelect';
import { v4 as uuidv4 } from 'uuid';
import { useCartListLocalStorage } from '../../../hooks/useCartListLocalStorage';
import { AddCartModalWrapper, ContactModalWrapper, Container, ContentWrapper, PackageListContainer, PackageListWrapper } from './InfoField.styled';
import BasicSnackbar from 'views/module/snackbar/BasicSnackbar';
import { useCustomRouterHook } from 'hooks/router/useCustomRouterHook';

export default function InfoFieldComponent(props) {
    const customRouter = useCustomRouterHook();
    const [addCartModalOpen, setAddCartModalOpen] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);

    const __handle = {
        action: {
            openAddCartModal: () => {
                setAddCartModalOpen(true);
            },
            closeAddCartModal: () => {
                setAddCartModalOpen(false);
            },
            openContactModal: () => {
                setContactModalOpen(true);
            },
            closeContactModal: () => {
                setContactModalOpen(false);
            },
            routeToRoom: (roomId) => {
                customRouter.push({
                    pathname: '/room',
                    query: {
                        roomId: roomId
                    }
                })
            }
        }
    }
    return (
        <>
            <Container>
                <ContentWrapper>
                    <div
                        className='room-box'
                    >
                        <div
                            className='badge-figure'
                            onClick={() => __handle.action.routeToRoom(props.product.room.id)}
                        >
                            <img
                                className='badge'
                                src={props.product.room.profileImageUri || '/assets/icon/face_default_black.svg'}
                                alt='badge'
                                loading='lazy'
                            ></img>
                        </div>
                        <div
                            className='nickname'
                            onClick={() => __handle.action.routeToRoom(props.product.room.id)}
                        >
                            {props.product.room.name} 님의 제품
                        </div>
                    </div>
                </ContentWrapper>
                <ContentWrapper>
                    <div className='product-title'>
                        {props.product.name}
                    </div>
                </ContentWrapper>
                <ContentWrapper>
                    <div
                        className='description-box'
                    >
                        {props.product.description}
                    </div>
                </ContentWrapper>
                {props.productPackages &&
                    <>
                        <LineBreakerBottom
                            gapTop={40}
                        />
                        <ContentWrapper>
                            <PackageListContainer>
                                <div className='title-el'>
                                    패키지 구성
                                </div>
                                <PackageListWrapper>
                                    {props.productPackages.map(r => {
                                        return (
                                            <div key={r.id} className='package-item-box'>
                                                <div className='thumbnail-box'>
                                                    <div className='thumbnail-figure'>
                                                        <img
                                                            className='thumbnail-el'
                                                            src={r.thumbnailUri || '/assets/icon/landscape_default_e0e0e0.svg'}
                                                            alt='thumbnail'
                                                            loading="lazy"
                                                        ></img>
                                                    </div>
                                                </div>
                                                <div className='title-box'>
                                                    <div className='title-el'>
                                                        {r.name}
                                                    </div>
                                                </div>
                                                <div className='unit-box'>
                                                    <div className='unit-el'>
                                                        {r.unit} 개
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </PackageListWrapper>
                            </PackageListContainer>
                        </ContentWrapper>
                    </>
                }
                <LineBreakerBottom
                    gapTop={40}
                />
                <ContentWrapper>
                    <div
                        className='region-list-box'
                    >
                        <div className='title'>픽업 | 반납 가능 장소</div>
                        <ul className='item-list'>
                            {props.product.regions.map(r => {
                                return (
                                    <li
                                        key={r.id}
                                        className='item'
                                    >
                                        {r.fullAddress}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </ContentWrapper>
                <ContentWrapper>
                </ContentWrapper>
                <ContentWrapper>
                    <div
                        className='rentalHour-box'
                    >
                        최소 대여 가능 시간 {props.product.minimumRentalHour}H
                    </div>
                    <div
                        className='price-box'
                    >
                        <div>
                            가격(1시간)
                        </div>
                        <div>
                            {numberFormatHandler().numberWithCommas(props.product.price || 0)} 원
                        </div>
                    </div>
                    {props.product.discountYn === 'y' &&
                        <div
                            className='price-box_24h'
                        >
                            <div>
                                {props.product.discountMinimumHour}H 이상 대여시
                            </div>
                            <div>
                                {props.product.discountRate} % 할인
                            </div>
                        </div>
                    }
                    <div
                        className='price-box_24h'
                    >
                        <div>
                            24H 기준 가격
                        </div>
                        <div>
                            {numberFormatHandler().numberWithCommas((props.product.price * 24) || 0)} 원
                        </div>
                    </div>
                </ContentWrapper>
                <ContentWrapper>
                    <div className='button-group'>
                        <SingleBlockButton
                            type='button'
                            className='contact-button'
                            onClick={__handle.action.openContactModal}
                        >
                            전화번호
                        </SingleBlockButton>
                        <SingleBlockButton
                            type='button'
                            className='add-button'
                            onClick={__handle.action.openAddCartModal}
                        >
                            장바구니에 담기
                        </SingleBlockButton>
                    </div>
                </ContentWrapper>
            </Container>

            <CommonModalComponent
                open={addCartModalOpen}
                onClose={__handle.action.closeAddCartModal}
            >
                <AddCartModal
                    product={props.product}
                    onActionCloseModal={__handle.action.closeAddCartModal}
                />
            </CommonModalComponent>

            <CommonModalComponent
                open={contactModalOpen}
                onClose={__handle.action.closeContactModal}
            >
                <ContactModal
                    product={props.product}
                    onClose={__handle.action.closeContactModal}
                />
            </CommonModalComponent>
        </>
    );
}

function AddCartModal({
    product,
    onActionCloseModal
}) {
    const [cartList, setCartList] = useCartListLocalStorage();
    const [unit, setUnit] = useState(1);
    const [unitList, setUnitList] = useState([]);

    useEffect(() => {
        if (!product?.maxOrderUnit) {
            return;
        }

        let units = [];
        for (let i = 0; i < product?.maxOrderUnit; i++) {
            units.push(i);
        }

        setUnitList([...units]);
    }, []);

    const __handle = {
        change: {
            unit: (e) => {
                let value = e.target.value;
                setUnit(value);
            }
        },
        submit: {
            confirm: (e) => {
                e.preventDefault();

                if (cartList.length >= 20) {
                    alert('장바구니는 최대 20개 까지 담을 수 있습니다.');
                    return;
                }

                let dataList = [
                    ...cartList
                ];
                dataList.push(
                    {
                        id: uuidv4(),
                        productId: product.id,
                        roomId: product.roomId,
                        roomName: product.room.name,
                        unit: unit
                    }
                )
                setCartList(dataList);
                onActionCloseModal();
            }
        }
    }
    return (
        <>
            <AddCartModalWrapper>
                <div className='product-title'>{product?.name}</div>
                <div className='count-select-box'>
                    <div className='label'>대여 수량 선택</div>
                    <CustomSelect
                        className='item'
                        value={unit || '1'}
                        onChange={__handle.change.unit}
                        required
                    >
                        {unitList?.map((r, index) => {
                            return (
                                <option key={`unit-${index + 1}`} value={r + 1}>{r + 1}개</option>
                            );
                        })}
                    </CustomSelect>
                </div>
                <div className='button-box'>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        onClick={onActionCloseModal}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        onClick={__handle.submit.confirm}
                        style={{
                            color: '#b39283',
                            fontWeight: '600'
                        }}
                    >
                        장바구니 담기
                    </SingleBlockButton>
                </div>
            </AddCartModalWrapper>
        </>
    );
}

function ContactModal({
    product,
    onClose
}) {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: ''
    });

    const __handle = {
        action: {
            copyToClipboard: () => {
                let phoneNumber = product.room.phoneNumber;
                navigator.clipboard.writeText(phoneNumber);

                __handle.action.openSnackbar({
                    message: '전화번호를 클립보드로 복사했습니다.'
                })
            },
            openSnackbar: ({ message }) => {
                setSnackbar({
                    ...snackbar,
                    open: true,
                    message: message
                })
            },
            closeSnackbar: () => {
                setSnackbar({
                    ...snackbar,
                    open: false,
                    message: ''
                })
            },
            tel: () => {
                const tel = document.createElement('a');
                tel.href = `tel:${product.room.phoneNumber}`;
                document.body.appendChild(tel);
                tel.click();
                document.body.removeChild(tel);
            }
        }
    }
    return (
        <>
            <ContactModalWrapper>
                <div className='title'>
                    {product.room.name}
                </div>
                <div className='phoneNumber-el'>
                    {product.room.phoneNumber}
                </div>
                <div className='button-group'>
                    <SingleBlockButton
                        className='button-el'
                        style={{
                            color: '#b39283'
                        }}
                        onClick={__handle.action.copyToClipboard}
                    >
                        전화번호 복사
                    </SingleBlockButton>
                    <SingleBlockButton
                        className='button-el'
                        style={{
                            background: '#b39283',
                            color: '#fff'
                        }}
                        onClick={__handle.action.tel}
                    >
                        전화걸기
                    </SingleBlockButton>
                </div>
            </ContactModalWrapper>

            {snackbar.open &&
                <BasicSnackbar
                    open={snackbar.open}
                    message={snackbar.message}
                    duration={2000}
                    onClose={__handle.action.closeSnackbar}
                    severity={'info'}
                />
            }
        </>
    );
}