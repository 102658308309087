import styled from 'styled-components';

export const Conatainer = styled.div`
    background:#f9fbfc;

    .MuiOutlinedInput-root{
        border-radius: 10px;
    }

    .MuiOutlinedInput-root>fieldset{
        border-color: #e0e0e0;
    }

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }


    .title-box{
        padding: 0 20px;
    }

    .title-el{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color: #303030;
        padding-bottom: 20px;
    }
`;

export const Wrapper = styled.div`
    padding: 20px;
`;

export const CardWrapper = styled.div`
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
`;

export const SearchWrapper = styled.div`
    .date-select-group{
        margin-top: 20px;
        display: flex;
    }

    .date-select-box{
        flex:1;
    }
    
    .orderType-buttons-group{
        margin-top: 20px;
    }

    .orderType-button-el{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        margin-right: 8px;
        margin-bottom: 8px;
        background: white;
        font-size: 14px;
        /* padding: 10px 15px; */
        width:70px;
        height: 32px;
        border: 1px solid #e0e0e0;
        border-radius: 15px;
        color:#404040;
        cursor: pointer;

        &:last-child{
            margin-right: 0;
        }
    }

    .orderType-button-active{
        background:#b39283;
        border: 1px solid #b39283;
        color:white;
        font-weight: 500;
    }

    .submit-button-el{
        margin:0;
        padding:0;
        margin-top: 20px;
        height: 48px;
        border-radius: 10px;
        border: 1px solid #b39283;
        background: white;
        color: #b39283;
        font-weight: 600;

        &:hover{
            border: 1px solid #ffffff;
            background: #b39283;
            color: #ffffff;
        }
    }
`;

export const ResultWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    .table-wrapper{
        border: 1px solid #e0e0e0;
        border-radius: 10px;

    }

    .table-el{
        width: 100%;
        text-align: center;
    }

    .table-el th, td{
        padding: 5px 10px;
        font-size: 16px;
        color:#303030;

        @media all and (max-width: 992px){
            padding: 5px 5px;
            font-size: 12px;
        }
    }

    .table-el .th-1{
        width:70%;
        border-bottom: 1px solid #e0e0e0;
    }

    .table-el .th-2{
        width:30%;
        border-bottom: 1px solid #e0e0e0;
    }

    .empty-box{
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        height: 100px;
    }

    .empty-el{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #303030;
        font-weight: 500;
    }
`;