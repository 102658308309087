import styled from 'styled-components';

export const Layout = styled.div`
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 150px;
    padding: 20px 10px 0 10px;
    display: flex;
    flex-direction: row;

    @media all and (max-width:992px){
        flex-direction: column;
    }
`;

export default function LayoutComponent(props) {
    return (
        <Layout>
            {props.children}
        </Layout>
    );
}