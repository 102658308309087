import { rentalOrderProductDataConnect } from "data_connect/rentalOrderProductDataConnect";
import { useState } from "react";

export default function useCountProducts(props) {
    const [countProducts, setCountProducts] = useState(null);

    const reqFetchCountProducts = async ({ params }) => {
        await rentalOrderProductDataConnect().countProducts({ params: { ...params } })
            .then(res => {
                if (res.status === 200) {
                    setCountProducts(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    return {
        countProducts,
        reqFetchCountProducts
    }
}