import { rentalOrderProductDataConnect } from "data_connect/rentalOrderProductDataConnect";
import { useEffect, useState } from "react";

export default function useRentalOrderProductsHook(props) {
    const [rentalOrderProducts, setRentalOrderProducts] = useState(null);

    useEffect(() => {
        if (!props.selectedRentalOrderInfo) {
            return;
        }
        reqFetchRentalOrderProducts();
    }, [props.selectedRentalOrderInfo])

    const reqFetchRentalOrderProducts = async () => {
        await rentalOrderProductDataConnect().searchListByRentalOrderInfoId({ rentalOrderInfoId: props.selectedRentalOrderInfo.id })
            .then(res => {
                if (res.status === 200) {
                    setRentalOrderProducts(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        rentalOrderProducts
    }
}