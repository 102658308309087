import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import FormFieldComponent from "./form-field/FormField.component";
import useForm from "./hooks/useForm";
import useResetPasswordToken from "./hooks/useResetPasswordToken";

export default function MainComponent(props) {
    const customRouter = useCustomRouterHook();
    const {
        reqCheckTokenValid
    } = useResetPasswordToken();
    const {
        reqChangePassword
    } = useForm();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function init() {
            let resetToken = customRouter.query.resetToken;

            if (!resetToken) {
                alert('페이지가 유효하지 않습니다.');
                customRouter.goBack();
                return;
            }

            let validChecker = await reqCheckTokenValid({ resetToken });

            if (validChecker === 'valid') {
                setIsLoading(false)
            } else {
                setIsLoading(true);
                alert('유효기간이 만료되었습니다.');
                customRouter.goBack();
            }
        }

        init();
    }, []);

    const __handle = {
        submit: {
            changePassword: async ({ form }) => {
                let body = {
                    ...form,
                    resetToken: customRouter.query.resetToken
                }
                await reqChangePassword({
                    body,
                    successCallback: () => { customRouter.push({ pathname: '/login', replace: true }) }
                })
            }
        }
    }
    if (!isLoading) {
        return (
            <>
                <FormFieldComponent
                    onSubmitChangePassword={__handle.submit.changePassword}
                />
            </>

        );
    }

    return null;
}