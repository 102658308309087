import CustomBlockButton from 'components/buttons/block-button/v1/CustomBlockButton';
import * as St from './ElLoadProduct.styled';
import { useEffect, useState } from 'react';
import { productDataConnect } from 'data_connect/productDataConnect';
import { useSelector } from 'react-redux';
import { CustomImage } from 'components/image/custom_image';
import PagenationComponentStateV2 from 'views/module/pagenation/PagenationStateComponentV2';

export function ElLoadProduct({
    toggleLoadProductModeOpen,
    onSelect
}) {
    const userRdx = useSelector(state => state.userRedux);

    const [productPage, setProductPage] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(30);
    const [productPagePending, setProductPagePending] = useState(false);


    const handleReqFetchProductPage = async () => {
        setProductPagePending(true);

        let params = {
            roomId: userRdx.userInfo.roomId,
            categoryId: categoryId || null,
            page: page || null,
            size: size || null,
            orderType: 'order_new',
            related: 'room'
        }

        const fetchResult = await productDataConnect().searchPage({ params: params })
            .then(res => {
                if (res.status === 200) {
                    return {
                        res: res,
                        content: res.data.data
                    }
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
            .finally(() => {
                setProductPagePending(false);
            })
            ;

        if (fetchResult?.content) {
            setProductPage(fetchResult?.content);
        }
    }

    const handleChangePage = (value) => {
        setPage(value);
    }

    const handleChangeSize = (value) => {
        setSize(value);
    }

    const handleSelectItem = (product) => {
        let body = {
            productId: product?.id,
            thumbnailUri: product?.thumbnailUri,
            productName: product?.name,
            price: product?.price,
            discountYn: product?.discountYn,
            discountMinimumHour: product?.discountMinimumHour,
            discountRate: product?.discountRate,
        }

        onSelect(body);
    }

    useEffect(() => {
        if (!userRdx.userInfo.roomId) {
            return;
        }

        handleReqFetchProductPage();
    }, [userRdx.userInfo.roomId, page, size]);

    return (
        <>
            <St.Container>
                <St.PrevButtonBox>
                    <CustomBlockButton
                        type='button'
                        onClick={() => toggleLoadProductModeOpen(false)}
                    >
                        이전
                    </CustomBlockButton>
                </St.PrevButtonBox>
                <St.CardListWrapper>
                    {productPage?.content?.map(product => {
                        return (
                            <St.CardBox key={product?.id} onClick={() => handleSelectItem(product)}>
                                <div className='flexBox flexGap-10 flexAlign-center'>
                                    <section className='thumbnailBox'>
                                        <CustomImage>
                                            <CustomImage.Image src={product?.thumbnailUri} />
                                        </CustomImage>
                                    </section>
                                    <section className='flexBox flexColumn'>
                                        <h3 className='productName'>{product?.name}</h3>
                                        <div className='price'>{product?.price}원</div>
                                        <div className='discount'>{product?.discountYn === 'y' ? <div>{product?.discountMinimumHour} 시간 이상 대여시 {product?.discountRate}% 할인</div> : <div style={{ color: '#a0a0a0' }}>할인이 적용 되지 않음</div>}</div>
                                    </section>
                                </div>
                            </St.CardBox>
                        );
                    })}
                    <PagenationComponentStateV2
                        align={'center'}
                        pageIndex={productPage?.number}
                        totalPages={Math.ceil(productPage?.totalElements / productPage?.size)}
                        isFirst={productPage?.first}
                        isLast={productPage?.last}
                        totalElements={productPage?.totalElements}
                        sizeElements={[30, 50, 100]}
                        size={productPage?.size}
                        onChangePage={handleChangePage}
                        onChangeSize={handleChangeSize}
                        isLoading={productPagePending}
                    />
                </St.CardListWrapper>
            </St.Container>
        </>
    );
}