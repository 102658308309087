import { userDataConnect } from "data_connect/userDataConnect";
import { useState } from "react";
import { useDispatch } from "react-redux";
import formatValidUtils from "utils/formatValidUtils";

export default function usePasswordHook(props) {
    const reduxDispatch = useDispatch();
    const [passwordJson, setPasswordJson] = useState({
        password: '',
        newPassword: '',
        newPasswordChecker: ''
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setPasswordJson({
            ...passwordJson,
            [name]: value
        })
    }

    const returnPasswordValid = (password) => {
        return formatValidUtils.isPasswordFormatValid(password);
    }

    const returnCompareValid = (password, passwordChecker) => {
        return formatValidUtils.isComparePasswordFormatValid(password, passwordChecker);
    }

    const reqFetchUserInfo = async () => {
        await userDataConnect().searchUserInfo()
            .then(res => {
                if (res.status === 200) {
                    reduxDispatch({
                        type: 'USER_REDUX_SET_DATA',
                        payload: {
                            userInfo: res.data.data,
                            isLoading: false,
                            status: 'fetched',
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err, err.response);
                reduxDispatch({
                    type: 'USER_REDUX_SET_DATA',
                    payload: {
                        userInfo: null,
                        isLoading: false,
                        status: 'fetched'
                    }
                });
            })
    }

    const reqChangePassword = async ({ body, successCallback }) => {
        let bool = await userDataConnect().changePassword({ body })
            .then(res => {
                if (res.status === 200) {
                    return true;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        if (bool) {
            alert('비밀번호가 변경되었습니다. 로그인 되어있는 모든 환경에서 로그아웃 됩니다.');
            await reqFetchUserInfo();

        }
    }

    return {
        passwordJson,
        onChangeValueOfName,
        returnPasswordValid,
        returnCompareValid,
        reqChangePassword
    }
}