import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FooterComponent from "../../../views/footer/FooterComponent";
import MainComponent from "../../../views/myadmin/products";
import NavbarMain from "../../../views/navbar/NavbarMain";
import { useCustomRouterHook } from "../../../hooks/router/useCustomRouterHook";

export default function MyadminProductsPage(props) {
    const customRouter = useCustomRouterHook();
    const userRdx = useSelector(state => state.userRedux);

    useEffect(() => {
        if (userRdx.isLoading) {
            return;
        }

        if (!userRdx.userInfo) {
            customRouter.push({
                pathname: '/',
                replace: true
            })
            return;
        }

        if (!userRdx.userInfo.roomId) {
            customRouter.push({
                pathname: '/myadmin/create',
                replace: true
            })
            return;
        }

    }, [userRdx]);

    if(userRdx.userInfo && userRdx.userInfo.roomId){
        return (
            <>
                <MainComponent />
            </>
        );
    }

    return null;

}