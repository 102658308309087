import axios from "axios"
import { axiosAuthInterceptor } from "./axiosInterceptors";
import { csrfDataConnect } from "./csrfDataConnect";

const MAIN_API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const productPackageDataConnect = () => {
    return {
        searchListByProductId: async ({ productId, params }) => {
            return await axios.get(`${MAIN_API_ADDRESS}/api/v1/product-packages/products/${productId}`, {
                params: { ...params },
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        }
    }
}

export {
    productPackageDataConnect
}