import SingleBlockButton from "views/module/button/SingleBlockButton";
import CustomCheckboxV2 from "views/module/checkbox/CustomCheckboxV2";
import CommonModalComponent from "views/module/modal/CommonModalComponent";
import CustomSelect from "views/module/select/CustomSelect";
import { useRef, useState } from "react";
import { numberFormatHandler } from "utils/numberFormatHandler";
import valueUtils from "utils/valueUtils";
import useAddProductHook from "../hooks/useAddProductHook";
import useProductCategoriesHook from "../hooks/useProductCategoriesHook";
import useProductImagesHook from "../hooks/useProductImagesHook";
import { Container, FormControlButtonGroup, FormControlInput, FormControlTextarea, FormControlWrapper, ImageListWrapper, PackageListWrapper, Wrapper } from "./FormField.styled";
import useProductPackagesHook from "../hooks/useProductPackagesHook";
import AddProductPackageModal from "./modal/AddProductPackageModal";
import ModifyProductPackageModal from "./modal/ModifyProductPackageModal";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import ImageOrderDndModal from "./modal/ImageOrderDndModal";

export default function FormFieldComponent({
    onSubmitAdd
}) {
    const fileUploaderRef = useRef();
    const {
        productCategories
    } = useProductCategoriesHook();
    const {
        addProduct,
        onChangeValueOfName,
        onChangePrice,
        onChangeMinimumRentalHour,
        onChangeDiscountYn,
        onChangeDiscountMinimumHour,
        onChangeDiscountRate,
        onChangeMaxOrderUnit,
        returnSubmitValid
    } = useAddProductHook();

    const {
        productImages,
        onPushImage,
        onDeleteImage,
        returnProductImagesSubmitValid,
        onSetProductImages
    } = useProductImagesHook();

    const {
        productPackages,
        onAddProductPackage,
        onDeleteProductPackage,
        onModifyProductPackage,
        returnProductPackagesSubmitValid,
        onSetProductPackages
    } = useProductPackagesHook({ packageYn: addProduct.packageYn });

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const [addProductPackageModalOpen, setAddProductPackageModalOpen] = useState(false);
    const [modifyProductPackageModalOpen, setModifyProductPackageModalOpen] = useState(false);
    const [selectedModifyProductPackage, setSelectedModifyProductPackage] = useState(null);
    const [imageOrderDndModalOpen, setImageOrderDndModalOpen] = useState(false);

    const __handle = {
        action: {
            openFileUploader: (e) => {
                if (productImages.length >= 10) {
                    alert('이미지는 최대 10개 까지 등록 가능합니다.');
                    return;
                }
                fileUploaderRef.current.click();
            },
            openAddProductPackageModal: () => {
                setAddProductPackageModalOpen(true);
            },
            closeAddProductPackageModal: () => {
                setAddProductPackageModalOpen(false);
            },
            openModifyProductPackageModal: (id) => {
                let selectedItem = productPackages.find(r => r.id === id);
                if (!selectedItem) {
                    return;
                }

                setSelectedModifyProductPackage({ ...selectedItem });
                setModifyProductPackageModalOpen(true);
            },
            closeModifyProductPackageModal: () => {
                setModifyProductPackageModalOpen(false);
                setSelectedModifyProductPackage(null);
            },
            openImageOrderDndModal: () => {
                setImageOrderDndModalOpen(true);
            },
            closeImageOrderDndModal: () => {
                setImageOrderDndModalOpen(false);
            },
            productPackagesOrderWithDnd: (result) => {
                if (!result.destination) {
                    return;
                }

                let newProductPackages = valueUtils.reorder(
                    productPackages,
                    result.source.index,
                    result.destination.index
                );

                onSetProductPackages(newProductPackages);
            }
        },
        submit: {
            confirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (!returnProductImagesSubmitValid()) {
                    return;
                }

                if (!returnProductPackagesSubmitValid()) {
                    return;
                }

                if (!returnSubmitValid()) {
                    return;
                }

                let body = {
                    ...addProduct,
                    price: valueUtils.isEmptyNumbers(parseInt(addProduct.price)) ? 0 : parseInt(addProduct.price),
                    minimumRentalHour: valueUtils.isEmptyNumbers(parseInt(addProduct.minimumRentalHour)) ? 1 : parseInt(addProduct.minimumRentalHour),
                    discountMinimumHour: valueUtils.isEmptyNumbers(parseInt(addProduct.discountMinimumHour)) ? 0 : parseInt(addProduct.discountMinimumHour),
                    discountRate: valueUtils.isEmptyNumbers(parseInt(addProduct.discountRate)) ? 0 : parseInt(addProduct.discountRate),
                    productImages: [...productImages],
                    productPackages: [...productPackages]
                }

                onSubmitAdd({
                    body: body,
                    successCallback: () => { }
                });
            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <form onSubmit={__handle.submit.confirm}>
                        <FormControlWrapper
                            style={{
                                borderBottom: '1px solid #e0e0e0'
                            }}
                        >
                            <div className='label'>이미지({productImages.length} / 10)</div>
                            <ImageListWrapper>
                                {productImages.map((r, index) => {
                                    return (
                                        <div
                                            key={r.id}
                                            className='image-item-box'
                                        >
                                            <img
                                                className='image-el'
                                                src={r.fileFullUri}
                                                alt="file"
                                            ></img>
                                            <button
                                                type='button'
                                                className='image-delete-button'
                                                onClick={() => onDeleteImage(r.id)}
                                            >
                                                <img
                                                    className='image-delete-button-icon'
                                                    src='/assets/icon/remove_default_red.svg'
                                                    alt='delete icon'
                                                ></img>
                                            </button>
                                        </div>
                                    );
                                })}
                                <button
                                    type='button'
                                    className='button-item'
                                    onClick={__handle.action.openFileUploader}
                                >
                                    <img
                                        className='button-icon'
                                        src='/assets/icon/add_default_b39283.svg'
                                        alt={'add icon'}
                                    ></img>
                                </button>
                                <button
                                    type='button'
                                    className='button-item'
                                    onClick={__handle.action.openImageOrderDndModal}
                                >
                                    <img
                                        className='button-icon'
                                        src='/assets/icon/priority_default_b39283.svg'
                                        alt={'priority icon'}
                                    ></img>
                                </button>
                                <input
                                    ref={fileUploaderRef}
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    style={{ display: 'none' }}
                                    onClick={(e) => e.target.value = ''}
                                    onChange={(e) => onPushImage(e)}
                                    hidden
                                />
                            </ImageListWrapper>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div className='label'>제품명</div>
                            <FormControlInput
                                type='text'
                                name='name'
                                value={addProduct.name || ''}
                                onChange={onChangeValueOfName}
                                required
                            ></FormControlInput>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div className='label'>카테고리</div>
                            <CustomSelect
                                style={{
                                    margin: 0,
                                    marginTop: '5px',
                                    height: '48px'
                                }}
                                name='productCategoryId'
                                value={addProduct.productCategoryId || ''}
                                onChange={onChangeValueOfName}
                                required
                            >
                                <option value=''>카테고리 선택</option>
                                {productCategories?.map(r => {
                                    return (
                                        <option key={r.id} value={r.id}>{r.name}</option>
                                    )
                                })}
                            </CustomSelect>
                        </FormControlWrapper>
                        {addProduct.packageYn === 'y' &&
                            <FormControlWrapper>
                                <div className='label'>패키지 구성</div>
                                <PackageListWrapper>
                                    <DragDropContext onDragEnd={__handle.action.productPackagesOrderWithDnd}>
                                        <Droppable
                                            droppableId={uuidv4()}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {productPackages.map((r, index) => {
                                                        return (
                                                            <Draggable
                                                                key={r.id}
                                                                draggableId={r.id}
                                                                index={index}

                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                        key={r.id}
                                                                        className='package-item-box'
                                                                    >
                                                                        <div className="image-box">
                                                                            <img
                                                                                className='image-el'
                                                                                src={r.thumbnailUri || '/assets/icon/landscape_default_e0e0e0.svg'}
                                                                                alt='thumbnail'
                                                                            ></img>
                                                                        </div>
                                                                        <div className='package-item-content-group'>
                                                                            <div className='info-box'>
                                                                                <div className='info-name'>
                                                                                    제품명: {r.name}
                                                                                </div>
                                                                                <div className='info-unit'>
                                                                                    수량: {r.unit} 개
                                                                                </div>
                                                                            </div>
                                                                            <div className='button-group'>
                                                                                <SingleBlockButton
                                                                                    type='button'
                                                                                    className='button-el'
                                                                                    style={{
                                                                                        border: '1px solid #b39283',
                                                                                        color: '#b39283'
                                                                                    }}
                                                                                    onClick={() => __handle.action.openModifyProductPackageModal(r.id)}
                                                                                >
                                                                                    수정
                                                                                </SingleBlockButton>
                                                                                <SingleBlockButton
                                                                                    type='button'
                                                                                    className='button-el'
                                                                                    onClick={() => onDeleteProductPackage(r.id)}
                                                                                >
                                                                                    삭제
                                                                                </SingleBlockButton>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <SingleBlockButton
                                        type='button'
                                        onClick={__handle.action.openAddProductPackageModal}
                                        className='add-package-item-button-el'
                                    >
                                        구성 제품 추가
                                    </SingleBlockButton>
                                </PackageListWrapper>
                            </FormControlWrapper>
                        }
                        <FormControlWrapper>
                            <div className='label'>시간당 가격(원)</div>
                            <FormControlInput
                                type='text'
                                name='price'
                                value={numberFormatHandler().numberWithCommas(addProduct.price) || ''}
                                onChange={onChangePrice}
                                required
                            ></FormControlInput>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div className='label'>최소 대여 가능 <span style={{ color: '#b39283' }}>시간</span></div>
                            <FormControlInput
                                type='number'
                                name='minimumRentalHour'
                                value={addProduct.minimumRentalHour || ''}
                                onChange={onChangeMinimumRentalHour}
                                required
                            ></FormControlInput>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div className='label'>최대 선택 가능 <span style={{ color: '#b39283' }}>수량</span></div>
                            <FormControlInput
                                type='number'
                                name='maxOrderUnit'
                                value={addProduct.maxOrderUnit || ''}
                                onChange={onChangeMaxOrderUnit}
                                required
                            ></FormControlInput>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div>
                                <CustomCheckboxV2
                                    label={'할인 적용하기 (선택)'}
                                    checked={addProduct.discountYn === 'y' ? true : false}
                                    onChange={onChangeDiscountYn}
                                    labelStyle={{
                                        color: '#000000de'
                                    }}
                                />
                            </div>
                            {addProduct.discountYn === 'y' &&
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1
                                        }}
                                    >
                                        <FormControlInput
                                            type='text'
                                            name='discountMinimumHour'
                                            value={addProduct.discountMinimumHour || ''}
                                            max={100}
                                            min={0}
                                            placeholder={''}
                                            onChange={onChangeDiscountMinimumHour}
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        ></FormControlInput>
                                    </div>
                                    <div style={{ fontSize: '14px', fontWeight: '500', margin: '0 5px' }}>시간 이상 대여시</div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <FormControlInput
                                            type='text'
                                            name='discountRate'
                                            value={addProduct.discountRate || ''}
                                            max={100}
                                            min={0}
                                            onChange={onChangeDiscountRate}
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        ></FormControlInput>
                                    </div>
                                    <div style={{ fontSize: '14px', fontWeight: '500', marginLeft: '5px' }}>(%) 할인</div>
                                </div>
                            }
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <div className='label'>설명 (선택)</div>
                            <FormControlTextarea
                                name='description'
                                value={addProduct.description || ''}
                                onChange={onChangeValueOfName}
                            ></FormControlTextarea>
                        </FormControlWrapper>
                        <FormControlWrapper>
                            <FormControlButtonGroup>
                                <SingleBlockButton
                                    type='submit'
                                    className='button-el'
                                    disabled={disabledBtn}
                                >
                                    추가
                                </SingleBlockButton>
                            </FormControlButtonGroup>
                        </FormControlWrapper>
                    </form>
                </Wrapper>
            </Container>

            {addProductPackageModalOpen &&
                <CommonModalComponent
                    open={addProductPackageModalOpen}
                    onClose={__handle.action.closeAddProductPackageModal}
                >
                    <AddProductPackageModal
                        onClose={__handle.action.closeAddProductPackageModal}
                        onConfirm={onAddProductPackage}
                    />
                </CommonModalComponent>
            }

            {modifyProductPackageModalOpen &&
                <CommonModalComponent
                    open={modifyProductPackageModalOpen}
                    onClose={__handle.action.closeModifyProductPackageModal}
                >
                    <ModifyProductPackageModal
                        selectedModifyProductPackage={selectedModifyProductPackage}
                        onClose={__handle.action.closeModifyProductPackageModal}
                        onConfirm={onModifyProductPackage}
                    />
                </CommonModalComponent>
            }

            {imageOrderDndModalOpen &&
                <CommonModalComponent
                    open={imageOrderDndModalOpen}
                    onClose={__handle.action.closeImageOrderDndModal}
                >
                    <ImageOrderDndModal
                        productImages={productImages}
                        onClose={__handle.action.closeImageOrderDndModal}
                        onSetProductImages={onSetProductImages}
                    />
                </CommonModalComponent>
            }
        </>
    );
}