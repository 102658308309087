import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { CardBox, CardListWrapper, Container, HeaderWrapper } from "./ProductsByCategoryField.styled";

export default function ProductsByCategoryFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            routeTo: (productId) => {
                customRouter.push({
                    pathname: `/product`,
                    query: {
                        ...customRouter.query,
                        productId: productId
                    }
                })
            }
        }
    }

    return (
        <>
            <Container>
                <HeaderWrapper>
                    <div className='title-el'>
                        이런 제품들을 찾으시나요?
                    </div>
                </HeaderWrapper>
                <CardListWrapper>
                    {props.productPage.content.map(r => {
                        return (
                            <CardBox
                                key={r.id}
                                onClick={() => __handle.action.routeTo(r.id)}
                            >
                                <div className='thumbnail-box'>
                                    <div className='thumbnail-figure'>
                                        <img
                                            className='thumbnail-el'
                                            src={r.thumbnailUri}
                                            alt='thumbnail'
                                            loading="lazy"
                                        ></img>
                                    </div>
                                </div>
                                <div className='room-box'>
                                    <div className='room-el'>
                                        {r.room.name}
                                    </div>
                                </div>
                                <div className='title-box'>
                                    <div className='title-el'>
                                        {r.name}
                                    </div>
                                </div>
                                <div className='price-box'>
                                    <div className='price-el'>
                                        {numberFormatHandler().numberWithCommas(r.price || 0)} 원 (1시간)
                                    </div>
                                </div>
                                <div className='place-box'>
                                    {r.regions.map(region => {
                                        return (
                                            <span
                                                className='place-badge-el'
                                                key={region.id}
                                            >
                                                {region.sido} {region.sigungu}
                                            </span>
                                        );
                                    })}
                                </div>
                            </CardBox>
                        );
                    })}
                </CardListWrapper>
            </Container>
        </>
    );
}