import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import { useState } from "react";
import useUserHook from "../hooks/useUserHook";
import { WithdrawalModalWrapper } from "../view-profile/ViewProfile.styled";

export default function WithdrawalModal({
    onClose
}) {
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();
    const {
        reqWithdrawal
    } = useUserHook();
    const [password, setPassword] = useState('');

    const __handle = {
        action: {
            onChangePassword: (e) => {
                let value = e.target.value;

                setPassword(value);
            }
        },
        submit: {
            confirm: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if (window.confirm('회원탈퇴시 연관된 모든 데이터는 삭제되며 복구가 불가합니다.\n정말로 회원탈퇴 하시겠습니까?')) {
                    let body = {
                        password: password
                    }
                    await reqWithdrawal({
                        body: body,
                        successCallback: () => {
                            alert('그동안 저희 사이트를 이용해주시고 사랑해주셔서 감사합니다.\n더 나은 서비스를 위해 더욱 노력하는 캠팔이 되겠습니다.');
                            window.location.reload();
                        }
                    })

                }
            }
        }
    }

    return (
        <>
            <WithdrawalModalWrapper>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={onClose}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <form
                    onSubmit={__handle.submit.confirm}
                >
                    <div className='content-group'>
                        <div className='title'>
                            <span style={{ color: '#b39283' }}>회원탈퇴</span>를 위한 본인확인 절차를 완료해 주세요.
                        </div>
                        <div className='content-box'>
                            <div className='input-box'>
                                <input
                                    className='input-el'
                                    type='password'
                                    placeholder="비밀번호"
                                    value={password || ''}
                                    onChange={__handle.action.onChangePassword}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className='button-group'>
                        <SingleBlockButton
                            type='submit'
                            className='button-el'
                            style={{
                                background: '#b39283',
                                flex: 1
                            }}
                            disabled={disabledBtn}
                        >
                            회원탈퇴
                        </SingleBlockButton>
                        <SingleBlockButton
                            type='button'
                            className='button-el'
                            style={{
                                background: '#959eae',
                                width: '60%'
                            }}
                            onClick={onClose}
                        >
                            취소
                        </SingleBlockButton>
                    </div>
                </form>
            </WithdrawalModalWrapper>
        </>
    );
}