import { useEffect, useState } from "react";
import { dateFormatUtils } from "utils/dateFormatUtils";
import valueUtils from "utils/valueUtils";

export default function useSearchFormHook(props) {
    const [searchForm, setSearchForm] = useState({
        startDate: dateFormatUtils().getStartDate(new Date()),
        endDate: dateFormatUtils().getEndDate(new Date()),
        orderTypes: []
    });

    useEffect(() => {
        onInitOrderTypes();
    }, [])

    const onInitOrderTypes = () => {
        setSearchForm({
            ...searchForm,
            orderTypes: [...props.orderTypes]
        })
    }

    const onChangeStartDate = (value) => {
        setSearchForm({
            ...searchForm,
            startDate: dateFormatUtils().getStartDate(value || new Date())
        })
    }

    const onChangeEndDate = (value) => {
        setSearchForm({
            ...searchForm,
            endDate: dateFormatUtils().getEndDate(value || new Date())
        })
    }

    const onSelectOrderType = (orderType) => {
        let currOrderTypes = [...searchForm.orderTypes];

        if (currOrderTypes.includes(orderType)) {
            currOrderTypes = currOrderTypes.filter(r => r !== orderType);
            setSearchForm({
                ...searchForm,
                orderTypes: [...currOrderTypes]
            })
            return;
        }

        currOrderTypes.push(orderType);
        setSearchForm({
            ...searchForm,
            orderTypes: [...currOrderTypes]
        })
    }

    const onCheckFormValid = () => {
        if (!dateFormatUtils().isValidDate(searchForm.startDate) || !dateFormatUtils().isValidDate(searchForm.endDate)) {
            throw new Error('시작 날짜 및 종료 날짜를 정확히 지정해 주세요.');
        }

        if (searchForm.startDate > searchForm.endDate) {
            throw new Error('시작 날짜는 종료 날짜 이후로만 선택 가능합니다.');
        }

        if(valueUtils.isEmptyValues(searchForm.orderTypes)){
            throw new Error('주문상태를 최소 1개 선택해 주세요.');
        }
    }

    return {
        searchForm,
        onChangeStartDate,
        onChangeEndDate,
        onSelectOrderType,
        onCheckFormValid
    }
}