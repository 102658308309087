import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import useForm from "../hooks/useForm";
import { Container, FormGroup, HeaderWrapper, InputBox, Wrapper } from "./FormField.styled";

export default function FormFieldComponent(props) {
    const {
        form,
        onChangeValueOfName,
        returnPasswordValid,
        returnPasswordCheckerValid
    } = useForm();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            confirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if(!returnPasswordValid(form.password)){
                    alert('비밀번호는 영문, 숫자, 특수문자 혼합 8-50자로 지정해주세요.');
                    return;
                }
                
                if (!returnPasswordCheckerValid(form.password, form.passwordChecker)) {
                    alert('비밀번호를 다시 확인해 주세요.');
                    return;
                }

                props.onSubmitChangePassword({ form: { ...form } })

            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <HeaderWrapper>
                        <div>
                        </div>
                        <div className='head-box'>
                            <div className='title'>
                                비밀번호 재설정
                            </div>
                        </div>
                        <div>
                        </div>
                    </HeaderWrapper>
                    <FormGroup
                        onSubmit={__handle.submit.confirm}
                    >
                        <InputBox>
                            <div
                                className='input-label'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >변경할 비밀번호</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <input
                                    type='password'
                                    className={`input-item`}
                                    name='password'
                                    value={form.password || ''}
                                    onChange={(e) => onChangeValueOfName(e)}
                                    placeholder={'영문, 숫자, 특수문자 혼합 8-50자'}
                                    required
                                ></input>
                            </div>
                        </InputBox>
                        <InputBox>
                            <div
                                className='input-label'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >변경할 비밀번호 확인</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <input
                                    type='password'
                                    className={`input-item`}
                                    name='passwordChecker'
                                    value={form.passwordChecker || ''}
                                    onChange={(e) => onChangeValueOfName(e)}
                                    required
                                ></input>
                            </div>
                        </InputBox>
                        <SingleBlockButton
                            type='submit'
                            className='submit-button'
                            disabled={disabledBtn || !returnPasswordValid(form.password) || !returnPasswordCheckerValid(form.password, form.passwordChecker)}
                        >
                            확인
                        </SingleBlockButton>
                    </FormGroup>
                </Wrapper>
            </Container>
        </>
    );
}