import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    margin-top: 20px;
`;

export const Wrapper = styled.div`
    .image-box{
        width:200px;
        overflow: hidden;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        cursor: pointer;
        -webkit-tap-highlight-color: #00000000;

        @media all and (max-width: 992px){
            width:150px;
        }
    }.image-box>.image-figure{
        position:relative;
        padding-bottom:100%;
    }.image-box>.image-figure>.image-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }
`;

export const ChangeProfileImageModalWrapper = styled.div`
    padding: 20px;

    .button-el{
        &:nth-child(1){
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:nth-last-child(1){
            /* margin-top: 10px; */
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        margin:0;
        height: 48px;
        border-color:#f0f0f0;
        color:#505050;

        @media all and (max-width:992px){
            height: 40px;
        }
    }
`;
