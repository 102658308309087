import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import { useDisabledBtn, useUser } from "../hooks/findPasswordValidationHooks";
import { Container, FormGroup, HeaderWrapper, InputBox, Wrapper } from "./FormField.styled";

export default function FormFieldComponent(props) {
    const customRouter = useCustomRouterHook();
    const {
        user,
        returnPhoneNumberValid,
        returnUsernameValid,
        returnPhoneNumberValidationCodeValid,
        onSetUser,
        onChangeValueOfName,
        reqSendPhoneNumberValidationCode
    } = useUser();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const [phoneValidationCodeInputModeOpen, setPhoneValidationCodeInputModeOpen] = useState(false);

    useEffect(() => {
        let username = customRouter.query.u;
        let phoneNumber = customRouter.query.p;

        onSetUser({
            ...user,
            username: username,
            phoneNumber: phoneNumber
        })
    }, []);

    const __handle = {
        submit: {
            sendPhoneNumberValidationCode: () => {
                if (!returnPhoneNumberValid(user.phoneNumber)) {
                    alert('알 수 없는 휴대전화 양식');
                    return;
                }

                let body = {
                    phoneNumber: user.phoneNumber
                }

                reqSendPhoneNumberValidationCode({
                    body: body,
                    successCallback: () => { setPhoneValidationCodeInputModeOpen(true) }
                });
            },
            registerResetPassword: (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if (!returnUsernameValid(user.username)) {
                    alert('알 수 없는 아이디 양식. 다시 시도해 주세요.');
                }

                if (!returnPhoneNumberValid(user.phoneNumber)) {
                    alert('알 수 없는 휴대전화 양식. 다시 시도해 주세요.');
                    return;
                }

                if (!returnPhoneNumberValidationCodeValid(user.phoneNumberValidationCode)) {
                    alert('인증번호를 다시 한번 확인해 주세요. ');
                    return;
                }

                let body = {
                    username: user.username,
                    phoneNumber: user.phoneNumber,
                    phoneNumberValidationCode: user.phoneNumberValidationCode
                }
                props.onSubmitRegisterResetPassword({ body });
            }
        }
    }
    return (
        <>
            <Container>
                <Wrapper>
                    <HeaderWrapper>
                        <div className='head-box'>
                        </div>
                        <div className='head-box'>
                            <div className='title'>
                                비밀번호 찾기
                            </div>
                        </div>
                        <div className='head-box'>
                        </div>
                    </HeaderWrapper>
                    <FormGroup onSubmit={__handle.submit.registerResetPassword}>
                        <InputBox>
                            <div className='case-text'>회원정보에 등록된 전화번호로 인증</div>
                        </InputBox>
                        <InputBox>
                            <SingleBlockButton
                                type='button'
                                onClick={__handle.submit.sendPhoneNumberValidationCode}
                                className='validation-button-el'
                            >
                                인증번호 발송
                            </SingleBlockButton>
                            {phoneValidationCodeInputModeOpen &&
                                <div
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <input
                                        type='text'
                                        className={`input-item`}
                                        name='phoneNumberValidationCode'
                                        value={user.phoneNumberValidationCode || ''}
                                        placeholder="인증번호를 입력하세요."
                                        onChange={(e) => onChangeValueOfName(e)}
                                        minLength={6}
                                        maxLength={6}
                                        required
                                    ></input>
                                    <div className='input-notice'>해당 번호로 인증번호를 발송했습니다.(유효시간 30분)</div>
                                    <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                    <div className='input-notice' style={{ color: 'red' }}>등록되어 있지 않은 휴대전화는 인증번호를 받을 수 없습니다.</div>
                                    <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메세지를 확인하여 주세요.</div>
                                </div>
                            }
                        </InputBox>
                        <SingleBlockButton
                            type='submit'
                            className='submit-button'
                            disabled={disabledBtn || !returnUsernameValid(user.username) || !returnPhoneNumberValid(user.phoneNumber) || !returnPhoneNumberValidationCodeValid(user.phoneNumberValidationCode)}
                        >
                            다음
                        </SingleBlockButton>
                    </FormGroup>
                </Wrapper>
            </Container>
        </>
    );
}