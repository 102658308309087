import { useEffect } from "react";
import { useCsSmsTemplates, useSelectedCsSmsTemplate } from "./hooks/smsTemplateHooks";
import MainLayout from "./layout/MainLayout";
import TemplateFormFieldComponent from "./template-form-field/TemplateFormField.component";
import TemplateListFieldComponent from "./template-list-field/TemplateListField.component";
import TopControlFieldComponent from "./top-control-field/TopControlField.component";

export default function MainComponent(props) {
    const {
        csSmsTemplates,
        reqCreateOne: reqCreateCsSmsTemplate,
        reqChangeMessage: reqChangeMessageInCsSmsTemplate,
        reqChangeName: reqChangeNameInCsSmsTemplate,
        reqDelete: reqDeleteCsSmsTemplate
    } = useCsSmsTemplates();

    const {
        selectedCsSmsTemplate,
        onActionSelect,
        onRefresh: onRefreshSelectedCsSmsTemplate,
        onSet: onSetSelectedCsSmsTemplate
    } = useSelectedCsSmsTemplate();

    useEffect(() => {
        if (!csSmsTemplates || !selectedCsSmsTemplate) {
            return;
        }

        let template = csSmsTemplates.find(r => r.id === selectedCsSmsTemplate.id);

        if (template) {
            onSetSelectedCsSmsTemplate(template);
        } else {
            onRefreshSelectedCsSmsTemplate();
        }

    }, [csSmsTemplates])

    return (
        <>
            <TopControlFieldComponent
                onSubmitAddTemplate={reqCreateCsSmsTemplate}
            />
            <MainLayout>
                <TemplateListFieldComponent
                    csSmsTemplates={csSmsTemplates}
                    selectedCsSmsTemplate={selectedCsSmsTemplate}

                    onActionSelect={onActionSelect}
                />
                <div style={{ padding: '20px' }}></div>
                <TemplateFormFieldComponent
                    csSmsTemplates={csSmsTemplates}
                    selectedCsSmsTemplate={selectedCsSmsTemplate}

                    onSubmitChangeMessage={reqChangeMessageInCsSmsTemplate}
                    onSubmitChangeName={reqChangeNameInCsSmsTemplate}
                    onSubmitDelete={reqDeleteCsSmsTemplate}
                />
            </MainLayout>
        </>
    );
}