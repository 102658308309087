import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import { useState } from "react";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { v4 as uuidv4 } from 'uuid';

export default function useAddProductPackageHook(props) {
    const [addProductPackage, setAddProductPackage] = useState({
        id: uuidv4(),
        name: '',
        unit: '',
        thumbnailUri: ''
    });

    const { __reqUploadImageFile } = useImageFileUploaderHook();

    const onChangeThumbnailUri = async (e) => {
        e.preventDefault();

        // 파일을 선택하지 않은 경우
        if (e.target.files.length <= 0) return;

        let imageInfos = await __reqUploadImageFile(e);

        let thumbnailUri = imageInfos[0].fileFullUri;

        setAddProductPackage({
            ...addProductPackage,
            thumbnailUri: thumbnailUri
        })

    }

    const onChangeValueOfName = async (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value && value.length > 50) {
            return;
        }

        setAddProductPackage({
            ...addProductPackage,
            [name]: value
        })
    }

    const onChangeUnit = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 100) {
            return;
        }

        setAddProductPackage({
            ...addProductPackage,
            unit: value
        })
    }

    const returnSubmitValid = () => {
        if (!returnNameValid()) {
            alert('제품명은 1-50 자로 입력해 주세요.');
            return false;
        }

        if (!returnUnitValid()) {
            alert('수량은 1-100 개로 입력해 주세요.');
            return false;
        }

        return true;
    }

    const returnNameValid = () => {
        if (addProductPackage.name && addProductPackage.name.length > 0 && addProductPackage.name.length < 50) {
            return true;
        }

        return false;
    }

    const returnUnitValid = () => {
        if (numberFormatHandler().checkNumberOnlyFormat(addProductPackage.unit) && addProductPackage.unit > 0 && addProductPackage.unit < 100) {
            return true;
        }

        return false;
    }

    return {
        addProductPackage,
        onChangeThumbnailUri,
        onChangeValueOfName,
        onChangeUnit,
        returnSubmitValid
    }
}