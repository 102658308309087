import { productDataConnect } from "data_connect/productDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useProductPage = () => {
    const customRouter = useCustomRouterHook();

    const [productPage, setProductPage] = useState(null);

    useEffect(() => {
        reqFetch();
    }, [customRouter.location]);

    const reqFetch = async () => {
        let roomId = customRouter.query.roomId || null;
        let page = customRouter.query.page || 1;
        let size = customRouter.query.size || 30;
        let orderType = customRouter.query.orderType || 'order_rank';
        let displayYn = 'y';
        let categoryId = customRouter.query.categoryId || null;
        let related = 'room';

        let params = {
            roomId: roomId,
            page: page,
            size: size,
            orderType: orderType,
            displayYn: displayYn,
            categoryId: categoryId,
            related: related
        }


        await productDataConnect().searchPage({ params })
            .then(res => {
                if (res.status === 200) {
                    setProductPage(res.data.data)
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        productPage
    }
}