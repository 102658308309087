import { useCustomRouterHook } from 'hooks/router/useCustomRouterHook';
import _ from 'lodash';
import React, { useReducer, useState } from 'react';
import { numberFormatHandler } from '../../../../utils/numberFormatHandler';
import SingleBlockButton from '../../../module/button/SingleBlockButton';
import ConfirmModalComponent from '../../../module/modal/ConfirmModalComponent';
import { CardWrapper, Container, ItemListWrapper } from './ProductListField.styled';

export default function ProductListFieldComponent(props) {
    const [product, dispatchProduct] = useReducer(productReducer, initialProduct);
    const [displayOnConfirmModalOpen, setDisplayOnConfirmModalOpen] = useState(false);
    const [displayOffConfirmModalOpen, setDisplayOffConfirmModalOpen] = useState(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
    const custoumRouter = useCustomRouterHook();

    const __product = {
        action: {
            openDisplayOnConfirmModal: (data) => {
                dispatchProduct({
                    type: 'SET_DATA',
                    payload: _.cloneDeep(data)
                })
                setDisplayOnConfirmModalOpen(true);
            },
            closeDisplayOnConfirmModal: () => {
                setDisplayOnConfirmModalOpen(false);
                dispatchProduct({
                    type: 'CLEAR'
                })
            },
            openDisplayOffConfirmModal: (data) => {
                dispatchProduct({
                    type: 'SET_DATA',
                    payload: _.cloneDeep(data)
                })
                setDisplayOffConfirmModalOpen(true);
            },
            closeDisplayOffConfirmModal: () => {
                setDisplayOffConfirmModalOpen(false);
                dispatchProduct({
                    type: 'CLEAR'
                })
            },
            openDeleteConfirmModal: (data) => {
                dispatchProduct({
                    type: 'SET_DATA',
                    payload: _.cloneDeep(data)
                })
                setDeleteConfirmModalOpen(true);
            },
            closeDeleteConfirmModal: () => {
                setDeleteConfirmModalOpen(false);
                dispatchProduct({
                    type: 'CLEAR'
                })
            },
            routeToModify: (productId) => {
                custoumRouter.push({
                    pathname: `/myadmin/product-modify?productId=${productId}`
                })
            }
        },
        submit: {
            confirmDisplayOff: () => {
                let body = {
                    ...product,
                    displayYn: 'n'
                }
                props.onSubmitChangeDisplayYn(body);
                __product.action.closeDisplayOffConfirmModal();
            },
            confirmDisplayOn: () => {
                let body = {
                    ...product,
                    displayYn: 'y'
                }
                props.onSubmitChangeDisplayYn(body);
                __product.action.closeDisplayOnConfirmModal();
            },
            confirmDelete: () => {
                props.onSubmitDelete(product);
                __product.action.closeDeleteConfirmModal();
            }
        }
    }

    return (
        <>
            <Container>
                <ItemListWrapper>
                    {props.products.map(product => {
                        return (
                            <ProductCard
                                key={product.id}
                                product={product}
                                allCategories={props.allCategories}
                                onActionOpenDisplayOnConfirmModal={__product.action.openDisplayOnConfirmModal}
                                onActionOpenDisplayOffConfirmModal={__product.action.openDisplayOffConfirmModal}
                                onActionOpenDeleteConfirmModal={__product.action.openDeleteConfirmModal}
                                onActionRouteToModify={__product.action.routeToModify}
                            />
                        );
                    })}
                </ItemListWrapper>
            </Container>

            <ConfirmModalComponent
                open={displayOffConfirmModalOpen}
                onClose={__product.action.closeDisplayOffConfirmModal}
                message='해당 제품의 전시상태를 전시중지로 변경 하시겠습니까?'
                onConfirm={__product.submit.confirmDisplayOff}
            />

            <ConfirmModalComponent
                open={displayOnConfirmModalOpen}
                onClose={__product.action.closeDisplayOnConfirmModal}
                message='해당 제품의 전시상태를 전시중으로 변경 하시겠습니까?'
                onConfirm={__product.submit.confirmDisplayOn}
            />

            <ConfirmModalComponent
                open={deleteConfirmModalOpen}
                onClose={__product.action.closeDeleteConfirmModal}
                message='해당 제품을 정말로 삭제하시겠습니까?'
                onConfirm={__product.submit.confirmDelete}
            />
        </>
    );
}

const initialProduct = null;
const productReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload;
        case 'CLEAR':
            return initialProduct;
        default: return initialProduct;
    }
}

function ProductCard({
    product,
    allCategories,
    onActionOpenDisplayOnConfirmModal,
    onActionOpenDisplayOffConfirmModal,
    onActionOpenDeleteConfirmModal,
    onActionRouteToModify
}) {
    return (
        <CardWrapper>
            <div className='image-box'>
                <div className='image-figure'>
                    <img
                        className='image-el'
                        src={product.thumbnailUri}
                        alt={'product thumbnail'}
                        loading={'lazy'}
                    ></img>
                </div>
            </div>
            <div className='content-box'>
                <div className='content-title'>{product.name}</div>
                <div className='content-price'>{numberFormatHandler().numberWithCommas(product.price || 0)} 원 (1시간)</div>
                <ul className='content-list'>
                    <li>
                        최소 대여 가능 시간 {product.minimumRentalHour}H
                    </li>
                    {product.discountYn === 'y' &&
                        <li>
                            {product.discountMinimumHour}H 이상 대여시 <span style={{ color: '#b39283' }}>{product.discountRate}% 할인</span>
                        </li>
                    }
                </ul>
                <div
                    className='content-category'
                >
                    {allCategories.map(r => {
                        if (r.id === product.productCategoryId) {
                            return (
                                <div
                                    key={r.id}
                                >
                                    {r.name}
                                </div>
                            );
                        } else {
                            return (
                                <React.Fragment key={r.id}></React.Fragment>
                            );
                        }
                    })}
                </div>
                <div
                    className='control-button-box'
                >
                    {product.displayYn === 'y' &&
                        (
                            <SingleBlockButton
                                type='button'
                                className='control-button-el'
                                style={{
                                    color: 'white',
                                    background: '#5fcf80'
                                }}
                                onClick={() => onActionOpenDisplayOffConfirmModal(product)}
                            >
                                전시중
                            </SingleBlockButton>
                        )
                    }

                    {product.displayYn === 'n' &&
                        (
                            <SingleBlockButton
                                type='button'
                                className='control-button-el'
                                style={{
                                    color: 'white',
                                    background: 'red'
                                }}
                                onClick={() => onActionOpenDisplayOnConfirmModal(product)}
                            >
                                전시중지
                            </SingleBlockButton>
                        )
                    }
                    <SingleBlockButton
                        type='button'
                        className='control-button-el'
                        style={{
                            color: '#2c73d2'
                        }}
                        onClick={() => onActionRouteToModify(product.id)}
                    >
                        수정
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='button'
                        className='control-button-el'
                        style={{
                            color: '#e56767'
                        }}
                        onClick={() => onActionOpenDeleteConfirmModal(product)}
                    >
                        삭제
                    </SingleBlockButton>
                </div>
            </div>
        </CardWrapper>
    );
}