import Slider from "react-slick";
import { St } from "./FdBanner.styled";
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery } from "@mui/material";
import { useRef, useState } from "react";
import DOMPurify from "dompurify";

const BANNERS = [
    {
        id: uuidv4(),
        desktopBannerName: 'banner3_desktop',
        desktopBannerUri: '/assets/banner/sample4/banner3.png',
        mobileBannerName: 'banner3',
        mobileBannerUri: '/assets/banner/sample4/banner3.png',
    },
    {
        id: uuidv4(),
        desktopBannerName: 'banner3_desktop',
        desktopBannerUri: '/assets/banner/sample4/banner2.png',
        mobileBannerName: 'banner3_mobile',
        mobileBannerUri: '/assets/banner/sample4/banner2.png',
        innerHtml: `
            <div class='card-text'>
                <div>비싼 캠핑장비 가격 때문에</div>
                <div>캠핑한번 가보기 힘드셨죠?</div>
                <div><span class='accent-1'>"캠팔"</span>로 대여해서 떠나보세요!</div>
            </div>
        `
    },
    {
        id: uuidv4(),
        desktopBannerName: 'banner2_desktop',
        desktopBannerUri: '/assets/banner/sample4/banner1.png',
        mobileBannerName: 'banner2_mobile',
        mobileBannerUri: '/assets/banner/sample4/banner1.png',
        innerHtml: `
            <div class='card-text'>
                <div>내 캠핑장비 이제는 팔지말고</div>
                <div><span class='accent-1'>"캠팔"</span>로 대여 해주고</div>
                <div><span class='accent-2'>수익화</span> 시작해 보세요!</div>
            </div>
        `
    },
]

export function FdBanner(props) {
    const isMobile = useMediaQuery(`(max-width: 992px)`);
    const sliderRef = useRef(null);
    const [sliderIndex, setSliderIndex] = useState({
        oldSlide: 0,
        activeSlide: 0,
        activeSlide2: 0
    });

    const SLIDER_SETTINGS = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            },
        ]
    };

    return (
        <>
            <St.Container>
                <St.SlickWrapper>
                    <Slider
                        ref={sliderRef}
                        {...SLIDER_SETTINGS}
                        beforeChange={(current, next) => {
                            setSliderIndex({
                                ...sliderIndex,
                                oldSlide: current,
                                activeSlide: next
                            })
                        }}
                        afterChange={(current) => {
                            setSliderIndex({
                                ...sliderIndex,
                                activeSlide2: current
                            })
                        }}
                    >
                        {BANNERS?.map(r => {
                            const sanitizedHtml = r.innerHtml ? DOMPurify.sanitize(r.innerHtml) : null;
                            return (
                                <St.CardItem
                                    key={r.id}
                                    style={{ background: sanitizedHtml ? '#000' : '#fff' }}
                                >
                                    <div className={`image-figure`}>
                                        <img
                                            className='image-el'
                                            src={isMobile ? r.mobileBannerUri : r.desktopBannerUri}
                                            alt={'banner'}
                                        ></img>
                                    </div>
                                </St.CardItem>
                            );
                        })}
                    </Slider>
                    <div
                        className='slick-prev-arrow'
                        type="button"
                        onClick={() => sliderRef?.current?.slickPrev()}
                    >
                        <img
                            className='icon'
                            src='/assets/icon/arrow_left_white.svg'
                            alt='left arrow'
                        ></img>
                    </div>
                    <div
                        className='slick-next-arrow'
                        type="button"
                        onClick={() => sliderRef?.current?.slickNext()}
                    >
                        <img
                            className='icon'
                            src='/assets/icon/arrow_right_white.svg'
                            alt='right arrow'
                        ></img>
                    </div>
                    {/* <div className='slick-numbering'>
                        {sliderIndex.activeSlide2 + 1} | {BANNERS.length}
                    </div> */}
                </St.SlickWrapper>
            </St.Container>
        </>
    );
}