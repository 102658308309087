import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SingleBlockButton from '../../../module/button/SingleBlockButton';
import CustomInput from '../../../module/form/CustomInput';
import ElementLoading from '../../../module/loading/ElementLoading';
import CommonModalComponent from '../../../module/modal/CommonModalComponent';

const Container = styled.div`
    margin-top: 20px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width:992px){
    }
`;

const ChangeNameModalWrapper = styled.form`
    .form-box{
        margin-top: 20px;
        padding:0 10px;
    }

    .button-box{
        margin-top: 70px;
        display: flex;
    }

    .button-box>.button-el{
        margin:0;
        border: none;
        letter-spacing: 0.5em;
        text-indent: 0.5em;
    }
`;

export const HeadWrapper = styled.div`
    display: flex;
    align-items: center;

    .title{
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;

        @media all and (max-width:992px){
            font-size: 20px;
        }
    }

    .title-loading{
        width:200px;
        height: 35px;
        border-radius: 10px;

        @media all and (max-width:992px){
            width:180px;
            height: 30px;
        }
    }

    .button-item{
        margin:0;
        margin-left: 20px;
        width: 100px;
        border: none;
        border-radius: 10px;
        background:#000;
        font-size: 14px;
        font-weight: 600;
        color:white;
        letter-spacing: 0.2em;
        text-indent: 0.2em;
        cursor: pointer;

        @media all and (max-width:992px){
            width: 80px;
            font-size: 12px;
        }
    }

    .button-item-loading{
        margin-left: 20px;
        width:100px;
        height: 35px;
        border-radius: 10px;

        @media all and (max-width:992px){
            width:80px;
            height: 30px;
        }
    }
`;

export default function NameFieldComponent(props) {
    const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);

    const __room = {
        action: {
            openChangeNameModal: () => {
                setChangeNameModalOpen(true);
            },
            closeChangeNameModal: () => {
                setChangeNameModalOpen(false);
            }
        },
        submit: {
            changeName: (roomName) => {
                if (props.room.name === roomName) {
                    __room.action.closeChangeNameModal();
                    return;
                }

                props.onSubmitChangeName({
                    name: roomName,
                    callback: () => __room.action.closeChangeNameModal()
                })
            }
        }
    }

    return (
        <>
            <Container>
                {props.room &&
                    <HeadWrapper>
                        <div className='title'>{props.room.name}</div>
                        <SingleBlockButton
                            type='button'
                            className='button-item'
                            onClick={__room.action.openChangeNameModal}
                        >
                            이름 변경
                        </SingleBlockButton>
                    </HeadWrapper>
                }

                {!props.room &&
                    <HeadWrapper>
                        <ElementLoading className='title-loading'></ElementLoading>
                        <ElementLoading className='button-item-loading'></ElementLoading>
                    </HeadWrapper>
                }
            </Container>

            <CommonModalComponent
                open={changeNameModalOpen}
                onClose={__room.action.closeChangeNameModal}
            >
                <ChangeNameModal
                    room={props.room}
                    onConfirm={__room.submit.changeName}
                    onClose={__room.action.closeChangeNameModal}
                />
            </CommonModalComponent>
        </>
    );
}

function ChangeNameModal({
    room,
    onConfirm,
    onClose
}) {
    const [roomName, setRoomName] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!room) {
            return;
        }

        setRoomName(room.name);
    }, [room])

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }

        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);

    }, [disabledBtn])

    const onChangeRoomName = (e) => {
        setRoomName(e.target.value);
    }

    const onSubmitChangeName = (e) => {
        e.preventDefault();
        setDisabledBtn(true);
        if (!roomName || roomName.search(/^[\s]|[\s]$/g) !== -1 || roomName.length < 2 || roomName.length > 15) {
            alert('룸 이름을 정확하게 입력해 주세요. 2-15 글자 내로 첫 글자와 마지막 글자에 공백은 허용되지 않습니다.');
            return;
        }
        onConfirm(roomName);
    }

    return (
        <ChangeNameModalWrapper onSubmit={onSubmitChangeName}>
            <div className='form-box'>
                <CustomInput
                    label={'룸 이름'}
                    placeholder={'룸 이름을 입력해 주세요. (2-15 글자)'}
                    value={roomName || ''}
                    onChange={onChangeRoomName}
                />
            </div>
            <div className='button-box'>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    onClick={onClose}
                >
                    취소
                </SingleBlockButton>
                <SingleBlockButton
                    type='submit'
                    className='button-el'
                    style={{
                        background: '#b39283',
                        color: '#fff',
                        fontWeight: '600'
                    }}
                    disabled={disabledBtn}
                >
                    확인
                </SingleBlockButton>
            </div>
        </ChangeNameModalWrapper>
    );

}