import SingleBlockButton from "views/module/button/SingleBlockButton";
import CustomInput from "views/module/form/CustomInput";
import CommonModalComponent from "views/module/modal/CommonModalComponent";
import ConfirmModalComponent from "views/module/modal/ConfirmModalComponent";
import { useEffect } from "react";
import { useCsSmsTemplate, useDisabledBtn } from "../hooks/smsTemplateHooks";
import { ChangeNameModalWrapper, Container, FunctionDescriptionWrapper, LayoutWrapper, LayoutWrapperLoading, Wrapper } from "./TemplateFormField.styled";

export default function TemplateFormFieldComponent(props) {
    const {
        csSmsTemplate: updatableCsSmsTemplate,
        changeNameModeOpen,
        changeMessageModeOpen,
        deleteModeOpen,
        onClear,
        onOpenChangeNameMode,
        onOpenChangeMessageMode,
        onChangeValueOfName,
        onOpenDeleteMode
    } = useCsSmsTemplate();

    useEffect(() => {
        onClear();
    }, [props.selectedCsSmsTemplate])

    const __handle = {
        submit: {
            confirmChangeName: () => {
                let id = props.selectedCsSmsTemplate.id;
                let name = updatableCsSmsTemplate.name;

                let body = {
                    id: id,
                    name: name
                }

                props.onSubmitChangeName({ body: body, callback: onClear })
            },
            confirmChangeMessage: () => {
                let id = props.selectedCsSmsTemplate.id;
                let message = updatableCsSmsTemplate.message;

                let body = {
                    id: id,
                    message: message
                }
                props.onSubmitChangeMessage({ body: body, callback: onClear })
            },
            confirmDelete: () => {
                let id = props.selectedCsSmsTemplate.id;

                let body = {
                    id: id
                }

                props.onSubmitDelete({ body: body, callback: onClear })
            }
        }
    }

    if (props.csSmsTemplates && props.selectedCsSmsTemplate) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <LayoutWrapper>
                            <div className='message-wrapper'>
                                {changeMessageModeOpen &&
                                    <textarea
                                        className='message-textarea-el'
                                        name='message'
                                        value={updatableCsSmsTemplate?.message || ''}
                                        onChange={onChangeValueOfName}
                                        style={{
                                            border: '1px solid #2c73d2'
                                        }}
                                    >
                                    </textarea>
                                }
                                {!changeMessageModeOpen &&
                                    <textarea
                                        className='message-textarea-el'
                                        name='message'
                                        value={props.selectedCsSmsTemplate.message || ''}
                                        placeholder='템플릿 메세지를 지정해 보세요.'
                                        style={{
                                            color: '#707070'
                                        }}
                                        readOnly
                                    >
                                    </textarea>
                                }
                            </div>
                            <div className='button-group'>
                                {changeMessageModeOpen &&
                                    <>
                                        <SingleBlockButton
                                            type='button'
                                            className='button-el'
                                            onClick={__handle.submit.confirmChangeMessage}
                                            style={{
                                                background: '#b39283',
                                                color: 'white',
                                                fontWeight: '600'
                                            }}
                                        >
                                            저장하기
                                        </SingleBlockButton>
                                        <SingleBlockButton
                                            type='button'
                                            className='button-el'
                                            onClick={() => onClear()}
                                        >
                                            취소
                                        </SingleBlockButton>
                                    </>
                                }
                                {!changeMessageModeOpen &&
                                    <>
                                        <SingleBlockButton
                                            type='button'
                                            className='button-el'
                                            onClick={() => onOpenChangeNameMode(props.selectedCsSmsTemplate)}
                                        >
                                            템플릿 이름 수정
                                        </SingleBlockButton>
                                        <SingleBlockButton
                                            type='button'
                                            className='button-el'
                                            onClick={() => onOpenChangeMessageMode(props.selectedCsSmsTemplate)}
                                        >
                                            메세지 수정
                                        </SingleBlockButton>
                                        <SingleBlockButton
                                            type='button'
                                            className='button-el'
                                            style={{
                                                color: '#e56767'
                                            }}
                                            onClick={() => onOpenDeleteMode(props.selectedCsSmsTemplate)}
                                        >
                                            템플릿 삭제
                                        </SingleBlockButton>
                                    </>
                                }

                            </div>
                        </LayoutWrapper>
                        <FunctionDescriptionWrapper>
                            <div className='header-el'>[알림문자 기능 설명]</div>
                            <ul className='description-list'>
                                <li>%$예약자이름$ : 주문정보의 예약자이름을 불러옵니다. <span style={{ color: '#808080' }}>"홍길동"</span></li>
                                <li>%$예약자연락처$ : 주문정보의 예약자연락처를 불러옵니다. <span style={{ color: '#808080' }}>"01012341234"</span></li>
                                <li>%$픽업날짜$ : 주문정보의 픽업날짜를 불러옵니다. <span style={{ color: '#808080' }}>"yy.mm.dd"</span></li>
                                <li>%$반납날짜$ : 주문정보의 반납날짜를 불러옵니다. <span style={{ color: '#808080' }}>"yy.mm.dd"</span></li>
                                <li>%$픽업시간$ : 주문정보의 픽업시간을 불러옵니다. <span style={{ color: '#808080' }}>"10:00"</span></li>
                                <li>%$반납시간$ : 주문정보의 반납시간을 불러옵니다. <span style={{ color: '#808080' }}>"10:00"</span></li>
                                <li>%$총대여시간$ : 주문정보의 총대여시간을 불러옵니다. <span style={{ color: '#808080' }}>"24H"</span></li>
                                <li>%$제품정보$ : 주문정보의 제품리스트를 불러옵니다. <span style={{ color: '#808080' }}>"캠핑의자 // 1"</span></li>
                                <li>%$최종금액$ : 주문정보의 최종금액을 불러옵니다. <span style={{ color: '#808080' }}>"10,000"</span></li>
                            </ul>
                        </FunctionDescriptionWrapper>
                    </Wrapper>
                </Container>

                {changeNameModeOpen &&
                    <CommonModalComponent
                        open={changeNameModeOpen}
                        onClose={onClear}
                    >
                        <ChangeNameModal
                            updatableCsSmsTemplate={updatableCsSmsTemplate}
                            onChangeValueOfName={onChangeValueOfName}
                            onClose={onClear}
                            onSubmitConfirm={__handle.submit.confirmChangeName}
                        />
                    </CommonModalComponent>
                }

                {deleteModeOpen &&
                    <ConfirmModalComponent
                        open={deleteModeOpen}
                        onClose={onClear}
                        message={'해당 템플릿을 삭제하시겠습니까?'}
                        onConfirm={__handle.submit.confirmDelete}
                    />
                }
            </>
        );
    }
    return (
        <Container>
            <Wrapper>
                <LayoutWrapperLoading>
                    <div className='empty-notice'>
                        템플릿을 선택해 주세요.
                    </div>
                </LayoutWrapperLoading>
            </Wrapper>
        </Container>
    );
}

function ChangeNameModal({
    updatableCsSmsTemplate,
    onSubmitConfirm,
    onChangeValueOfName,
    onClose
}) {
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            confirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (updatableCsSmsTemplate.name > 20) {
                    alert('템플릿 이름은 20자 이내로 입력해 주세요.');
                    return;
                }

                onSubmitConfirm(updatableCsSmsTemplate.name);
            }
        }
    }
    if (updatableCsSmsTemplate) {

        return (
            <ChangeNameModalWrapper>
                <form
                    className='form-box'
                    onSubmit={__handle.submit.confirm}
                >
                    <div className='input-box'>
                        <CustomInput
                            label={'템플릿 이름'}
                            name={'name'}
                            value={updatableCsSmsTemplate.name || ''}
                            onChange={onChangeValueOfName}
                        />
                    </div>
                    <div className='button-box'>
                        <SingleBlockButton
                            className='button-el'
                            type='button'
                            onClick={onClose}
                        >
                            취소
                        </SingleBlockButton>
                        <SingleBlockButton
                            className='button-el'
                            type='submit'
                            style={{
                                color: '#b39283',
                                fontWeight: 600
                            }}
                            disabled={disabledBtn}
                        >
                            생성
                        </SingleBlockButton>
                    </div>
                </form>
            </ChangeNameModalWrapper>
        );
    }
    return null;
}