import { TextField } from "@mui/material";
import { fontSize } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SingleBlockButton from "views/module/button/SingleBlockButton";
import LineBreakerBottom from "views/module/fragment/LineBreakerBottom";
import dayjs from "dayjs";
import { dateFormatUtils } from "utils/dateFormatUtils";
import valueUtils from "utils/valueUtils";
import useCountProducts from "../hooks/useCountProducts";
import useSearchFormHook from "../hooks/useSearchFormHook";
import { CardWrapper, Conatainer, ResultWrapper, SearchWrapper, Wrapper } from "../styles/CalculateModal.styled";

export default function CalculateModalComponent(props) {
    const {
        searchForm,
        onChangeStartDate,
        onChangeEndDate,
        onSelectOrderType,
        onCheckFormValid
    } = useSearchFormHook({ orderTypes: props.orderTypes });

    const {
        countProducts,
        reqFetchCountProducts
    } = useCountProducts();

    const __handle = {
        submit: {
            confirm: async (e) => {
                e.preventDefault();

                try {
                    onCheckFormValid();
                    let params = {
                        startDate: dateFormatUtils().getStartDateForSearch(searchForm.startDate).toISOString(),
                        endDate: dateFormatUtils().getEndDateForSearch(searchForm.endDate).toISOString(),
                        orderTypes: searchForm.orderTypes.join(',')
                    }

                    await reqFetchCountProducts({ params });
                } catch (err) {
                    alert(err.message);
                    return;
                }
            }
        }
    }
    return (
        <>
            <Conatainer>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={() => props.onClose()}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <div className='title-box'>
                    <div className='title-el'>
                        <span style={{ color: '#b39283' }}>기간별 제품 수량</span>을 확인해 보세요.
                    </div>
                </div>
                <Wrapper>
                    <CardWrapper>
                        <SearchWrapper>
                            <form onSubmit={(e) => __handle.submit.confirm(e)}>
                                <div className='date-select-group'>
                                    <div className='date-select-box'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={'ko-KR'}
                                        >
                                            <DatePicker
                                                label="시작 날짜 선택"
                                                inputFormat="YYYY.MM.DD"
                                                mask={'____.__.__'}
                                                toolbarFormat="YY.MM.DD dd"
                                                showToolbar={false}
                                                disablePast={false}
                                                value={dayjs(searchForm?.startDate || new Date())}
                                                onChange={(value) => onChangeStartDate(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='date-picker'
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ padding: 10 }}></div>
                                    <div className='date-select-box'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={'ko-KR'}
                                        >
                                            <DatePicker
                                                label="종료 날짜 선택"
                                                inputFormat="YYYY.MM.DD"
                                                mask={'____.__.__'}
                                                toolbarFormat="YY.MM.DD dd"
                                                showToolbar={false}
                                                disablePast={false}
                                                value={dayjs(searchForm?.endDate || new Date())}
                                                onChange={(value) => onChangeEndDate(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className='date-picker'
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className='orderType-buttons-group'>
                                    {orderTypes.map(r => {
                                        return (
                                            <button
                                                key={`orderTypeButton-${r.orderType}`}
                                                type='button'
                                                className={`orderType-button-el ${searchForm.orderTypes.includes(r.orderType) && 'orderType-button-active'}`}
                                                onClick={() => onSelectOrderType(r.orderType)}
                                            >{r.typeName}</button>
                                        );
                                    })}
                                </div>
                                <SingleBlockButton
                                    type='submit'
                                    className='submit-button-el'
                                >
                                    조회
                                </SingleBlockButton>
                            </form>
                        </SearchWrapper>
                        <LineBreakerBottom
                            gapTop={20}
                        />
                        <ResultWrapper>
                            {countProducts &&
                                (
                                    <>
                                        {(countProducts.length !== 0 && countProducts.totalSum !== 0) ?
                                            <div className='table-wrapper'>
                                                <table
                                                    className='table-el'
                                                    cellSpacing={0}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="th-1"
                                                            >제품명 ({countProducts?.length})</th>
                                                            <th
                                                                className="th-2"
                                                            >수량 ({countProducts?.totalSum})</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {countProducts?.products?.map(r => {
                                                            return (
                                                                <tr key={`productName-${r.productName}`}>
                                                                    <td
                                                                    >{r.productName}</td>
                                                                    <td
                                                                    >{r.unitSum} 개</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className='empty-box'>
                                                <div className='empty-el'>
                                                    조회결과가 없습니다.
                                                </div>
                                            </div>
                                        }
                                    </>
                                )

                            }
                            {!countProducts &&
                                (
                                    <div className='empty-box'>
                                        <div className='empty-el'>
                                            조회결과가 이곳에 나타납니다.
                                        </div>
                                    </div>
                                )
                            }
                        </ResultWrapper>
                    </CardWrapper>
                </Wrapper>
            </Conatainer>
        </>
    );
}

const orderTypes = [
    {
        orderType: 'newOrder',
        typeName: '신규주문'
    },
    {
        orderType: 'confirmOrder',
        typeName: '주문확인'
    },
    {
        orderType: 'confirmReservation',
        typeName: '예약확정'
    },
    {
        orderType: 'pickedUp',
        typeName: '픽업완료'
    },
    {
        orderType: 'returned',
        typeName: '반납완료'
    },
    {
        orderType: 'completed',
        typeName: '완료내역'
    },
    {
        orderType: 'cancelled',
        typeName: '취소내역'
    },

]