import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div`
    padding: 0 60px 0 60px;

    @media all and (max-width: 992px){
        padding: 0 10px;
    }
`;

export const ContentWrapper = styled.div`
    margin-top: 40px;
`;

export const ContentTitle = styled.div`
    font-size: 20px;
    font-weight: 600;

    @media all and (max-width: 992px){
        font-size: 18px;
    }
`;

export const ContentBox = styled.div`
    box-sizing: border-box;
    background: #fff;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    border-radius: 10px;
    padding:0 20px;

    @media all and (max-width: 992px){
        padding:0 10px;
    }
`;

export const DefaultInfoWrapper = styled.div`
    .myinfo-group1{
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .myinfo-sub1{
            width: 70px;
            height: 70px;
            border: 1px solid #e0e0e0;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            cursor: pointer;

            @media all and (max-width: 992px){
                width: 50px;
                height: 50px;
            }

            .image-el{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .myinfo-sub2{
            margin-left: 20px;
            flex:1;

            @media all and (max-width: 992px){
                margin-left: 10px;
            }

            .myinfo-sub2-box1{
                width:100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .nickname-el{
                    flex:1;
                    word-break:break-all;
                    font-size: 20px;
                    font-weight: 600;
                    color: #000;
    
                    @media all and (max-width: 992px){
                        font-size: 18px;
                    }
                }
            }


            .username-el{
                word-break:break-all;
                font-size: 16px;
                font-weight: 600;
                color: #505050;

                @media all and (max-width: 992px){
                    font-size: 14px;
                }
            }
        }
    }

    .myinfo-group2{
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        justify-content: space-between;
        &:nth-last-child(1){
            border-bottom: none;
        }

        .myinfo-sub1{
            display: flex;
            align-items: center;

            .icon-el{
                width:20px;
                height: 20px;
            }

            .content-el{
                flex:1;
                word-break:break-all;
                font-weight: 500;
                color: #303030;
                margin-left: 10px;
                font-size: 16px;

                @media all and (max-width: 992px){
                    font-size: 14px;
                }
            }
        }
    }


    .myinfo-edit-button-el{
        width:auto;
        margin: 0;
        padding: 5px 5px;
        border-radius: 5px;
        background: #f0f0f0;
        color:#808080;
        font-size: 13px;
        word-break: keep-all;
        min-width: 35px;

        @media all and (max-width: 992px){
            font-size: 11px;
        }
    }
`;

export const MarketingConsentWrapper = styled.div`
    .group1{
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        justify-content: space-between;
        &:nth-last-child(1){
            border-bottom: none;
        }

        .sub1{
            display: flex;
            align-items: center;

            .icon-el{
                width:20px;
                height: 20px;
            }

            .content-el{
                flex:1;
                word-break: break-all;
                font-weight: 500;
                color: #303030;
                margin-left: 10px;
                font-size: 16px;

                @media all and (max-width:992px) {
                    font-size: 14px;
                }
            }
        }
    }


    .on-button-el{
        width:auto;
        margin: 0;
        padding: 5px 5px;
        border-radius: 5px;
        background: #fff;
        border:1px solid #5fcf80;
        color:#5fcf80;
        font-size: 13px;
        word-break: keep-all;
        min-width: 35px;

        @media all and (max-width: 992px){
            font-size: 11px;
        }
    }

    .off-button-el{
        width:auto;
        margin: 0;
        padding: 5px 5px;
        border-radius: 5px;
        background: #fff;
        border:1px solid #e56767;
        color:#e56767;
        font-size: 13px;
        word-break: keep-all;
        min-width: 35px;

        @media all and (max-width: 992px){
            font-size: 11px;
        }
    }
`;

export const WithdrawalElement = styled.button`
    user-select: none;
    -webkit-tap-highlight-color: #00000000;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 500;
    background:none;
    border:none;
    color:#808080;
    cursor:pointer;
`;

export const ModifyProfileImageUriModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;
        }
    
        .header-close-button-icon{
            width:100%;
            height: 100%;
        }
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .input-box{
        .select-button-el{
            margin:0;
            padding:0;
            margin-top: 20px;
            height: 48px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 600;
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;

export const ModifyNicknameModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;
        }
    
        .header-close-button-icon{
            width:100%;
            height: 100%;
        }
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .input-box{
        .input-label{
    
        }
    
        .input-el{
            width:100%;
            box-sizing: border-box;
            padding: 15px 10px;
            font-size: 14px;
            border:1px solid #e0e0e0;
            border-radius: 5px;
            &:focus{
                outline:none;
            }
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;

export const ModifyPhoneNumberModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .info-box{
        display: flex;
        align-items: center;

        .icon-el{
            width:25px;
            height: 25px;
        }

        .info-el{
            font-weight: 500;
            color: #303030;
            margin-left: 10px;
            font-size: 17px;
            word-break: break-all;
        }
    }

    .input-box{
        margin-top: 10px;
        
        .flex{
            display: flex;
        }

        .input-el{
            width:100%;
            box-sizing: border-box;
            padding: 15px 10px;
            font-size: 14px;
            border:1px solid #e0e0e0;
            border-radius: 5px;
            flex:1;
            &:focus{
                outline:none;
            }
        }

        .button-el{
            margin:0;
            padding:0;
            margin-left: 5px;
            border-radius: 5px;
            width:80px;
        }

        .input-notice{
            color: #707070;
            font-size: 12px;
            margin-top: 3px;

            @media all and (max-width: 992px){
                font-size: 10px;
            }
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;

export const ModifyEmailModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .info-box{
        display: flex;
        align-items: center;

        .icon-el{
            width:25px;
            height: 25px;
        }

        .info-el{
            font-weight: 500;
            color: #303030;
            margin-left: 10px;
            font-size: 17px;
            word-break: break-all;
        }
    }

    .input-box{
        margin-top: 10px;
        
        .flex{
            display: flex;
        }

        .input-el{
            width:100%;
            box-sizing: border-box;
            padding: 15px 10px;
            font-size: 14px;
            border:1px solid #e0e0e0;
            border-radius: 5px;
            flex:1;
            &:focus{
                outline:none;
            }
        }

        .button-el{
            margin:0;
            padding:0;
            margin-left: 5px;
            border-radius: 5px;
            width:80px;
        }

        .input-notice{
            color: #707070;
            font-size: 12px;
            margin-top: 3px;

            @media all and (max-width: 992px){
                font-size: 10px;
            }
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;

export const ModifyPasswordModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .info-box{
        display: flex;
        align-items: center;

        .icon-el{
            width:25px;
            height: 25px;
        }

        .info-el{
            font-weight: 500;
            color: #303030;
            margin-left: 10px;
            font-size: 17px;
        }
    }

    .input-box{
        margin-top: 10px;
        
        .flex{
            display: flex;
        }

        .input-el{
            width:100%;
            box-sizing: border-box;
            padding: 15px 10px;
            font-size: 14px;
            border:1px solid #e0e0e0;
            border-radius: 5px;
            flex:1;
            &:focus{
                outline:none;
            }
        }

        .button-el{
            margin:0;
            padding:0;
            margin-left: 5px;
            border-radius: 5px;
            width:80px;
        }

        .input-notice{
            color: #707070;
            font-size: 12px;
            margin-top: 3px;

            @media all and (max-width: 992px){
                font-size: 10px;
            }
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;

export const WithdrawalModalWrapper = styled.div`

    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;
        }
    
        .header-close-button-icon{
            width:100%;
            height: 100%;
        }
    }


    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;
    }

    .input-box{
        margin-top: 10px;
        .input-el{
            width:100%;
            box-sizing: border-box;
            padding: 15px 10px;
            font-size: 14px;
            border:1px solid #e0e0e0;
            border-radius: 5px;
            flex:1;
            &:focus{
                outline:none;
            }
        }
    }


    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }

`;