import styled from 'styled-components';

export const Container = styled.div`
    overflow: hidden;
    background:#f9fbfc;
    min-height: 500px;
`;

export const Wrapper = styled.div`
    overflow: hidden;
    width: 600px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;

    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    background:#ffffff;
    border-radius: 15px;
    @media all and (max-width: 992px){
        width: 90%;
    }
`;

export const HeaderWrapper = styled.div`
    margin-top: 40px;
    position: relative;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;

    @media all and (max-width: 992px){
        padding: 0 20px;
    }

    .head-box{
        width: 130px;
    }

    .title{
        font-weight: 600;
        font-size: 18px;
        text-align: center;

        @media all and (max-width: 992px){
            font-size: 15px;
        }
    }

    .prev-button-el{
        position:relative;
        font-weight: 500;
        font-size: 18px;
        color: #606060;
        padding:0;
        margin:0;
        width:40px;
        height: 40px;
        border-radius: 50%;
        border:none;

        @media all and (max-width: 992px){
            width:30px;
            height: 30px;
        }
    }

    .prev-button-icon{
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width:80%;
    }

    .page{
        font-weight: 500;
        font-size: 18px;
        color: #606060;
        text-align: right;

        @media all and (max-width: 992px){
            font-size: 15px;
        }
    }
`;

export const FormGroup = styled.form`
    padding: 0 50px;

    @media all and (max-width: 992px){
        padding: 0 20px;
    }

    .submit-button{
        width: 100%;
        height: 48px;
        margin-top: 40px;
        margin-bottom: 40px;
        padding:0;

        background: #b39283;
        border: none;
        border-radius: 10px;

        font-size: 16px;
        font-weight: 600;
        color: white;

        transition: all .5s;
        
        &:hover{
            background: #b39283e0;
        }

        &:disabled{
            cursor: not-allowed;
            background: #e0e0e0;
        }

        @media all and (max-width: 992px){
            font-size: 14px;
            height: 48px;
        }
    }
`;

export const InputBox = styled.div`
    width: 100%;
    margin-top: 40px;

    .input-label{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        color: #303030;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .input-item{
        width: 100%;
        height: 48px;
        padding: 0 12px;

        border: 1px solid #e1e1e1;
        border-radius: 10px;
        box-sizing: border-box;

        font-size: 16px;

        transition: all .5s;
        outline: none;
        &:hover{
            border: 1px solid #b39283;
        }
        &:focus{
            border: 1px solid #b39283;
            box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
        }

        @media all and (max-width: 992px){
            font-size: 14px;
        }
    }

    .input-notice{
        color: #707070;
        font-size: 12px;
        margin-top: 3px;

        @media all and (max-width: 992px){
            font-size: 10px;
        }
    }

    .validation-button-el{
        margin: 0;
        width: 150px;
        height: 48px;
        margin-left: 5px;
        border-radius: 5px;
        font-size: 14px;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .valid-label{
        display:inline-block;
        margin-right:10px;
        padding:1px 5px;

        border:1px solid red;
        font-size: 12px;
        font-weight: 400;

        color:red;

        @media all and (max-width: 992px){
            font-size: 10px;
        }
    }

    .pass-valid-label{
        border:1px solid #50bb1a;
        color:#50bb1a;
    }
`;