import styled from 'styled-components';

export const Container = styled.div`

`;

export const PrevButtonBox = styled.div`
    button{
        border-radius: 10px;
        background-color: #f6f6f6;
        border: none;
        width: 100px;
        color: #000;
        font-weight: 700;
    }
`;

export const CardListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 10px;
`;

export const CardBox = styled.div`
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;

    .flexBox{
        display:flex;
    }

    .flexColumn{
        flex-direction: column;
    }

    .flexGap-10{
        gap:10px;
    }

    .flexAlign-center{
        align-items: center;
    }

    .thumbnailBox{
        width: 80px;
    }

    .productName{
        margin: 0;
        font-size: 14px;
    }

    .price{
        font-size: 14px;
        font-weight: 600;
    }

    .discount{
        font-size: 14px;
        font-weight: 600;
    }
`;