import useDisabledBtn from "hooks/button/useDisabledBtn";

const { default: SingleBlockButton } = require("views/module/button/SingleBlockButton");
const { useRef } = require("react");
const { default: useAddProductPackageHook } = require("../../hooks/useAddProductPackageHook");
const { AddProductPackageModalWrapper } = require("../FormField.styled");

export default function AddProductPackageModal({
    onClose,
    onConfirm
}) {
    const fileUploaderRef = useRef();
    const {
        addProductPackage,
        onChangeThumbnailUri,
        onChangeValueOfName,
        onChangeUnit,
        returnSubmitValid
    } = useAddProductPackageHook();
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        action: {
            openFileUploader: (e) => {
                fileUploaderRef.current.click();
            },
        },
        submit: {
            onConfirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if (!returnSubmitValid()) {
                    return;
                }

                onConfirm({ ...addProductPackage })
                onClose();
            }
        }
    }

    return (
        <>
            <AddProductPackageModalWrapper>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={onClose}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <form onSubmit={__handle.submit.onConfirm}>
                    <div className='content-group'>
                        <div className='title'>
                            <span style={{ color: '#b39283' }}>패키지 구성 제품</span>을 추가해 주세요.
                        </div>
                        <div className='content-box'>
                            <button
                                type='button'
                                className='image-add-button-item'
                                onClick={__handle.action.openFileUploader}
                            >
                                {addProductPackage.thumbnailUri &&
                                    <img
                                        className='image-item'
                                        src={addProductPackage.thumbnailUri || '/assets/icon/add_default_gray.svg'}
                                        alt="file"
                                    ></img>
                                }
                                {!addProductPackage.thumbnailUri &&
                                    <img
                                        className='image-add-button-icon'
                                        src='/assets/icon/add_default_gray.svg'
                                        alt={'add default'}
                                    ></img>
                                }
                            </button>
                            <input
                                ref={fileUploaderRef}
                                type="file"
                                accept="image/*"
                                multiple
                                style={{ display: 'none' }}
                                onClick={(e) => e.target.value = ''}
                                onChange={(e) => onChangeThumbnailUri(e)}
                            />
                            <div className='input-box'>
                                <div className='input-label'>제품명</div>
                                <input
                                    type='text'
                                    className='input-item'
                                    name='name'
                                    value={addProductPackage?.name || ''}
                                    onChange={onChangeValueOfName}
                                ></input>
                            </div>
                            <div className='input-box'>
                                <div className='input-label'>수량</div>
                                <input
                                    type='text'
                                    className='input-item'
                                    name='unit'
                                    value={addProductPackage?.unit || ''}
                                    onChange={onChangeUnit}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className='button-group'>
                        <SingleBlockButton
                            type='button'
                            className='button-el'
                            style={{
                                background: '#959eae',
                                flex: 1
                            }}
                            onClick={onClose}
                        >
                            취소
                        </SingleBlockButton>
                        <SingleBlockButton
                            type='submit'
                            className='button-el'
                            style={{
                                background: '#b39283',
                                width: '60%'
                            }}
                            disabled={disabledBtn}
                        >
                            확인
                        </SingleBlockButton>
                    </div>
                </form>
            </AddProductPackageModalWrapper>
        </>
    );
}