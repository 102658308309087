import SingleBlockButton from "views/module/button/SingleBlockButton";
import CommonModalComponent from "views/module/modal/CommonModalComponent";
import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import { useRef, useState } from "react";
import { ChangeProfileImageModalWrapper, Container, Wrapper } from "./ProfileImageField.styled";

export default function ProfileImageFieldComponent(props) {
    const [changeProfileImageModeOpen, setChangeProfileImageModeOpen] = useState(false);

    const __handle = {
        action: {
            openChangeProfileImageMode: () => {
                setChangeProfileImageModeOpen(true);
            },
            closeChangeProfileImageMode: () => {
                setChangeProfileImageModeOpen(false);
            }
        },
        submit: {
            confirmChangeProfileImage: ({
                profileImageUri
            }) => {
                let body = {
                    profileImageUri: profileImageUri
                }
                props.onSubmitChangeProfileImage({
                    body: body,
                    callback: __handle.action.closeChangeProfileImageMode
                })
            }
        }
    }
    if (props.room) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <div
                            className='image-box'
                            onClick={__handle.action.openChangeProfileImageMode}
                        >
                            <div className='image-figure'>
                                <img
                                    className='image-el'
                                    src={props.room.profileImageUri || '/assets/icon/face_default_black.svg'}
                                    alt={'profile'}
                                    loading='lazy'
                                ></img>
                            </div>
                        </div>
                    </Wrapper>
                </Container>

                {changeProfileImageModeOpen &&
                    <CommonModalComponent
                        open={changeProfileImageModeOpen}
                        onClose={__handle.action.closeChangeProfileImageMode}
                    >
                        <ChangeProfileImageModal
                            onSubmitConfirm={__handle.submit.confirmChangeProfileImage}
                        />
                    </CommonModalComponent>
                }
            </>
        );
    }

    return null;
}

function ChangeProfileImageModal({
    onSubmitConfirm
}) {
    const { __reqUploadImageFile } = useImageFileUploaderHook();
    const fileUploaderRef = useRef();

    const __handle = {
        action: {
            openfileUploader: () => {
                fileUploaderRef.current.click();
            }
        },
        submit: {
            changeSelectedImage: async (e) => {
                e.preventDefault();
                // 파일을 선택하지 않은 경우
                if (e.target.files.length <= 0) return;

                let imageInfos = await __reqUploadImageFile(e);

                onSubmitConfirm({
                    profileImageUri: imageInfos[0].fileFullUri
                })
            },
            changeDefaultImage: () => {
                onSubmitConfirm({
                    profileImageUri: ''
                })
            }
        }
    }
    return (
        <>
            <ChangeProfileImageModalWrapper>
                <SingleBlockButton
                    className='button-el'
                    type='button'
                    onClick={__handle.action.openfileUploader}
                >
                    이미지 선택
                </SingleBlockButton>
                <SingleBlockButton
                    className='button-el'
                    type='button'
                    onClick={__handle.submit.changeDefaultImage}
                >
                    기본 이미지로 설정
                </SingleBlockButton>
            </ChangeProfileImageModalWrapper>
            <input
                ref={fileUploaderRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onClick={(e) => e.target.value = ''}
                onChange={(e) => __handle.submit.changeSelectedImage(e)}
                hidden
            />
        </>
    );
}