import { productDataConnect } from "data_connect/productDataConnect";
import { useEffect, useState } from "react";

export const useProductPageByRoom = () => {
    const [productPage, setProductPage] = useState(null);

    const reqFetch = async ({ params }) => {
        await productDataConnect().searchPage({ params })
            .then(res => {
                if (res.status === 200) {
                    setProductPage(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        productPage,
        reqFetch
    }
}

export const useProductPageByCategory = () => {
    const [productPage, setProductPage] = useState(null);

    const reqFetch = async ({ params }) => {
        await productDataConnect().searchPage({ params })
            .then(res => {
                if (res.status === 200) {
                    setProductPage(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        productPage,
        reqFetch
    }
}