import { dateFormatUtils } from "utils/dateFormatUtils";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { InfoWrapper, ProductListWrapper, Wrapper } from "./OrderDetail.styled";
import { returnProductOrderPrice, returnProductsTotalPrice, returnRentalDiffHours, returnStatus } from "./OrderDetail.utils";
import LineBreakerBottom from '../../../module/fragment/LineBreakerBottom';
import { Link } from "react-router-dom";

export default function OrderDetailComponent(props) {
    return (
        <>
            {props.selectedRentalOrderInfo &&
                (
                    <Wrapper>
                        <>
                            <Info
                                selectedRentalOrderInfo={props.selectedRentalOrderInfo}
                            />
                            <LineBreakerBottom
                                gapTop={20}
                            />
                            {props.rentalOrderProducts &&
                                <ProductList
                                    selectedRentalOrderInfo={props.selectedRentalOrderInfo}
                                    rentalOrderProducts={props.rentalOrderProducts}
                                />
                            }
                        </>
                    </Wrapper>
                )
            }

            {!props.selectedRentalOrderInfo &&
                <Wrapper>
                    <div className='empty-box'>
                        주문건을 선택해 주세요.
                    </div>
                </Wrapper>
            }
        </>
    );
}

function Info({
    selectedRentalOrderInfo,
}) {
    return (
        <InfoWrapper>
            <div className='info-group'>
                <div className='info-box'>
                    <div className='label'>
                        주문일시
                    </div>
                    <div className='info-el'>
                        <div className='info-el'>{dateFormatUtils().dateToYYMMDDHHmmss(selectedRentalOrderInfo.createdAt)}</div>
                    </div>
                </div>
                <div className='info-box'>
                    <div className='label'>
                        주문번호
                    </div>
                    <div className='info-el'>
                        {selectedRentalOrderInfo.orderNumber}
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className='info-box'>
                    <div className='label'>
                        예약자 이름
                    </div>
                    <div className='info-el'>
                        <div className='info-el'>{selectedRentalOrderInfo.borrower}</div>
                    </div>
                </div>
                <div className='info-box'>
                    <div className='label'>
                        예약자 연락처
                    </div>
                    <div className='info-el'>
                        {selectedRentalOrderInfo.borrowerPhoneNumber}
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className="info-box">
                    <div className='label'>대여기간</div>
                    <div className='info-el'>
                        {dateFormatUtils().dateToYYMMDD(selectedRentalOrderInfo.pickupDate)} {selectedRentalOrderInfo.pickupTime} ~ {dateFormatUtils().dateToYYMMDD(selectedRentalOrderInfo.returnDate)} {selectedRentalOrderInfo.returnTime} ({returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime })}H)
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className="info-box">
                    <div className='label'>픽업 | 반납 장소</div>
                    <div className='info-el'>{selectedRentalOrderInfo.returnPlace}</div>
                </div>
            </div>
            <div className='info-group'>
                <div className="info-box">
                    <div className='label'>대여처</div>
                    <div className='info-el'>
                        <Link
                            className='link'
                            to={`/room?roomId=${selectedRentalOrderInfo.room.id}`}
                        >
                            {selectedRentalOrderInfo.room.name}
                        </Link>
                    </div>
                </div>
            </div>
        </InfoWrapper>
    );
}

function ProductList({
    selectedRentalOrderInfo,
    rentalOrderProducts
}) {
    return (
        <ProductListWrapper>
            <div className='total-price-box'>
                총 주문 가격 : {numberFormatHandler().numberWithCommas(returnProductsTotalPrice({ products: rentalOrderProducts, diffHours: returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime }) }) || 0)} 원
            </div>

            {rentalOrderProducts.map(r => {
                let diffHours = returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime });
                let productPrice = returnProductOrderPrice({ price: r.price, unit: r.unit, discountYn: r.discountYn, discountRate: r.discountRate, diffHours: diffHours, discountMinimumHour: r.discountMinimumHour });

                return (
                    <div
                        key={r.id}
                        className='product-box'
                    >
                        <div className='thumbnail-box'>
                            <div className='thumbnail-figure'>
                                <img
                                    className="thumbnail-el"
                                    src={r.thumbnailUri}
                                    alt='thumbnail'
                                ></img>
                            </div>
                        </div>
                        <div className='product-info-box'>
                            <span
                                className='status-badge'
                            >
                                {returnStatus({ type: r.status })}
                            </span>
                            <div className='title'>
                                {r.productName}
                            </div>
                            <div className='count'>
                                {numberFormatHandler().numberWithCommas(r.price || 0)} 원 / {r.unit}개 / {diffHours}H
                            </div>
                            {r.discountYn === 'y' && (diffHours >= r.discountMinimumHour) &&
                                <div className='discount'>{r.discountRate}% 할인 적용</div>
                            }
                            <div className='totalPrice'>
                                상품 최종 가격: {numberFormatHandler().numberWithCommas(productPrice || 0)} 원
                            </div>
                        </div>
                    </div>
                );
            })}
        </ProductListWrapper>
    );
}