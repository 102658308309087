import { productCategoryDataConnect } from "data_connect/productCategoryDataConnect";
import { useEffect, useState } from "react";

export default function useProductCategoriesHook(props) {
    const [productCategories, setProductCategories] = useState(null);

    useEffect(() => {
        reqFetchProductCategories()
    }, [])

    const reqFetchProductCategories = async () => {
        await productCategoryDataConnect().searchList()
            .then(res => {
                if (res.status === 200) {
                    setProductCategories(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response)
            })
    }

    return {
        productCategories
    }
}