import { useState } from "react";
import { GlobalCommonBodyContainer } from "../../../globalStyledComponent";
import SingleBlockButton from "../../module/button/SingleBlockButton";
import CommonModalComponent from "../../module/modal/CommonModalComponent";
import { numberFormatHandler } from "../../../utils/numberFormatHandler";
import { CartProductCard, HeadFieldWrapper, Wrapper } from "./CartListField.styled";
import { useSelector } from "react-redux";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import OrderFormModalComponent from "./modal/OrderFormModalV2.component";

export default function CartListFieldComponent(props) {
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook();
    const [orderFormModalOpen, setOrderFormModalOpen] = useState(false);

    const __handle = {
        action: {
            openOrderFormModal: () => {
                if (!userRdx.userInfo) {
                    alert('로그인 후 이용해 주시기 바랍니다.');
                    customRouter.push({
                        pathname: '/login'
                    })
                    return;
                }
                setOrderFormModalOpen(true);
            },
            closeOrderFormModal: () => {
                setOrderFormModalOpen(false);
            }
        },
        submit: {
            deleteCartItem: (cartProduct) => {
                props.onSubmitDeleteCartItem(cartProduct);
            },
            orderReception: (rentalOrderInfo) => {
                props.onSubmitOrderReception({
                    body: rentalOrderInfo,
                    callback: __handle.action.closeOrderFormModal
                });
            }
        }
    }

    return (
        <>
            <GlobalCommonBodyContainer>
                <Wrapper>
                    <HeadFieldWrapper>
                        <div className='room-info-box'>
                            {props.aggregatedProduct.roomName} 님의 제품
                        </div>
                        <SingleBlockButton
                            type='button'
                            className='button-el'
                            onClick={() => props.onSubmitDeleteCartItemsByRoomId(props.aggregatedProduct.roomId)}
                        >
                            <img
                                className='icon'
                                src='/assets/icon/folderDelete_default_e56767.svg'
                                alt='folder delete'
                            ></img>
                        </SingleBlockButton>
                    </HeadFieldWrapper>
                    <div>
                        {props.aggregatedProduct.cartProducts.map(r => {
                            return (
                                <CartProductCard key={r.cartId}>
                                    <div className='card-wrapper'>
                                        <div
                                            className='delete-button-box'
                                        >
                                            <SingleBlockButton
                                                type='button'
                                                className='button-el'
                                                onClick={() => __handle.submit.deleteCartItem(r)}
                                            >
                                                <img
                                                    className='icon'
                                                    src='/assets/icon/remove_default_red.svg'
                                                    alt='remove icon'
                                                ></img>
                                            </SingleBlockButton>
                                        </div>
                                        <div className='image-box'>
                                            <div className='image-figure'>
                                                <img
                                                    className='image-el'
                                                    src={r.thumbnailUri}
                                                    alt='thumbnail'
                                                ></img>
                                            </div>
                                        </div>
                                        <div className='content-box'>
                                            <div className='product-name'>
                                                <div>{r.productName}</div>
                                            </div>
                                            <div className='product-info'>
                                                <div>가격(1시간): {numberFormatHandler().numberWithCommas(r.price || 0)} 원</div>
                                                {r.discountYn === 'y' &&
                                                    <div>{r.discountMinimumHour}H 이상 대여시 할인: {r.discountRate} %</div>
                                                }
                                                <div>최소 대여 가능 시간: {r.minimumRentalHour}H</div>
                                                <div>수량: {r.unit} 개</div>
                                            </div>
                                        </div>
                                    </div>
                                </CartProductCard>
                            );
                        })}
                    </div>
                    <SingleBlockButton
                        type='button'
                        className='order-submit-button-el'
                        onClick={__handle.action.openOrderFormModal}
                    >
                        주문하기
                    </SingleBlockButton>
                </Wrapper>
            </GlobalCommonBodyContainer>

            {orderFormModalOpen &&
                <CommonModalComponent
                    open={orderFormModalOpen}
                    onClose={__handle.action.closeOrderFormModal}
                >
                    <OrderFormModalComponent
                        aggregatedProduct={props.aggregatedProduct}
                        onClose={__handle.action.closeOrderFormModal}
                        onSubmitOrderReception={__handle.submit.orderReception}
                    />
                </CommonModalComponent>
            }
        </>
    );
}

