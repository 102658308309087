import { useRef, useState } from "react";
import CommonModalComponent from "views/module/modal/CommonModalComponent";
import * as St from './MdEditRentalProduct.styled';
import CustomInput from "components/input/default/v1/CustomInput";
import CustomBlockButton from "components/buttons/block-button/v1/CustomBlockButton";
import { CustomImage } from "components/image/custom_image";
import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import { CircularProgress } from "@mui/material";
import { numberFormatHandler } from "utils/numberFormatHandler";
import CustomCheckboxV2 from "views/module/checkbox/CustomCheckboxV2";
import { ElLoadProduct } from "./ElLoadProduct";

export function MdEditRentalProduct({
    open = false,
    onClose = () => { },
    editTargetItem,
    onConfirm
}) {
    const fileUploaderRef = useRef();
    const imageUploader = useImageFileUploaderHook();

    const [editRentalProductInfo, setEditRentalProductInfo] = useState({ ...editTargetItem });
    const [loadProductModeOpen, setLoadProductModeOpen] = useState(false);

    const toggleLoadProductModeOpen = (bool) => {
        setLoadProductModeOpen(bool);
    }

    const handleOpenThumbnailUploader = () => {
        fileUploaderRef.current.click();
    }

    const handleChangeThumnailUri = async (e) => {
        e.preventDefault();

        // 파일을 선택하지 않은 경우
        if (e.target.files.length <= 0) return;

        let imageInfos = await imageUploader.__reqUploadImageFile(e);

        let thumbnailUri = imageInfos[0].fileFullUri;

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                thumbnailUri: thumbnailUri
            }
        })
    }

    const handleChangeProductName = (e) => {
        let value = e.target.value;

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                productName: value
            }
        })
    }

    const handleChangeUnit = (e) => {
        let value = e.target.value;

        value = value.replaceAll(',', '');

        if (!value) {
            setEditRentalProductInfo(prev => {
                return {
                    ...prev,
                    unit: ''
                }
            })
            return;
        }

        let regex = /^[1-9][0-9]{0,5}$/

        if (!regex.test(value)) {
            return;
        }

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                unit: value
            }
        })
    }

    const handleChangePrice = (e) => {
        let value = e.target.value;

        value = value.replaceAll(',', '');

        if (!value) {
            setEditRentalProductInfo(prev => {
                return {
                    ...prev,
                    price: ''
                }
            })
            return;
        }

        let regex = /^[1-9][0-9]{0,6}$/

        if (!regex.test(value)) {
            return;
        }

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                price: value
            }
        })
    }

    const handleChangeDiscountYn = (e) => {
        let checked = e.target.checked;

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                discountYn: checked ? 'y' : 'n'
            }
        })
    }

    const handleChangeDiscountMinimumHour = (e) => {
        let value = e.target.value;

        if (!value) {
            setEditRentalProductInfo(prev => {
                return {
                    ...prev,
                    discountMinimumHour: ''
                }
            })
            return;
        }

        let regex = /^(0|[1-9][0-9]{0,3})$/

        if (!regex.test(value)) {
            return;
        }

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                discountMinimumHour: value
            }
        })
    }

    const handleChangeDiscountRate = (e) => {
        let value = e.target.value;

        if (!value) {
            setEditRentalProductInfo(prev => {
                return {
                    ...prev,
                    discountRate: ''
                }
            })
            return;
        }

        let regex = /^(0|[1-9][0-9]?|100)$/

        if (!regex.test(value)) {
            return;
        }

        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                discountRate: value
            }
        })
    }

    const handleChangeInfoWithLoadedProduct = (body) => {
        console.log(editRentalProductInfo);
        setEditRentalProductInfo(prev => {
            return {
                ...prev,
                productId: body?.productId,
                thumbnailUri: body?.thumbnailUri,
                productName: body?.productName,
                price: body?.price,
                discountYn: body?.discountYn,
                discountMinimumHour: body?.discountMinimumHour,
                discountRate: body?.discountRate,
            }
        });
        toggleLoadProductModeOpen(false);
    }

    const handleSubmit = () => {
        try {
            // 대표이미지 체크 : 필수 값
            if (!editRentalProductInfo?.thumbnailUri) {
                throw new Error('대표이미지를 지정해 주세요.');
            }

            // 제품명 체크 : 필수 값, 1-50자 내외
            if (!editRentalProductInfo?.productName || editRentalProductInfo?.productName?.length <= 0 || editRentalProductInfo?.productName?.length > 50) {
                throw new Error('제품명은 1-50자 내외 필수 입력 입니다.');
            }
            // 개수 체크 : 필수 값, 1-999999 내외
            if (!editRentalProductInfo?.unit || editRentalProductInfo?.unit < 1 || editRentalProductInfo?.unit > 999999) {
                throw new Error('개수는 1-999,999 내외 필수 입력 입니다.');
            }
            // 가격 체크 : 필수 값, 1-9999999 내외
            if (!editRentalProductInfo?.price || editRentalProductInfo?.price < 1 || editRentalProductInfo?.price > 9999999) {
                throw new Error('가격은 1-9,999,999 내외 필수 입력 입니다.');
            }
            // 할인 체크 : discountYn 은 y 또는 n
            if (!editRentalProductInfo?.discountYn || !(editRentalProductInfo?.discountYn === 'y' || editRentalProductInfo?.discountYn === 'n')) {
                throw new Error('할인 적용하기에서 오류가 발생했습니다. 새로고침 후 다시 시도해 주세요.');
            }
            // discountYn이 y 일 경우 discountMinimumHour는 0-9999, discountRate는 0-100
            if (!editRentalProductInfo?.discountMinimumHour || editRentalProductInfo?.discountMinimumHour < 0 || editRentalProductInfo?.discountMinimumHour > 9999) {
                throw new Error('최소 할인 시간은 0-9,999 내외 필수 입력 입니다.');
            }

            if (!editRentalProductInfo?.discountRate || editRentalProductInfo?.discountRate < 0 || editRentalProductInfo?.discountRate > 100) {
                throw new Error('할인율은 0-100 내외 필수 입력 입니다.');
            }
        } catch (err) {
            alert(err.message);
            return;
        }

        onConfirm(editRentalProductInfo);
    }

    return (
        <>
            <CommonModalComponent
                open={open}
                onClose={() => onClose()}
            >
                <St.Container>
                    <St.Title>
                        주문 제품 정보 수정
                    </St.Title>
                    {loadProductModeOpen &&
                        <ElLoadProduct
                            toggleLoadProductModeOpen={toggleLoadProductModeOpen}
                            onSelect={handleChangeInfoWithLoadedProduct}
                        />
                    }

                    {!loadProductModeOpen &&
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                            <St.FormControlBox>
                                <CustomBlockButton
                                    type='button'
                                    className='loadProductButton'
                                    onClick={() => toggleLoadProductModeOpen(true)}
                                >
                                    제품 불러오기
                                </CustomBlockButton>
                            </St.FormControlBox>
                            <St.FormControlBox>
                                <label>대표이미지</label>
                                <div className='thumbnailBox'>
                                    <CustomImage>
                                        <CustomImage.Image src={editRentalProductInfo?.thumbnailUri} />
                                    </CustomImage>
                                    {imageUploader.pending &&
                                        <div className='pendingField'>
                                            <div className='progressBox'>
                                                <CircularProgress className='progress' />
                                            </div>
                                        </div>
                                    }
                                    {!imageUploader.pending &&
                                        <div
                                            className='hoverField'
                                            onClick={() => handleOpenThumbnailUploader()}
                                        >
                                            <div className='text'>
                                                이미지 변경
                                            </div>
                                            <input
                                                ref={fileUploaderRef}
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                style={{ display: 'none' }}
                                                onClick={(e) => e.target.value = ''}
                                                onChange={(e) => handleChangeThumnailUri(e)}
                                            />
                                        </div>
                                    }
                                </div>
                            </St.FormControlBox>
                            <St.FormControlBox>
                                <label>제품명</label>
                                <CustomInput
                                    type='text'
                                    placeholder='제품명'
                                    value={editRentalProductInfo?.productName}
                                    onChange={(e) => handleChangeProductName(e)}
                                />
                            </St.FormControlBox>
                            <St.FormControlBox>
                                <label>개수</label>
                                <CustomInput
                                    type='text'
                                    placeholder='개수'
                                    value={editRentalProductInfo?.unit ? numberFormatHandler().numberWithCommas(editRentalProductInfo?.unit) : ''}
                                    onChange={(e) => handleChangeUnit(e)}
                                />
                            </St.FormControlBox>
                            <St.FormControlBox>
                                <label>가격</label>
                                <CustomInput
                                    type='text'
                                    placeholder='가격'
                                    value={editRentalProductInfo?.price ? numberFormatHandler().numberWithCommas(editRentalProductInfo?.price) : ''}
                                    onChange={(e) => handleChangePrice(e)}
                                />
                            </St.FormControlBox>
                            <St.FormControlBox>
                                <CustomCheckboxV2
                                    label="할인 적용하기"
                                    checked={editRentalProductInfo?.discountYn === 'y' ? true : false}
                                    onChange={(e) => handleChangeDiscountYn(e)}
                                />
                                {editRentalProductInfo?.discountYn === 'y' &&
                                    <>
                                        <div className='flexBox' style={{ marginTop: '10px' }}>
                                            <section>
                                                <CustomInput
                                                    style={{ textAlign: 'center' }}
                                                    type='text'
                                                    placeholder='적용된 할인'
                                                    value={editRentalProductInfo?.discountMinimumHour}
                                                    onChange={(e) => handleChangeDiscountMinimumHour(e)}
                                                />
                                            </section>
                                            <section style={{ fontSize: 12 }}>
                                                시간 이상 대여시
                                            </section>
                                        </div>
                                        <div className='flexBox' style={{ marginTop: '10px' }}>
                                            <section>
                                                <CustomInput
                                                    style={{ textAlign: 'center' }}
                                                    type='text'
                                                    placeholder='적용된 할인'
                                                    value={editRentalProductInfo?.discountRate}
                                                    onChange={(e) => handleChangeDiscountRate(e)}
                                                />
                                            </section>
                                            <section style={{ fontSize: 12 }}>
                                                (%) 할인
                                            </section>
                                        </div>
                                    </>
                                }
                            </St.FormControlBox>
                            <St.BottomButtonGroup>
                                <CustomBlockButton
                                    type='button'
                                    className='cancel'
                                    onClick={() => onClose()}
                                >
                                    취소
                                </CustomBlockButton>
                                <CustomBlockButton
                                    type='submit'
                                    className='confirm'
                                    disabled={imageUploader.pending}
                                >
                                    완료
                                </CustomBlockButton>
                            </St.BottomButtonGroup>
                        </form>
                    }
                </St.Container>
            </CommonModalComponent>
        </>
    );
}