import SingleBlockButton from 'views/module/button/SingleBlockButton';
import CommonModalComponent from 'views/module/modal/CommonModalComponent';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCustomRouterHook } from '../../../hooks/router/useCustomRouterHook';
import CustomSelect from '../../module/select/CustomSelect';
import { CategorySelectWrapper, Container, OrderTypeWrapper, RegionSelectModalWrapper, Wrapper } from './CategoryField.styled';

const __ext_regionButtonTag = (sidos, querySido, querySigungu) => {
    let sidoAndSigungus = sidos.find(r => r.sido === querySido);
    let sidoName = '';
    let sigunguName = '';
    let tagName = '지역 전체';

    if (sidoAndSigungus) {
        sidoName = sidoAndSigungus.sido;
        sigunguName = sidoAndSigungus.sigungus?.find(r => r === querySigungu) || '';
    }

    if (sidoName) {
        tagName = sidoName;
    }

    if (sigunguName) {
        tagName += ` / ${sigunguName}`;
    }
    return tagName;
}

export default function CategoryFieldComponent(props) {
    const customRouter = useCustomRouterHook();
    const [regionSelectModalOpen, setRegionSelectModalOpen] = useState(false);

    const __handle = {
        action: {
            changeRoute: (e) => {
                let value = e.target.value;
                delete customRouter.query.page;

                if (!value) {
                    delete customRouter.query.categoryId;

                    customRouter.push({
                        pathname: customRouter.pathname,
                        query: {
                            ...customRouter.query
                        },
                        replace: true
                    });
                    return;
                }

                customRouter.push({
                    pathname: customRouter.pathname,
                    query: {
                        ...customRouter.query,
                        categoryId: value
                    },
                    replace: true
                })
            },
            changeRouteByOrderType: (type) => {
                if (customRouter.query.orderType === type) {
                    return;
                }
                delete customRouter.query.page;

                customRouter.push({
                    pathname: customRouter.pathname,
                    query: {
                        ...customRouter.query,
                        orderType: type
                    },
                    replace: true
                });
            },
            openRegionSelectModal: () => {
                setRegionSelectModalOpen(true);
            },
            closeRegionSelectModal: () => {
                setRegionSelectModalOpen(false);
            }
        },
        submit: {
            selectRegion: ({
                sido,
                sigungu
            }) => {
                let query = {
                    ...customRouter.query
                }

                delete query.page;

                query = {
                    ...query,
                    sido: sido,
                    sigungu: sigungu
                }

                if (!sigungu) {
                    delete query.sigungu;
                }

                if (!sido) {
                    delete query.sido;
                    delete query.sigungu;
                }

                customRouter.push({
                    pathname: customRouter.pathname,
                    query: {
                        ...query
                    },
                    replace: true
                })
                __handle.action.closeRegionSelectModal();
            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <CategorySelectWrapper>
                        <div className='control-box'>
                            <SingleBlockButton
                                type='button'
                                className='button-el'
                                onClick={__handle.action.openRegionSelectModal}
                            >
                                {__ext_regionButtonTag(props.sidos, customRouter.query.sido, customRouter.query.sigungu)}
                            </SingleBlockButton>
                        </div>
                        <div className='gap-block'></div>
                        <div className='control-box'>
                            <CustomSelect
                                className='select-el'
                                value={customRouter.query.categoryId || ''}
                                onChange={__handle.action.changeRoute}
                            >
                                <option value=''>카테고리 전체</option>
                                {props.categories.map(r => {
                                    return (
                                        <option
                                            key={r.id}
                                            value={r.id}
                                        >
                                            {r.name}
                                        </option>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </CategorySelectWrapper>
                    <OrderTypeWrapper>
                        <div
                            className={`item-el ${!customRouter.query.orderType || customRouter.query.orderType === 'order_rank' ? 'item-el-active' : ''}`}
                            onClick={() => __handle.action.changeRouteByOrderType('order_rank')}
                        >
                            랭킹순
                        </div>
                        <div
                            className={`item-el ${customRouter.query.orderType === 'order_new' ? 'item-el-active' : ''}`}
                            onClick={() => __handle.action.changeRouteByOrderType('order_new')}
                        >
                            최신순
                        </div>
                    </OrderTypeWrapper>
                </Wrapper>
            </Container>

            {regionSelectModalOpen && props.sidos &&
                <CommonModalComponent
                    maxWidth='sm'
                    open={regionSelectModalOpen}
                    onClose={__handle.action.closeRegionSelectModal}
                >
                    <RegionSelectModal
                        sidos={props.sidos}
                        onSubmitSelectRegion={__handle.submit.selectRegion}
                        onClose={__handle.action.closeRegionSelectModal}
                    />
                </CommonModalComponent>
            }
        </>
    );
}

function RegionSelectModal({
    sidos,
    onSubmitSelectRegion,
    onClose
}) {
    const customRouter = useCustomRouterHook();
    const [sigungus, setSigungus] = useState([]);
    const [selectedSido, setSelectedSido] = useState('');
    const [selectedSigungu, setSelectedSigungu] = useState('');

    useEffect(() => {
        let querySido = customRouter.query?.sido || '';
        let querySigungu = customRouter.query?.sigungu || '';

        let sidoAndSigungus = sidos.find(r => r.sido === querySido);

        if (sidoAndSigungus) {
            setSelectedSido(sidoAndSigungus.sido);
            setSigungus([...sidoAndSigungus.sigungus]);

            let sigungu = sidoAndSigungus.sigungus.find(r => r === querySigungu);
            if (sigungu) {
                setSelectedSigungu(sigungu);
            }
        }
    }, [])

    const __handle = {
        action: {
            onChangeSelectedSidoValue: (e) => {
                let value = e.target.value;
                let currSigungus = sidos.find(r => r.sido === value)?.sigungus || [];

                setSelectedSido(value);
                setSelectedSigungu('');
                setSigungus([...currSigungus]);
            },
            onChangeSelectedSigunguValue: (e) => {
                let value = e.target.value;
                setSelectedSigungu(value);
            }
        },
        submit: {
            selectRegion: () => {
                onSubmitSelectRegion({
                    sido: selectedSido,
                    sigungu: selectedSigungu
                })
            },
            clearRegion: () => {
                if (!customRouter.query.sido && !customRouter.query.sigungu) {
                    onClose();
                    return;
                }

                onSubmitSelectRegion({
                    sido: '',
                    sigungu: ''
                })
            }
        }
    }

    return (
        <RegionSelectModalWrapper>
            <div className='select-group'>
                <div className='select-box'>
                    <div className='select-label'>
                        시/도
                    </div>
                    <CustomSelect
                        className='select-el'
                        onChange={__handle.action.onChangeSelectedSidoValue}
                        value={selectedSido || ''}
                    >
                        <option value=''>시/도 전체</option>
                        {sidos.map(r => {
                            return (
                                <option
                                    key={`sido-${r.sido}`}
                                    value={r.sido}
                                >{r.sido}</option>
                            );
                        })}
                    </CustomSelect>
                </div>
                <div className='select-group-block'></div>
                <div className='select-box'>
                    <div className='select-label'>
                        시/군/구
                    </div>
                    <CustomSelect
                        className='select-el'
                        onChange={__handle.action.onChangeSelectedSigunguValue}
                        value={selectedSigungu || ''}
                    >
                        <option value=''>시/군/구 전체</option>
                        {sigungus?.map(r => {
                            return (
                                <option
                                    key={`sigungu-${r}`}
                                    value={r}
                                >{r}</option>
                            );
                        })}
                    </CustomSelect>
                </div>
            </div>
            <div className='button-group'>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    onClick={__handle.submit.clearRegion}
                >
                    초기화
                </SingleBlockButton>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    style={{
                        background: '#b39283',
                        color: '#fff'
                    }}
                    onClick={__handle.submit.selectRegion}
                >
                    적용
                </SingleBlockButton>
            </div>
        </RegionSelectModalWrapper>
    );
}