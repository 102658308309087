import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import FormFieldComponent from "./form-field/FormField.component";
import { useUser } from "./hooks/findPasswordValidationHooks";

export default function MainComponent(props) {
    const customRouter = useCustomRouterHook();
    const [isLoading, setIsLoading] = useState(true);
    const {
        reqCheckDuplicateUser,
        reqRegisterResetPassword
    } = useUser();

    useEffect(() => {
        async function init() {
            let u = customRouter.query.u;
            let p = customRouter.query.p;
            let message = await reqCheckDuplicateUser({ username: u, phoneNumber: p });

            if (message === 'duplicated') {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            alert('등록된 회원이 아닙니다.');
            customRouter.goBack();
        }

        init();
    }, []);

    const __handle = {
        submit: {
            registerResetPassword: async ({ body }) => {
                let data = await reqRegisterResetPassword({ body });
                if (data) {
                    customRouter.push({
                        pathname: '/find/password/reset',
                        query: {
                            resetToken: data.resetToken
                        },
                        replace: true
                    })
                }
            }
        }
    }

    if (!isLoading) {
        return (
            <>
                <FormFieldComponent
                    onSubmitRegisterResetPassword={__handle.submit.registerResetPassword}
                />
            </>
        );
    }
    return null;
}