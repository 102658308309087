import styled from 'styled-components';

const InputBox = styled.div`
    .label{
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }

    .input-el{
        width:100%;
        padding:10px;
        box-sizing: border-box;
        border:none;
        border-bottom: 1px solid #e0e0e0;
        letter-spacing: 0.1em;
        outline: none;
        font-size: 16px;

        &:focus{
            border-bottom: 1px solid #b39283;
        }
    }
`;

export default function CustomInput({
    label,
    type,
    name,
    value,
    onChange,
    required,
    placeholder,
    labelClassName,
    className,
    style,
    ...props
}) {
    return (
        <InputBox>
            {label &&
                <div className={`label ${labelClassName}`}>{label || ''}</div>
            }
            <input
                className={`input-el ${className}`}
                type={type || 'text'}
                name={name}
                value={value || ''}
                onChange={onChange instanceof Function ? (e) => onChange(e) : () => { }}
                placeholder={placeholder || ''}
                required={required}
            ></input>
        </InputBox>
    );
}