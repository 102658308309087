import { resetPasswordTokenDataConnect } from "data_connect/resetPasswordTokenDataConnect";
import { userDataConnect } from "data_connect/userDataConnect";
import { validationDataConnect } from "data_connect/validationDataConnect";
import { useEffect, useState } from "react"
import formatValidUtils from "utils/formatValidUtils";

export const useUser = () => {
    const [user, setUser] = useState({
        username: '',
        phoneNumber: '',
        phoneNumberValidationCode: ''
    });

    const reqCheckDuplicateUser = async ({ username, phoneNumber }) => {
        return await userDataConnect().checkDuplicateUsernameAndPhoneNumber({ username, phoneNumber })
            .then(res => {
                if (res.status === 200) {
                    return res.data.message;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const reqSendPhoneNumberValidationCode = async ({ body, successCallback }) => {
        let phoneNumber = body.phoneNumber;
        let validationType = 'forFindPassword';

        await validationDataConnect().sendPhoneValidationCodeV2({ phoneNumber, validationType })
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const reqRegisterResetPassword = async ({ body }) => {
        return await resetPasswordTokenDataConnect().create({ body })
            .then(res => {
                if (res.status === 200) {
                    return res.data.data;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setUser({
            ...user,
            [name]: value
        })
    }

    const onSetUser = (data) => {
        setUser({ ...data });
    }

    const returnUsernameValid = (username) => {
        return formatValidUtils.isUsernameFormatValid(username);
    };

    const returnPhoneNumberValid = (phoneNumber) => {
        return formatValidUtils.isPhoneNumberFormatValid(phoneNumber);
    }

    const returnPhoneNumberValidationCodeValid = (phoneNumberValidationCode) => {
        if (phoneNumberValidationCode && phoneNumberValidationCode.length === 6) {
            return true;
        }

        return false;
    }
    return {
        user,
        returnUsernameValid,
        returnPhoneNumberValid,
        returnPhoneNumberValidationCodeValid,
        onSetUser,
        onChangeValueOfName,
        reqCheckDuplicateUser,
        reqSendPhoneNumberValidationCode,
        reqRegisterResetPassword
    }
}

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }
        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [
        disabledBtn,
        setDisabledBtn
    ]
}