import { userDataConnect } from "data_connect/userDataConnect";
import { validationDataConnect } from "data_connect/validationDataConnect";
import { useEffect, useState } from "react";
import formatValidUtils from "utils/formatValidUtils";

export const useSignupForm = () => {
    const [signupForm, setSignupForm] = useState({
        username: '',
        password: '',
        passwordChecker: '',
        phoneNumber: '',
        phoneNumberValidationCode: '',
        nickname: ''
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setSignupForm({
            ...signupForm,
            [name]: value
        })
    }

    const reqSendPhoneValidationCode = async ({
        phoneNumber,
        successCallback
    }) => {
        await validationDataConnect().sendPhoneValidationCode({ phoneNumber })
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const reqIsDuplicateUsername = async ({ username }) => {
        return await userDataConnect().checkDuplicateUsername({ username })
            .then(res => {
                if (res.status === 200 && res.data.message === 'not_duplicated') {
                    return false;
                } else {
                    return true;
                }
            })
            .catch(err => {
                console.log(err, err.response);
                return true;
            })
    }

    const reqSignup = async ({
        body,
        successCallback
    }) => {
        await userDataConnect().signup(body)
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                }
            })
            .catch(err => {
                let res = err.response;
                if (res.status === 500) {
                    alert('undefined error.')
                    return;
                }

                alert(res.data.memo);
            })
    }

    return {
        signupForm,
        onChangeValueOfName,
        reqSendPhoneValidationCode,
        reqSignup,
        reqIsDuplicateUsername
    }
}

export const useSignupValid = () => {
    const [signupValid, setSignupValid] = useState({
        username: false,
        usernamePassDuplicete: false,
        password: false,
        passwordChecker: false,
        phoneNumber: false,
        phoneNumberValidationCode: false,
        nickname: false
    });
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        if (
            signupValid.username
            && signupValid.usernamePassDuplicete
            && signupValid.password
            && signupValid.passwordChecker
            && signupValid.phoneNumber
            && signupValid.phoneNumberValidationCode
            && signupValid.nickname
        ) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }

    }, [signupValid])

    const returnUsernameValid = (username) => {
        return formatValidUtils.isUsernameFormatValid(username);
    }

    const returnPasswordValid = (password) => {
        return formatValidUtils.isPasswordFormatValid(password);
    }

    const returnPasswordCheckerValid = (password, passwordChecker) => {
        if (!password || !passwordChecker) {
            return false;
        }

        if (password === passwordChecker) {
            return true;
        }

        return false;
    }

    const returnNicknameValid = (nickname) => {
        return formatValidUtils.isNicknameFormatValid(nickname);
    }

    const returnPhoneNumberValid = (phoneNumber) => {
        return formatValidUtils.isPhoneNumberFormatValid(phoneNumber)
    }

    const returnPhoneValidationCodeValid = (phoneNumberValidationCode) => {
        if (phoneNumberValidationCode && phoneNumberValidationCode.length === 6) {
            return true;
        }

        return false;
    }

    const onSetSignupValid = (form) => {
        setSignupValid({ ...form })
    }

    return {
        signupValid,
        canSubmit,
        returnUsernameValid,
        returnPasswordValid,
        returnPasswordCheckerValid,
        returnNicknameValid,
        returnPhoneNumberValid,
        returnPhoneValidationCodeValid,
        onSetSignupValid
    }
}

export const usePhoneValidationCodeInputMode = () => {
    const [phoneValidationCodeInputModeOpen, setPhoneValidationCodeInputModeOpen] = useState(false);

    const onOpenPhoneValidationCodeInputMode = () => {
        setPhoneValidationCodeInputModeOpen(true);
    }

    const onClosePhoneValidationCodeInputMode = () => {
        setPhoneValidationCodeInputModeOpen(false);
    }

    return {
        phoneValidationCodeInputModeOpen,
        onOpenPhoneValidationCodeInputMode,
        onClosePhoneValidationCodeInputMode
    }
}

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }
        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [
        disabledBtn,
        setDisabledBtn
    ]
}

export const useConsentForm = () => {
    const [consentForm, setConsentForm] = useState({
        serviceTermsYn: 'n',
        privacyPolicyYn: 'n',
        marketingYn: 'n'
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let checked = e.target.checked;

        setConsentForm({
            ...consentForm,
            [name]: checked ? 'y' : 'n'
        })
    }

    const onSelectAll = () => {
        if (returnSelectedAll()) {
            setConsentForm({
                serviceTermsYn: 'n',
                privacyPolicyYn: 'n',
                marketingYn: 'n'
            });
        } else {
            setConsentForm({
                serviceTermsYn: 'y',
                privacyPolicyYn: 'y',
                marketingYn: 'y'
            });
        }
    }

    const returnSelectedAll = () => {
        if (
            consentForm.serviceTermsYn === 'y'
            && consentForm.privacyPolicyYn === 'y'
            && consentForm.marketingYn === 'y'
        ) {
            return true;
        }

        return false;
    }

    return {
        consentForm,
        returnSelectedAll,
        onChangeValueOfName,
        onSelectAll
    }
}