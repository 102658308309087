import { userDataConnect } from "data_connect/userDataConnect";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function useProfileImageUriHook(props) {
    const [profileImageUri, setProfileImageUri] = useState('');
    const reduxDispatch = useDispatch();

    const reqFetchUserInfo = async () => {
        await userDataConnect().searchUserInfo()
            .then(res => {
                if (res.status === 200) {
                    reduxDispatch({
                        type: 'USER_REDUX_SET_DATA',
                        payload: {
                            userInfo: res.data.data,
                            isLoading: false,
                            status: 'fetched',
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err, err.response);
                reduxDispatch({
                    type: 'USER_REDUX_SET_DATA',
                    payload: {
                        userInfo: null,
                        isLoading: false,
                        status: 'fetched'
                    }
                });
            })
    }

    const reqChangeProfileImageUri = async ({ body, successCallback }) => {
        let bool = await userDataConnect().changeProfileImageUri({ body })
            .then(res => {
                if (res.status === 200) {
                    return true;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })

        if (bool) {
            alert('변경되었습니다.');
            await reqFetchUserInfo();
            successCallback();
        }
    }

    return {
        reqChangeProfileImageUri
    }
}