import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import { useEffect, useState } from "react";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { v4 as uuidv4 } from 'uuid';

export default function useModifyProductPackageHook(props) {
    const [modifyProductPackage, setModifyProductPackage] = useState(null);

    const { __reqUploadImageFile } = useImageFileUploaderHook();

    useEffect(() => {
        if (!props.selectedProductPackage) {
            return;
        }

        onSetModifyProductPackage(props.selectedProductPackage);
    }, [props.selectedProductPackage])

    const onSetModifyProductPackage = (item) => {
        setModifyProductPackage({
            ...item
        })
    }

    const onChangeThumbnailUri = async (e) => {
        e.preventDefault();

        // 파일을 선택하지 않은 경우
        if (e.target.files.length <= 0) return;

        let imageInfos = await __reqUploadImageFile(e);

        let thumbnailUri = imageInfos[0].fileFullUri;

        setModifyProductPackage({
            ...modifyProductPackage,
            thumbnailUri: thumbnailUri
        })

    }

    const onChangeValueOfName = async (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value && value.length > 50) {
            return;
        }

        setModifyProductPackage({
            ...modifyProductPackage,
            [name]: value
        })
    }

    const onChangeUnit = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 100) {
            return;
        }

        setModifyProductPackage({
            ...modifyProductPackage,
            unit: value
        })
    }

    const returnSubmitValid = () => {
        if (!returnNameValid()) {
            alert('제품명은 1-50 자로 입력해 주세요.');
            return false;
        }

        if (!returnUnitValid()) {
            alert('수량은 1-100 개로 입력해 주세요.');
            return false;
        }

        return true;
    }

    const returnNameValid = () => {
        if (modifyProductPackage.name && modifyProductPackage.name.length > 0 && modifyProductPackage.name.length < 50) {
            return true;
        }

        return false;
    }

    const returnUnitValid = () => {
        if (numberFormatHandler().checkNumberOnlyFormat(modifyProductPackage.unit) && modifyProductPackage.unit > 0 && modifyProductPackage.unit < 100) {
            return true;
        }

        return false;
    }

    return {
        modifyProductPackage,
        onChangeThumbnailUri,
        onChangeValueOfName,
        onChangeUnit,
        returnSubmitValid
    }
}