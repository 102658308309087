import styled from 'styled-components';

export const GlobalCommonBodyContainer = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px 0 10px;

    @media all and (max-width:992px){
    }
`;