import SingleBlockButton from 'views/module/button/SingleBlockButton';
import CommonModalComponent from 'views/module/modal/CommonModalComponent';
import useDisabledBtn from 'hooks/button/useDisabledBtn';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useUserConsentHook from '../hooks/useUserConsentHook';
import ModifyEmailModal from '../modal/ModifyEmailModal';
import ModifyNicknameModal from '../modal/ModifyNicknameModal';
import ModifyPasswordModal from '../modal/ModifyPasswordModal';
import ModifyPhoneNumberModal from '../modal/ModifyPhoneNumberModal';
import ModifyProfileImageUriModal from '../modal/ModifyProfileImageUriModal';
import WithdrawalModal from '../modal/WithdrawalModal';
import { Container, ContentBox, ContentTitle, ContentWrapper, DefaultInfoWrapper, MarketingConsentWrapper, WithdrawalElement, Wrapper } from './ViewProfile.styled';

export default function ViewProfileComponent(props) {
    const {
        userConsent,
        reqChangeMarketingPhoneYn,
        reqChangeMarketingEmailYn
    } = useUserConsentHook();
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const [modifyNicknameModalOpen, setModifyNicknameModalOpen] = useState(false);
    const [modifyPhoneNumberModalOpen, setModifyPhoneNumberModalOpen] = useState(false);
    const [modifyEmailModalOpen, setModifyEmailModalOpen] = useState(false);
    const [modifyPasswordModalOpen, setModifyPasswordModalOpen] = useState(false);
    const [modifyProfileImageUriModalOpen, setModifyProfileImageUriModalOpen] = useState(false);
    const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);

    const userRdx = useSelector(state => state.userRedux);

    const __handle = {
        action: {
            openModifyNicknameModal: () => {
                setModifyNicknameModalOpen(true);
            },
            closeModifyNicknameModal: () => {
                setModifyNicknameModalOpen(false);
            },
            openModifyPhoneNumberModal: () => {
                setModifyPhoneNumberModalOpen(true);
            },
            closeModifyPhoneNumberModal: () => {
                setModifyPhoneNumberModalOpen(false);
            },
            openModifyEmailModal: () => {
                setModifyEmailModalOpen(true);
            },
            closeModifyEmailModal: () => {
                setModifyEmailModalOpen(false);
            },
            openModifyPasswordModal: () => {
                setModifyPasswordModalOpen(true);
            },
            closeModifyPasswordModal: () => {
                setModifyPasswordModalOpen(false);
            },
            openModifyProfileImageUriModal: () => {
                setModifyProfileImageUriModalOpen(true);
            },
            closeModifyProfileImageUriModal: () => {
                setModifyProfileImageUriModalOpen(false);
            },
            openWithdrawalModal: () => {
                setWithdrawalModalOpen(true);
            },
            closeWithdrawalModal: () => {
                setWithdrawalModalOpen(false);
            }
        },
        submit: {
            changeUserConsentMarketingPhoneYn: async (yn) => {
                setDisabledBtn(true);
                let body = {
                    marketingPhoneYn: 'y'
                }

                if (yn === 'y') {
                    body.marketingPhoneYn = 'n';
                }

                await reqChangeMarketingPhoneYn({
                    body: body,
                    successCallback: () => {
                        if (body.marketingPhoneYn === 'y') {
                            alert('마케팅 정보 수신에 동의하셨습니다.');
                            return;
                        } else {
                            alert('마케팅 정보 수신 동의가 해제되었습니다.');
                            return;
                        }
                    }
                })
            },
            changeUserConsentMarketingEmailYn: async (yn) => {
                setDisabledBtn(true);
                let body = {
                    marketingEmailYn: 'y'
                }

                if (yn === 'y') {
                    body.marketingEmailYn = 'n';
                }

                await reqChangeMarketingEmailYn({
                    body: body,
                    successCallback: () => {
                        if (body.marketingEmailYn === 'y') {
                            alert('마케팅 정보 수신에 동의하셨습니다.');
                            return;
                        } else {
                            alert('마케팅 정보 수신 동의가 해제되었습니다.');
                            return;
                        }
                    }
                })
            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <ContentWrapper>
                        <ContentTitle>
                            기본 정보
                        </ContentTitle>
                        <ContentBox
                            style={{
                                border: '1px solid #b39283'
                            }}
                        >
                            <DefaultInfoWrapper>
                                <div className='myinfo-group1'>
                                    <div
                                        className='myinfo-sub1'
                                        onClick={__handle.action.openModifyProfileImageUriModal}
                                    >
                                        <img
                                            className='image-el'
                                            src={userRdx?.userInfo?.profileImageUri || '/assets/icon/face_default_black.svg'}
                                            alt="profile"
                                        />
                                    </div>
                                    <div className='myinfo-sub2'>
                                        <div className='myinfo-sub2-box1'>
                                            <div className='nickname-el'>{userRdx?.userInfo?.nickname}</div>
                                            <SingleBlockButton
                                                type='button'
                                                className='myinfo-edit-button-el'
                                                onClick={__handle.action.openModifyNicknameModal}
                                            >
                                                이름 수정
                                            </SingleBlockButton>
                                        </div>
                                        <div className='username-el'>{userRdx?.userInfo?.username}</div>
                                    </div>
                                </div>
                                <div
                                    className='myinfo-group2'
                                >
                                    <div className='myinfo-sub1'>
                                        <img
                                            className='icon-el'
                                            src='/assets/icon/phone_default_black.svg'
                                            alt='phone icon'
                                        ></img>
                                        <div
                                            className='content-el'
                                        >
                                            {userRdx?.userInfo?.phoneNumber}
                                        </div>
                                    </div>
                                    <SingleBlockButton
                                        type='button'
                                        className='myinfo-edit-button-el'
                                        onClick={__handle.action.openModifyPhoneNumberModal}
                                    >
                                        수정
                                    </SingleBlockButton>
                                </div>
                                <div
                                    className='myinfo-group2'
                                >
                                    <div className='myinfo-sub1'>
                                        <img
                                            className='icon-el'
                                            src='/assets/icon/mail_default_black.svg'
                                            alt='mail icon'
                                        ></img>
                                        <div
                                            className='content-el'
                                        >
                                            {userRdx?.userInfo?.email || '이메일을 등록해 주세요.'}
                                        </div>
                                    </div>
                                    <SingleBlockButton
                                        type='button'
                                        className='myinfo-edit-button-el'
                                        onClick={__handle.action.openModifyEmailModal}
                                    >
                                        수정
                                    </SingleBlockButton>
                                </div>
                                <div
                                    className='myinfo-group2'
                                >
                                    <div className='myinfo-sub1'>
                                        <img
                                            className='icon-el'
                                            src='/assets/icon/lock_default_black.svg'
                                            alt='lock icon'
                                        ></img>
                                        <div
                                            className='content-el'
                                        >
                                            비밀번호
                                        </div>
                                    </div>
                                    <SingleBlockButton
                                        type='button'
                                        className='myinfo-edit-button-el'
                                        onClick={__handle.action.openModifyPasswordModal}
                                    >
                                        수정
                                    </SingleBlockButton>
                                </div>
                            </DefaultInfoWrapper>
                        </ContentBox>
                    </ContentWrapper>
                    {userConsent &&
                        <ContentWrapper>
                            <ContentTitle>
                                마케팅 정보수신 동의
                            </ContentTitle>
                            <ContentBox>
                                <MarketingConsentWrapper>
                                    <div
                                        className='group1'
                                    >
                                        <div className='sub1'>
                                            <img
                                                className='icon-el'
                                                src='/assets/icon/phone_default_black.svg'
                                                alt='phone icon'
                                            ></img>
                                            <div
                                                className='content-el'
                                            >
                                                휴대전화
                                            </div>
                                        </div>
                                        {userConsent.marketingPhoneYn === 'y' &&
                                            <SingleBlockButton
                                                type='button'
                                                className='on-button-el'
                                                onClick={() => __handle.submit.changeUserConsentMarketingPhoneYn(userConsent.marketingPhoneYn)}
                                                disabled={disabledBtn}
                                            >
                                                On
                                            </SingleBlockButton>
                                        }
                                        {userConsent.marketingPhoneYn === 'n' &&
                                            <SingleBlockButton
                                                type='button'
                                                className='off-button-el'
                                                onClick={() => __handle.submit.changeUserConsentMarketingPhoneYn(userConsent.marketingPhoneYn)}
                                                disabled={disabledBtn}
                                            >
                                                Off
                                            </SingleBlockButton>
                                        }
                                    </div>
                                    <div
                                        className='group1'
                                    >
                                        <div className='sub1'>
                                            <img
                                                className='icon-el'
                                                src='/assets/icon/mail_default_black.svg'
                                                alt='mail icon'
                                            ></img>
                                            <div
                                                className='content-el'
                                            >
                                                이메일
                                            </div>
                                        </div>
                                        {userConsent.marketingEmailYn === 'y' &&
                                            <SingleBlockButton
                                                type='button'
                                                className='on-button-el'
                                                onClick={() => __handle.submit.changeUserConsentMarketingEmailYn(userConsent.marketingEmailYn)}
                                                disabled={disabledBtn}
                                            >
                                                On
                                            </SingleBlockButton>
                                        }
                                        {userConsent.marketingEmailYn === 'n' &&
                                            <SingleBlockButton
                                                type='button'
                                                className='off-button-el'
                                                onClick={() => __handle.submit.changeUserConsentMarketingEmailYn(userConsent.marketingEmailYn)}
                                                disabled={disabledBtn}
                                            >
                                                Off
                                            </SingleBlockButton>
                                        }
                                    </div>
                                </MarketingConsentWrapper>
                            </ContentBox>
                        </ContentWrapper>
                    }
                    <WithdrawalElement
                        type='button'
                        onClick={__handle.action.openWithdrawalModal}
                    >
                        회원탈퇴
                    </WithdrawalElement>
                </Wrapper>
            </Container>

            {modifyNicknameModalOpen &&
                <CommonModalComponent
                    open={modifyNicknameModalOpen}
                    onClose={__handle.action.closeModifyNicknameModal}
                >
                    <ModifyNicknameModal
                        onClose={__handle.action.closeModifyNicknameModal}
                    />
                </CommonModalComponent>
            }

            {modifyPhoneNumberModalOpen &&
                <CommonModalComponent
                    open={modifyPhoneNumberModalOpen}
                    onClose={__handle.action.closeModifyPhoneNumberModal}
                >
                    <ModifyPhoneNumberModal
                        onClose={__handle.action.closeModifyPhoneNumberModal}
                    />
                </CommonModalComponent>
            }

            {modifyEmailModalOpen &&
                <CommonModalComponent
                    open={modifyEmailModalOpen}
                    onClose={__handle.action.closeModifyEmailModal}
                >
                    <ModifyEmailModal
                        onClose={__handle.action.closeModifyEmailModal}
                    />
                </CommonModalComponent>
            }

            {modifyPasswordModalOpen &&
                <CommonModalComponent
                    open={modifyPasswordModalOpen}
                    onClose={__handle.action.closeModifyPasswordModal}
                >
                    <ModifyPasswordModal
                        onClose={__handle.action.closeModifyPasswordModal}
                    />
                </CommonModalComponent>
            }

            {modifyProfileImageUriModalOpen &&
                <CommonModalComponent
                    open={modifyProfileImageUriModalOpen}
                    onClose={__handle.action.closeModifyProfileImageUriModal}
                >
                    <ModifyProfileImageUriModal
                        onClose={__handle.action.closeModifyProfileImageUriModal}
                    />
                </CommonModalComponent>
            }

            {withdrawalModalOpen &&
                <CommonModalComponent
                    open={withdrawalModalOpen}
                    onClose={__handle.action.closeWithdrawalModal}
                >
                    <WithdrawalModal
                        onClose={__handle.action.closeWithdrawalModal}
                    />
                </CommonModalComponent>
            }
        </>
    );
}
