import { roomDataConnect } from "data_connect/roomDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";

export const useRoom = () => {
    const customRouter = useCustomRouterHook();

    const [room, setRoom] = useState(null);

    useEffect(() => {

        reqFetch();
    }, []);

    const reqFetch = async () => {
        let roomId = customRouter.query.roomId || null;

        await roomDataConnect().searchOne({ roomId })
            .then(res => {
                if (res.status === 200) {
                    setRoom(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        room
    }
}