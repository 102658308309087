import styled from 'styled-components';

const Container = styled.div`
    background: #f9fbfc;
    overflow: hidden;
`;

const Wrapper = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;

    @media all and (max-width: 992px){
        flex-direction: column;
    }

`;

export default function MyaccountMainLayoutComponent(props) {
    return (
        <>
            <Container>
                <Wrapper>
                    {props.children}
                </Wrapper>
            </Container>
        </>
    );
}