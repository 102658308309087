import { useEffect, useState } from "react";
import formatValidUtils from "utils/formatValidUtils";

export const useUser = () => {
    const [user, setUser] = useState({
        username: '',
        phoneNumber: ''
    });

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setUser({
            ...user,
            [name]: value
        })
    }

    const returnUsernameValid = (username) => {
        return formatValidUtils.isUsernameFormatValid(username);
    }

    const returnPhoneNumberValid = (phoneNumber) => {
        return formatValidUtils.isPhoneNumberFormatValid(phoneNumber);
    }

    return {
        user,
        returnUsernameValid,
        returnPhoneNumberValid,
        onChangeValueOfName
    }
}

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }
        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [
        disabledBtn,
        setDisabledBtn
    ]
}