import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
    width:100%;
    overflow: hidden;
    /* padding:0 40px; */

    @media all and (max-width:992px){
        padding:0;
    }
`;

export const ContentWrapper = styled.div`
    margin-top: 40px;
    
    &:nth-child(1){
        margin-top: 0;
    }

    .room-box{
        display: flex;
        align-items: center;
        background:#00000008;
        border-radius: 5px;
        padding:10px
    }

    .room-box>.badge-figure{
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        overflow: hidden;
        background:#f0f0f080;
        -webkit-tap-highlight-color: #00000000;
        cursor: pointer;

        @media all and (max-width:992px){
            width: 30px;
            height: 30px;
        }
    }

    .room-box>.badge-figure>.badge{
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .room-box>.nickname{
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;
        -webkit-tap-highlight-color: #00000000;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .product-title{
        font-size: 30px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 20px;
        }
    }

    .rentalHour-box{
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .price-box{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    
    .price-box>div{
        font-size: 24px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 18px;
        }
    }

    .price-box>div:nth-last-child(1){
        display: flex;
        justify-content: flex-end;
        width: 200px;
    }

    .price-box_24h{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    
    .price-box_24h>div{
        font-size: 16px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .price-box_24h>div:nth-last-child(1){
        display: flex;
        justify-content: flex-end;
        width: 200px;
    }

    .region-list-box{

    }

    .region-list-box>.title{
        font-size: 18px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 15px;
        }
    }

    .region-list-box>.item-list{
        /* list-style: none; */
        margin-top: 5px;
        padding: 0 0 0 20px;
    
        /* max-width: 250px; */
        /* width: 100%; */
    }

    .region-list-box>.item-list>.item{
        /* margin-top: 5px; */
        line-height: 1.5;
        font-size: 16px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .service-number-box{

    }

    .service-number-box>.title{
        font-size: 18px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 15px;
        }
    }

    .service-number-box>.number{
        margin-top: 5px;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .description-box{
        white-space: pre-line;
        word-break: keep-all;
        font-size: 16px;
        line-height: 1.5;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .button-group{
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
    }

    .button-group>.contact-button{
        width: 300px;
        height: 48px;
        margin: 0;
        padding: 0;
        border: 1px solid #b39283;
        background: white;
        color: #b39283;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        margin-right: 10px;
        letter-spacing: 0.07em;
        text-indent: 0.07em;

        @media all and (max-width:992px){
            flex:1;
            height: 38px;
            font-size: 15px;
        }
    }

    .button-group>.add-button{
        width: 300px;
        height: 48px;
        margin: 0;
        padding: 0;
        border: 1px solid #b39283;
        background: #b39283;
        color: white;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        letter-spacing: 0.07em;
        text-indent: 0.07em;

        @media all and (max-width:992px){
            flex:1;
            height: 38px;
            font-size: 15px;
        }
    }
`;

export const AddCartModalWrapper = styled.div`
    .product-title{
        padding: 10px;
        font-weight: 600;
        letter-spacing: 0.08em;
        font-size: 16px;

        @media all and (max-width: 992px){
            font-size: 14px;
        }
    }

    .count-select-box{
        padding: 30px 10px;
    }

    .count-select-box>.label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
        letter-spacing: 0.08em;
    }

    .count-select-box>.item{
        width: 100%;
        margin:0;
        box-sizing: border-box;
        letter-spacing: 0.08em;
    }

    .button-box{
        display: flex;
    }

    .button-box>.button-el{
        margin:0;
        border:none;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
    }
`;

export const ContactModalWrapper = styled.div`
    .title{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        text-align: center;
        padding:20px;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .phoneNumber-el{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        text-align: center;
        padding:20px;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .button-group{
        margin-top: 20px;
        display: flex;
    }

    .button-el{
        margin:0;
        padding:0;
        height: 38px;
        border: none;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;

export const PackageListContainer = styled.div`
    .title-el{
        font-size: 16px;
        font-weight: 700;
        color:#b39283;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }
`;

export const PackageListWrapper = styled.div`
    /* border: 1px solid #e0e0e0; */
    margin-top: 10px;
    display:flex;
    overflow: auto;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    &::-webkit-scrollbar {
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #f0f0f040;
        border-top: 1.5px solid white;
        border-bottom: 1.5px solid white;
    }
    &::-webkit-scrollbar-thumb {
        background:#f5f5f5;
        border-radius: 10px;
    }

    @media all and (max-width:992px){
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-track {
            background: unset;
            border-top: unset;
            border-bottom: unset;
        }
        &::-webkit-scrollbar-thumb {
            background:unset;
            border-radius: unset;
        }
    }

    .package-item-box{
        user-select: none;
        overflow: hidden;
        border-radius: 10px;
        width: 150px;
        box-sizing: border-box;
        margin-right: 10px;
        flex: 0 0 auto; /* basis 를 90%로 조절함*/
        scroll-snap-align: start;
        padding-bottom: 10px;
        box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);

        &:nth-last-child(1){
            margin-right: 0;
        }

        @media all and (max-width:992px){
            width: 120px;
        }

        .thumbnail-box{
            overflow: hidden;
            width:100%;
        }

        .thumbnail-box>.thumbnail-figure{
            position:relative;
            padding-bottom: 100%;
        }

        .thumbnail-box>.thumbnail-figure>.thumbnail-el{
            position: absolute;
            width:100%;
            height:100%;
            object-fit: cover;
        }

        .title-box{
            margin-top: 10px;
            padding: 0 10px;
            overflow: hidden;
            box-sizing: border-box;
        }

        .title-box>.title-el{
            font-size: 14px;
            letter-spacing: 0.08em;
            line-height: 1.3;
            height: 2.6em;
            color:#505050;
            font-weight: 600;

            /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            /* 글자가 라인 두줄 범위 넘어가면 ...처리 */

            @media all and (max-width:992px){
                font-size: 12px;
            }
        }

        .unit-box{
            margin-top: 10px;
            padding: 0 10px;
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            .unit-el{
                font-size: 14px;
                letter-spacing: 0.08em;
                color:#505050;
                font-weight: 600;

                @media all and (max-width:992px){
                    font-size: 12px;
                }
            }
        }
    }
`;