import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useState } from "react";
import { useDisabledBtn, useUser, useUsername } from "../hooks/findPasswordHooks";
import { Container, FormGroup, HeaderWrapper, InputBox, Wrapper } from "./ValidationFormField.styled";

export default function ValidationFormFieldComponent(props) {
    const customRouter = useCustomRouterHook();
    const {
        user,
        returnUsernameValid,
        returnPhoneNumberValid,
        onChangeValueOfName
    } = useUser();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            next: (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                if (!returnUsernameValid(user.username)) {
                    alert('아이디를 정확하게 입력해 주세요.');
                    return;
                }

                if (!returnPhoneNumberValid(user.phoneNumber)) {
                    alert('휴대전화를 정확하게 입력해 주세요.');
                    return;
                }

                customRouter.push({
                    pathname: '/find/password/validation',
                    query: {
                        u: user.username,
                        p: user.phoneNumber
                    }
                })
            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <HeaderWrapper>
                        <div className='head-box'>
                        </div>
                        <div className='head-box'>
                            <div className='title'>
                                비밀번호 찾기
                            </div>
                        </div>
                        <div className='head-box'>
                        </div>
                    </HeaderWrapper>
                    <FormGroup onSubmit={__handle.submit.next}>
                        <InputBox>
                            <div
                                className='input-label'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >아이디</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <input
                                    type='text'
                                    className={`input-item`}
                                    name='username'
                                    value={user.username || ''}
                                    onChange={(e) => onChangeValueOfName(e)}
                                    placeholder={'회원님의 아이디를 입력해 주세요.'}
                                    required
                                ></input>
                            </div>
                        </InputBox>
                        <InputBox>
                            <div
                                className='input-label'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: '10px'
                                    }}
                                >휴대전화</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <input
                                    type='text'
                                    className={`input-item`}
                                    name='phoneNumber'
                                    value={user.phoneNumber || ''}
                                    onChange={(e) => onChangeValueOfName(e)}
                                    placeholder={'회원정보에 등록된 휴대전화를 입력해 주세요. ex)01012341234'}
                                    required
                                ></input>
                            </div>
                        </InputBox>
                        <SingleBlockButton
                            type='submit'
                            className='submit-button'
                            disabled={disabledBtn}
                        >
                            다음
                        </SingleBlockButton>
                    </FormGroup>
                </Wrapper>
            </Container>
        </>
    );
}