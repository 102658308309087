import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FooterComponent from "../../views/footer/FooterComponent";
import MainComponent from "../../views/login";
import { useCustomRouterHook } from "../../hooks/router/useCustomRouterHook";

export default function LoginPage(props) {
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook()

    useEffect(() => {
        if (userRdx.isLoading) {
            return;
        }

        if (userRdx.userInfo) {
            customRouter.push({
                pathname: '/',
                replace: true
            })
        }

    }, [userRdx]);

    if (userRdx.isLoading) {
        return null;
    }

    if (userRdx.userInfo) {
        return null;
    }

    return (
        <React.Fragment>
            <MainComponent />
        </React.Fragment>
    );
}