import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 50px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-el{
        flex:1;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;

        @media all and (max-width: 992px){
            font-size: 16px;
        }
    }
    
    .more-button-el{
        margin: 0;
        padding: 0;
        width:100px;
        height: 34px;
        border-radius: 5px;
        font-size: 14px;

        @media all and (max-width: 992px){
            font-size: 12px;
            width:70px;
            height: 30px;
        }
    }
`;

export const CardListWrapper = styled.div`
    margin-top: 20px;
    display:flex;
    overflow-x: auto;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    &::-webkit-scrollbar {
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #f0f0f040;
        border-top: 1.5px solid white;
        border-bottom: 1.5px solid white;
    }
    &::-webkit-scrollbar-thumb {
        background:#f5f5f5;
        border-radius: 10px;
    }

    @media all and (max-width:992px){
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-track {
            background: unset;
            border-top: unset;
            border-bottom: unset;
        }
        &::-webkit-scrollbar-thumb {
            background:unset;
            border-radius: unset;
        }
    }
`;

export const CardBox = styled.div`
    user-select: none;
    -webkit-tap-highlight-color: #00000000;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    overflow: hidden;
    border-radius: 10px;
    width: 250px;
    box-sizing: border-box;
    margin-right: 10px;
    flex: 0 0 auto; /* basis 를 90%로 조절함*/
    scroll-snap-align: start;
    cursor: pointer;
    padding-bottom: 10px;

    &:nth-last-child(1){
        margin-right: 0;
    }

    @media all and (max-width:992px){
        width: 200px;
    }

    .thumbnail-box{
        overflow: hidden;
        width:100%;
    }

    .thumbnail-box>.thumbnail-figure{
        position:relative;
        padding-bottom: 100%;
    }

    .thumbnail-box>.thumbnail-figure>.thumbnail-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .title-box{
        margin-top: 10px;
        padding: 0 10px;
        overflow: hidden;
        box-sizing: border-box;
    }

    .title-box>.title-el{
        font-size: 16px;
        letter-spacing: 0.08em;
        font-weight: 500;
        line-height: 1.3;
        height: 2.6em;
        color:#404040;

        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .price-box{
        display: flex;
        margin-top: 10px;
        padding: 0 10px;
        box-sizing: border-box;
        justify-content: flex-end;
    }

    .price-el{
        font-size: 16px;
        letter-spacing: 0.08em;
        font-weight: 500;
        line-height: 1.3;
        word-break: keep-all;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }
`;