import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top:0;
    z-index: 100;
    width:100%;
`;

export default function IEAlert(props) {
    const [isIE, setIsIE] = useState(false);
    useEffect(() => {
      checkIE();
    }, []);

    const checkIE = () => {
        const agent = navigator.userAgent.toLowerCase();

        if (
            (navigator.appName === 'Netscape' &&
                navigator.userAgent.search('Trident') !== -1) ||
            agent.indexOf('msie') !== -1
        ) {
            setIsIE(true);
        }
    }

    if(isIE){
        return (
            <Container>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
                <Alert severity="error">이 페이지는 IE 를 지원하지 않습니다. IE 이외의 다른 브라우저를 이용해 주세요.</Alert>
            </Container>
        );
    }

    return null;
}