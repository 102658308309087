import { csSmsLogDataConnect } from "data_connect/csSmsLogDataConnect";
import { useEffect, useState } from "react";

export default function useCsSmsLogsHook(props) {
    const [csSmsLogs, setCsSmsLogs] = useState(null);

    useEffect(() => {
        reqFetchCsSmsLogs();
    }, []);

    const reqFetchCsSmsLogs = async () => {
        let params = {
            rentalOrderInfoId: props.rentalOrderInfo.id
        }

        await csSmsLogDataConnect().searchList({ params })
            .then(res => {
                if (res.status === 200) {
                    setCsSmsLogs(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        csSmsLogs
    }
}