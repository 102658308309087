import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useImageFileUploaderHook } from "views/module/uploader/useImageFileUploaderHook";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import { useRef } from "react";
import useProfileImageUriHook from "../hooks/useProfileImageUriHook";
import { ModifyProfileImageUriModalWrapper } from "../view-profile/ViewProfile.styled";

export default function ModifyProfileImageUriModal({
    onClose
}) {
    const { __reqUploadImageFile } = useImageFileUploaderHook();
    const fileUploaderRef = useRef();
    const {
        reqChangeProfileImageUri
    } = useProfileImageUriHook();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        action: {
            openfileUploader: () => {
                fileUploaderRef.current.click();
            }
        },
        submit: {
            modifySelectedImage: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                // 파일을 선택하지 않은 경우
                if (e.target.files.length <= 0) return;

                let imageInfos = await __reqUploadImageFile(e);

                let body = {
                    profileImageUri: imageInfos[0].fileFullUri
                }

                await reqChangeProfileImageUri({
                    body: body,
                    successCallback: () => onClose()
                })
            },
            modifyDefaultImage: async () => {
                setDisabledBtn(true);
                
                let body = {
                    profileImageUri: ''
                }

                await reqChangeProfileImageUri({
                    body: body,
                    successCallback: () => onClose()
                })
            }
        }
    }
    return (
        <>
            <ModifyProfileImageUriModalWrapper>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={onClose}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <form onSubmit={__handle.submit.modify}>
                    <div className='content-group'>
                        <div className='title'>
                            수정하실 회원님의 <span style={{ color: '#b39283' }}>프로필 이미지</span>를 선택해 주세요.
                        </div>
                        <div className='content-box'>
                            <div className='input-box'>
                                <SingleBlockButton
                                    type='button'
                                    className='select-button-el'
                                    style={{
                                        borderColor: '#b39283',
                                        color: '#b39283'
                                    }}
                                    onClick={__handle.action.openfileUploader}
                                    disabled={disabledBtn}
                                >
                                    이미지 선택
                                </SingleBlockButton>
                                <SingleBlockButton
                                    type='button'
                                    className='select-button-el'
                                    onClick={__handle.submit.modifyDefaultImage}
                                    disabled={disabledBtn}
                                >
                                    기본 이미지로 설정
                                </SingleBlockButton>
                            </div>
                        </div>
                    </div>
                    <div className='button-group'>
                        <SingleBlockButton
                            type='button'
                            className='button-el'
                            style={{
                                background: '#959eae',
                                flex: 1
                            }}
                            onClick={onClose}
                        >
                            취소
                        </SingleBlockButton>
                    </div>
                </form>
            </ModifyProfileImageUriModalWrapper>
            <input
                ref={fileUploaderRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onClick={(e) => e.target.value = ''}
                onChange={(e) => __handle.submit.modifySelectedImage(e)}
                hidden
                disabled={disabledBtn}
            />
        </>
    );
}