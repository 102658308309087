import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width: 992px){
    }
`;

export const Wrapper = styled.div`
    padding:50px 0;
`;

export const RoomSummaryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    

    @media all and (max-width: 992px){
        flex-direction: column;
    }

    .profile-image-wrapper{
        display: flex;
        align-items: center;

        @media all and (max-width: 992px){
            flex:1;
            justify-content: center;
        }
    }

    .profile-image-box{
        width: 200px;
        height: 200px;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 992px){
            width: 150px;
            height: 150px;
        }
    }

    .profile-image{
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .count-wrapper{
        display: flex;
        align-items: center;

        @media all and (max-width: 992px){
            margin-top: 40px;
            flex:1;
            justify-content: space-around;
        }
    }

    .count-box{
        user-select: none;
        display: flex;
        align-items: center;
        width: 100px;
        height: 100px;
        margin-left: 20px;

        @media all and (max-width: 992px){
            margin-left: 0;
            flex:1;
        }
    }

    .count-box-content{
        flex: 1;
        text-align: center;
    }

    .count-box-content .title{
        font-size: 16px;
        font-weight: 500;
        color:#505050;
    }

    .count-box-content .count{
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
        color:#505050;
    }
`;

export const IntroductionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    @media all and (max-width: 992px){
        flex-direction: column;
        margin-top: 10px;
    }

    .flex-block{
        padding: 20px;

        @media all and (max-width: 992px){
            padding: 10px;
        }
    }

    .content-box{
        flex:1;
    
        .title{
            font-size: 18px;
            font-weight: 600;

            @media all and (max-width: 992px){
                font-size: 16px;
            }
        }
    
        .description{
            font-size: 16px;
            margin-top: 5px;
            white-space: pre-line;
            letter-spacing: 0.08em;
            line-height: 1.5;
            color:#404040;
            font-weight: 500;

            @media all and (max-width: 992px){
                font-size: 14px;
            }
        }
    }
`;
