import { productDataConnect } from "data_connect/productDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useSelector } from "react-redux";

export default function useProductHook(props) {
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook();

    const reqCreate = async ({
        body,
        successCallback
    }) => {
        let roomId = userRdx.userInfo.roomId;

        await productDataConnect().createOne({
            roomId: roomId,
            body: body
        })
            .then(res => {
                if (res.status === 200) {
                    alert('제품이 추가되었습니다.');
                    customRouter.push({
                        pathname: '/myadmin/products',
                        replace: true
                    })
                    return;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error');
                    return;
                }

                alert(res.data.memo);
            })
            ;
    }

    return {
        reqCreate
    }
}