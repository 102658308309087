import { GlobalCommonBodyContainer } from '../../../../../globalStyledComponent';
import { useCustomRouterHook } from '../../../../../hooks/router/useCustomRouterHook';
import SingleBlockButton from '../../../../module/button/SingleBlockButton';
import { Wrapper } from './TopControlField.styled';

export default function TopControlFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const onRouteToPath = ({ pathname }) => {
        customRouter.push({
            pathname: pathname
        })
    }

    return (
        <>
            <GlobalCommonBodyContainer>
                <Wrapper>
                    <SingleBlockButton
                        type='button'
                        className='link-button-el'
                        onClick={() => onRouteToPath({ pathname: '/myadmin/customer-service/sms-template' })}
                    >
                        알림문자 템플릿
                    </SingleBlockButton>
                </Wrapper>
            </GlobalCommonBodyContainer>
        </>
    );
}