import styled from 'styled-components';

export const Wrapper = styled.div`
    position:relative;
    flex:1;
    border:1px solid #e0e0e0;
    border-radius: 15px;
    background: #fff;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    overflow-y: scroll;
    box-sizing: border-box;
    height: 500px;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }
    
    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }

    @media all and (max-width:992px){
        flex:none;
        height: 300px;
    }

    .empty-box{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color:#303030;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }
`;

export const Item = styled.div`
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
    -webkit-tap-highlight-color: #00000000;
    cursor: pointer;

    &:last-child{
        border-bottom: none;
    }

    .text-group{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        word-break:break-all;

        @media all and (max-width:992px){
            flex-direction: column;
        }

        .text-el-md{
            font-size: 16px;
            line-height: 1.5;
            color:#303030;

            @media all and (max-width:992px){
                font-size: 12px;
            }
            
            .label{
                display: inline-block;
                width:65px;

                @media all and (max-width:992px){
                    width:55px;
                }
            }
        }
    }
`;