import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    width: 400px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 16%);
    clip-path: inset(0px -16px 0px 0px);
    background: #fff;
    min-height: 800px;
    overflow: hidden;
    
    @media all and (max-width: 992px){
        width: 100%;
        flex:1;
        min-height: 0;
        box-shadow: none;
        clip-path: inset(0px 0px 0px 0px);
    }
`;

const HeaderWrapper = styled.div`
    margin-top: 40px;
    padding: 0 20px;

    @media all and (max-width: 992px){
        margin-top: 20px;
        padding: 0 10px;
    }

    .head-title-el{
        font-size: 25px;
        font-weight: 700;
    }
`;

const InfoWrapper = styled.div`
    @media all and (max-width: 992px){
        display: none;
    }

    .profile-image-wrapper{
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .profile-image-box{
        width: 120px;
        height: 120px;
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        @media all and (max-width: 992px){
            width: 150px;
            height: 150px;
        }
    }

    .profile-image{
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .profile-info-wrapper{
        margin-top: 10px;
    }

    .nickname-el{
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        color:#000;
    }
    
    .username-el{
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color:#505050;
    }
`;

const NavItemListWrapper = styled.div`
    margin-top: 40px;
    padding: 0 40px;

    @media all and (max-width: 992px){
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 10px;
        display: flex;
    }

    .item-box{
        margin-top: 30px;

        @media all and (max-width: 992px){
            margin-top: 0;
        }
    }

    .item-el{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        font-size: 20px;
        color:#000;
        font-weight: 600;
        text-decoration: none;
        /* text-decoration: underline;
        text-underline-position: under; */

        @media all and (max-width: 992px){
            font-size: 16px;
            margin-right: 10px;
        }
    }
`;

export default function MyaccountNavbarComponent(props) {
    const userRdx = useSelector(state => state.userRedux);
    return (
        <>
            <Container>
                <HeaderWrapper>
                    <div className='head-title-el'>
                        내 정보 관리
                    </div>
                </HeaderWrapper>
                <InfoWrapper>
                    <div className='profile-image-wrapper'>
                        <div
                            className='profile-image-box'
                        >
                            <img
                                className='profile-image'
                                src={userRdx?.userInfo?.profileImageUri || '/assets/icon/face_default_black.svg'}
                                alt="profile"
                            />
                        </div>
                    </div>
                    <div className='profile-info-wrapper'>
                        <div className='nickname-el'>
                            {userRdx?.userInfo?.nickname}
                        </div>
                        <div className='username-el'>
                            {userRdx?.userInfo?.username}
                        </div>
                    </div>
                </InfoWrapper>
                <NavItemListWrapper>
                    <div
                        className='item-box'
                    >
                        <Link
                            className='item-el'
                            to='#'
                        >
                            내프로필
                        </Link>
                    </div>
                    <div
                        className='item-box'
                    >
                        <Link
                            className='item-el'
                            to='/myadmin'
                        >
                            마이룸
                        </Link>
                    </div>
                </NavItemListWrapper>
            </Container>
        </>
    );
}