import styled from 'styled-components';

export const Container = styled.div`
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
`;

export const Wrapper = styled.div`
    padding:10px;
    @media all and (max-width:992px){
        padding:0;
    }
`;

export const FormControlWrapper = styled.div`
    padding:20px 10px;

    .label{
        font-size: 14px;
        font-weight: 500;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;

export const FormControlInput = styled.input`
    box-sizing: border-box;
    margin-top: 5px;
    width:100%;
    padding:0 10px;
    height: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-size: 14px;

    @media all and (max-width:992px){
        font-size: 12px;
    }
    
    &:focus{
        outline:none;
        border: 1px solid #b39283;
    }

    &:read-only{
        cursor: pointer;
    }
`;

export const FormControlTextarea = styled.textarea`
    box-sizing: border-box;
    margin-top: 5px;
    padding:10px;
    width:100%;
    height: 200px;
    resize:none;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;

    @media all and (max-width:992px){
        font-size: 12px;
    }

    &:focus{
        outline:none;
        border: 1px solid #b39283;
    }

    &:read-only{
        cursor: pointer;
    }
`;

export const FormControlButtonGroup = styled.div`
    .button-el{
        margin: 0;
        padding:0;
        height: 48px;
        background: #000000;
        color: white;
        border: none;
        font-size: 16px;
        font-weight: 600;
        border-radius: 10px;
    }
`;

export const ImageListWrapper = styled.div`
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .button-item{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        position: relative;
        overflow: hidden;
        width:120px;
        height: 120px;
        background: white;
        border:1px solid #b39283;
        border-radius: 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;

        @media all and (max-width:992px){
            width:70px;
            height: 70px;
        }
    }

    .button-icon{
        width: 50px;
        height: 50px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);

        @media all and (max-width:992px){
            width:40px;
            height: 40px;
        }
    }

    .image-item-box{
        position: relative;
        overflow: hidden;
        width:120px;
        height: 120px;
        background: white;
        border:1px solid #e0e0e0;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 5px;
        margin-bottom: 5px;

        @media all and (max-width:992px){
            width:70px;
            height: 70px;
        }
    }

    .image-item-box:hover>.image-el{
        -webkit-filter: grayscale(50%) blur(1px);
	    filter: grayscale(50%) blur(1px);
    }

    .image-item-box:hover>.image-delete-button{
        display: block;
    }

    .image-el{
        width:100%;
        height: 100%;
        object-fit: cover;
    }

    .image-delete-button{
        display: none;
        position:absolute;
        padding:0;
        margin:0;
        box-sizing: border-box;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:25px;
        height: 25px;
        border:1px solid #e56767;
        border-radius: 50%;
        background:white;
        cursor: pointer;
    }

    .image-delete-button-icon{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:25px;
        height: 25px;
    }
`;

export const PackageListWrapper = styled.div`
    margin-top: 5px;

    .package-item-box{
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .image-box{
        width:70px;
        height: 70px;
        overflow: hidden;
        border:1px solid #e0e0e0;
        border-radius: 10px;

        .image-el{
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .package-item-content-group{
        flex:1;
        display: flex;
        justify-content: space-between;

        .info-box{
            flex:1;
            margin-left: 10px;
            font-size: 16px;

            @media all and (max-width:992px){
                font-size: 14px;
            }
        }

        .button-group{
            display: flex;
            align-items: center;

            .button-el{
                margin:0;
                padding:0 10px;
                height: 30px;
                margin-left: 5px;
                border-radius: 5px;
                font-size: 14px;
                background: #fff;
                color:#808080;
            }
        }
    }

    .add-package-item-button-el{
        margin:10px 0 0 0;
        padding:0;
        height: 48px;
        border: 1px solid #b39283;
        border-radius: 10px;
        color:#b39283;
    }
`;

export const AddProductPackageModalWrapper = styled.div`
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }

    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;

        .image-add-button-item{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            position: relative;
            overflow: hidden;
            width:150px;
            height: 150px;
            background: white;
            border:1px solid #e0e0e0;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            padding:0;
        }

        .image-add-button-icon{
            width: 50px;
            height: 50px;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
        }

        .image-box{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            position: relative;
            overflow: hidden;
            width:150px;
            height: 150px;
            background: white;
            border:1px solid #e0e0e0;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
        }

        .image-item{
            width:100%;
            height: 100%;
            object-fit: cover;
        }
        
        .image-box:hover>.image-item{
            -webkit-filter: grayscale(50%) blur(1px);
            filter: grayscale(50%) blur(1px);
        }

        .input-box{
            padding:20px 0;

            .input-label{
                font-size: 14px;
                font-weight: 500;
    
                @media all and (max-width:992px){
                    font-size: 12px;
                }
            }
    
            .input-item{
                box-sizing: border-box;
                width:100%;
                padding: 15px 10px;
                border:none;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                margin-top: 5px;
                font-size: 14px;
    
                @media all and (max-width:992px){
                    font-size: 12px;
                }
                
                &:focus{
                    outline:none;
                    border: 1px solid #b39283;
                }
    
                &:read-only{
                    cursor: pointer;
                }
            }
        }

    }
    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }
`;

export const ModifyProductPackageModalWrapper = styled.div`
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }

    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-box{
        margin-top: 40px;

        .image-add-button-item{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            position: relative;
            overflow: hidden;
            width:150px;
            height: 150px;
            background: white;
            border:1px solid #e0e0e0;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            padding:0;
        }

        .image-add-button-icon{
            width: 50px;
            height: 50px;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
        }

        .image-box{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            position: relative;
            overflow: hidden;
            width:150px;
            height: 150px;
            background: white;
            border:1px solid #e0e0e0;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
        }

        .image-item{
            width:100%;
            height: 100%;
            object-fit: cover;
        }
        
        .image-box:hover>.image-item{
            -webkit-filter: grayscale(50%) blur(1px);
            filter: grayscale(50%) blur(1px);
        }

        .input-box{
            padding:20px 0;

            .input-label{
                font-size: 14px;
                font-weight: 500;
    
                @media all and (max-width:992px){
                    font-size: 12px;
                }
            }
    
            .input-item{
                box-sizing: border-box;
                width:100%;
                padding: 15px 10px;
                border:none;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                margin-top: 5px;
                font-size: 14px;
    
                @media all and (max-width:992px){
                    font-size: 12px;
                }
                
                &:focus{
                    outline:none;
                    border: 1px solid #b39283;
                }
    
                &:read-only{
                    cursor: pointer;
                }
            }
        }

    }
    .button-group{
        margin-top: 40px;
        display: flex;

        .button-el{
            margin:0;
            padding:0;
            height: 48px;
            border:none;
            color:#fff;
            font-size: 18px;
            font-weight: 500;
        }
    }
`;

export const ImageOrderDndModalWrapper = styled.div`
    
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px 0 20px;

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:40px;
            height: 40px;
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    }

    .content-group{
        padding: 0 20px;
    }

    .title{
        border-bottom: 1px solid #000;
        font-size: 20px;
        font-weight: 400;
        color:#303030;
        padding-bottom: 20px;
    }

    .content-wrapper{
        padding:20px 0;
    }

    .content-item-box{
        display: flex;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        margin-top: 10px;
        background:#fff;
        /* padding: 10px; */
        width:100%;
        margin-left: auto;
        margin-right: auto;
    }

    .content-numbering-el{
        text-align: center;
        width:50px;
        border-right: 1px solid #e0e0e0;
    }

    .content-image{
        flex:1;
        justify-content: center;
    }
    
    .image-item-box{
        position: relative;
        overflow: hidden;
        width:70px;
        height: 70px;
        background: white;
        border:1px solid #e0e0e0;
        /* border-radius: 10px; */
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }

    .image-el{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
`;