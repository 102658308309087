import styled from 'styled-components';
import { dateFormatUtils } from "../../../../../utils/dateFormatUtils";
import PagenationComponent from "../../../../module/pagenation/PagenationComponent";
import { useCustomRouterHook } from "../../../../../hooks/router/useCustomRouterHook";
import { Container, ItemWrapper, ItemWrapperLoading, Wrapper } from './CustomerListField.styled';
import ElementLoading from 'views/module/loading/ElementLoading';

const __ext_getOrderCount = (rentalOrderProducts) => {
    let newOrder = 0;
    let confirmOrder = 0;
    let confirmReservation = 0;
    let pickedUp = 0;
    let returned = 0;
    let completed = 0;
    let cancelled = 0;

    rentalOrderProducts.forEach(r => {
        switch (r.status) {
            case 'newOrder':
                newOrder += 1;
                break;
            case 'confirmOrder':
                confirmOrder += 1;
                break;
            case 'confirmReservation':
                confirmReservation += 1;
                break;
            case 'pickedUp':
                pickedUp += 1;
                break;
            case 'returned':
                returned += 1;
                break;
            case 'completed':
                completed += 1;
                break;
            case 'cancelled':
                cancelled += 1;
                break;
            default: break;
        }
    });

    return {
        newOrder,
        confirmOrder,
        confirmReservation,
        pickedUp,
        returned,
        completed,
        cancelled
    }
}
export default function CustomerListFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    if (props.rentalOrderInfoPage) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <ItemList
                            rentalOrderInfos={props.rentalOrderInfoPage.content}
                            selectedRentalOrderInfo={props.selectedRentalOrderInfo}
                            onActionSelectRentalOrderInfo={props.onActionSelectRentalOrderInfo}
                        />
                    </Wrapper>
                    <PagenationComponent
                        isFirst={props.rentalOrderInfoPage.first}
                        isLast={props.rentalOrderInfoPage.last}
                        pageIndex={props.rentalOrderInfoPage.number}
                        size={customRouter.query.size || 30}
                        sizeElements={[20, 50]}
                        totalPages={props.rentalOrderInfoPage.totalPages}
                        totalElements={props.rentalOrderInfoPage.totalElements}
                        align={'right'}
                    />
                </Container>
            </>
        );
    }

    return (
        <Loading />
    );
}

function ItemList({
    rentalOrderInfos,
    selectedRentalOrderInfo,
    onActionSelectRentalOrderInfo
}) {
    return (
        <>
            {rentalOrderInfos?.map(r => {
                let orderCount = __ext_getOrderCount(r.rentalOrderProducts);
                return (
                    <ItemWrapper
                        key={r.id}
                        onClick={(e) => onActionSelectRentalOrderInfo(r)}
                        style={{
                            background: `${r.id === selectedRentalOrderInfo?.id ? '#b3928330' : ''}`
                        }}
                    >
                        <div className='top-box'>
                            <div>{r.borrower}</div>
                            <div>{dateFormatUtils().dateToYYMMDDHHmmss(r.createdAt)}</div>
                        </div>
                        <div className='count-board-box'>
                            <div
                                className='item'
                                style={{
                                    color: '#e56767'
                                }}
                            >
                                <div>신규주문 {orderCount.newOrder}</div>
                            </div>
                            <div className='item'>
                                <div>주문확인 {orderCount.confirmOrder}</div>
                            </div>
                            <div className='item'>
                                <div>예약확정 {orderCount.confirmReservation}</div>
                            </div>
                            <div className='item'>
                                <div>픽업완료 {orderCount.pickedUp}</div>
                            </div>
                            <div className='item'>
                                <div>반납완료 {orderCount.returned}</div>
                            </div>
                            <div
                                className='item'
                                style={{
                                    color: '#808080'
                                }}
                            >
                                <div>완료내역 {orderCount.completed}</div>
                            </div>
                            <div
                                className='item'
                                style={{
                                    color: '#808080'
                                }}
                            >
                                <div>취소내역 {orderCount.cancelled}</div>
                            </div>
                        </div>
                    </ItemWrapper>
                );
            })}
        </>
    );
}

function Loading() {
    return (
        <Container>
            <Wrapper>
                <ItemWrapperLoading>
                    <div className='top-box'>
                        <ElementLoading className='name' />
                        <ElementLoading className='created-date' />
                    </div>
                    <div className='count-board-box'>
                        <div
                            className='item'
                            style={{
                                color: '#e56767'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                    </div>
                </ItemWrapperLoading>
                <ItemWrapperLoading>
                    <div className='top-box'>
                        <ElementLoading className='name' />
                        <ElementLoading className='created-date' />
                    </div>
                    <div className='count-board-box'>
                        <div
                            className='item'
                            style={{
                                color: '#e56767'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                    </div>
                </ItemWrapperLoading>
                <ItemWrapperLoading>
                    <div className='top-box'>
                        <ElementLoading className='name' />
                        <ElementLoading className='created-date' />
                    </div>
                    <div className='count-board-box'>
                        <div
                            className='item'
                            style={{
                                color: '#e56767'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div className='item'>
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                        <div
                            className='item'
                            style={{
                                color: '#808080'
                            }}
                        >
                            <ElementLoading className='item-loading' />
                        </div>
                    </div>
                </ItemWrapperLoading>
            </Wrapper>
        </Container>
    );
}