import { Container, ContentElement } from "./EmptyField.styled";

export default function EmptyFieldComponent(props) {
    return (
        <Container>
            <ContentElement>
                장바구니가 비었습니다.
            </ContentElement>
        </Container>
    );
}