import styled from "styled-components";

const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 100px;
    padding: 0 10px;
`;

const Wrapper = styled.div`
    border:1px solid #e1e1e1;
    padding:20px;

    & ul{
        padding: 0;
        margin-left: 30px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }

    & ol{
        padding: 0;
        margin-left: 30px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }

    & ol>li{
        font-size: 14px;
    }

    & h3{
        font-weight: 600;
    }
    & .box{
        &:nth-last-child(1){
            border:none;
        }
        border-bottom: 1px solid #e1e1e1;
        padding-bottom:20px;
        padding-top: 20px;
    }
    & .title{
        text-align: center;
        font-weight: 700;
    }

    & .text{
        font-size: 14px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }
`;

export default function MainComponent(props) {
    return (
        <>
            <Container>
                <Wrapper>
                    <h2 className='title'>이용약관</h2>
                    <div className='box'>
                        <h3>제1조(목적)</h3>
                        <div className='text'>
                            이 약관은 피아르 첫째 (이하 '회사'라 함)가 제공하는 캠팔(campal.co.kr) 서비스 및 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제2조(정의)</h3>
                        <div>
                            <ol>
                                <li>'사이트'란 캠팔(campal.co.kr)을 말합니다.</li>
                                <li>'회원'이란 사이트에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다.</li>
                                <li>'아이디(ID)'란 회원 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 문자와 숫자의 조합을 말합니다.</li>
                                <li>'비밀번호'란 회원의 정보 보호와 안전한 서비스 이용을 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.</li>
                                <li>'포인트'란 회사가 회원에게 제공하는 현금화 할 수 있는 무형의 자산을 말합니다.</li>
                                <li>'대여자'란 캠팔 서비스를 이용하여 제품을 빌리는 회원을 말합니다.</li>
                                <li>'대여처'란 캠팔 서비스를 이용하여 자신의 제품을 등록하고, '대여자'에게 제품을 빌려주는 회원을 말합니다.</li>
                                <li>정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제3조(이용약관 효력 및 적용과 변경)</h3>
                        <div>
                            <ol>
                                <li>회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 회원가입 절차와 사이트 초기화면에 게시합니다.</li>
                                <li>회사는 '약관의 규제에 관한 법률', '전자거래기본법', '전자서명법', '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                                <li>회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관 적용일자 7일 전부터 적용일자까지 사이트에 공지합니다. 다만, 개정 내용이 회원에게 불리한 경우에는 개정약관 적용일자 30일 전부터 적용일자까지 사이트에 공지하거나 회원이 등록한 이메일 주소로 약관 개정 사실을 발송하여 고지합니다.</li>
                                <li>회원이 명시적으로 개정약관의 적용에 대해 거부의 의사표시를 하지 아니한 경우 개정약관에 동의한 것으로 봅니다.</li>
                                <li>회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</li>
                                <li>이 약관에 동의하는 것은 정기적으로 회사가 운영하는 어플리케이션을 접속하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 회사는 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원 또는 이용자의 피해에 대하여 책임을 부담하지 않습니다.</li>
                                <li>본 약관에 명시되지 않은 사항에 대해서는 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령 및 회사가 정한 서비스의 세부이용지침 등의 규정 및 일반 상관례에 의합니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제4조(이용계약 체결)</h3>
                        <div>
                            <ol>
                                <li>이용계약은 회원이 되고자 하는 자가 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 하고 회사가 승낙함으로써 체결됩니다.</li>
                                <li>회원 가입시 신청 양식에서 요구한 정보를 작성하고 '서비스 이용약관'과 '개인정보처리방침'에 동의하는 것은 이 약관 및 '개인정보처리방침'의 내용을 숙지하고, 회사의 각종 서비스 정책과 공지사항을 준수하는 것에 대해 동의하는 것으로 봅니다.</li>
                                <li>회사는 아래 각 호의 경우에 승낙을 철회하거나 거절할 수 있습니다.
                                    <ul>
                                        <li>a. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 사실이 있는 경우. 단, 회원자격 상실 후 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.</li>
                                        <li>b. 등록을 신청한 사람이 만 14세 미만일 경우</li>
                                        <li>c. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
                                        <li>d. 회원 가입시 기재사항 및 기타 기재사항의 등록 내용에 허위, 기재누락, 도용, 오류 표기가 있는 경우</li>
                                    </ul>
                                </li>
                                <li>회사는 서비스를 이용하는 회원을 등급별로 구분하여 서비스 이용 범위에 차등을 둘 수 있습니다.</li>
                                <li>회원은 불법, 편법, 서비스 운영 정책의 우회를 목적으로 복수의 회원 가입을 해서는 안됩니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제5조(회원정보의 변경)</h3>
                        <div>
                            <ol>
                                <li>회원은 개인정보관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 서비스 관리를 위해 이름, 성별, 아이디 등은 직접 수정이 불가능하며, 수정이 필요한 경우 회사에 소정의 절차를 거쳐 요청하는 것으로 합니다.</li>
                                <li>회원은 회원정보로 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편, 기타 방법으로 회사에 그 변경사항을 알려야 합니다.</li>
                                <li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제6조(회원의 아이디, 비밀번호, 이름, 닉네임의 관리에 대한 의무)</h3>
                        <div>
                            <ol>
                                <li>회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
                                <li>회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다. 그 사실을 회사에 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                                <li>
                                    회사는 회원의 아이디, 이름 또는 닉네임이 아래 각 호에 해당되는 경우 회원의 아이디, 이름 또는 닉네임의 사용을 제한 또는 변경할 수 있습니다.
                                    <ul>
                                        <li>a. 개인정보 유출이 우려되는 경우</li>
                                        <li>b. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</li>
                                        <li>c. 회사 및 회사의 운영자로 오인할 우려가 있는 경우</li>
                                        <li>d. 기타 합리적인 사유가 있는 경우</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제7조(회원에 대한 통지)</h3>
                        <div>
                            <ol>
                                <li>회사는 회원이 사이트에 등록한 이메일, 전화번호 등의 연락처 또는 커뮤니케이션이 가능한 서비스를 통해 회원에게 통지할 수 있습니다.</li>
                                <li>불특정 다수 회원에 대한 통지를 해야 할 경우에는 통지 사항을 사이트 게시판 등에 게시함으로써 제 1항의 통지에 갈음할 수 있습니다.</li>
                                <li>회원이 개인정보를 허위로 기재하거나 기재사항의 변경 관리를 소홀히 하면 회사의 중요한 통지를 받지 못할 수 있습니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제8조(회사의 의무)</h3>
                        <div>
                            <ol>
                                <li>회사는 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해 최선을 다합니다.</li>
                                <li>회사는 안정적인 서비스의 제공을 위하여, 설비에 장애가 생기거나 손상된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.</li>
                                <li>회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법 및 회사의 개인정보처리방침이 적용됩니다.</li>
                                <li>회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만 사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 회원에게 처리 과정 및 결과를 전달합니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제9조(회원의 의무)</h3>
                        <div>
                            <ol>
                                <li>
                                    회원은 아래 각 호에 해당하는 행위를 하여서는 안 됩니다.
                                    <ul>
                                        <li>a. 회원정보에 허위내용을 등록하는 행위</li>
                                        <li>b. 회사가 게시한 정보의 변경</li>
                                        <li>c. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                                        <li>d. 회사 및 기타 제3자의 명예를 훼손하거나 업무를 방해하는 행위</li>
                                        <li>e. 회사의 운영자, 직원, 관계자, 기타 제3자의 정보를 도용하거나 사칭하는 행위</li>
                                        <li>f. 공공질서 또는 미풍양속에 위배되는 정보를 메시지, 화상, 음성 등으로 유포하는 행위</li>
                                        <li>g. 회사의 동의 없이 영리를 목적으로 서비스를 이용하는 행위</li>
                                        <li>h. 회사의 동의 없이 회원의 이용 권한을 타인에게 양도, 증여하거나 이를 담보로 제공하는 행위</li>
                                        <li>i. 기타 불법적이거나 부당한 행위</li>
                                    </ul>
                                </li>

                                <li>회원은 관계법, 이 약관의 규정, 운영 정책, 서비스와 관련한 공지사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</li>

                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제10조(서비스의 제공)</h3>
                        <div>
                            <ol>
                                <li>
                                    회사는 다음과 같은 업무를 수행합니다.
                                    <ul>
                                        <li>a. 제품 장기/단기 임대 및 임차에 관한 중개 관련 업무 지원 서비스</li>
                                        <li>b. 캠팔에서 제공하는 재화 또는 용역에 대한 정보제공 서비스</li>
                                    </ul>
                                </li>
                                <li>회사는 제1항의 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다.</li>
                                <li>서비스는 연중무휴 1일 24시간 제공함을 원칙으로 합니다.</li>
                                <li>회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다.</li>
                                <li>회사는 업무상 또는 기술상 서비스의 제공이 불가능한 경우 혹은 정기점검 등의 유지보수가 필요할 경우 서비스를 일시적으로 중단할 수 있으며, 이 경우 회원에게 이메일 또는 사이트 내 게시판 등에 사전에 공지합니다. 단, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제11조(서비스의 변경 및 중단)</h3>
                        <div>
                            <ol>
                                <li>회사는 변경될 서비스의 내용 및 제공일자를 회원에게 등록된 이메일로 통지할 수 있으며 서비스를 변경하여 제공할 수 있습니다.</li>
                                <li>회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</li>
                                <li>회사는 사업종목의 전환, 업체간의 통합, 사업폐지 등 기타 회사의 판단에 의해 불가피한 영업상의 이유가 있다고 인정되는 경우 제공하는 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의 서비스 내용을 게시한 곳에 그 제공일자 이전 30일 전부터 공지합니다. 단, 변동내용을 구체적으로 공지하기가 불가능한 경우에는 30일 전에 그 취지 및 공지가 불가능한 변동사유를 현재의 서비스를 게시한 곳에 공지합니다.</li>
                                <li>
                                    회사는 다음 각 호의 1에 해당하는 사유가 발생한 경우 제7조에 명시된 서비스의 일부 또는 전부를 중단할 수 있습니다.
                                    <ul>
                                        <li>a. 천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부기관의 통제 기타 회사의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는 경우</li>
                                        <li>b. 기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우</li>
                                        <li>c. 캠팔의 시스템을 포함한 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우</li>
                                        <li>d. 서비스가 제3자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에 따라 변경되거나 중지되는 경우</li>
                                        <li>e. 기타 캠팔의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</li>
                                    </ul>
                                </li>
                                <li>제3항 또는 제4항의 사유로 서비스 내용이 변경 또는 중단되는 경우, 회사는 그로 인해 회원이 입은 손해에 대하여 고의 또는 중과실이 없는 한 배상하지 아니합니다.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>제12조(위험에 대한 인지)</h3>
                        <ol>
                            <li>대여자는 제품이 해당 사항과 관련된 보증 없이 제공된다는 것에 대해 이해하고 인지합니다.</li>
                            <li>대여자는 위험이 따르는 활동이며 신체적 또는 정신적 부상, 하반신 마비, 죽음, 또는 재산이나 본인에게 손상이 갈 수 있는 예상치 못했던 위험이 따른다는 것을 이해합니다. 대여자는 이 같은 위험요소가 대여처의 책임과 관련이 없음을 인지합니다.</li>
                            <li>위험요소에는 낙하, 교통사고, 다른 사람과의 충돌, 안전하지 않은 속도로 운행, 장비 결함, 바깥 온도에 따른 몸의 이상 (저체온증, 일사병, 햇볕에 의한 화상, 수분 부족 등)이 포함됩니다.</li>
                            <li>도로, 길가, 비포장 도로, 제품 도로, 제품 레인, 또는 제품을 타는 그 어떤 곳에서도 제품에서 떨어지거나, 다른 제품, 오토바이, 자동차 또는 다른 물체와 충돌하여 부상 또는 죽음을 당할 수 있는 위험요소가 존재한다는 사실을 인지합니다.</li>
                            <li>또한 탈것 제품을 대여한 대여자의 경우 타이어 손상, 브레이크 오작동, 바인딩 결함 또는 기타 이유나 특정 날씨에 따른 위험요소 역시 존재함을 인지합니다.</li>
                            <li>대여자는 헬멧이나 장갑, 안전화 등 안전장비 같은 것들이 이 같은 위험을 감소시킬 수 있지만, 사고 당시 부상에 대한 위험이 여전히 존재함을 충분히 인지해야 합니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제13조(제품사용 금지조항)</h3>
                        <ol>
                            <li>회사는 허가된 용도 외에 금지된 용도로 제품이 이용 될 경우 관련 법률에 의거하여 해당사항을 담당 관청에 통보 할 의무를 가집니다.</li>
                            <li>
                                회사는 아래의 각호의 용도로 제품 사용을 금지합니다.
                                <ul>
                                    <li>a. 범죄를 위한 운행이나 장물 운반 등 불법적인 사용</li>
                                    <li>b. 무분별하고 부주의한 비정상적인 제품 운행</li>
                                    <li>c. 사전허가 없이 국외로 이동하는 경우</li>
                                    <li>d. 제품을 손상시키거나 이 계약서에 명시되어 있지 않는 목적으로 제품을 사용하는 행위</li>
                                </ul>
                            </li>
                            <li>회사는 계약 조항에 위배되는 행동이나 위의 조항에 대한 위반행위를 한 회원에게는 서비스를 제공하지 않을 권리를 가집니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제14조(회사의 면책)</h3>
                        <ol>
                            <li>회사는 천재지변 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
                            <li>회사는 대여자의 귀책사유로 인한 서비스 이용에 장애가 생긴 경우 책임을 지지 않습니다.</li>
                            <li>회사는 대여자가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다.</li>
                            <li>회사는 대여처가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성에 대하여 책임을 지지 않습니다.</li>
                            <li>회사는 거래시스템만을 제공할 뿐이며, 거래시스템을 이용한 거래 내용에 관한 모든 분쟁에 대해서는 당해 거래 당사자인 대여처 또는 대여자가 책임을 져야 합니다.</li>
                            <li>대여자가 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 대여자 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우, 당해 대여자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제15조(쿠폰과 포인트)</h3>
                        <ol>
                            <li>쿠폰과 포인트는 회사가 프로모션 용도로 회원에게 제공하는 것으로서, 정해진 조건에 따라 경비의 전부 또는 일부를 할인 받을 수 있는 이용권을 말합니다.</li>
                            <li>쿠폰과 포인트는 회사의 내부 정책에 따라 회원에게 개별적으로 부여할 수 있고, 회원은 쿠폰과 포인트에 명시된 조건에 따라 사용할 수 있습니다.</li>
                            <li>회사로부터 무상 제공받은 쿠폰과 포인트는 회원 간 거래가 불가능하며 어떠한 경우에도 현금으로 환불되거나 타인에게 양도되지 않습니다.</li>
                            <li>회원이 회사의 시스템 오류나 정책을 악용하여 부당한 방법으로 쿠폰이나 포인트를 적립할 경우, 회사는 회원의 서비스 이용을 정지할 수 있으며, 적립된 쿠폰과 포인트를 말소할 수 있습니다.</li>
                            <li>회사는 필요한 경우 회원의 쿠폰과 포인트 정보를 검색할 수 있으며, 쿠폰과 포인트가 착오로 지급된 경우에는 이를 임의로 정정할 수 있습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제16조(포인트 환급)</h3>
                        <ol>
                            <li>회원은 회사가 정한 운영정책과 절차에 따라 일정 금액 이상의 포인트을 현금으로 환급 요청 할 수 있습니다.</li>
                            <li>
                                포인트 1원 당 현금 1원으로 환급 되며 환급시 아래 각 호의 세금 및 제반 수수료를 환급 요청 금액에서 차감합니다.
                                <ul>
                                    <li>a. 원천징수대상 사업소득 3.3%</li>
                                </ul>
                            </li>
                            <li>회원의 귀책사유로 인한 제반 비용은 회원부담을 원칙으로 합니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제17조(포인트 소멸)</h3>
                        <ol>
                            <li>적립된 날로부터 2년 간 사용되지 않은 포인트은 소멸됩니다.</li>
                            <li>회원의 서비스 이용계약이 해지 될 경우 포인트은 소멸됩니다.</li>
                        </ol>
                    </div>


                    <div className='box'>
                        <h3>제18조(이용계약 해지)</h3>
                        <ol>
                            <li>회원은 언제든지 서비스 내의 회원탈퇴 기능을 이용하여 이용계약 해지 신청을 할 수 있습니다.</li>
                            <li>회원이 계약을 해지하는 경우 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 개인정보가 소멸됩니다.</li>
                            <li>회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 회사는 일방적으로 계약을 해지할 수가 있습니다.</li>
                            <li>회원이 1년 동안 회사의 서비스에 로그인한 기록이 없는 경우 휴면 계정으로 전환되며, 그로부터 1년간 로그인한 기록이 없는 경우 자동적으로 계약이 해지되어 개인정보는 즉시 소멸됩니다.</li>
                            <li>본 계약이 해지 된 경우 회원이 보유한 포인트 등을 받을 권리 및 적립된 포인트 등은 소멸되며 회사는 계약이 해지 된 이후의 포인트 등 지급에 대한 요구, 문의 등 일체의 처리를 행하지 않는 것으로 합니다.</li>
                            <li>본 계약이 해지 된 경우 회원이 공용게시판에 등록한 게시물, 댓글 등은 삭제되지 않습니다.</li>
                            <li>본 계약이 해지된 경우 SNS 데이터는 모두 삭제 됩니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제19조(휴면계정)</h3>
                        <ol>
                            <li>회원이 1년 동안 회사의 서비스에 로그인한 기록이 없는 경우 해당 회원의 아이디는 휴면아이디가 되어 회원을 비롯한 모든 서비스에 대한 이용이 정지되고, 회사는 약관 제18조에 따른 이용계약해지가 이루어지기 전까지 휴면계정의 개인정보를 다른 계정과 별도로 관리합니다.</li>
                            <li>회사는 1항에 의한 서비스 이용정지 30일 전 이메일을 통하여 서비스 이용 정지에 대하여 안내합니다.</li>
                            <li>회원은 서비스 이용정지 이후에 사이트 상 휴면상태 해지신청을 통해 서비스를 정상적으로 이용할 수 있습니다.</li>
                            <li>회원의 아이디가 휴면아이디가 되더라도 회원은 약관 제17조의 기간 내에 포인트를 사용할 수 있습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제20조(이용제한)</h3>
                        <ol>
                            <li>회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 전체 또는 일부 서비스를 일시정지, 영구이용정지, 포인트 출금 제한 등으로 서비스의 이용을 제한 할 수 있습니다.</li>
                            <li>회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 서비스의 이용을 재개 할 수 있습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제21조 (피연결회사와 캠팔의 책임)</h3>
                        <ol>
                            <li>캠팔 홈페이지와 다른 피연결회사(회사의 서비스 화면과 링크 등으로 연결된 홈페이지를 운영하는 회사를 말합니다)는 독자적으로 운영되며, 캠팔은 회원과 피연결 회사간에 이루어진 일체의 거래에 대해서 어떠한 책임도 지지 않습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제22조 (저작권의 귀속 및 이용제한)</h3>
                        <ol>
                            <li>캠팔에 작성한 저작물에 대한 저작권 기타 지적재산권은 캠팔에 귀속합니다.</li>
                            <li>회원은 캠팔을 이용함으로써 얻은 정보를 캠팔의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제23조 (공개게시물의 삭제)</h3>
                        <ol>
                            <li>
                                회원의 공개게시물의 내용이 다음 각 호에 해당되는 경우 캠팔은 회원에게 사전 통지 없이 공개게시물을 삭제할 수 있고 해당 회원의 회원 자격을 제한 및 정지 또는 상실시킬 수 있습니다.
                                <ul>
                                    <li>a. 회사, 다른 회원 또는 제 3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</li>
                                    <li>b. 공서양속에 위반하는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우</li>
                                    <li>c. 범죄행위와 관련이 있다고 판단되는 내용인 경우</li>
                                    <li>d. 회사의 저작권, 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                                    <li>e. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
                                    <li>f. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우</li>
                                    <li>g. 기타 관계법령 및 회사의 지침 등에 위배된다고 판단되는 경우</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제24조 (분쟁해결)</h3>
                        <ol>
                            <li>캠팔은 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 캠팔 고객센터를 설치/운영합니다.</li>
                            <li>캠팔은 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시 통보해 드립니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제25조 (손해배상)</h3>
                        <ol>
                            <li>캠팔은 회원에게 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 발생한 손해가 회사의 고의 또는 과실에 의한 경우를 제외하고는 이에 대하여 책임을 부담하지 아니합니다.</li>
                            <li>회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제26조 (준거법 및 합의관할)</h3>
                        <ol>
                            <li>본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국법 및 상관례에 따릅니다.</li>
                            <li>캠팔 서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 제소 당시의 회원의 주소에 의하고, 회원의 주소가 불분명한 경우에는 민사소송법상의 관할법원에서 제기합니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>제27조(개정이력)</h3>
                        <div className='text'>서비스 이용약관 시행일: 2022년 08월 15일</div>
                        <div className='text'>서비스 이용약관 변경공고일: 2022년 08월 15일</div>
                    </div>
                </Wrapper>
            </Container>
        </>
    );
}