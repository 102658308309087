import { useEffect } from "react";
import { useSelector } from "react-redux";
import FooterComponent from "../../../views/footer/FooterComponent";
import MainComponent from "../../../views/myadmin/create";
import NavbarMain from "../../../views/navbar/NavbarMain";
import { useCustomRouterHook } from "../../../hooks/router/useCustomRouterHook";

export default function MyadminCreatePage(props) {
    const customRouter = useCustomRouterHook();
    const userRdx = useSelector(state => state.userRedux);

    useEffect(() => {
        if (userRdx.isLoading) {
            return;
        }

        if(!userRdx.userInfo){
            customRouter.push({
                pathname: '/login',
                replace: true
            })
            return;
        }

        if (userRdx.userInfo.roomId) {
            customRouter.push({
                pathname: '/myadmin',
                replace: true
            })
            return;
        }

    }, [userRdx]);

    if(userRdx.userInfo){
        return (
            <>
                <MainComponent />
            </>
        );
    }

    return null;

}