import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import valueUtils from "utils/valueUtils";
import { ImageOrderDndModalWrapper } from "../FormField.styled";
import { v4 as uuidv4 } from 'uuid';
export default function ImageOrderDndModal({
    productImages,
    onSetProductImages,
    onClose
}) {

    const __handle = {
        action: {
            orderWithDnd: (result) => {
                if (!result.destination) {
                    return;
                }

                let newProductImages = valueUtils.reorder(
                    productImages,
                    result.source.index,
                    result.destination.index
                )

                onSetProductImages(newProductImages)
            }
        }
    }
    return (
        <>
            <ImageOrderDndModalWrapper>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={onClose}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <div className='content-group'>
                    <div className='title'>
                        이미지를 움직여 순서를 변경해 보세요.
                    </div>
                    <div className='content-wrapper'>
                        <DragDropContext onDragEnd={__handle.action.orderWithDnd}>
                            <Droppable
                                droppableId={uuidv4()}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {productImages.map((r, index) => {
                                            return (
                                                <Draggable
                                                    key={r.id}
                                                    draggableId={r.id}
                                                    index={index}

                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided.draggableProps.style
                                                            }}
                                                            className='content-item-box'
                                                        >
                                                            <div className='content-image'>
                                                                <div className='image-item-box'>
                                                                    <img
                                                                        className='image-el'
                                                                        src={r.fileFullUri}
                                                                        alt="file"
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </ImageOrderDndModalWrapper>
        </>
    );
}
