import { userDataConnect } from "data_connect/userDataConnect";
import { validationDataConnect } from "data_connect/validationDataConnect";
import { useEffect, useState } from "react";
import formatValidUtils from "utils/formatValidUtils";

export const useValidationForm = () => {
    const [validationForm, setValidationForm] = useState({
        phoneNumber: '',
        phoneNumberValidationCode: ''
    });

    const reqSendPhoneValidationCode = async ({
        phoneNumber,
        successCallback
    }) => {
        await validationDataConnect().sendPhoneValidationCodeV2({ phoneNumber, validationType: 'forFindUsername' })
            .then(res => {
                if (res.status === 200) {
                    successCallback();
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const reqFindUsername = async ({ body }) => {
        return await userDataConnect().findUsername({ body })
            .then(res => {
                if (res.status === 200) {
                    return res.data.data;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setValidationForm({
            ...validationForm,
            [name]: value
        })
    }

    const returnPhoneNumberValid = (phoneNumber) => {
        return formatValidUtils.isPhoneNumberFormatValid(phoneNumber);

    }

    const returnPhoneNumberValidationCodeValid = (phoneNumberValidationCode) => {
        if (phoneNumberValidationCode && phoneNumberValidationCode.length === 6) {
            return true;
        }
        return false;
    }

    return {
        validationForm,
        reqSendPhoneValidationCode,
        reqFindUsername,
        onChangeValueOfName,
        returnPhoneNumberValid,
        returnPhoneNumberValidationCodeValid
    }
}

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }
        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [
        disabledBtn,
        setDisabledBtn
    ]
}