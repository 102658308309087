import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import { useSelector } from "react-redux";
import useEmailHook from "../hooks/useEmailHook";
import { ModifyEmailModalWrapper } from "../view-profile/ViewProfile.styled";

export default function ModifyEmailModal({
    onClose
}) {
    const userRdx = useSelector(state => state.userRedux);

    const {
        email,
        emailValidationCode,
        validationInputModeOpen,
        returnEmailValid,
        onChangeEmail,
        onChangeEmailValidationCode,
        reqSendEmailValidationCode,
        reqChangeEmail
    } = useEmailHook();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            modify: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                let body = {
                    email: email,
                    emailValidationCode: emailValidationCode
                }

                await reqChangeEmail({
                    body: body,
                    successCallback: () => onClose()
                });
            },
            sendEmailValidationCode: async () => {
                setDisabledBtn(true);
                if (!returnEmailValid(email)) {
                    alert('이메일 형식이 정확하지 않습니다.');
                    return;
                }

                const body = {
                    email: email
                }
                await reqSendEmailValidationCode({
                    body: body,
                    successCallback: () => { }
                });
            }
        }
    }
    return (
        <ModifyEmailModalWrapper>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    onClick={onClose}
                    className='header-close-button-el'
                >
                    <img
                        className='header-close-button-icon'
                        src='/assets/icon/close_default_959eae.svg'
                        alt='close icon'
                    ></img>
                </button>
            </div>
            <form onSubmit={__handle.submit.modify}>
                <div className='content-group'>
                    <div className='title'>
                        회원정보 중 <span style={{ color: '#b39283' }}>이메일</span>을 수정하기 위해 인증절차가 필요합니다.
                    </div>
                    <div className='content-box'>
                        <div className='info-box'>
                            <img
                                className='icon-el'
                                src='/assets/icon/mail_default_black.svg'
                                alt='phone icon'
                            ></img>
                            <div
                                className='info-el'
                            >
                                {userRdx?.userInfo?.email || '현재 등록된 이메일이 없습니다.'}
                            </div>
                        </div>
                    </div>
                    <div className='content-box'>
                        <div className='input-box'>
                            <div className='flex'>
                                <input
                                    className='input-el'
                                    type='email'
                                    placeholder='이메일'
                                    value={email || ''}
                                    onChange={onChangeEmail}
                                    required
                                ></input>
                                <SingleBlockButton
                                    type='button'
                                    className='button-el'
                                    onClick={__handle.submit.sendEmailValidationCode}
                                    disabled={disabledBtn}
                                >
                                    인증
                                </SingleBlockButton>
                            </div>
                        </div>
                        {validationInputModeOpen &&
                            <div className='input-box'>
                                <input
                                    className='input-el'
                                    type='text'
                                    placeholder='인증번호'
                                    value={emailValidationCode || ''}
                                    onChange={onChangeEmailValidationCode}
                                    required
                                ></input>
                                <div className='input-notice'>해당 이메일로 인증번호를 발송했습니다.(유효시간 30분)</div>
                                <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                <div className='input-notice' style={{ color: 'red' }}>이미 등록된 이메일은 인증번호를 받을 수 없습니다.</div>
                                <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메일함을 확인하여 주세요.</div>
                            </div>
                        }
                    </div>
                </div>
                <div className='button-group'>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        style={{
                            background: '#959eae',
                            flex: 1
                        }}
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='submit'
                        className='button-el'
                        style={{
                            background: '#b39283',
                            width: '60%'
                        }}
                        disabled={disabledBtn}
                    >
                        변경
                    </SingleBlockButton>
                </div>
            </form>
        </ModifyEmailModalWrapper>
    );
}