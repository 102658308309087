import CategoryFieldComponent from "./category-field/CategoryField.component";
import HeadFieldComponent from "./head-field/HeadField.component";
import { useProductCategories } from "./hooks/productCategoryHooks";
import { useProductPage } from "./hooks/productHooks";
import { useRegions } from "./hooks/regionHook";
import { useRoom } from "./hooks/roomHooks";
import IntroduceFieldComponent from "./introduce-field/IntroduceField.component";
import ProductListFieldComponent from "./product-list-field/ProductListField.component";
import SideNavFieldComponent from "./side-nav-field/SideNavField.component";
import styled from 'styled-components';
import FloatCartMainComponent from "views/float-cart";
import { useEffect, useRef } from "react";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import valueUtils from "utils/valueUtils";

const Container = styled.div`
    margin-bottom: 100px;
`;

export default function MainComponent(props) {
    const scrollRef = useRef(null);

    const {
        room
    } = useRoom();
    const {
        productPage
    } = useProductPage();
    const {
        productCategories
    } = useProductCategories();

    const {
        regions
    } = useRegions();

    useEffect(() => {
        if (
            valueUtils.isEmptyValues(room)
            || valueUtils.isEmptyValues(productPage)
            || valueUtils.isEmptyValues(productCategories)
            || valueUtils.isEmptyValues(regions)
        ) {
            return;
        }

        let scroll = JSON.parse(localStorage.getItem("scroll_v1")) || {};

        if (scroll && scroll.room) {
            window.scrollTo(0, scroll.room);
            delete scroll.room;
        }
        localStorage.setItem('scroll_v1', JSON.stringify(scroll));
    }, [room, productPage, productCategories, regions])
    return (
        <>
            <Container>
                <HeadFieldComponent
                    room={room}
                />
                <IntroduceFieldComponent
                    productPage={productPage}
                    room={room}
                    regions={regions}
                />
                <SideNavFieldComponent />
                <div ref={scrollRef}></div>
                <CategoryFieldComponent
                    productCategories={productCategories}
                />
                <ProductListFieldComponent
                    scrollRef={scrollRef}
                    productPage={productPage}
                />
            </Container>
            <FloatCartMainComponent />
        </>
    );
}