import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
`;

export const Title = styled.h3`
    margin-bottom: 30px;
    margin-top: 0;
`;

export const FormControlBox = styled.div`
    margin-bottom: 30px;
    
    label{
        display: inline-block;
        font-size: 12px;
        margin-bottom: 5px;
        color: #666;
    }

    .flexBox{
        display: flex;
        gap: 10px;

        section{
            flex:1;
        }

        section.first{

        }

        section.second{

        }
    }

    input{
        border-radius: 10px;
    }
`;

export const BottomButtonGroup = styled.div`
    display: flex;
    gap: 10px;
    button{
        border-radius: 10px;
    }

    button.cancel{
        background-color: #fff;
        color: #444;
    }

    button.confirm{
        font-weight: 700;
        background-color: var(--mainColor);
        color: #fff;
        border: 1px solid var(--mainColor);
    }
`;