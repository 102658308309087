import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1280px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
`;

export const ContentElement = styled.div`
    text-align: center;
    font-weight: 600;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 100px 0;
    box-shadow: 1px 1px 10px 0 rgb(72 75 108 / 8%);
    background: #fff;
`;