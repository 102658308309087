import { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SingleBlockButton from "views/module/button/SingleBlockButton";
import LineBreakerBottom from "views/module/fragment/LineBreakerBottom";

// ------------ MUI DatePicker Import Start
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/ko';
// ------------ MUI DatePicker Import End

import { dateFormatUtils } from "utils/dateFormatUtils";
import { numberFormatHandler } from "utils/numberFormatHandler";
import { OrderFormConfirmationContentBox, OrderFormModalV2Wrapper } from "../CartListField.styled";
import CustomCheckboxV2 from "views/module/checkbox/CustomCheckboxV2";
import useOrderBasicInfoHook from "views/cart/hooks/useOrderBasicInfoHook";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import formatValidUtils from "utils/formatValidUtils";


export default function OrderFormModalV2Component({
    aggregatedProduct,
    onClose,
    onSubmitOrderReception
}) {
    const {
        orderBasicInfo,
        reqSendPhoneValidationCode,
        onSetServiceAgreementYn,
        onChangeValueOfName,
        onChangePickupDate,
        onChangeReturnDate,
        onChangePickupAndReturnPlace,
        onChangeServiceAgreementYn,
        onChangeSameWithOrdererFlag,
        onCheckFormValid,
        onCheckServiceAgreementYnValid,
        onCheckPhoneValidationCodeValid
    } = useOrderBasicInfoHook();

    const [orderConfirmationFlag, setOrderConfirmationFlag] = useState(false);
    const [minRentalHour, setMinRentalHour] = useState(0);
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    useEffect(() => {
        let minimumRentalHourList = aggregatedProduct.cartProducts.map(r => r.minimumRentalHour);
        let minRentalHour = Math.max.apply(null, minimumRentalHourList);

        setMinRentalHour(minRentalHour);
    }, [])

    const __handle = {
        action: {
            viewToOrderConfirmation: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);
                try {
                    // 기본 폼 체크 로직 실행
                    onCheckFormValid({
                        minRentalHour
                    });

                    // 주문자와 대여자가 다를경우 대여자 연락처 인증번호가 정확한지 체크하는 로직 실행
                    if (!orderBasicInfo.sameWithOrdererFlag) {
                        await onCheckPhoneValidationCodeValid();
                    }

                    setOrderConfirmationFlag(true);
                } catch (err) {
                    alert(err.message);
                }
            },
            viewToOrderForm: () => {
                onSetServiceAgreementYn('n')
                setOrderConfirmationFlag(false);
            }
        },
        return: {
            totalPrice: ({ diffHours }) => {
                let totalPrice = 0;
                aggregatedProduct.cartProducts?.forEach(r => {
                    totalPrice += __handle.return.productOrderPrice({ price: r.price, unit: r.unit, discountYn: r.discountYn, discountRate: r.discountRate, diffHours: diffHours, discountMinimumHour: r.discountMinimumHour });
                });
                return totalPrice;
            },
            productOrderPrice: ({ price, unit, diffHours, discountYn, discountRate, discountMinimumHour }) => {
                if (discountYn === 'y' && (diffHours >= discountMinimumHour)) {
                    return (price * unit * diffHours * (1 - (discountRate / 100)));
                } else {
                    return price * unit * diffHours;
                }

            }
        },
        submit: {
            orderReception: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                try {
                    // 기본 폼 체크 로직 실행
                    onCheckFormValid({
                        minRentalHour
                    });

                    // 개인정보 수집 및 이용에 동의 체크 로직 실행
                    onCheckServiceAgreementYnValid();

                    // 주문자와 대여자가 다를경우 대여자 연락처 인증번호가 정확한지 체크하는 로직 실행
                    if (!orderBasicInfo.sameWithOrdererFlag) {
                        await onCheckPhoneValidationCodeValid();
                    }

                    let rentalOrderProducts = aggregatedProduct.cartProducts.map(r => {
                        return {
                            productName: r.productName,
                            thumbnailUri: r.thumbnailUri,
                            price: r.price,
                            discountYn: r.discountYn,
                            discountMinimumHour: r.discountMinimumHour,
                            discountRate: r.discountRate,
                            unit: r.unit,
                            productId: r.productId
                        }
                    })

                    let rentalOrderInfo = {
                        orderer: orderBasicInfo.orderer,
                        ordererPhoneNumber: orderBasicInfo.ordererPhoneNumber,
                        borrower: orderBasicInfo.borrower,
                        borrowerPhoneNumber: orderBasicInfo.borrowerPhoneNumber,
                        borrowerPhoneNumberValidationCode: orderBasicInfo.borrowerPhoneNumberValidationCode,
                        sameWithOrdererFlag: orderBasicInfo.sameWithOrdererFlag,
                        pickupDate: orderBasicInfo.pickupDate,
                        pickupTime: orderBasicInfo.pickupTime,
                        pickupPlace: orderBasicInfo.pickupPlace,
                        returnDate: orderBasicInfo.returnDate,
                        returnTime: orderBasicInfo.returnTime,
                        returnPlace: orderBasicInfo.returnPlace,
                        serviceAgreementYn: orderBasicInfo.serviceAgreementYn,
                        rentalOrderProducts: [...rentalOrderProducts]
                    }

                    onSubmitOrderReception(rentalOrderInfo);
                } catch (err) {
                    alert(err.message);
                }
            },
            sendPhoneValidationCode: async () => {
                try {
                    formatValidUtils.checkPhoneNumberFormatValid(orderBasicInfo.borrowerPhoneNumber);

                    await reqSendPhoneValidationCode({
                        phoneNumber: orderBasicInfo.borrowerPhoneNumber,
                        successCallback: () => {
                            alert('해당번호로 인증번호를 발송했습니다.');
                            return;
                        }
                    })
                } catch (err) {
                    alert(err.message);
                }
            }
        }
    }


    if (orderConfirmationFlag) {
        return (
            <OrderConfirmationView
                orderBasicInfo={orderBasicInfo}
                aggregatedProduct={aggregatedProduct}
                disabledBtn={disabledBtn}

                __handle={__handle}
                onClose={onClose}
                onChangeServiceAgreementYn={onChangeServiceAgreementYn}
            />
        );
    }

    return (
        <BasicInfoFormView
            orderBasicInfo={orderBasicInfo}
            aggregatedProduct={aggregatedProduct}
            minRentalHour={minRentalHour}

            __handle={__handle}
            onClose={onClose}
            onChangeValueOfName={onChangeValueOfName}
            onChangePickupDate={onChangePickupDate}
            onChangeReturnDate={onChangeReturnDate}
            onChangePickupAndReturnPlace={onChangePickupAndReturnPlace}
            onChangeSameWithOrdererFlag={onChangeSameWithOrdererFlag}
        />
    )
}

function BasicInfoFormView({
    orderBasicInfo,
    aggregatedProduct,
    minRentalHour,

    __handle,
    onClose,
    onChangeValueOfName,
    onChangePickupDate,
    onChangeReturnDate,
    onChangePickupAndReturnPlace,
    onChangeSameWithOrdererFlag,
}) {
    return (
        <>
            <OrderFormModalV2Wrapper>
                <div className='header-close-button-box'>
                    <button
                        type='button'
                        onClick={onClose}
                        className='header-close-button-el'
                    >
                        <img
                            className='header-close-button-icon'
                            src='/assets/icon/close_default_959eae.svg'
                            alt='close icon'
                        ></img>
                    </button>
                </div>
                <div className='title-box'>
                    <div className='title-el'>
                        주문서
                    </div>
                </div>
                <form onSubmit={(e) => __handle.action.viewToOrderConfirmation(e)}>
                    <div className='content-container'>
                        <div className='content-wrapper'>
                            <div className='content-wrapper-label'>주문자 정보</div>
                            <div className='content-group'>
                                <div
                                    className='content-el'
                                    style={{
                                        flex: '1'
                                    }}
                                >
                                    이름
                                </div>
                                <div
                                    className='content-el'
                                    style={{
                                        flex: '1'
                                    }}
                                >
                                    {orderBasicInfo.orderer}
                                </div>
                            </div>
                            <div className='content-group'>
                                <div
                                    className='content-el'
                                >
                                    연락처
                                </div>
                                <div
                                    className='content-el'
                                >
                                    {orderBasicInfo.ordererPhoneNumber}
                                </div>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='content-wrapper-label'>대여자 정보</div>
                            <div className='content-input-box'>
                                <div
                                    style={{
                                        width: '110px'
                                    }}
                                >
                                    <CustomCheckboxV2
                                        label={'주문자와 동일'}
                                        onChange={(e) => onChangeSameWithOrdererFlag(e)}
                                        checked={orderBasicInfo.sameWithOrdererFlag || false}
                                    />
                                </div>
                            </div>
                            {orderBasicInfo.sameWithOrdererFlag &&
                                <>
                                    <div className='content-group'>
                                        <div
                                            className='content-el'
                                            style={{
                                                flex: '1'
                                            }}
                                        >
                                            대여자 이름
                                        </div>
                                        <div
                                            className='content-el'
                                            style={{
                                                flex: '1'
                                            }}
                                        >
                                            {orderBasicInfo.borrower}
                                        </div>
                                    </div>
                                    <div className='content-group'>
                                        <div
                                            className='content-el'
                                        >
                                            대여자 연락처
                                        </div>
                                        <div
                                            className='content-el'
                                        >
                                            {orderBasicInfo.borrowerPhoneNumber}
                                        </div>
                                    </div>
                                </>
                            }

                            {!orderBasicInfo.sameWithOrdererFlag &&
                                (
                                    <>
                                        <div className='content-input-box'>
                                            <TextField
                                                type='text'
                                                label='대여자 이름'
                                                className='content-input-item'
                                                placeholder='ex) 홍길동'
                                                name='borrower'
                                                value={orderBasicInfo?.borrower || ''}
                                                onChange={(e) => onChangeValueOfName(e)}
                                                required
                                            ></TextField>
                                        </div>
                                        <div
                                            className='content-input-box'
                                            style={{
                                                display: 'flex'
                                            }}
                                        >
                                            <TextField
                                                type='text'
                                                label='대여자 연락처'
                                                className='content-input-item'
                                                placeholder='ex)01012341234'
                                                name='borrowerPhoneNumber'
                                                value={orderBasicInfo?.borrowerPhoneNumber || ''}
                                                onChange={(e) => onChangeValueOfName(e)}
                                                required
                                            ></TextField>
                                            <SingleBlockButton
                                                type='button'
                                                style={{
                                                    margin: '0',
                                                    padding: '0',
                                                    height: '56px',
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '10px',
                                                    width: '100px',
                                                    marginLeft: '10px'
                                                }}
                                                onClick={() => __handle.submit.sendPhoneValidationCode()}
                                            >
                                                인증하기
                                            </SingleBlockButton>
                                        </div>
                                        <div
                                            className='content-input-box'
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            <TextField
                                                type='text'
                                                label='인증번호'
                                                className='content-input-item'
                                                placeholder='ex)01012341234'
                                                name='borrowerPhoneNumberValidationCode'
                                                value={orderBasicInfo?.borrowerPhoneNumberValidationCode || ''}
                                                onChange={(e) => onChangeValueOfName(e)}
                                                required
                                            ></TextField>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                        <div className='content-wrapper'>
                            <div className='content-wrapper-label'>대여 정보</div>
                            <div className='control-box'>
                                <FormControl
                                    fullWidth
                                    required
                                >
                                    <InputLabel>픽업 | 반납 장소 선택</InputLabel>
                                    <Select
                                        label="픽업 | 반납 장소 선택"
                                        value={orderBasicInfo?.pickupPlace || ''}
                                        name='pickupPlace'
                                        onChange={(e) => onChangePickupAndReturnPlace(e)}
                                        required
                                    >
                                        {aggregatedProduct.regions?.map(r => {
                                            return (<MenuItem value={r.fullAddress} key={r.id}>{r.fullAddress}</MenuItem>);
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                className="time-control-group"
                            >
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'ko-KR'}
                                    >
                                        <DatePicker
                                            label="픽업 날짜 선택"
                                            inputFormat="YYYY.MM.DD"
                                            mask={'____.__.__'}
                                            toolbarFormat="YY.MM.DD dd"
                                            showToolbar={false}
                                            disablePast={true}
                                            value={dayjs(orderBasicInfo?.pickupDate || new Date())}
                                            onChange={onChangePickupDate}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className='date-picker'
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="time-control-group-block"></div>
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <FormControl
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>픽업 시간 선택</InputLabel>
                                        <Select
                                            label="픽업 시간 선택"
                                            value={orderBasicInfo?.pickupTime || ''}
                                            name='pickupTime'
                                            onChange={(e) => onChangeValueOfName(e)}
                                        >
                                            <MenuItem value='09:00'>09:00</MenuItem>
                                            <MenuItem value='10:00'>10:00</MenuItem>
                                            <MenuItem value='11:00'>11:00</MenuItem>
                                            <MenuItem value='12:00'>12:00</MenuItem>
                                            <MenuItem value='13:00'>13:00</MenuItem>
                                            <MenuItem value='14:00'>14:00</MenuItem>
                                            <MenuItem value='15:00'>15:00</MenuItem>
                                            <MenuItem value='16:00'>16:00</MenuItem>
                                            <MenuItem value='17:00'>17:00</MenuItem>
                                            <MenuItem value='18:00'>18:00</MenuItem>
                                            <MenuItem value='19:00'>19:00</MenuItem>
                                            <MenuItem value='20:00'>20:00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div
                                className='time-control-group'
                            >
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            label="반납 날짜 선택"
                                            inputFormat="YYYY.MM.DD"
                                            mask={'____.__.__'}
                                            toolbarFormat="YY.MM.DD dd"
                                            showToolbar={false}
                                            disablePast={true}

                                            value={dayjs(orderBasicInfo?.returnDate || new Date())}
                                            onChange={(value) => onChangeReturnDate(value)}
                                            renderInput={(params) => <TextField {...params} className='date-picker' />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="time-control-group-block"></div>
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <FormControl
                                        fullWidth
                                        required
                                    >
                                        <InputLabel>반납 시간 선택</InputLabel>
                                        <Select
                                            label="반납 시간 선택"
                                            value={orderBasicInfo?.returnTime || ''}
                                            name='returnTime'
                                            onChange={(e) => onChangeValueOfName(e)}
                                        >
                                            <MenuItem value='09:00'>09:00</MenuItem>
                                            <MenuItem value='10:00'>10:00</MenuItem>
                                            <MenuItem value='11:00'>11:00</MenuItem>
                                            <MenuItem value='12:00'>12:00</MenuItem>
                                            <MenuItem value='13:00'>13:00</MenuItem>
                                            <MenuItem value='14:00'>14:00</MenuItem>
                                            <MenuItem value='15:00'>15:00</MenuItem>
                                            <MenuItem value='16:00'>16:00</MenuItem>
                                            <MenuItem value='17:00'>17:00</MenuItem>
                                            <MenuItem value='18:00'>18:00</MenuItem>
                                            <MenuItem value='19:00'>19:00</MenuItem>
                                            <MenuItem value='20:00'>20:00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='diffHour-box'>최소 대여 가능 시간 : <span style={{ color: '#e56767' }}>{minRentalHour}H</span></div>
                        </div>
                    </div>
                    <div className="button-box">
                        <SingleBlockButton
                            type='button'
                            className='button-el'
                            onClick={onClose}
                            style={{
                                flex: '1',
                                background: '#959eae',
                            }}
                        >
                            취소
                        </SingleBlockButton>
                        <SingleBlockButton
                            type='submit'
                            className='button-el'
                            style={{
                                background: '#b39283',
                                fontWeight: '600',
                                width: '60%'
                            }}
                        >
                            주문 확인
                        </SingleBlockButton>

                    </div>
                </form>
            </OrderFormModalV2Wrapper>
        </>
    );
}

function OrderConfirmationView({
    orderBasicInfo,
    aggregatedProduct,
    disabledBtn,

    __handle,
    onClose,
    onChangeServiceAgreementYn
}) {
    let pDate = dateFormatUtils().dateFromDateAndHH_mm(orderBasicInfo.pickupDate, orderBasicInfo.pickupTime);
    let rDate = dateFormatUtils().dateFromDateAndHH_mm(orderBasicInfo.returnDate, orderBasicInfo.returnTime);
    let diffHours = dateFormatUtils().getDiffHoursFromDates(pDate, rDate);


    return (
        <OrderFormModalV2Wrapper>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    onClick={onClose}
                    className='header-close-button-el'
                >
                    <img
                        className='header-close-button-icon'
                        src='/assets/icon/close_default_959eae.svg'
                        alt='close icon'
                    ></img>
                </button>
            </div>
            <div className='title-box'>
                <div className='title-el'>
                    주문서 확인
                </div>
            </div>

            <OrderFormConfirmationContentBox>
                <div className='info-item-box'>
                    <div className='info-item-label'>대여자 이름</div>
                    <div className='info-item-content'>{orderBasicInfo.borrower}</div>
                </div>
                <div className='info-item-box'>
                    <div className='info-item-label'>대여자 연락처</div>
                    <div className='info-item-content'>{orderBasicInfo.borrowerPhoneNumber}</div>
                </div>
                <div className='info-item-box'>
                    <div className='info-item-label'>픽업 장소</div>
                    <div className='info-item-content'>{orderBasicInfo.pickupPlace}</div>
                </div>
                <div className='info-item-box'>
                    <div className='info-item-label'>반납 장소</div>
                    <div className='info-item-content'>{orderBasicInfo.returnPlace}</div>
                </div>
                <div className='info-item-box'>
                    <div className='info-item-label'>픽업 날짜 및 시간</div>
                    <div className='info-item-content'>{dateFormatUtils().dateToYYMMDD(orderBasicInfo.pickupDate)} {orderBasicInfo.pickupTime}</div>
                </div>
                <div className='info-item-box'>
                    <div className='info-item-label'>반납 날짜 및 시간</div>
                    <div className='info-item-content'>{dateFormatUtils().dateToYYMMDD(orderBasicInfo.returnDate)} {orderBasicInfo.returnTime}</div>
                </div>
            </OrderFormConfirmationContentBox>
            <LineBreakerBottom></LineBreakerBottom>
            <OrderFormConfirmationContentBox>
                {aggregatedProduct?.cartProducts.map(r => {
                    let price = __handle.return.productOrderPrice({ price: r.price, unit: r.unit, discountYn: r.discountYn, discountRate: r.discountRate, diffHours: diffHours, discountMinimumHour: r.discountMinimumHour });
                    return (
                        <div className='item-box' key={r.cartId}>
                            <div className='item-name'>{r.productName} x {r.unit} 개 x ({diffHours}H)</div>
                            {r.discountYn === 'y' && (diffHours >= r.discountMinimumHour) &&
                                <>
                                    <div className='item-discounted'>{r.discountRate}% 할인 적용!!</div>
                                </>
                            }
                            <div className='item-price'>{numberFormatHandler().numberWithCommas(price || 0)} 원</div>
                        </div>
                    );
                })}
                <div className='info-price-box'>
                    <div className='info-price-label'>
                        합계 금액
                    </div>
                    <div className='info-price-content'>
                        {numberFormatHandler().numberWithCommas(__handle.return.totalPrice({ diffHours: diffHours }) || 0)} 원
                    </div>
                </div>
            </OrderFormConfirmationContentBox>
            <LineBreakerBottom></LineBreakerBottom>
            <OrderFormConfirmationContentBox>
                <ul
                    className='info-notice-box'
                >
                    <li className='info-notice-content'>
                        제품 대여시 대여처에 따라 보증금이 발생 할 수 있습니다.
                    </li>
                    <li className='info-notice-content'>
                        우천시 대여처별 상황에 따라 예약이 취소될 수 있습니다.
                    </li>
                    <li className='info-notice-content'>
                        예약 취소 및 기타 문의 사항은 해당 대여처로 연락하시기 바랍니다.
                    </li>
                    <li className='info-notice-content'>
                        위 안내 사항은 기본 예시사항이며 제품 대여 정책은 대여처별로 상이 할 수 있습니다. 제품 대여에 대한 정책은 대여처가 정한 사항을 우선적으로 따릅니다.
                    </li>
                    <li className='info-notice-content'>
                        대여처 연락처: {aggregatedProduct.roomPhoneNumber}
                    </li>
                </ul>
            </OrderFormConfirmationContentBox>
            <OrderFormConfirmationContentBox>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={orderBasicInfo?.serviceAgreementYn === 'y'}
                            onChange={(e) => onChangeServiceAgreementYn(e)}
                            name="serviceAgreementYn"
                            color="primary"
                        />
                    }
                    label={<b style={{ fontSize: '12px' }}><u>개인정보수집 및 이용</u>에 동의</b>}
                />
                <table style={{ width: '100%', fontSize: '10px', textAlign: 'center', border: '1px solid #888' }}>
                    <colgroup>
                        <col width="33%" />
                        <col width="33%" />
                        <col width="33%" />
                    </colgroup>
                    <thead style={{ border: '1px solid #888' }}>
                        <tr>
                            <th style={{ border: '1px solid #888', padding: '8px', fontWeight: '700' }}>목적</th>
                            <th style={{ border: '1px solid #888', fontWeight: '700' }}>항목</th>
                            <th style={{ border: '1px solid #888', fontWeight: '700' }}>보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ border: '1px solid #888', padding: '8px', wordBreak: 'keep-all' }}>주문 접수 및 이용자 식별</td>
                            <td style={{ border: '1px solid #888', wordBreak: 'keep-all' }}>이름, 전화번호</td>
                            <td style={{ border: '1px solid #888', wordBreak: 'keep-all' }}>3개월</td>
                        </tr>
                    </tbody>
                </table>
            </OrderFormConfirmationContentBox>
            <div className="button-box">
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    onClick={() => __handle.action.viewToOrderForm()}
                    style={{
                        background: '#959eae',
                        flex: 1
                    }}
                >
                    이전
                </SingleBlockButton>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    style={{
                        background: '#b39283',
                        width: '60%'
                    }}
                    onClick={(e) => __handle.submit.orderReception(e)}
                    disabled={disabledBtn}
                >
                    주문 접수
                </SingleBlockButton>
            </div>
        </OrderFormModalV2Wrapper>
    );
}