import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
`;

export const Wrapper = styled.div`
    
`;

export const LayoutWrapper = styled.div`
    overflow: hidden;
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 5px;

    .message-wrapper{
        flex:1;
        padding: 20px;
        border-right: 1px solid #e0e0e0;
        box-sizing: border-box;

        @media all and (max-width: 992px){
            padding: 10px;
        }
    }

    .message-wrapper .selected-off{
        font-size: 14px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .view-message-box{
        height: 250px;
        overflow: auto;
        font-size: 14px;
        letter-spacing: 0.08em;
        font-family: monospace;
        border: 1px solid #e0e0e0;
        padding:10px;
        cursor:default;
        white-space: pre-line;
    }

    .message-textarea-el{
        height: 250px;
        overflow: auto;
        font-size: 14px;
        letter-spacing: 0.08em;
        font-family: monospace;
        border: 1px solid #e0e0e0;
        padding:10px;
        box-sizing: border-box;
        resize: none;
        width:100%;
        outline: none;
        border-radius: 5px;

        &::-webkit-scrollbar{
            background: #e1e1e130;
            height:5px;
            width: 5px;
        }

        &::-webkit-scrollbar-track{
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #00000030;
            border-radius: 10px;
        }
    }

    .button-group{
        width:150px;

        @media all and (max-width: 992px){
            width:100px;
        }
    }

    .button-group>.button-el{
        margin:0;
        border:none;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }
`;

export const FunctionDescriptionWrapper = styled.div`
    margin-top: 20px;
    .header-el{
        letter-spacing: 0.1em;
        font-weight: 600;
        font-size: 16px;
    }

    .description-list{
        padding:0 20px;
        margin:0;
        margin-top: 5px;
        font-size: 13px;
        letter-spacing: 0.1em;
        line-height: 1.5;
        font-weight: 600;

        @media all and (max-width: 992px){
            font-size: 10px;
        }
    }
`;

export const ChangeNameModalWrapper = styled.div`
    .form-box{
    }
    
    .input-box{
        padding:20px;
    }

    .button-box{
        margin-top: 20px;
        display: flex;
    }

    .button-box>.button-el{
        margin:0;
        border: none;
        letter-spacing: 0.5em;
        text-indent: 0.5em;
    }
`;

export const LayoutWrapperLoading = styled.div`
    overflow: hidden;
    border: 1px solid #e0e0e0;
    border-radius: 5px;

    .empty-notice{
        font-size: 14px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
            height: 150px;
        }
    }
`;