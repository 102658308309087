import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
`;

export const Wrapper = styled.div`
`;

export const OrderTypeWrapper = styled.div`
    display: flex;
    margin-top: 10px;

    .item-el{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
        letter-spacing: 0.05em;
        color:#707070;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .item-el-active{
        color:#b39283;
        font-weight: 700;
        text-decoration: underline;
        text-underline-position: under;
    }
`;

export const CategorySelectWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    @media all and (max-width: 992px){
        justify-content: normal;
    }

    .control-box{
        width: 300px;
        height: 43px;
        @media all and (max-width: 992px){
            flex:1;
            height: 38px;
        }
    }

    .button-el{
        width:100%;
        height: 100%;
        margin:0;
        padding:0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 500;
        color:#505050;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .gap-block{
        padding: 0 5px;
    }

    .select-el{
        width:100%;
        height: 100%;
        margin:0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 500;
        color:#505050;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }
`;

export const RegionSelectModalWrapper = styled.div`
    .select-group{
        padding: 20px;
        display: flex;
        flex-direction: row;

        @media all and (max-width:992px){
            font-size: 12px;
            flex-direction: column;
        }
    }

    .select-group-block{
        padding: 10px;
    }

    .select-box{
        flex:1;
    }

    .select-label{
        font-size: 14px;
        font-weight: 500;
    }

    .select-el{
        margin:0;
        margin-top: 5px;
    }

    .button-group{
        margin-top: 20px;
        display: flex;
    }

    .button-el{
        margin:0;
        padding:0;
        height: 38px;
        border: none;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;