import { productDataConnect } from "data_connect/productDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useProductHook(props) {
    const [product, setProduct] = useState(null);
    const userRdx = useSelector(state => state.userRedux);
    const customRouter = useCustomRouterHook();

    useEffect(() => {
        if (!userRdx?.userInfo?.roomId || !customRouter?.query?.productId) {
            return;
        }

        reqFetchProduct();
    }, [])

    const reqFetchProduct = async () => {
        let roomId = userRdx.userInfo?.roomId;
        let productId = customRouter.query.productId;
        await productDataConnect().searchOneForModify({ productId: productId, roomId: roomId })
            .then(res => {
                if (res.status === 200) {
                    setProduct(res.data.data);
                    return;
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    const reqUpdate = async ({
        body,
        successCallback
    }) => {
        let productId = body.id;

        await productDataConnect().updateOne({
            productId: productId,
            body: body
        })
            .then(res => {
                if (res.status === 200) {
                    alert('변경되었습니다.');
                    customRouter.push({
                        pathname: '/myadmin/products',
                        replace: true
                    })
                    return;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error');
                    return;
                }

                alert(res.data.memo);
            })
            ;
    }

    return {
        product,
        reqUpdate
    }
}