import { useState } from 'react';
import styled from 'styled-components';
import { userDataConnect } from '../../data_connect/userDataConnect';
import { useCustomRouterHook } from '../../hooks/router/useCustomRouterHook';
import ConsentFieldComponent from './consent-field/ConsentField.component';
import FormFieldComponent from './form-field-v2/FormField.component';
import { useConsentForm, useSignupForm } from './hooks/signupHooks';

const Container = styled.div`

`;

export default function MainComponent(props) {
    const customRouter = useCustomRouterHook();
    const [signupFormModeOpen, setSignupFormModeOpen] = useState(false);

    const {
        reqSignup
    } = useSignupForm();

    const {
        consentForm,
        returnSelectedAll,
        onChangeValueOfName,
        onSelectAll
    } = useConsentForm();

    const __handle = {
        action: {
            openSignupFormMode: () => {
                if (consentForm.serviceTermsYn === 'n') {
                    alert('서비스 이용약관 동의는 필수 선택 입니다.');
                    return;
                }

                if (consentForm.privacyPolicyYn === 'n') {
                    alert('서비스 개인정보취급방침 동의는 필수 선택 입니다.');
                    return;
                }
                setSignupFormModeOpen(true);
            },
            closeSignupFormModal: () => {
                setSignupFormModeOpen(false);
            }
        },
        submit: {
            signup: async (signupForm) => {
                let body = {
                    ...signupForm,
                    ...consentForm
                }

                await reqSignup({
                    body: body,
                    successCallback: () => {
                        customRouter.push({
                            pathname: '/login',
                            replace: true
                        })
                    }
                })

            }
        }
    }

    if (signupFormModeOpen) {
        return (
            <Container>
                <FormFieldComponent
                    onSubmitSignup={__handle.submit.signup}
                    onActionCloseSignupFormMode={__handle.action.closeSignupFormModal}
                />
            </Container>
        );
    }

    return (
        <>
            <Container>
                <ConsentFieldComponent
                    consentForm={consentForm}
                    returnSelectedAll={returnSelectedAll}
                    onChangeValueOfName={onChangeValueOfName}
                    onSelectAll={onSelectAll}
                    onActionOpenSignupFormMode={__handle.action.openSignupFormMode}
                />
            </Container>
        </>
    );
}