import SingleBlockButton from "views/module/button/SingleBlockButton";
import useDisabledBtn from "hooks/button/useDisabledBtn";
import usePasswordHook from "../hooks/usePasswordHook";
import { ModifyPasswordModalWrapper } from "../view-profile/ViewProfile.styled";

export default function ModifyPasswordModal({
    onClose
}) {

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();
    const {
        passwordJson,
        onChangeValueOfName,
        returnPasswordValid,
        returnCompareValid,
        reqChangePassword
    } = usePasswordHook();

    const __handle = {
        submit: {
            modify: async (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (!returnPasswordValid(passwordJson.password)) {
                    alert('현재 비밀번호를 다시 확인해 주세요.');
                    return;
                }

                if (!returnPasswordValid(passwordJson.newPassword)) {
                    alert('비밀번호는 영문, 숫자, 특수문자 혼합 8-50자로 지정해 주세요.');
                    return;
                }

                if (!returnCompareValid(passwordJson.newPassword, passwordJson.newPasswordChecker)) {
                    alert('새 비밀번호를 다시 확인해 주세요.');
                    return;
                }

                if (returnCompareValid(passwordJson.password, passwordJson.newPassword)) {
                    alert('현재 비밀번호와 다른 비밀번호로 지정해 주세요.');
                    return;
                }

                let body = {
                    ...passwordJson
                }

                await reqChangePassword({
                    body: body,
                    successCallback: () => onClose()
                })
            }
        }
    }
    return (
        <ModifyPasswordModalWrapper>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    onClick={onClose}
                    className='header-close-button-el'
                >
                    <img
                        className='header-close-button-icon'
                        src='/assets/icon/close_default_959eae.svg'
                        alt='close icon'
                    ></img>
                </button>
            </div>
            <form onSubmit={__handle.submit.modify}>
                <div className='content-group'>
                    <div className='title'>
                        안전한 <span style={{ color: '#b39283' }}>비밀번호</span>를 지정하여 계정을 보호하세요.
                    </div>
                    <div className='content-box'>
                        <div className='input-box'>
                            <input
                                className='input-el'
                                type='password'
                                name='password'
                                value={passwordJson.password || ''}
                                onChange={onChangeValueOfName}
                                placeholder='현재 비밀번호'
                            ></input>
                        </div>
                        <div className='input-box'>
                            <input
                                className='input-el'
                                type='password'
                                name='newPassword'
                                value={passwordJson.newPassword || ''}
                                onChange={onChangeValueOfName}
                                placeholder='새 비밀번호'
                            ></input>
                        </div>
                        <div className='input-box'>
                            <input
                                className='input-el'
                                type='password'
                                name='newPasswordChecker'
                                value={passwordJson.newPasswordChecker || ''}
                                onChange={onChangeValueOfName}
                                placeholder='새 비밀번호 확인'
                            ></input>
                        </div>
                    </div>
                </div>
                <div className='button-group'>
                    <SingleBlockButton
                        type='button'
                        className='button-el'
                        style={{
                            background: '#959eae',
                            flex: 1
                        }}
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        type='submit'
                        className='button-el'
                        style={{
                            background: '#b39283',
                            width: '60%'
                        }}
                        disabled={disabledBtn}
                    >
                        변경
                    </SingleBlockButton>
                </div>
            </form>
        </ModifyPasswordModalWrapper>
    );
}