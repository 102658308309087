import PagenationComponent from 'views/module/pagenation/PagenationComponent';
import { useCustomRouterHook } from 'hooks/router/useCustomRouterHook';
import _ from 'lodash';
import React from 'react';
import { numberFormatHandler } from '../../../utils/numberFormatHandler';
import { CardWrapper, Container, ItemListWrapper, PagenationWrapper } from './ProductListField.styled';

export default function ProductListFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    if (props.productPage) {
        return (
            <>
                <Container>
                    <ItemListWrapper>
                        {props.productPage.content.map(product => {
                            return (
                                <ProductCard
                                    key={product.id}
                                    product={product}
                                />
                            );
                        })}
                    </ItemListWrapper>
                    <PagenationWrapper>
                        <PagenationComponent
                            isFirst={props.productPage.first}
                            isLast={props.productPage.last}
                            pageIndex={props.productPage.number}
                            size={customRouter.query.size || 30}
                            sizeElements={[30, 50, 100]}
                            totalPages={props.productPage.totalPages}
                            totalElements={props.productPage.totalElements}
                            align={'center'}
                            onPrevEvent={() => {
                                props.scrollRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
                            }}
                            onNextEvent={() => {
                                props.scrollRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
                            }}
                        />
                    </PagenationWrapper>
                </Container>
            </>
        );
    }

    return null;
}

function ProductCard({
    product
}) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            routeToProduct: (productId) => {
                let scroll = JSON.parse(localStorage.getItem('scroll_v1'));

                localStorage.setItem('scroll_v1', JSON.stringify({
                    ...scroll,
                    room: window.scrollY
                }));

                customRouter.push({
                    pathname: `/product?productId=${productId}`
                })
            }
        }
    }
    return (
        <CardWrapper
            onClick={() => __handle.action.routeToProduct(product.id)}
        >
            <div className='image-box'>
                <div className='image-figure'>
                    <img
                        className='image-el'
                        src={product.thumbnailUri}
                        alt={'product thumbnail'}
                        loading={'lazy'}
                    ></img>
                </div>
            </div>
            <div className='content-box'>
                <div className='content-title'>{product.name}</div>
                <div className='content-price'>{numberFormatHandler().numberWithCommas(product.price || 0)} 원 (1시간)</div>
                <ul className='content-list'>
                    <li>
                        최소 대여 가능 시간 {product.minimumRentalHour}H
                    </li>
                    {product.discountYn === 'y' &&
                        <li>
                            {product.discountMinimumHour}H 이상 대여시 <span style={{ color: '#b39283' }}>{product.discountRate}% 할인</span>
                        </li>
                    }
                </ul>
            </div>
        </CardWrapper>
    );
}