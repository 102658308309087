import FormFieldComponent from "./form-field/FormField.component";
import useProductHook from "./hooks/useProductHook";

export default function MainComponent(props) {
    const {
        reqCreate
    } = useProductHook();
    return (
        <>
            <FormFieldComponent
                onSubmitAdd={reqCreate}
            />
        </>
    );
}