import SingleBlockButton from "views/module/button/SingleBlockButton";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { dateFormatUtils } from "utils/dateFormatUtils";
import { Container, ContentGroup, HeaderWrapper, Wrapper } from "./ResultField.styled";

export default function ResultFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            routeToLogin: () => {
                customRouter.push({
                    pathname: '/login',
                    replace: true
                })
            }
        }
    }
    return (
        <>
            <Container>
                <Wrapper>
                    <HeaderWrapper>
                        <div className='head-box'>
                        </div>
                        <div className='head-box'>
                            <div className='title'>
                                아이디 찾기
                            </div>
                        </div>
                        <div className='head-box'>
                        </div>
                    </HeaderWrapper>
                    <ContentGroup>
                        <div className='content-box'>
                            <div>회원님의 아이디는</div>
                            <div><span className='accent'>{props.username.username}</span> 입니다.</div>
                        </div>
                        <div className='content-box'>
                            가입일자 : {dateFormatUtils().dateToYYMMDDHHmmss(props.username.createdAt)}
                        </div>
                        <SingleBlockButton
                            className='link-button'
                            onClick={__handle.action.routeToLogin}
                        >
                            로그인 페이지로 이동
                        </SingleBlockButton>
                    </ContentGroup>
                </Wrapper>
            </Container>
        </>
    );
}