import FormFieldComponent from "./form-field/FormField.component";
import useProductHook from "./hooks/useProductHook";

export default function MainComponent(props) {
    const {
        product,
        reqUpdate
    } = useProductHook();

    if (product) {
        return (
            <>
                <FormFieldComponent
                    product={product}
                    onSubmitModify={reqUpdate}
                />
            </>
        );
    }

    return null;
}