import MyaccountMainLayoutComponent from "./layout/MainLayout";
import MyaccountNavbarComponent from "./navbar/MyaccountNavbar.component";
import ViewProfileComponent from "./view-profile/ViewProfile.component";

export default function MainComponent(props) {
    return (
        <>
            <MyaccountMainLayoutComponent>
                <MyaccountNavbarComponent />
                <ViewProfileComponent />
            </MyaccountMainLayoutComponent>
        </>
    );
}