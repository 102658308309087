import { regionDataConnect } from "data_connect/regionDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";

export const useRegions = () => {
    const customRouter = useCustomRouterHook();
    const [regions, setRegions] = useState(null);

    useEffect(() => {
        reqFetch();
    }, [])

    const reqFetch = async () => {
        let roomId = customRouter.query.roomId || null;

        await regionDataConnect().searchListByRoomId({ roomId })
            .then(res => {
                if (res.status === 200) {
                    setRegions(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        regions
    }
}