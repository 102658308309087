import { useEffect, useState } from "react";
import { numberFormatHandler } from "utils/numberFormatHandler";

export default function useModifyProductHook(props) {

    const [modifyProduct, setModifyProduct] = useState(null);

    useEffect(() => {
        if (!props.product) {
            return;
        }

        onSetModifyProduct(props.product);
    }, [props.product])

    useEffect(() => {
        if(!modifyProduct?.productCategoryId){
            return;
        }

        if (modifyProduct.productCategoryId === '5eaa96eb-2421-11ed-b93e-061b211b7e76') {
            onSetModifyProduct({
                ...modifyProduct,
                packageYn: 'y'
            })
            return;
        }

        onSetModifyProduct({
            ...modifyProduct,
            packageYn: 'n'
        })

    }, [modifyProduct?.productCategoryId]);


    const onSetModifyProduct = (data) => {
        setModifyProduct({ ...data });
    }

    const onChangeValueOfName = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setModifyProduct({
            ...modifyProduct,
            [name]: value
        })
    }

    const onChangePrice = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberWithCommasOnlyFormat(value)) {
            return;
        }

        value = value.replace(/,/g, '');

        if (value < 0 || value > 1000000000) {
            return;
        }

        setModifyProduct({
            ...modifyProduct,
            price: value
        })
    }

    const onChangeMinimumRentalHour = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
            return;
        }

        setModifyProduct({
            ...modifyProduct,
            minimumRentalHour: value
        })
    }

    const onChangeDiscountYn = (e) => {
        let checked = e.target.checked;

        let discountYn = checked ? 'y' : 'n';

        setModifyProduct({
            ...modifyProduct,
            discountYn: discountYn
        })
    }

    const onChangeDiscountMinimumHour = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 10000) {
            return;
        }

        setModifyProduct({
            ...modifyProduct,
            discountMinimumHour: value
        })
    }

    const onChangeDiscountRate = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 100) {
            return;
        }

        setModifyProduct({
            ...modifyProduct,
            discountRate: value
        })
    }

    const onChangeMaxOrderUnit = (e) => {
        let value = e.target.value;

        if (!numberFormatHandler().checkNumberOnlyFormat(value) || value < 0 || value > 1000) {
            return;
        }

        setModifyProduct({
            ...modifyProduct,
            maxOrderUnit: value
        })
    }

    const checkNameValid = () => {
        if (!modifyProduct.name) {
            throw Error('제품명은 필수 입력입니다.');
        }

        if (modifyProduct.name.length < 0 || modifyProduct.name.length > 50) {
            throw Error('제품명은 1-50자로 입력해 주세요.');
        }
    }


    const checkProductCategoryValid = () => {
        if (!modifyProduct.productCategoryId) {
            throw Error('카테고리는 필수 선택입니다.');
        }
    }

    const checkPriceValid = () => {
        if (!modifyProduct.price || modifyProduct.price < 0 || modifyProduct.price > 1000000000) {
            throw Error('가격을 정확하게 입력해주세요. 0-1000000000 까지만 허용됩니다.');
        }
    }

    const checkMinimumRentalHourValid = () => {
        if (modifyProduct.minimumRentalHour <= 0 || modifyProduct.minimumRentalHour > 10000) {
            throw Error('최소 대여 가능 시간을 정확하게 입력해 주세요. 1-10000 까지만 허용됩니다.');
        }
    }

    const checkMaxOrderUnitValid = () => {
        if (modifyProduct.maxOrderUnit <= 0 || modifyProduct.maxOrderUnit > 100) {
            throw Error('최대 선택 가능 수량을 정확하게 입력해 주세요. 1-100 까지만 허용됩니다.');
        }
    }

    const checkDiscountMinimumHourValid = () => {
        if (modifyProduct.discountYn === 'y') {
            if (modifyProduct.discountMinimumHour <= 0 || modifyProduct.discountMinimumHour > 2400) {
                throw Error('할인 적용 시간을 정확하게 입력해 주세요. 1-2400 까지만 허용됩니다.');
            }
        }
    }

    const checkDiscountRateValid = () => {
        if (modifyProduct.discountYn === 'y') {
            if (modifyProduct.discountRate <= 0 || modifyProduct.discountRate > 100) {
                throw Error('할인율을 정확하게 입력해 주세요. 1-100 까지만 허용됩니다.');
            }
        }
    }

    const checkDescriptionValid = () => {
        if (modifyProduct.description && modifyProduct.description.length > 1000) {
            throw Error('설명은 최대 1000자 까지만 허용됩니다.');
        }
    }

    const returnSubmitValid = () => {
        try {
            checkNameValid();
            checkProductCategoryValid();
            checkPriceValid();
            checkMinimumRentalHourValid();
            checkMaxOrderUnitValid();
            checkDiscountMinimumHourValid();
            checkDiscountRateValid();
            checkDescriptionValid();
        } catch (err) {
            alert(err.message)
            return false;
        }

        return true;
    }

    return {
        modifyProduct,
        onChangeValueOfName,
        onChangePrice,
        onChangeMinimumRentalHour,
        onChangeDiscountYn,
        onChangeDiscountMinimumHour,
        onChangeDiscountRate,
        onChangeMaxOrderUnit,
        returnSubmitValid
    }
}