import { resetPasswordTokenDataConnect } from "data_connect/resetPasswordTokenDataConnect"

export default function useResetPasswordToken() {
    const reqCheckTokenValid = async ({ resetToken }) => {
        return await resetPasswordTokenDataConnect().checkTokenValid({ resetToken })
            .then(res => {
                if (res.status === 200) {
                    return res.data.message;
                }
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
            ;
    }

    return {
        reqCheckTokenValid
    }
}