import axios from "axios"
import { csrfDataConnect } from "./csrfDataConnect";

const MAIN_API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const validationDataConnect = () => {
    return {
        sendPhoneValidationCode: async ({ phoneNumber }) => {
            await csrfDataConnect().getApiCsrf();
            return await axios.post(`${MAIN_API_ADDRESS}/api/v1/validations/phone/validation-code/action:send`, {
                phoneNumber: phoneNumber
            }, {
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        },
        sendPhoneValidationCodeV2: async ({ phoneNumber, validationType }) => {
            await csrfDataConnect().getApiCsrf();
            return await axios.post(`${MAIN_API_ADDRESS}/api/v2/validations/phone/validation-code/action:send`, {
                phoneNumber: phoneNumber
            }, {
                params: {
                    validationType: validationType || null
                },
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        },
        sendEmailValidationCode: async ({ email }) => {
            await csrfDataConnect().getApiCsrf();
            return await axios.post(`${MAIN_API_ADDRESS}/api/v1/validations/email/validation-code/action:send`, {
                email: email
            }, {
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        },
        sendEmailValidationCodeV2: async ({ email, validationType }) => {
            await csrfDataConnect().getApiCsrf();
            return await axios.post(`${MAIN_API_ADDRESS}/api/v2/validations/email/validation-code/action:send`, {
                email: email
            }, {
                params: {
                    validationType: validationType || null
                },
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        },
        checkPhoneValidationCodeValid: async ({ phoneNumber, validationCode }) => {
            await csrfDataConnect().getApiCsrf();
            return await axios.post(`${MAIN_API_ADDRESS}/api/v2/validations/phone/validation-code/action:check-validation`, {
                phoneNumber: phoneNumber,
                validationCode: validationCode
            }, {
                withCredentials: true,
                xsrfCookieName: 'x_api_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        }
    }
}

export {
    validationDataConnect
}