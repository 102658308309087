import styled from 'styled-components';
import MainComponent from '../../views/search/order-v4';

const Container = styled.div`

`;

const SearchOrderPage = (props) => {
    return (
        <>
            <Container>
                <MainComponent></MainComponent>
            </Container>
        </>
    );
}
export default SearchOrderPage;