import { dateFormatUtils } from "utils/dateFormatUtils";
import { numberFormatHandler } from "utils/numberFormatHandler";


export const returnRentalDiffHours = ({
    pickupDate,
    pickupTime,
    returnDate,
    returnTime
}) => {
    let pDate = dateFormatUtils().dateFromDateAndHH_mm(pickupDate, pickupTime);
    let rDate = dateFormatUtils().dateFromDateAndHH_mm(returnDate, returnTime);
    let diffHours = dateFormatUtils().getDiffHoursFromDates(pDate, rDate);

    return diffHours;
}

export const returnProductOrderPrice = ({ price, unit, diffHours, discountYn, discountRate, discountMinimumHour }) => {
    if (discountYn === 'y' && (diffHours >= discountMinimumHour)) {
        return (price * unit * diffHours * (1 - (discountRate / 100)));
    } else {
        return price * unit * diffHours;
    }
}

export const returnProductsTotalPrice = ({ products, diffHours }) => {
    let totalPrice = 0;
    products.forEach(r => {
        totalPrice += returnProductOrderPrice({ price: r.price, unit: r.unit, discountYn: r.discountYn, discountRate: r.discountRate, diffHours: diffHours, discountMinimumHour: r.discountMinimumHour });
    });
    return totalPrice;
}

export const returnTransformedSmsText = ({
    smsText,
    rentalOrderInfo
}) => {
    let borrower = rentalOrderInfo.borrower;
    let borrowerPhoneNumber = rentalOrderInfo.borrowerPhoneNumber;
    let pickupDate = rentalOrderInfo.pickupDate;
    let pickupTime = rentalOrderInfo.pickupTime;
    let returnDate = rentalOrderInfo.returnDate;
    let returnTime = rentalOrderInfo.returnTime;
    let products = rentalOrderInfo.rentalOrderProducts;

    let replacers = [
        {
            from: '%$예약자이름$',
            replace: (text) => {
                return text.replaceAll(
                    '%$예약자이름$',
                    `${borrower}`
                );
            }
        },
        {
            from: '%$예약자연락처$',
            replace: (text) => {
                return text.replaceAll(
                    '%$예약자연락처$',
                    `${borrowerPhoneNumber}`
                );
            }
        },
        {
            from: '%$픽업날짜$',
            replace: (text) => {
                return text.replaceAll(
                    '%$픽업날짜$',
                    `${dateFormatUtils().dateToYYMMDD(pickupDate)}`
                );
            }
        },
        {
            from: '%$반납날짜$',
            replace: (text) => {
                return text.replaceAll(
                    '%$반납날짜$',
                    `${dateFormatUtils().dateToYYMMDD(returnDate)}`
                );
            }
        },
        {
            from: '%$픽업시간$',
            replace: (text) => {
                return text.replaceAll(
                    '%$픽업시간$',
                    `${pickupTime}`
                );
            }
        },
        {
            from: '%$반납시간$',
            replace: (text) => {
                return text.replaceAll(
                    '%$반납시간$',
                    `${returnTime}`
                );
            }
        },
        {
            from: '%$총대여시간$',
            replace: (text) => {
                let diffHours = returnRentalDiffHours({
                    pickupDate: pickupDate,
                    pickupTime: pickupTime,
                    returnDate: returnDate,
                    returnTime: returnTime
                });

                return text.replaceAll(
                    '%$총대여시간$',
                    `${diffHours}`
                )
            }
        },
        {
            from: '%$제품정보$',
            replace: (text) => {
                let productsInfoText = ``;
                products.forEach((r, index, array) => {
                    productsInfoText += `${index + 1}. ${r.productName} // ${r.unit}개`
                    if (index !== array.length - 1) {
                        productsInfoText += '\n';
                    }
                })
                return text.replaceAll(
                    '%$제품정보$',
                    `${productsInfoText}`
                );
            }
        },
        {
            from: '%$최종금액$',
            replace: (text) => {
                return text.replaceAll(
                    '%$최종금액$',
                    `${numberFormatHandler().numberWithCommas(
                        returnProductsTotalPrice({
                            products: products,
                            diffHours: returnRentalDiffHours({
                                pickupDate: pickupDate,
                                pickupTime: pickupTime,
                                returnDate: returnDate,
                                returnTime: returnTime
                            })
                        })
                    )}`
                )
            }
        },
    ];

    replacers.forEach(r => {
        smsText = r.replace(smsText);
    })

    return smsText;
}

export const returnStatus = ({ type }) => {
    switch (type) {
        case 'newOrder':
            return '신규주문';
        case 'confirmOrder':
            return '주문확인';
        case 'confirmReservation':
            return '예약확정';
        case 'pickedUp':
            return '픽업완료';
        case 'returned':
            return '반납완료';
        case 'completed':
            return '완료내역';
        case 'cancelled':
            return '취소내역';
        default: return '';
    }
}