import { rentalOrderInfoDataConnect } from "data_connect/rentalOrderInfoDataConnect";
import { useEffect, useState } from "react";

export default function useRentalOrderInfoPageHook(props) {
    const [rentalOrderInfoPage, setRentalOrderInfoPage] = useState(null);

    useEffect(() => {
        reqFetchRentalOrderInfoPage();
    }, []);

    const reqFetchRentalOrderInfoPage = async () => {
        await rentalOrderInfoDataConnect().searchPageMyOrder()
            .then(res => {
                if (res.status === 200) {
                    setRentalOrderInfoPage(res.data.data);
                }
            })
            .catch(err => {

            })
    }

    return {
        rentalOrderInfoPage
    }
}