import ElementLoading from "views/module/loading/ElementLoading";
import valueUtils from "utils/valueUtils";
import { CardItemBox, CardItemBoxLoading, CartItemsWrapper, CartItemsWrapperLoading, Container, Wrapper } from "./TemplateListField.styled";

export default function TemplateListFieldComponent(props) {
    if (props.csSmsTemplates) {
        if (!valueUtils.isEmptyValues(props.csSmsTemplates)) {
            return (
                <>
                    <Container>
                        <Wrapper>
                            <CartItemsWrapper>
                                {props.csSmsTemplates?.map(r => {
                                    return (
                                        <CardItemBox
                                            key={r.id}
                                            onClick={() => props.onActionSelect(r)}
                                            style={{
                                                background: `${props.selectedCsSmsTemplate?.id === r.id ? '#b3928330' : ''}`
                                            }}
                                        >
                                            {r.name}
                                        </CardItemBox>
                                    );
                                })}
                            </CartItemsWrapper>
                        </Wrapper>
                    </Container>
                </>
            );
        }

        return (
            <Container>
                <Wrapper>
                    <CartItemsWrapperLoading>
                        <div className='empty-notice'>
                            템플릿을 생성하고 반복 알림문자를 관리해 보세요.
                        </div>
                    </CartItemsWrapperLoading>
                </Wrapper>
            </Container>
        );
    }

    return (
        <Container>
            <Wrapper>
                <CartItemsWrapperLoading>
                    <CardItemBoxLoading>
                        <ElementLoading className='card-item' />
                    </CardItemBoxLoading>
                    <CardItemBoxLoading>
                        <ElementLoading className='card-item' />
                    </CardItemBoxLoading>
                    <CardItemBoxLoading>
                        <ElementLoading className='card-item' />
                    </CardItemBoxLoading>
                </CartItemsWrapperLoading>
            </Wrapper>
        </Container>
    );
}