import { useCustomRouterHook } from '../../../hooks/router/useCustomRouterHook';
import { Container, NavWrapper } from './SideNavField.styled';

export default function SideNavFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            routeToViewType: (viewType) => {
                let query = {
                    ...customRouter.query,
                    viewType: viewType
                };
                customRouter.push({
                    pathname: '/room',
                    query: query,
                    replace: true
                })
            }
        }
    }
    return (
        <>
            <Container>
                <NavWrapper>
                    <div
                        className={`nav-item ${!customRouter.query.viewType || customRouter.query.viewType === 'product' ? 'active' : ''}`}
                        onClick={() => __handle.action.routeToViewType('product')}
                    >
                        제품
                    </div>
                    <div
                        className='nav-item'
                        style={{
                            color: '#e0e0e0'
                        }}
                    >
                        후기(준비중)
                    </div>
                    <div
                        className='nav-item'
                        style={{
                            color: '#e0e0e0'
                        }}
                    >
                        문의(준비중)
                    </div>
                </NavWrapper>
            </Container>
        </>
    );
}