import { Container, IntroductionWrapper, RoomSummaryWrapper, Wrapper } from "./IntroduceField.styled";

export default function IntroduceFieldComponent(props) {
    if (props.room && props.productPage && props.regions) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <RoomSummaryWrapper>
                            <div className='profile-image-wrapper'>
                                <div
                                    className='profile-image-box'
                                >
                                    <img
                                        className='profile-image'
                                        src={props.room.profileImageUri || '/assets/icon/face_default_black.svg'}
                                        alt="profile"
                                    />
                                </div>
                            </div>
                            <div className='count-wrapper'>
                                <div
                                    className='count-box'
                                >
                                    <div
                                        className='count-box-content'
                                    >
                                        <div className='title'>제품수</div>
                                        <div className='count'>{props.productPage.totalElements} 개</div>
                                    </div>
                                </div>
                                <div
                                    className='count-box'
                                >
                                    <div
                                        className='count-box-content'
                                    >
                                        <div
                                            className='title'
                                            style={{
                                                color: '#e0e0e0'
                                            }}
                                        >
                                            평점
                                        </div>
                                        <div
                                            className='count'
                                            style={{
                                                color: '#e0e0e0'
                                            }}
                                        >
                                            준비중...
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='count-box'
                                >
                                    <div
                                        className='count-box-content'
                                    >
                                        <div
                                            className='title'
                                            style={{
                                                color: '#e0e0e0'
                                            }}
                                        >
                                            후기
                                        </div>
                                        <div
                                            className='count'
                                            style={{
                                                color: '#e0e0e0'
                                            }}
                                        >
                                            준비중...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RoomSummaryWrapper>
                        <IntroductionWrapper>
                            <div className='content-box'>
                                <div className='title'>소개글</div>
                                <div className='description'>
                                    {props.room.introduction}
                                </div>
                            </div>
                            <div className='flex-block'></div>
                            <div className='content-box'>
                                <div className='title'>픽업 | 반납 장소</div>
                                <div className='description'>
                                    {props.regions.map(r => {
                                        return (
                                            <div key={r.id}>
                                                {r.fullAddress}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </IntroductionWrapper>
                    </Wrapper>
                </Container>
            </>
        );
    }

    return null;

}