import styled from 'styled-components';
import { GlobalCommonBodyContainer } from '../../../globalStyledComponent';

const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px 0 10px;

    @media all and (max-width:992px){
    }
`;

const Wrapper = styled.div`
    font-size: 25px;
    font-weight: 700;

    @media all and (max-width: 992px){
        font-size: 24px;
    }
`;
export default function HeadFieldComponent(props) {
    return (
        <>
            <Container>
                <Wrapper>
                    장바구니
                </Wrapper>
            </Container>
        </>
    );
}