import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
`;

export const Wrapper = styled.div`
`;

export const OrderTypeWrapper = styled.div`
    display: flex;
    margin-top: 10px;

    .item-el{
        user-select: none;
        -webkit-tap-highlight-color: #00000000;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
        letter-spacing: 0.05em;
        color:#707070;
        font-weight: 500;

        @media all and (max-width: 992px){
            font-size: 12px;
        }
    }

    .item-el-active{
        color:#b39283;
        font-weight: 700;
        text-decoration: underline;
        text-underline-position: under;
    }
`;

export const CategorySelectWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .select-el{
        width:300px;
        height: 43px;
        margin:0;
        cursor: pointer;
        font-size: 14px;
        border-radius: 5px;

        @media all and (max-width: 992px){
            width:150px;
            height: 35px;
            font-size: 12px;
        }
    }
`;