import { productCategoryDataConnect } from "data_connect/productCategoryDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";

export const useProductCategories = () => {
    const customRouter = useCustomRouterHook();
    const [productCategories, setProductCategories] = useState(null);

    useEffect(() => {
        reqFetch();
    }, [])

    const reqFetch = async () => {
        let roomId = customRouter.query.roomId || null;

        await productCategoryDataConnect().searchListByRoom({ roomId })
            .then(res => {
                if (res.status === 200) {
                    setProductCategories(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        productCategories
    }
}