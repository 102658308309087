import styled from "styled-components";

const Container = styled.div`
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 100px;
    padding: 0 10px;
`;

const Wrapper = styled.div`
    border:1px solid #e1e1e1;
    padding:20px;
    overflow: hidden;

    & ul{
        padding: 0;
        margin-left: 30px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }

    & ol{
        padding: 0;
        margin-left: 30px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }

    & ol>li{
        font-size: 14px;
    }

    & h3{
        font-weight: 600;
    }
    & .box{
        &:nth-last-child(1){
            border:none;
        }
        border-bottom: 1px solid #e1e1e1;
        padding-bottom:20px;
        padding-top: 20px;
    }
    & .title{
        text-align: center;
        font-weight: 700;
    }

    & .text{
        font-size: 14px;
        letter-spacing: 0.08em;
        line-height: 1.7;
    }

    .table-box{
        overflow-x: scroll;
    }

    & table{
        width:100%; 
        border-collapse: collapse;
    }
    
    & table th, td{
        padding:3px 8px;
    }

    & table th{
        text-align: center;
        font-weight: 600;
        background: #f1f1f1;
        font-size: 14px;
        letter-spacing: 0.08em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    & table td{
        font-size: 14px;
        letter-spacing: 0.08em;
        word-break: keep-all;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    & table tr, th, td{
        border:1px solid #e1e1e1;
    }
`;

export default function MainComponent(props) {
    return (
        <>
            <Container>
                <Wrapper>
                    <h2 className='title'>개인정보처리방침</h2>
                    <div className='box'>
                        <div className='text'>
                            <b>피아르 첫째</b>에서 제공하는 캠팔 서비스(이하 “회사”)는 이용자의 개인정보를 매우 중요하게 생각하며 정보통신서비스 제공자가 준수하여야 하는 관련 법령 및 규정을 준수하고 있습니다. 본 개인정보처리방침은 회사의 웹사이트에 적용되며, 다음과 같은 내용을 담고 있습니다.
                        </div>
                        <ol>
                            <li>개인정보의 수집∙이용 목적</li>
                            <li>수집하는 개인정보 항목 및 수집방법</li>
                            <li>개인정보의 제3자 제공</li>
                            <li>개인정보의 취급위탁</li>
                            <li>개인정보 보유 및 이용기간</li>
                            <li>개인정보 파기절차 및 방법</li>
                            <li>이용자 및 법정대리인의 권리</li>
                            <li>쿠키의 운영 및 수집 거부 방법</li>
                            <li>개인정보 보호를 위한 기술적∙관리적 보호 대책</li>
                            <li>개인정보 관리책임자 및 담당부서</li>
                            <li>링크 사이트에 대한 책임</li>
                            <li>개인정보처리방침의 고지 의무</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>1. 개인정보의 수집 및 이용 목적</h3>
                        <div className='text'>회사는 아래와 같은 목적으로 서비스 제공을 위한 최소한의 개인정보만을 수집하며, 수집한 정보를 목적 외로 사용하거나, 이용자의 동의 없이 외부에 공개하지 않습니다.</div>
                        <ol>
                            <li>회원관리 : 회원제 서비스 제공에 따른 개인식별, 가입의사 확인, 이용약관 위반 회원에 대한 이용제한 조치, 가입 및 가입횟수 제한, 서비스 부정 이용 제재, 비인가 사용 방지, 만 14세 미만 아동의 개인정보 수집 시 법정대리인 동의 여부 확인, 추후 법정대리인 본인 확인, 고충 처리 및 분쟁 조정을 위한 기록 보존, 고지사항 전달, 회원탈퇴 의사의 확인</li>
                            <li>신규 서비스 개발 및 마케팅 광고에의 활용 : 신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트·광고성 정보 및 참여 기회 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>2. 수집하는 개인정보 항목 및 수집방법</h3>
                        <div className='text'>회사는 아래의 경우 개인정보를 수집합니다.</div>
                        <div className='table-box'>
                            <table>
                                <colgroup>
                                    <col width="33%" />
                                    <col width="33%" />
                                    <col width="33%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>수집 시기</th>
                                        <th>수집 방법</th>
                                        <th>수집 항목</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>회원가입시</td>
                                        <td>웹사이트</td>
                                        <td>필수항목: 아이디, 이름, 닉네임, 이메일주소, 휴대전화번호, 비밀번호</td>
                                    </tr>
                                    <tr>
                                        <td>대여 신청시</td>
                                        <td>웹사이트</td>
                                        <td>
                                            <div>회원정보(이름, 휴대전화번호)</div>
                                            <div>배송정보(이름, 휴대전화번호, 주소)</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>입금신청시</td>
                                        <td>웹사이트</td>
                                        <td>입금계좌, 이름, 주민등록번호(세금처리를위함)</td>
                                    </tr>
                                    <tr>
                                        <td>이벤트신청시</td>
                                        <td>웹사이트</td>
                                        <td>이름, 전화번호, 이메일주소, 이벤트 참여를 위한 추가 개인정보</td>
                                    </tr>
                                    <tr>
                                        <td>기타</td>
                                        <td>자동 혹은 수동으로 생성, 수집</td>
                                        <td>[공통] 쿠키, 접속IP, 접속환경, 접속일시, 불량 혹은 비정상 이용기록, 서비스이용기록, [모바일]os버전, 단말기정보</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>3. 개인정보의 제3자 제공</h3>
                        <div className='text'>회사는 원활한 서비스 제공을 위하여 관련 정보를 필요 범위 내에서 아래 업체에게 제공합니다.</div>
                        <div className='table-box'>
                            <table>
                                <colgroup>
                                    <col width="25%" />
                                    <col width="25%" />
                                    <col width="25%" />
                                    <col width="25%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>제공받는 자</th>
                                        <th>제공목적</th>
                                        <th>제공항목</th>
                                        <th>보유 및 이용기간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>대여처 및 판매자</td>
                                        <td>주문상품의 예약확인 및 배송</td>
                                        <td>
                                            <div>회원정보(ID, 이름, 휴대전화번호, 이메일 주소)</div>
                                            <div>배송정보(이름, 휴대전화번호, 주소)</div>
                                        </td>
                                        <td rowSpan={2}>
                                            재화 또는 서비스 제공을 완료한 후, 내부 방침 및 기타 관련법령에 의한 정보보호 사유에 따라 (제5조. 개인정보 보유 및 이용기간) 일정 기간 저장 후 파기
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>(주)채널코퍼레이션</td>
                                        <td>챗봇 서비스 (FAQ, 안내)</td>
                                        <td>
                                            <div>회원정보(이름, 이메일주소, 추천을 위한 추가 개인정보)</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ol>
                            <li>회사는 회원의 개인정보를 동의 없이 제3자에게 제공하지 않습니다. 다만, 법령의 규정에 의한 경우는 예외로 합니다.</li>
                            <li>이용자가 대여 신청을 하는 경우, 개인정보를 제공받는 자에 관하여 개별적으로 사전동의를 받습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>4. 개인정보의 취급위탁</h3>
                        <div className='text'>회사는 향상된 서비스 제공을 위하여 전문업체에 일부 서비스를 위탁 처리하고 있습니다. 회사는 위탁 시 관련법령에 따라 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 취하고 있으며, 위탁처리기관의 해당 업무에 필요한 최소한의 개인정보만 전달됩니다.</div>
                        <div className='table-box'>
                            <table>
                                <colgroup>
                                    <col width="33%" />
                                    <col width="33%" />
                                    <col width="33%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>수탁업체</th>
                                        <th>위탁업무</th>
                                        <th>보유 및 이용기간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>NICE평가정보(주)</td>
                                        <td>본인확인</td>
                                        <td>
                                            해당 업체에서 이미 보유하고 있는 개인정보이기 때문에 별도로 저장하지 않음
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>(주)채널코퍼레이션</td>
                                        <td>챗봇 서비스</td>
                                        <td>
                                            서비스의 제공 목적이 달성된 후 파기
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>5. 개인정보 보유 및 이용기간</h3>
                        <div className='text'>회사는 원칙적으로 이용자의 개인정보 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 다음의 경우는 예외로 합니다.</div>
                        <div className='table-box'>
                            <table>
                                <colgroup>
                                    <col width="33%" />
                                    <col width="33%" />
                                    <col width="33%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>보존 근거</th>
                                        <th>보존 기록</th>
                                        <th>보유 기간</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>통신비밀보호법</td>
                                        <td>서비스 이용 관련 개인정보(로그인기록)</td>
                                        <td>
                                            3개월
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                        <td>서비스 이용 관련 개인정보(로그인기록)</td>
                                        <td>
                                            5년
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                        <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                        <td>
                                            5년
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                                        <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                        <td>
                                            3년
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>회사 내부 방침</td>
                                        <td>부정이용기록(불량 혹은 비정상 이용기록): 휴대전화번호, 이메일주소, IP, 로그기록</td>
                                        <td>
                                            1년
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='box'>
                        <h3>6. 개인정보 파기절차 및 방법</h3>
                        <div className='text'>회사는 원칙적으로 개인정보 처리목적이 달성된 경우 지체 없이 해당 개인정보를 파기하며, 파기절차 및 파기방법은 다음과 같습니다.</div>
                        <ol>
                            <li>파기절차 이용자가 회원가입 등을 위해 입력한 정보는 수집 목적이 달성된 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우가 외에는 다른 목적으로 이용되지 않습니다.</li>
                            <li>
                                파기방법
                                <ul>
                                    <li>a. 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
                                    <li>b. 종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>7. 이용자 및 법정대리인의 권리</h3>
                        <div className='text'>이용자 또는 법정대리인은 회사에 대해 언제든지 자신 혹은 만 14세 미만 아동의 개인정보 보호 관련 권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 회사의 개인정보 처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.</div>
                        <ol>
                            <li>개인정보 조회, 수정을 위해서는 “개인정보변경” (또는 '회원정보수정' 등)을, 회원탈퇴를 위해서는 웹사이트에서 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</li>
                            <li>혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</li>
                            <li>이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
                            <li>회사는 이용자 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>8. 쿠키의 운영 및 수집 거부 방법</h3>
                        <ol>
                            <li>회사는 이용자에게 맞춤형 서비스를 제공하고, 보다 신속한 서비스 제공을 위해 이용자에 대한 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다. 쿠키란 웹사이트 방문 시, 서버가 이용자의 하드디스크에 저장하는 작은 기록 정보 파일을 말합니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 개인을 식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을 거부하는 경우에는 일부 서비스 이용이 어려울 수 있습니다.</li>
                            <li>
                                쿠키의 설치 / 운용 및 거부 방법 안내 이용자는 사용하는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용 혹은 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 지정할 수 있습니다.
                                <ul>
                                    <li>a. Internet Explorer 의 경우 [도구]메뉴에서 [인터넷 옵션]을 선택합니다. [개인정보 탭]에서 설정합니다. [개인정보취급 수준]을 설정합니다.</li>
                                    <li>b. Chrome의 경우 [설정] 하단 [고급설정 표시]를 클릭합니다. [개인정보] 콘텐츠 설정 -&gt; [쿠키] 영역에서 원하시는 정책을 선택합니다.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>9. 개인정보 보호를 위한 기술적∙관리적 보호 대책</h3>
                        <div className="text">”회사”는 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적 노력을 하고 있습니다.</div>
                        <ol>
                            <li>개인정보 암호화 이용자의 비밀번호 등 중요정보는 암호화되어 저장, 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만 가능합니다.</li>
                            <li>해킹 등에 대비한 대책회사는 해킹이나 악성코드에 의하여 이용자들의 개인정보가 유출∙훼손되는 것을 방지하기 위하여 침입차단 시스템을 24시간 운영하여 외부로부터의 무단접근을 통제하고 있으며, 백신 프로그램을 설치하여 시스템이 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 암호화통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 더불어 회사는 더욱 안전한 개인정보 취급을 위해 최대한의 기술적 방법을 구비하기 위해 노력하고 있습니다.</li>
                            <li>개인정보 취급 직원의 최소화 및 교육회사는 개인정보를 취급하는 직원을 최소한으로 제한하고 있으며, 관련 직원들에 대한 수시 교육을 실시하여 개인정보처리방침의 중요성을 인지시키고 있습니다.</li>
                            <li>개인정보보호전담조직의 운영개인정보 보호를 위해 개인정보보호전담부서를 운영하고 있으며, 개인정보처리방침의 이행사항 및 개인정보 취급자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>10. 개인정보보호최고책임자 및 관리자의 연락처</h3>
                        <div className='text'>이용자는 회사의 서비스를 이용하는 중 발생하는 모든 개인정보 관련 문의, 불만처리 등에 관한 사항을 개인정보 관리 책임자 혹은 담당부서로 문의할 수 있습니다. 회사는 이용자의 문의에 대한 신속하고 성실한 답변 및 처리를 위해 노력하고 있습니다.</div>
                        <br />
                        <div className='text'>[개인정보보호최고책임자] 양태영 : 070) 7704-7720 이메일주소 : piaar.techteam@gmail.com</div>
                        <div className='text'>[개인정보보호관리자] 박세훈 : 070) 7704-7720 이메일주소 : piaar.techteam@gmail.com</div>
                        <br />
                        <div className='text'>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.</div>
                        <ol>
                            <li>개인정보침해신고센터, 개인정보 분쟁조정위원회 (privacy.kisa.or.kr / 국번없이 118)</li>
                            <li>대검찰청 사이버수사과 (www.spo.go.kr / 02-3480-3571)</li>
                            <li>경찰청 사이버테러대응센터 (www.ctrc.go.kr / 1566-0112)</li>
                        </ol>
                    </div>
                    <div className='box'>
                        <h3>11. 링크 사이트에 대한 책임</h3>
                        <div className='text'>회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나 링크 웹사이트들은 회사의 개인정보처리방침이 적용되지 않으므로, 해당 링크를 통해 외부 웹사이트로 이동하시는 경우, 해당 서비스의 정책을 검토하시기 바랍니다.</div>
                    </div>
                    <div className='box'>
                        <h3>12. 개인정보처리방침의 고지 의무</h3>
                        <div className='text'>회사는 개인정보처리방침에 대한 변경이 있을 경우에는 개정 개인정보처리방침의 시행일로부터 최소 7일 전에 홈페이지의 공지사항 또는 이메일을 통해 고지합니다.</div>
                    </div>
                    <div className='box'>
                        <h3>13. 개정이력</h3>
                        <div className='text'>개인정보처리방침 시행일: 2022년 08월 15일</div>
                        <div className='text'>개인정보처리방침 변경공고일: 2022년 08월 15일</div>
                    </div>
                </Wrapper>
            </Container>
        </>
    );
}