import styled from 'styled-components';

export const Container = styled.div`
    position:relative;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 30px 0;
    align-items:center;

    @media all and (max-width: 992px){
        padding: 10px 0;
    }
`;

export const Title = styled.div`
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-indent: 0.1em;

    @media all and (max-width: 992px){
        padding: 10px 10px;
        font-size: 20px;
    }
`;